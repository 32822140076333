import { Dictionary } from '../../../../utils/languages';

const translations = {
  'LP Equity': {
    ENG: 'LP Equity'
  },
  'Total Collateral': {
    ENG: 'Total Collateral'
  },
  'Total Debt': {
    ENG: 'Total Debt'
  },
  'Current Leverage': {
    ENG: 'Current Leverage'
  },
  'Liquidation Prices': {
    ENG: 'Liquidation Prices (TWAP)'
  },
  Deposit: {
    ENG: 'Deposit'
  },
  Deposited: {
    ENG: 'Deposited'
  },
  Withdraw: {
    ENG: 'Withdraw'
  },
  Supply: {
    ENG: 'Supply'
  },
  Supplied: {
    ENG: 'Supplied'
  },
  Leverage: {
    ENG: 'Leverage'
  },
  Deleverage: {
    ENG: 'Deleverage'
  },
  Obtain: {
    ENG: 'Obtain'
  },
  Borrow: {
    ENG: 'Borrow'
  },
  Borrowed: {
    ENG: 'Borrowed'
  },
  Repay: {
    ENG: 'Repay'
  },
  'Supply Balance': {
    ENG: 'Supply Balance'
  },
  'Net APR': {
    ENG: 'Net APR'
  },
  'Expected monthly profit': {
    ENG: 'Expected monthly profit'
  },
  'Expected yearly profit': {
    ENG: 'Expected yearly profit'
  }
} as Dictionary;

export default translations;
