import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import InputBigAmount from 'components/InputBigAmount';
import { parseUnits } from 'ethers/lib/utils';
import { useApproveTowerStake } from 'hooks/useApprove';
import useTowerStake from 'hooks/useTowerStake';
import { useState } from 'react';
import { InteractionModalContainer } from '.';
import { useTokenBalance } from '../../hooks/useData';
import InteractionButton from '../InteractionButton';

/**
 * Props for the stake interaction modal.
 * @property show Shows or hides the modal.
 * @property toggleShow A function to update the show variable to show or hide the Modal.
 */
export interface TowerStakeInteractionModalProps {
  show: boolean;
  toggleShow(s: boolean): void;
  towerPoolAddress: string;
  stakeAddress: string;
  stakeSymbol: string;
  title?: string;
  message?: string | JSX.Element;
  actionButtonLabel?: string;
}

export default function TowerStakeInteractionModal({ show, toggleShow, towerPoolAddress, stakeAddress, stakeSymbol, title, message, actionButtonLabel = 'Stake' }: TowerStakeInteractionModalProps): JSX.Element {
  const [val, setVal] = useState<string>('0');

  const { chainId } = useWeb3React<Web3Provider>();

  if (!chainId) {
    throw new Error('Invalid chain ID!');
  }
  const { amount: bigAmount, decimals } = useTokenBalance(stakeAddress);

  const max = bigAmount;

  const amount = parseUnits(val, decimals);
  const invalidInput = amount.gt(bigAmount) || amount.eq(0);
  const [approvalState, onApprove] = useApproveTowerStake(amount, towerPoolAddress, stakeAddress, invalidInput);
  const [stakeState, stake] = useTowerStake(approvalState, towerPoolAddress, amount, decimals, invalidInput);

  const onStake = async () => {
    await stake();
    setVal('0');
    toggleShow(false);
  };

  return (
    <InteractionModalContainer
      title={title || 'Stake'}
      show={show}
      toggleShow={toggleShow}>
      <>
        {message && <div className='text-xs sm:text-sm text-textSecondary'>{message}</div>}
        <InputBigAmount
          val={val}
          setVal={setVal}
          suffix={stakeSymbol}
          availableSuffix={`${stakeSymbol}\u00A0`}
          availableTitle='Available'
          available={bigAmount}
          max={max}
          decimals={decimals} />
        <div className='mt-4 flex justify-between'>
          <InteractionButton
            name='Approve'
            onCall={onApprove}
            state={approvalState} />
          <InteractionButton
            name={actionButtonLabel}
            onCall={onStake}
            state={stakeState} />
        </div>
      </>
    </InteractionModalContainer>
  );
}
