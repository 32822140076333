
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as TAROT } from 'assets/images/icons/tarot-logo.svg';
import clsx from 'clsx';
import InputBigAmount from 'components/InputBigAmount';
import InteractionButton from 'components/InteractionButton';
import ChainDestModal from 'components/InteractionModal/ChainDestModal';
import ChainModal from 'components/InteractionModal/ChainModal';
import QuestionHelper from 'components/QuestionHelper';
import TarotImage from 'components/UI/TarotImage';
import { ACTIVE_CHAINS, CHAIN_DETAILS, CHAIN_ICON_PATHS, CHAIN_LABELS } from 'config/web3/chains';
import { TAROT_V2_ADDRESSES } from 'config/web3/contracts/tarot';
import { parseUnits } from 'ethers/lib/utils';
import useBridge from 'hooks/useBridge';
import { useBridgeStatus, useTokenBalance } from 'hooks/useData';
import useTarotRouter from 'hooks/useTarotRouter';
import { useState } from 'react';
import { parse18 } from 'utils/big-amount';
import { formatToDecimals } from 'utils/format';

const BridgeContent = (): JSX.Element | null => {
  const tarotRouter = useTarotRouter();
  const chainId = tarotRouter.chainId;
  const [chainModalOpen, setChainModalOpen] = useState(false);
  const [chainDestModalOpen, setChainDestModalOpen] = useState(false);
  const [destChain, setDestChain] = useState<number>(ACTIVE_CHAINS.filter(c => c !== chainId)[0]);
  const [val, setVal] = useState<string>('0');

  const handleChainModalOpen = () => {
    setChainModalOpen(true);
  };
  const handleChainDestModalOpen = () => {
    setChainDestModalOpen(true);
  };

  const { amount: bigAmount, decimals } = useTokenBalance(TAROT_V2_ADDRESSES[chainId]);

  const max = bigAmount;
  const amount = parseUnits(val, decimals);
  const invalidInput = amount.gt(bigAmount) || amount.eq(0);
  const [bridgeState, bridge] = useBridge(amount, destChain, invalidInput);
  const bridgeStatus = useBridgeStatus(destChain, amount);

  const onBridge = async () => {
    await bridge();
    setVal('0');
  };

  return (
    <div className='mt-6 space-y-3 md:mx-4 mb-12'>
      <div
        className={clsx(
          'flex',
          'flex-col',
          'md:flex-row',
          'space-y-8',
          'space-x-0',
          'md:space-x-8',
          'md:space-y-0',
          'justify-around')}>
        <div className='self-center flex flex-col p-2 space-y-4 mb-0 w-full xs:mb-0 xs:max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg'>
          <div className='flex flex-col justify-around !mt-0 !mb-4'>
            <div className='flex flex-col items-center'>
              <div className='combined z-10 mb-4'>
                <TAROT
                  className={clsx(
                    'absolute',
                    'opacity-50',
                    'animate-ping-slow-once',
                    'w-24',
                    'h-24'
                  )} />
                <div
                  className={clsx(
                    'absolute',
                    'animate-fade-in',
                    'w-24',
                    'h-24'
                  )}>
                  <div
                    className={clsx(
                      'absolute')}>
                    <TAROT
                      className={clsx(
                        'absolute',
                        'w-24',
                        'h-24'
                      )} />
                  </div>
                </div>
                <TAROT
                  className={clsx(
                    'animate-fade-out',
                    'w-24',
                    'h-24'
                  )} />
              </div>
              <div className='text-xl sm:text-2xl text-textPrimary font-semibold'>Tarot Bridge</div>
              <div className='!mb-8 text-lg text-textSecondary italic'>Bridge TAROT Across Chains</div>

              {tarotRouter.account ? (
                <>
                  <div
                    className={clsx(
                      'p-0',
                      'sm:p-2'
                    )}>
                    <div className='flex flex-col items-center justify-start space-y-6 my-6 sm:my-2'>
                      <div className='-mb-2'>Select Networks</div>
                      <div className='flex space-x-4 items-center mb-4'>
                        <div
                          className='inline-block self-center cursor-pointer'
                          onClick={handleChainModalOpen}
                          title='Source Chain'>
                          <TarotImage
                            width={42}
                            height={42}
                            className={clsx(
                              'inline-block'
                            )}
                            src={CHAIN_ICON_PATHS[chainId || -1]}
                            placeholder='/assets/images/default.png'
                            error='/assets/images/default.png'
                            alt={CHAIN_LABELS[chainId || -1]} />
                        </div>
                        <FontAwesomeIcon
                          className='text-tarotJade-200'
                          icon={faChevronRight} />
                        <div
                          className='flex self-center cursor-pointer'
                          onClick={handleChainDestModalOpen}
                          title='Destination'>
                          <TarotImage
                            width={42}
                            height={42}
                            className={clsx(
                              'inline-block'
                            )}
                            src={CHAIN_ICON_PATHS[destChain || -1]}
                            placeholder='/assets/images/default.png'
                            error='/assets/images/default.png'
                            alt={CHAIN_LABELS[destChain || -1]} />
                        </div>
                      </div>
                      <div className='w-full space-y-4 xl:w-96 flex flex-col justify-center'>
                        <InputBigAmount
                          val={val}
                          setVal={setVal}
                          suffix='TAROT'
                          availableSuffix={`TAROT\u00A0`}
                          availableTitle='Available'
                          available={bigAmount}
                          max={max}
                          decimals={decimals} />
                        <div className='text-center'>Bridge from {CHAIN_LABELS[chainId]} to {CHAIN_LABELS[destChain]}{bridgeStatus.pathAvailable ? '' : ' is currently unavailable.'}</div>
                        {bridgeStatus.nativeFee && (
                          <div className='flex justify-center space-x-2 items-center'>
                            <div className='text-center'>Fee: ~{formatToDecimals(parse18(bridgeStatus.nativeFee), 6)} {CHAIN_DETAILS[chainId].nativeCurrency.symbol}</div>
                            <QuestionHelper
                              text={`The fee for the transaction on ${CHAIN_LABELS[destChain]} is collected on ${CHAIN_LABELS[chainId]}.`} />
                          </div>
                        )}
                        <div className='flex w-full pt-2'>
                          <div className='flex-grow'></div>
                          <InteractionButton
                            name='Bridge'
                            onCall={onBridge}
                            state={bridgeState} />
                          <div className='flex-grow'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : <div>Connect wallet to bridge TAROT</div>}
            </div>
          </div>
        </div>
      </div>
      {chainId &&
      <>
        <ChainModal
          show={chainModalOpen}
          toggleShow={setChainModalOpen} />
        <ChainDestModal
          setDestChain={setDestChain}
          show={chainDestModalOpen}
          toggleShow={setChainDestModalOpen} />
      </>
      }
    </div>
  );
};

const Bridge = (): JSX.Element | null => {
  return <BridgeContent />;
};

export default Bridge;
