
import { Web3Provider } from '@ethersproject/providers';
import { faChevronRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWeb3React } from '@web3-react/core';
import { ReactComponent as TAROT } from 'assets/images/icons/tarot-logo.svg';
import clsx from 'clsx';
import InteractionButton, { ButtonState } from 'components/InteractionButton';
import MigrateV2InteractionModal from 'components/InteractionModal/MigrateV2InteractionModal';
import TarotImage from 'components/UI/TarotImage';
import { CHAIN_IDS } from 'config/web3/chains';
import { TAROT_ADDRESSES } from 'config/web3/contracts/tarot';
import { TAROT_MIGRATOR_ADDRESSES } from 'config/web3/contracts/tarot-migrators';
import { getAddress } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { PAGES } from 'utils/constants/links';

interface Params {
  mini: boolean;
}

const MigrateContent = ({ mini }: Params): JSX.Element | null => {
  const { chainId: web3ChainId } = useWeb3React<Web3Provider>();
  const [showMigrateV2Modal, toggleMigrateV2Modal] = useState(false);

  const periodBegin = 1701388800;
  const periodEnd = 1704067200;

  const calculateStarted = () => {
    return new Date().getTime() / 1000 >= periodBegin;
  };

  const calculateTimeLeft = () => {
    if (calculateStarted()) {
      return Math.max(0, periodEnd - Math.floor(new Date().getTime() / 1000));
    } else {
      return Math.max(0, periodBegin - Math.floor(new Date().getTime() / 1000));
    }
  };

  const padDHMS = (t: number) => {
    return `${t < 10 ? '0' : ''}${t}`;
  };

  const formatSecondsAsDHMS = (t: number) => {
    let n = t;
    const days = Math.floor(n / (24 * 3600));
    n %= (24 * 3600);
    const hours = Math.floor(n / 3600);
    n %= 3600;
    const minutes = Math.floor(n / 60);
    n %= 60;
    const seconds = n;
    return (
      <>
        <span>{padDHMS(days)}</span>
        <span className='text-base'>D:</span>
        <span>{padDHMS(hours)}</span>
        <span className='text-base'>H:</span>
        <span>{padDHMS(minutes)}</span>
        <span className='text-base'>M:</span>
        <span>{padDHMS(seconds)}</span>
        <span className='text-base'>S</span>
      </>);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [started, setStarted] = useState(calculateStarted());

  useEffect(() => {
    const timer = setTimeout(() => {
      setStarted(calculateStarted());
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  if (mini) {
    return (
      <>
        <div className='w-full flex p-2'>
          <div className='flex flex-col w-full items-center rounded-xl justify-center p-2 py-4 border border-tarotMistyRose-700 bg-tarotMistyRose transform transform-gpu bg-opacity-50'>
            <TarotImage
              className={clsx(
                'w-10',
                'h-10',
                'mb-2'
              )}
              src={`/assets/images/token-icons/${getAddress(TAROT_ADDRESSES[CHAIN_IDS.FANTOM])}.png`}
              placeholder='/assets/images/default.png'
              error='/assets/images/default.png'
              alt='TAROT' />
            <div className='text-xl font-semibold mb-1 text-textPrimary'>TAROT II</div>
            {started && timeLeft > 0 && <div className='text-base text-textPrimary mb-3'>MIGRATION IS LIVE</div>}
            <div className='flex flex-col items-center justify-start w-full'>
              {started && timeLeft <= 0 && <div className='text-base font-medium text-textSecondary'>The migration deadline has passed.</div>}
              {!started && <div className='text-base font-medium text-textSecondary uppercase'>Starts in</div>}
              {started && timeLeft > 0 && <div className='text-base font-medium text-textSecondary uppercase'>Time remaining</div>}
              {timeLeft > 0 && <span className='font-medium text-lg text-textSecondary'>{formatSecondsAsDHMS(timeLeft)}</span>}
            </div>
            <div className='my-2 flex space-x-4'>
              {started && timeLeft > 0 &&
              <div>
                <NavLink
                  key='Migrate'
                  to={PAGES.MIGRATE}
                  className={clsx(
                    'inline-flex',
                    'items-center',
                    'px-1',
                    'pt-1',
                    'border-b-2',
                    'text-sm',
                    'xl:text-base',
                    'font-medium',
                    'filter',
                    'brightness-150'
                  )}>
                          Migrate
                </NavLink>
              </div>
              }
              <div
                className={clsx(
                  'inline-flex',
                  'items-center',
                  'px-1',
                  'pt-1',
                  'border-b-2',
                  'text-sm',
                  'xl:text-base',
                  'font-medium',
                  'filter',
                  'brightness-150'
                )}>
                <a
                  target='_blank'
                  href='https://tarotfinance.medium.com/tarot-ii-the-migration-continues-2175d3ea1f6b'
                  rel='noopener noreferrer'>
                Learn More <FontAwesomeIcon
                    icon={faExternalLinkAlt} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className='mt-6 space-y-3 md:mx-4 mb-12'>
      <div
        className={clsx(
          'flex',
          'flex-col',
          'md:flex-row',
          'space-y-8',
          'space-x-0',
          'md:space-x-8',
          'md:space-y-0',
          'justify-around')}>
        <div className='self-center flex flex-col p-2 space-y-4 mb-0 w-full xs:mb-0 xs:max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg'>
          <div className='flex flex-col justify-around !mt-0 !mb-4'>
            <div className='flex flex-col items-center'>
              <div className='combined z-10 mb-4'>
                <TAROT
                  className={clsx(
                    'absolute',
                    'opacity-50',
                    'animate-ping-slow-once',
                    'w-24',
                    'h-24'
                  )} />
                <div
                  className={clsx(
                    'absolute',
                    'animate-fade-in',
                    'w-24',
                    'h-24'
                  )}>
                  <div
                    className={clsx(
                      'absolute')}>
                    <TAROT
                      className={clsx(
                        'absolute',
                        'w-24',
                        'h-24'
                      )} />
                  </div>
                </div>
                <TAROT
                  className={clsx(
                    'animate-fade-out',
                    'w-24',
                    'h-24'
                  )} />
              </div>
              <div className='text-xl sm:text-2xl text-textPrimary font-semibold'>TAROT II</div>
              <div className='!mb-8 text-lg text-textSecondary italic'>The Legend Continues</div>

              <>
                <div
                  className={clsx(
                    'p-0',
                    'sm:p-6',
                    'overflow-hidden',
                    'bg-tarotBlackHaze-800',
                    'shadow',
                    'rounded-xl',
                    'border',
                    'border-tarotBlackHaze-300',
                    'hover:shadow-xl',
                    'hover:bg-tarotBlackHaze-850',
                    'md:filter',
                    'md:saturate-75',
                    'hover:saturate-100',
                    'transition-all',
                    'duration-500',
                    'h-full',
                    'mb-8'
                  )}>
                  <div className='flex flex-col items-center justify-start space-y-6 my-6 sm:my-2'>
                    <div className='flex space-x-4 items-center'>
                      <TarotImage
                        className={clsx(
                          'w-10',
                          'h-10'
                        )}
                        src={`/assets/images/token-icons/${getAddress(TAROT_ADDRESSES[CHAIN_IDS.FANTOM])}.png`}
                        placeholder='/assets/images/default.png'
                        error='/assets/images/default.png'
                        alt='TAROT' />
                      <FontAwesomeIcon
                        className='text-tarotJade-200'
                        icon={faChevronRight} />
                      <TarotImage
                        className={clsx(
                          'w-10',
                          'h-10'
                        )}
                        src={`/assets/images/token-icons/${getAddress(TAROT_ADDRESSES[CHAIN_IDS.FANTOM])}.png`}
                        placeholder='/assets/images/default.png'
                        error='/assets/images/default.png'
                        alt='TAROT' />
                    </div>
                    <div className='flex flex-row sm:bg-tarotBlackHaze sm:border items-center border-tarotBlackHaze-400 rounded-lg p-4 sm:p-8 space-x-4 mt-2 mb-2 sm:w-full'>
                      <div className='text-center text-sm text-textSecondary'>Migrate your TAROT to <b>TAROT v2</b>, the new token that powers the omnichain future of Tarot.</div>
                    </div>
                    <div className='flex flex-col items-center justify-start w-full'>
                      {started && timeLeft <= 0 && <div className='text-base font-semibold text-textSecondary'>The migration deadline has passed.</div>}
                      {!started && <div className='text-base font-semibold text-textSecondary uppercase'>Starts in</div>}
                      {started && timeLeft > 0 && <div className='text-base font-semibold text-textSecondary uppercase'>Time remaining</div>}
                      {timeLeft > 0 && <span className='font-semibold text-lg text-textSecondary'>{formatSecondsAsDHMS(timeLeft)}</span>}
                    </div>
                    {web3ChainId && TAROT_MIGRATOR_ADDRESSES[web3ChainId as any] && started && timeLeft > 0 &&
                    <InteractionButton
                      className='text-sm xs:text-base sm:text-lg'
                      name='Migrate'
                      onCall={() => toggleMigrateV2Modal(true)}
                      state={ButtonState.Ready} />

                    }
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {web3ChainId && TAROT_MIGRATOR_ADDRESSES[web3ChainId as any] &&
      <MigrateV2InteractionModal
        show={showMigrateV2Modal}
        toggleShow={toggleMigrateV2Modal} />
      }
    </div>
  );
};

const Migrate = (params: Params): JSX.Element | null => {
  return <MigrateContent {...params} />;
};

export default Migrate;
