import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import clsx from 'clsx';
import TarotImage from 'components/UI/TarotImage';
import { ACTIVE_BRIDGE_CHAINS, CHAIN_ICON_PATHS, CHAIN_LABELS } from 'config/web3/chains';
import { InteractionModalContainer } from '.';

export interface ChainDestModalProps {
  show: boolean;
  toggleShow(s: boolean): void;
  setDestChain(chainId: number): void;
}

export default function ChainDestModal({ show, toggleShow, setDestChain }: ChainDestModalProps): JSX.Element {
  const { chainId } = useWeb3React<Web3Provider>();

  const selectChain = (c: number) => {
    setDestChain(c);
    toggleShow(false);
  };

  return (
    <InteractionModalContainer
      title='Select Destination'
      show={show}
      toggleShow={toggleShow}
      wide={true}>
      <>
        <div className='p-4'>
          <div className={clsx('mt-2 mb-2 grid grid-cols-3 portrait:grid-cols-2 items-center justify-center')}>
            {ACTIVE_BRIDGE_CHAINS.filter(c => c !== chainId).map(c => (
              <div
                key={c}
                onClick={() => selectChain(c)}
                className={clsx(
                  'flex',
                  'cursor-pointer',
                  'hover:bg-tarotBlackHaze-700',
                  'rounded-lg',
                  'flex-col',
                  'space-y-2',
                  'items-center',
                  'm-2',
                  'p-4'
                )}>

                <TarotImage
                  width={32}
                  height={32}
                  className={clsx(
                    'inline-block'
                  )}
                  src={CHAIN_ICON_PATHS[c || -1]}
                  placeholder='/assets/images/default.png'
                  error='/assets/images/default.png'
                  alt={CHAIN_LABELS[c || -1]} />
                <div>{CHAIN_LABELS[c]}</div>
              </div>
            ))}
          </div>
        </div>
      </>
    </InteractionModalContainer>
  );
}
