
import { CHAIN_IDS } from 'config/web3/chains';

const TOWER_POOL_FACTORY_ADDRESSES: {
  [chainId: number]: string[];
} = {
  [CHAIN_IDS.ARBITRUM]: ['0x27EEf135Dbc00e5c3CDF5658d690d4c05DCa1b14'],
  [CHAIN_IDS.OPTIMISM]: ['0x8d92B5f12c1BEBA3583861e9c3067d088Bc2ff05', '0x8F530176932fEB8F9520ec6B4C4051A9b47dFe50'],
  [CHAIN_IDS.ZKSYNC]: ['0xA1f58B384FD3983E7BEdD590701F3D2f7970C4ED'],
  [CHAIN_IDS.BASE]: ['0xB0d74D24Ae94985c614A52d79d1BDEc0A6F57bEE'],
  [CHAIN_IDS.LINEA]: ['0x46fcDe1b89D61f5cBfAaB05C2914C367F8301F30'],
  [CHAIN_IDS.SCROLL]: ['0x283e62CFe14b352dB8e30A9575481DCbf589Ad98']
};

export {
  TOWER_POOL_FACTORY_ADDRESSES
};
