import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import InputBigAmount from 'components/InputBigAmount';
import { parseUnits } from 'ethers/lib/utils';
import { useApproveMigrateV2 } from 'hooks/useApprove';
import { useState } from 'react';
import { InteractionModalContainer } from '.';
import { useTokenBalance } from '../../hooks/useData';
import InteractionButton from '../InteractionButton';
import { TAROT_ADDRESSES } from 'config/web3/contracts/tarot';
import useMigrateV2 from 'hooks/useMigrateV2';

/**
 * Props for the mint interaction modal.
 * @property show Shows or hides the modal.
 * @property toggleShow A function to update the show variable to show or hide the Modal.
 */
export interface MigrateV2InteractionModalProps {
  show: boolean;
  toggleShow(s: boolean): void;
}

export default function MigrateV2InteractionModal({ show, toggleShow }: MigrateV2InteractionModalProps): JSX.Element {
  const [val, setVal] = useState<string>('0');

  const { chainId } = useWeb3React<Web3Provider>();

  if (!chainId) {
    throw new Error('Invalid chain ID!');
  }
  const { amount: bigAmount, decimals } = useTokenBalance(TAROT_ADDRESSES[chainId]);

  const max = bigAmount;
  const amount = parseUnits(val, decimals);
  const invalidInput = amount.gt(bigAmount) || amount.eq(0);
  const [approvalState, onApprove] = useApproveMigrateV2(amount, invalidInput);
  const [migrateV2State, migrateV2] = useMigrateV2(approvalState, amount, invalidInput);

  const onMigrateV2 = async () => {
    await migrateV2();
    setVal('0');
    toggleShow(false);
  };

  return (
    <InteractionModalContainer
      title='Migrate to TAROT v2'
      show={show}
      toggleShow={toggleShow}>
      <>
        <InputBigAmount
          val={val}
          setVal={setVal}
          suffix='TAROT v1'
          availableSuffix={`TAROT v1\u00A0`}
          availableTitle='Available'
          available={bigAmount}
          max={max}
          decimals={decimals} />
        <div className='mt-4 flex justify-between'>
          <InteractionButton
            name='Approve'
            onCall={onApprove}
            state={approvalState} />
          <InteractionButton
            name='Migrate'
            onCall={onMigrateV2}
            state={migrateV2State} />
        </div>
      </>
    </InteractionModalContainer>
  );
}
