
import { ReactComponent as SpinIcon } from 'assets/images/icons/spin.svg';
import clsx from 'clsx';
import TowerPoolCard from 'components/TowerPoolCard';
import TarotImage from 'components/UI/TarotImage';
import useAccount from 'hooks/useAccount';
import { useTowerPools } from 'hooks/useData';
import { LAYOUT } from 'utils/constants/styles';

const TowerContent = (): JSX.Element | null => {
  const account = useAccount();
  const towerPools = useTowerPools(account) as any;
  if (!towerPools) {
    return (
      <div
        className='z-tarotAppBar bg-tarotBlackHaze fixed left-0 min-w-full flex justify-center items-center'
        style={{ top: `${LAYOUT.appBarHeight}px`, height: `calc(100% - ${LAYOUT.appBarHeight}px)` }}>
        <SpinIcon
          className={clsx(
            'animate-spin',
            'w-8',
            'h-8',
            'text-tarotJade-200',
            'filter',
            'brightness-150'
          )} />
      </div>
    );
  }
  return (
    <div className='mt-1 space-y-3 md:mx-4 mb-12'>
      <div
        className={clsx(
          'flex',
          'flex-col',
          'md:flex-row',
          'space-y-8',
          'space-x-0',
          'md:space-x-8',
          'md:space-y-0',
          'justify-around')}>
        <div className='self-center flex flex-col p-2 space-y-4 mb-0 w-full'>
          <div className='flex flex-col justify-around !mt-0 !mb-4'>
            <div className='flex flex-col items-center'>
              <TarotImage
                src='/the-tower.png'
                className='w-56' />
            </div>
          </div>
        </div>
      </div>
      <div className='w-full xl:px-24'>
        {towerPools.length === 0 ? <div className='self-center flex items-center justify-center'>Coming Soon</div> :
          <div
            className={clsx(
              'grid',
              'grid-cols-1',
              'lg:grid-cols-2',
              'gap-x-8',
              'gap-y-8',
              'auto-rows-fr')}>
            {towerPools.map((towerPoolInfo: { lendingPool: any, rewardInfoList: any[]; earnedList: { [x: string]: any; }; }, i: number) =>
              <div
                key={`t-${i}`}
                className={clsx(
                  'p-6',
                  'overflow-hidden',
                  'bg-tarotBlackHaze-750',
                  'shadow',
                  'rounded-xl',
                  'border',
                  'border-tarotLilac-800',
                  'hover:shadow-xl',
                  'hover:bg-tarotBlackHaze-850',
                  'md:filter',
                  'md:saturate-75',
                  'hover:saturate-100',
                  'transition-all',
                  'duration-350',
                  'h-full'
                )}>
                <TowerPoolCard
                  towerPoolInfo={towerPoolInfo}
                  isDashboard={false} />
              </div>
            )
            }
          </div>
        }
      </div>
    </div>
  );
};

const Tower = (): JSX.Element | null => {
  return <TowerContent />;
};

export default Tower;
