import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { RadioGroup } from '@headlessui/react';
import { InteractionModalContainer } from '.';
import { useEffect, useState } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/local-storage';

/**
 * Props for the connect wallet modal.
 * @property show Shows or hides the modal.
 * @property toggleShow A function to update the show variable to show or hide the Modal.
 */
export interface RpcModalProps {
  show: boolean;
  toggleShow(s: boolean): void;
}

/**
 * Styled component for the norrow modal.
 * @param param0 any Props for component
 * @see RpcModalProps
 */

export default function RpcModal({ show, toggleShow }: RpcModalProps): JSX.Element {
  const { active } = useWeb3React<Web3Provider>();
  const [rpc, setRpc] = useState('default');
  const [iddp, setIddp] = useState('default');

  const handleSetRpc = (value: string) => {
    setLocalStorageItem('rpc', value, -1);
    setRpc(value);
    if (active) {
      window.location.reload();
    }
  };

  const handleSetIddp = (value: string) => {
    // When iddp changes, remove pool data from localStorage to force refetch
    const keysToRemove = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i) || '';
      if (key.includes('-')) {
        keysToRemove.push(key);
      }
    }
    keysToRemove.forEach(key => {
      localStorage.removeItem(key);
    });
    setLocalStorageItem('iddp', value, -1);
    setIddp(value);
    if (active) {
      window.location.reload();
    }
  };

  useEffect(() => {
    const rpcFromStorage = getLocalStorageItem('rpc');
    if (rpcFromStorage && rpcFromStorage !== rpc) {
      setRpc(rpcFromStorage);
    }
  }, [rpc]);

  useEffect(() => {
    const iddpFromStorage = getLocalStorageItem('iddp');
    if (iddpFromStorage && iddpFromStorage !== iddp) {
      setIddp(iddpFromStorage);
    }
  }, [iddp]);

  const styleOptionBase = 'flex cursor-pointer px-4 py-2 w-56 hover:bg-tarotBlackHaze-850 border border-transparent hover:border-tarotBlackHaze-200 space-x-4 items-center text-center self-center justify-between rounded-lg';
  const styleOptionChecked = 'bg-tarotBlackHaze-700';

  return (
    <InteractionModalContainer
      title='Configuration'
      show={show}
      toggleShow={toggleShow}>
      <div className='pt-4 pb-4 flex flex-col justify-center items-center space-y-8'>
        <p>Select the RPC to use for read requests in the Tarot UI. This setting does not affect the RPC used for submitting transactions.</p>
        <RadioGroup
          value={rpc}
          onChange={handleSetRpc}>
          <RadioGroup.Option value='default'>
            {({ checked }) => (
              <div className={`${styleOptionBase} ${checked ? styleOptionChecked : ''}`}><span>Default</span>{checked ? <FontAwesomeIcon icon={faCheck} /> : <></>}</div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value='wallet'>
            {({ checked }) => (
              <div className={`${styleOptionBase} ${checked ? styleOptionChecked : ''}`}><span>Wallet</span>{checked ? <FontAwesomeIcon icon={faCheck} /> : <></>}</div>
            )}
          </RadioGroup.Option>
        </RadioGroup>
        <p>Include deactivated and disabled pools? Note: Enabling this setting will reduce the performance of the Tarot UI.</p>
        <RadioGroup
          value={iddp}
          onChange={handleSetIddp}>
          <RadioGroup.Option value='default'>
            {({ checked }) => (
              <div className={`${styleOptionBase} ${checked ? styleOptionChecked : ''}`}><span>No</span>{checked ? <FontAwesomeIcon icon={faCheck} /> : <></>}</div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value='include'>
            {({ checked }) => (
              <div className={`${styleOptionBase} ${checked ? styleOptionChecked : ''}`}><span>Yes</span>{checked ? <FontAwesomeIcon icon={faCheck} /> : <></>}</div>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      </div>
    </InteractionModalContainer>
  );
}
