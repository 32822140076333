
import { CHAIN_IDS } from 'config/web3/chains';

const LZ_CHAIN_IDS: {
  [chainId: number]: number;
} = {
  [CHAIN_IDS.ETHEREUM_MAIN_NET]: 101,
  [CHAIN_IDS.FANTOM]: 112,
  [CHAIN_IDS.OPTIMISM]: 111,
  [CHAIN_IDS.ARBITRUM]: 110,
  [CHAIN_IDS.CANTO]: 159,
  [CHAIN_IDS.BSC]: 102,
  [CHAIN_IDS.KAVA]: 177,
  [CHAIN_IDS.AVAX]: 106,
  [CHAIN_IDS.POLYGON]: 109,
  [CHAIN_IDS.ZKSYNC]: 165,
  [CHAIN_IDS.BASE]: 184
};

export {
  LZ_CHAIN_IDS
};
