
import { CHAIN_IDS } from 'config/web3/chains';

const TAROT_MIGRATOR_ADDRESSES: {
  [chainId: number]: string;
} = {
  [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0x8f80d8B1e593f8af0c716cb84026b5B7DB66f690',
  [CHAIN_IDS.FANTOM]: '0x1e0cB1591c8d3b2dA3a006C40Df8Dc9eC64A8910',
  [CHAIN_IDS.OPTIMISM]: '0x24005ec2445103050D2D72F691dE287D9E4672A4',
  [CHAIN_IDS.ARBITRUM]: '0x07b414ad4E9b0866A30a05c6Bc38fA48F7604602',
  [CHAIN_IDS.CANTO]: '0x1b1f8089C8Ecc430aAbe341504b0d0e5BBdeC46E',
  [CHAIN_IDS.BSC]: '0x28BD427E8e32F38b90C8E903d482a67D86E5f862',
  [CHAIN_IDS.KAVA]: '0x974eb77D357Dd73a44713D17Fe291Cee4670B291',
  [CHAIN_IDS.AVAX]: '0xAF20Cb327c8d2cDDE1318B615B6965951a17dA87',
  [CHAIN_IDS.POLYGON]: '0x5ECfeC22AA950CB5A3b4fd7249DC30B2BD160f18',
  [CHAIN_IDS.ZKSYNC]: '0x6380C015dE8D2Ba5A73AcF297216C3b7e40bB67e'
};

export {
  TAROT_MIGRATOR_ADDRESSES
};
