import { BigNumberish } from 'ethers';
import { ZERO_ADDRESS } from 'utils/address';
import { DEX } from '../dexs';
import { VaultType } from './vault-details';
import { getLocalStorageItem } from 'utils/local-storage';

const LENDING_POOL_DETAILS_MAP_ROOT: {
  [pool: string]: LendingPoolDetails;
} = {
  '0xa48869049e36f8bfe0cc5cf655632626988c0140': {
    lendingPoolAddress: '0xa48869049e36f8bfe0cc5cf655632626988c0140',
    isTarotVault: false,
    dex: DEX.SUSHI,
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xa48869049e36f8bfe0cc5cf655632626988c0140',
    collateralAddress: '0xfb505abd709c3ab7b62aa21f639be3840163be05',
    borrowableAddress0: '0x65a4810e68ab2b011140b940f8edf8ee84bc141d',
    borrowableAddress1: '0x74e657267d3588d6330cf719368627f8b6f13303',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x12b42fab7821f8141a24589640c4d056946ecac6': {
    lendingPoolAddress: '0x12b42fab7821f8141a24589640c4d056946ecac6',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 0,
    symbol0: 'WFTM',
    symbol1: 'BOO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    uniswapV2PairAddress: '0xec7178f4c41f346b2721907f5cf7628e388a7a58',
    collateralAddress: '0xbfd68879c32bc0341e9ddd90c7eec85d794b7363',
    borrowableAddress0: '0xd05f23002f6d09cf7b643b69f171cc2a3eacd0b3',
    borrowableAddress1: '0x9514d6c3f62bba5cba2565fbecd437fe3a218fbb',
    farmingPoolAddress0: '0x5f0a89d57017f947f2cfef6cbacbe60a7eefd208',
    farmingPoolAddress1: '0x56d4099438a5e1a80689a78cc39cfcf4f84c8d78',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x72f6a9adcafdc150ce69bd69e3455ee0ed7d2242': {
    lendingPoolAddress: '0x72f6a9adcafdc150ce69bd69e3455ee0ed7d2242',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 1,
    symbol0: 'WFTM',
    symbol1: 'SPIRIT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
    uniswapV2PairAddress: '0x30748322b6e34545dbe0788c421886aeb5297789',
    collateralAddress: '0x22886046e637a1e77d5d100280c838217d85cdc1',
    borrowableAddress0: '0x93a97db4fea1d053c31f0b658b0b87f4b38e105d',
    borrowableAddress1: '0x8d45f1496bf8a5f291dde3b40ac83a950a27a055',
    farmingPoolAddress0: '0x14de3e43674852e09091c0ae4ad38583c869aa8f',
    farmingPoolAddress1: '0xdb433573e93b4b4c717d64e93dbc7677dbc3f1f7',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xde203f137ef23aff18f051addc36624b5a0ef0c2': {
    lendingPoolAddress: '0xde203f137ef23aff18f051addc36624b5a0ef0c2',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 4,
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xe7e90f5a767406eff87fdad7eb07ef407922ec1d',
    collateralAddress: '0x70d3efe42f8934fa670652d0052a9e7b1eee7cd1',
    borrowableAddress0: '0x710675a9c8509d3df254792c548555d3d0a69494',
    borrowableAddress1: '0x6e11aad63d11234024efb6f7be345d1d5b8a8f38',
    farmingPoolAddress0: '0x0ec8d2cbddf9d17b4d7c770637d739e48035251a',
    farmingPoolAddress1: '0x08ddd4b74c5692f0b07cda177ba33962db21c761',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x5642b215b15d36194e90bbf217e6b7014a8965ce': {
    lendingPoolAddress: '0x5642b215b15d36194e90bbf217e6b7014a8965ce',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 2,
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    collateralAddress: '0x64c3f581849068beb54fdebe3e05288b309ef447',
    borrowableAddress0: '0xb7fa3710a69487f37ae91d74be55578d1353f9df',
    borrowableAddress1: '0xff0bc3c7df0c247e5ce1ea220c7095ce1b6dc745',
    farmingPoolAddress0: '0xd64913a182571ccc7f836060bb0b5f8e9eb4ba1b',
    farmingPoolAddress1: '0xfaa719314b0dd7595656e0f7358406f7d11bd9bb',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x3e0c11024e364026046d0072f95ff898fbd7da3e': {
    lendingPoolAddress: '0x3e0c11024e364026046d0072f95ff898fbd7da3e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 5,
    symbol0: 'WFTM',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xf0702249f4d3a25cd3ded7859a165693685ab577',
    collateralAddress: '0xc8b2935b6d078a9118d24166a0ec1d200922ee8d',
    borrowableAddress0: '0x845b1619eb0c7c0f9bc7d5494a0b332f6d8fd4f6',
    borrowableAddress1: '0xcda31b40671229776a5f9aedb6bbfc7e7a62eae6',
    farmingPoolAddress0: '0xf3e24e2a927f12a26b8fe52f7f5d3f86afced789',
    farmingPoolAddress1: '0x4d604b3eb4bbce50efea0432cb3f5b3490153be8',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xcf9f94adcf137e7398e65a0c8a3b6caf127f760e': {
    lendingPoolAddress: '0xcf9f94adcf137e7398e65a0c8a3b6caf127f760e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 4,
    symbol0: 'WFTM',
    symbol1: 'BTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x321162cd933e2be498cd2267a90534a804051b11',
    uniswapV2PairAddress: '0xfdb9ab8b9513ad9e419cf19530fee49d412c3ee3',
    collateralAddress: '0x603f668931ea70db8e44210c6a22d805103fc56d',
    borrowableAddress0: '0x7a7dd36bcca42952cc1e67bca1be44097ff5b644',
    borrowableAddress1: '0x9800ac596e345e6a7179b33deeae2efaf7c9b8e7',
    farmingPoolAddress0: '0xa3907e0b229d4abd14818c4f798ab84f3e400c8b',
    farmingPoolAddress1: '0x508d4bbf34584a5bebeed04bb8121f94a5d12945',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xd4ca5899c042545d602363b6dcf0aeaa42f85158': {
    lendingPoolAddress: '0xd4ca5899c042545d602363b6dcf0aeaa42f85158',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 2,
    symbol0: 'WFTM',
    symbol1: 'BTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x321162cd933e2be498cd2267a90534a804051b11',
    uniswapV2PairAddress: '0x279b2c897737a50405ed2091694f225d83f2d3ba',
    collateralAddress: '0xbfae93ced8824c1e62040dc85715a6edeedabb96',
    borrowableAddress0: '0xe4eb3bd58c6021de054505e85179bbd2ebc03566',
    borrowableAddress1: '0xe02a4886718da7ec9e66eb75b1f22d5d3753a715',
    farmingPoolAddress0: '0xad98e4a5ebcc4ae94e1d2ae55b8e1ca69811a087',
    farmingPoolAddress1: '0x06426e1c851c34991e79f5caea6e802866b1c50f',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x7c8b3ba13c5bb891d765871d830e9b6f159ace13': {
    lendingPoolAddress: '0x7c8b3ba13c5bb891d765871d830e9b6f159ace13',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 3,
    symbol0: 'WFTM',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x613bf4e46b4817015c01c6bb31c7ae9edaadc26e',
    collateralAddress: '0x753fc37744e640119de02ed03b4f8ced6baad1e6',
    borrowableAddress0: '0x8254086911e2a08bf0a179e87a1d45fb6b0f64e9',
    borrowableAddress1: '0x6eedde8005d974f004f9ff1e50f6cd2aeff9d407',
    farmingPoolAddress0: '0xf75ef6ba5a704609a31d3f4e2af0b78b29935112',
    farmingPoolAddress1: '0x6b3c5c875237a5be0774034277c2fbbe180b6aca',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x84311ecc54d7553378c067282940b0fdfb913675': {
    lendingPoolAddress: '0x84311ecc54d7553378c067282940b0fdfb913675',
    isTarotVault: false,
    dex: DEX.SUSHI,
    symbol0: 'WFTM',
    symbol1: 'ICE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xf16e81dce15b08f326220742020379b855b87df9',
    uniswapV2PairAddress: '0x84311ecc54d7553378c067282940b0fdfb913675',
    collateralAddress: '0x692ba088feebcd9f57017e573174d8d14f6a9089',
    borrowableAddress0: '0x8958aa800ea1baa1c47a76ce441b9ff95548fb6a',
    borrowableAddress1: '0x49fdee95ac2fdfabb9bded9db9ede35e4213c5a4',
    farmingPoolAddress0: '0xc66a06991937b770ae35de273543bd9b377f7956',
    farmingPoolAddress1: '0xb717ce85d2a1357285e9c4b32a6ebb1548e8598d',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xb9eef82528fe73f32deab5918af431972c24f552': {
    lendingPoolAddress: '0xb9eef82528fe73f32deab5918af431972c24f552',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 7,
    symbol0: 'WFTM',
    symbol1: 'ICE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xf16e81dce15b08f326220742020379b855b87df9',
    uniswapV2PairAddress: '0x936d23c83c2469f6a14b9f5beaec13879598a5ac',
    collateralAddress: '0x3de2ba8aa129ac4e23f62d55f61ac699960a7133',
    borrowableAddress0: '0x60d5ef2b19078773fc7ea61599d7e5219218bf8e',
    borrowableAddress1: '0x3b893dbe7d79b63f9f7357837248fff00b318e97',
    farmingPoolAddress0: '0x8046ba0ccd220b318d17368bbc1b0e401930d526',
    farmingPoolAddress1: '0xf3133ed63875f515112159a85f38e074765454f7',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x29652f57e18c502f342eba20ebf4d42128b222ee': {
    lendingPoolAddress: '0x29652f57e18c502f342eba20ebf4d42128b222ee',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 17,
    symbol0: 'WFTM',
    symbol1: 'ICE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xf16e81dce15b08f326220742020379b855b87df9',
    uniswapV2PairAddress: '0x623ee4a7f290d11c11315994db70fb148b13021d',
    collateralAddress: '0xae7401ffde555a69240f1348b31d3cfa784e0f5e',
    borrowableAddress0: '0x7d0eb2b3edec482c86e0d588a0f1b3a36b99d336',
    borrowableAddress1: '0x09663b211c559cc5430ee1f4680d6de2888631dd',
    farmingPoolAddress0: '0xd3be88da8d7549501423271d08850f4b15cc8620',
    farmingPoolAddress1: '0xd81f3e702fbdd115ff6dd254c85cb20e0c4ccc1d',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x081e5c833f8b66d8f5ca96574a3a6729143d077a': {
    lendingPoolAddress: '0x081e5c833f8b66d8f5ca96574a3a6729143d077a',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 24,
    symbol0: 'WFTM',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0x6f86e65b255c9111109d2d2325ca2dfc82456efc',
    collateralAddress: '0x949d6299d76b68719fd33b2bcec49ea176455a8d',
    borrowableAddress0: '0x4e4a8ae836cbe9576113706e166ae1194a7113e6',
    borrowableAddress1: '0xf13ac080bb77ad70fecc2da4107faf5d8d3c4e46',
    farmingPoolAddress0: '0xadb6432003aa5e37671d8a2be5286bfce92c9abd',
    farmingPoolAddress1: '0xb8cf3aba7f002820ab1a801cfe7a44c22908b836',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x9e93c992eedcad949e11e30898a4c58e378aedaa': {
    lendingPoolAddress: '0x9e93c992eedcad949e11e30898a4c58e378aedaa',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 30,
    symbol0: 'WFTM',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0xb32b31dfafbd53e310390f641c7119b5b9ea0488',
    collateralAddress: '0x2fc8ca465415985f169f67b33be058fd5c3ba738',
    borrowableAddress0: '0xb566727f4edf30ba13939e304d828e30d4063c59',
    borrowableAddress1: '0x2c4107f7de56f1e0f99f31fadb69284207d5fda6',
    farmingPoolAddress0: '0x8464f75b81be31e94b7e1d93f3d0629d9e86596d',
    farmingPoolAddress1: '0x8a6796d146a2d65f462ba83018a8812b4a3acf17',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x5e2982b55d79327887b297cdca76a7562393408b': {
    lendingPoolAddress: '0x5e2982b55d79327887b297cdca76a7562393408b',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 10,
    symbol0: 'CRV',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1e4f97b9f9f913c46f1632781732927b9019c68b',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x374c8acb146407ef0ae8f82baafcf8f4ec1708cf',
    collateralAddress: '0x41837b6304a94426ad3c59d882d0104238d5ad6b',
    borrowableAddress0: '0x054ec0cb1798f609cb08f0848ce6cc1982acd1fe',
    borrowableAddress1: '0xeaab0eb61326499a4bc79ecdbc6f3bb17b323dd6',
    farmingPoolAddress0: '0x535055b0363e412b61b2fc39729673e50cb705f9',
    farmingPoolAddress1: '0x2b9ca2b3bb31b8375bddc1512fe095a46dd379c1',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x9652e127c69a59ee723882fb69d7fa59cd866f50': {
    lendingPoolAddress: '0x9652e127c69a59ee723882fb69d7fa59cd866f50',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 17,
    symbol0: 'fUSDT',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xd14dd3c56d9bc306322d4cea0e1c49e9ddf045d4',
    collateralAddress: '0xcea9c816dae9d96e7d563c8def6549948a179d8f',
    borrowableAddress0: '0x83016ff687944613c2f79eb8ab3d85fd55e8e39f',
    borrowableAddress1: '0x1cee4fd447d7ce967fddae4b7da872a3a1d04f4b',
    farmingPoolAddress0: '0xfc9dbfc7d081fe15b198af0634104e440992e631',
    farmingPoolAddress1: '0xef92c2ca392df6d351aa7d82c6d688648437bf4f',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x35d5c753563f10a7b3fad397c13ca0de785b8cb2': {
    lendingPoolAddress: '0x35d5c753563f10a7b3fad397c13ca0de785b8cb2',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 21,
    symbol0: 'WFTM',
    symbol1: 'BNB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',
    uniswapV2PairAddress: '0x74fe5ddc4c27f91a1898ccd5ac62dfeb2d3df726',
    collateralAddress: '0x7e6347ce254d4661346f59f265d93356a228b552',
    borrowableAddress0: '0xd875860d6c7386e296c21374ea789c5ce574c6df',
    borrowableAddress1: '0x319d788931129f36ca1c6632bb8f536705523fba',
    farmingPoolAddress0: '0x5d7a9e6aa96af8fbaa9a40af13f8a23018705796',
    farmingPoolAddress1: '0x3b7a555a00ee4db2670fa0cc528582c76514626e',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x5021e2f1fb0040af695d45b17f32e2cf04555770': {
    lendingPoolAddress: '0x5021e2f1fb0040af695d45b17f32e2cf04555770',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 25,
    symbol0: 'SPIRIT',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0xffbfc0446ca725b21256461e214e9d472f9be390',
    collateralAddress: '0xf092a0c7ce5b5859890d2dad14c75a557207243d',
    borrowableAddress0: '0xd4f7cd723c01c2f38388ca85aadd920c3df5c76e',
    borrowableAddress1: '0xc8c57213af8241b3762ca330cf4f7cf7d7157bfc',
    farmingPoolAddress0: '0x859cf2158c9263e54014f4204244a91b11adf34b',
    farmingPoolAddress1: '0x75408ad366920cf800aa46403f88d4c6f2b76d22',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x59ca0775b04fbe64793af430748e6c4239644a86': {
    lendingPoolAddress: '0x59ca0775b04fbe64793af430748e6c4239644a86',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 1,
    symbol0: 'fUSDT',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x5965e53aa80a0bcf1cd6dbdd72e6a9b2aa047410',
    collateralAddress: '0x53409182cfe736c7bc54130cfa1d1ce74662123c',
    borrowableAddress0: '0x98682a6a8aa07902b71e2dc7a5e5073ed282ff42',
    borrowableAddress1: '0xbeb8c1266b6a561f2f10b2d242628d7ed4ba458e',
    farmingPoolAddress0: '0x92ce34dbdbe1718b77a0019a80ad30c6920c994d',
    farmingPoolAddress1: '0xf208c107183095edd52c2a5e33f96fd2d2be3c49',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xd29acf6899b8e19c052ab36a981880d090bace67': {
    lendingPoolAddress: '0xd29acf6899b8e19c052ab36a981880d090bace67',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 3,
    symbol0: 'WFTM',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428',
    collateralAddress: '0x932dc1ce6833a9a0270a689eb0c92326386ef5c3',
    borrowableAddress0: '0x604ea00f00c25747d369d9d114590a483e23ff48',
    borrowableAddress1: '0x31c43ab4827c4ec0e5377db6a22fe83d3c415da1',
    farmingPoolAddress0: '0xbf9b246add25763744daccf2c4efe701d58ba741',
    farmingPoolAddress1: '0xeb78bb29a33506389e8f68e5d9348f469f76866a',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xd2f9933140492a7407136707a21425a2df69c2c9': {
    lendingPoolAddress: '0xd2f9933140492a7407136707a21425a2df69c2c9',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 14,
    symbol0: 'CRV',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1e4f97b9f9f913c46f1632781732927b9019c68b',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xb471ac6ef617e952b84c6a9ff5de65a9da96c93b',
    collateralAddress: '0x48cb984f81335114630c5372a87b16f5c9ada07d',
    borrowableAddress0: '0xcd473b8cf083a362414cf86fbda72ab2a49750be',
    borrowableAddress1: '0x5b80b6e16147bc339e22296184f151262657a327',
    farmingPoolAddress0: '0xc327003f1fd0ec16b9d54e2139a798217057df78',
    farmingPoolAddress1: '0x3eee106ceec87cb1c2278a8626972a42a1275ead',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xe9cf8996424136634f58b48edb868287d5203e9a': {
    lendingPoolAddress: '0xe9cf8996424136634f58b48edb868287d5203e9a',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 19,
    symbol0: 'WFTM',
    symbol1: 'BNB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',
    uniswapV2PairAddress: '0x956de13ea0fa5b577e4097be837bf4ac80005820',
    collateralAddress: '0x8c9c672223a93739fa8a8bc5a2933f0ea7fb6751',
    borrowableAddress0: '0xf63d4894c605c246fbe238514355e3cd9680cff0',
    borrowableAddress1: '0xe69c2c761931c4bf719cf5931af37c6a09889d06',
    farmingPoolAddress0: '0xc0ad7ecc6980dd62368d64a31cf1dbdb4fdef8f7',
    farmingPoolAddress1: '0xd4ffaebcda66717c6022a5e5d40e2bd1ac58f02a',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xafb56de46fba7bdcbc569eec0e6bb62b6e41f5f7': {
    lendingPoolAddress: '0xafb56de46fba7bdcbc569eec0e6bb62b6e41f5f7',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 40,
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0xf050133847bb537c7476d054b8be6e30253fbd05',
    collateralAddress: '0x970d1cb2f261de3b956ef1c51ca89eacab0126f8',
    borrowableAddress0: '0x8c97dcb6a6b08e8beece3d75e918fbc076c094ab',
    borrowableAddress1: '0x63218cf07df7c177bc96573d645decf155760290',
    farmingPoolAddress0: '0x8fe009e17f5906d3300910a75254f9592ca5242c',
    farmingPoolAddress1: '0x508025775244a09efcfcf2c300ff18e8df223c01',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xdffae68e2b7fb1f5438df5c771fd8ae73a9b4067': {
    lendingPoolAddress: '0xdffae68e2b7fb1f5438df5c771fd8ae73a9b4067',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 27,
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x11d90ea9d16e1ee5879b299a819f6d618816d70f',
    collateralAddress: '0x1dc63864950831bd6335b2e89560a74be4b70006',
    borrowableAddress0: '0x5dd76071f7b5f4599d4f2b7c08641843b746ace9',
    borrowableAddress1: '0xe0d10cefc6cdfbbde41a12c8bbe9548587568329',
    farmingPoolAddress0: '0x2ccac9f9439383516b0aea82d333c1af1d16e6f8',
    farmingPoolAddress1: '0x54cb7f772461759c383fe4677e2da3c4b0db33db',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xe86382644014c9507e7adc6250193516ebf8ccc0': {
    lendingPoolAddress: '0xe86382644014c9507e7adc6250193516ebf8ccc0',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 28,
    symbol0: 'WFTM',
    symbol1: 'SCREAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
    uniswapV2PairAddress: '0x30872e4fc4edbfd7a352bfc2463eb4fae9c09086',
    collateralAddress: '0x27cd3f8f18eae08485834fda12b42e813e49fd0d',
    borrowableAddress0: '0x037d3b5213c53a54c5de243aade6e7bbd8858c70',
    borrowableAddress1: '0x557ffb85a5ffb0bf878636b748487c3ec4abb8a9',
    farmingPoolAddress0: '0x5024de556ec5518c7df4d8cced991827761bbb7d',
    farmingPoolAddress1: '0x599da730b9a26a682095cf12fe1a0d638411410e',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x01233eb846f1ed6137448c2d64b3d9c69ade5a9d': {
    lendingPoolAddress: '0x01233eb846f1ed6137448c2d64b3d9c69ade5a9d',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 23,
    symbol0: 'YFI',
    symbol1: 'WOOFY',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 12,
    tokenAddress0: '0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    tokenAddress1: '0xd0660cd418a64a1d44e9214ad8e459324d8157f1',
    uniswapV2PairAddress: '0x287ebf376c59a037b8d8e0e987461b2fd8550d8c',
    collateralAddress: '0x7ce2f84e452cac413b04f4e8ca948ca0158b94de',
    borrowableAddress0: '0x24b1c4e292abe72a6764d380cfaadbabd14d80de',
    borrowableAddress1: '0xa11d823eb85d23c7a12e46cb3c250df50df98277',
    farmingPoolAddress0: '0x223cdaa1fed53c7272d3cb54df72fa8f973b1d2c',
    farmingPoolAddress1: '0xbab1815cae6a3d85afca554c6f91786a9ec74392',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xfdef392adc84607135c24ca45de5452d77aa10de': {
    lendingPoolAddress: '0xfdef392adc84607135c24ca45de5452d77aa10de',
    isTarotVault: false,
    dex: DEX.SPOOKY,
    symbol0: 'USDC',
    symbol1: 'fUSDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    uniswapV2PairAddress: '0xfdef392adc84607135c24ca45de5452d77aa10de',
    collateralAddress: '0xacb8eaeb3182ca5f312ecc37f7f8115cbc295456',
    borrowableAddress0: '0x7623abcb2a3da6bb14bbb713b58c9b11fc9713b1',
    borrowableAddress1: '0x9b5eb381e66170b60a7737414db3624509f03460',
    farmingPoolAddress0: '0x6ee3a657b884ee15488b4e3ada36165ff4082ac2',
    farmingPoolAddress1: '0x5692a1d2be132cbe0ec76346292c01f677830bb7',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xd837e86c951dd98b80195deb39e4166c485efff3': {
    lendingPoolAddress: '0xd837e86c951dd98b80195deb39e4166c485efff3',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 27,
    symbol0: 'fUSDT',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0xc931f61b1534eb21d8c11b24f3f5ab2471d4ab50',
    uniswapV2PairAddress: '0x1ac51904cfaad15679b3500f0fc41d2971657f80',
    collateralAddress: '0x402365800291dd4fbdd183b96b8d96606fb96123',
    borrowableAddress0: '0x69026594c52b47fb26716d4cc1934e1f2d4f84ee',
    borrowableAddress1: '0xdca2b13c3e796a2a476516ebb2731356bd4bf293',
    farmingPoolAddress0: '0x8717aa8c3f0655968e474ea6427038955ef22615',
    farmingPoolAddress1: '0x7c897328bf874de6d2962a64f5af2ad7a2dba916',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xc10eab109a9f4a696d60321b426bc86c272429eb': {
    lendingPoolAddress: '0xc10eab109a9f4a696d60321b426bc86c272429eb',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 52,
    symbol0: 'fUSDT',
    symbol1: 'SPELL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x468003b688943977e6130f4f68f23aad939a1040',
    uniswapV2PairAddress: '0x31c0385dde956f95d43dac80bd74fee149961f4c',
    collateralAddress: '0x415f1b65cc25b40e432cb56042f99868b40dffd1',
    borrowableAddress0: '0x6051a30c995a69800056ff17bf932d67cf353339',
    borrowableAddress1: '0xa1b006dcaae5264dbde224cb1df875ab754c4511',
    farmingPoolAddress0: '0x20f2008a1fcc7046fac87b27dac8db55617f5351',
    farmingPoolAddress1: '0xac0e9a536aa8bdaf9d0b722293ac50f7e4643a37',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x50644123ee3fd57226fbbebeb79c9e7f61b2be7e': {
    lendingPoolAddress: '0x50644123ee3fd57226fbbebeb79c9e7f61b2be7e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 6,
    symbol0: 'WFTM',
    symbol1: 'LINK',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    uniswapV2PairAddress: '0x89d9bc2f2d091cfbfc31e333d6dc555ddbc2fd29',
    collateralAddress: '0x1b08a8776f964baf5b80626da5950ab86e3a58bd',
    borrowableAddress0: '0xdf79ea5d777f28cab9fd42acda6208a228c71b59',
    borrowableAddress1: '0x98ad887d54593e9f514a4b5b4791dc0a9a62145d',
    farmingPoolAddress0: '0x07f19e9b4bed8f6abeb2ebe739db5cdc830a2479',
    farmingPoolAddress1: '0x6a714b5789bea3f24d6e91a3a6dfbd3298e124c5',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xe556317f66f0c4f44ccd7fef20e17fa3cde2cbd9': {
    lendingPoolAddress: '0xe556317f66f0c4f44ccd7fef20e17fa3cde2cbd9',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 32,
    symbol0: 'WFTM',
    symbol1: 'SHADE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x3a3841f5fa9f2c283ea567d5aeea3af022dd2262',
    uniswapV2PairAddress: '0x20aa395f3bcc4dc44a94215d129650533b3da0b3',
    collateralAddress: '0x7c00f62156d8f201bc129ceebe48109220836b81',
    borrowableAddress0: '0x10a1ba0f63d71e83dc74f05c878223b2ae828300',
    borrowableAddress1: '0xbd300fe1298ba08fa22527bf9f89410cca1617ae',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xcaf9f25ea321ab2675f8adb13c5df58e8918d0dd': {
    lendingPoolAddress: '0xcaf9f25ea321ab2675f8adb13c5df58e8918d0dd',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 26,
    symbol0: 'YFI',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x0845c0bfe75691b1e21b24351aac581a7fb6b7df',
    collateralAddress: '0x7036601839bee58c64476815d03d5c8bb388f539',
    borrowableAddress0: '0x024d7143c04487757dfb4c55526df32173592395',
    borrowableAddress1: '0x6bb913d4b277ab2b8dadff079e244f6e61d08d6c',
    farmingPoolAddress0: '0x7cc111e4e514f629ff773a7eadd30fda94c4fdf8',
    farmingPoolAddress1: '0x6903f1c3494bcfae28e58693dad39ed0872bb41c',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xc0c9e66bf0f5cc40bfeb76ade0e650e8f15b7eae': {
    lendingPoolAddress: '0xc0c9e66bf0f5cc40bfeb76ade0e650e8f15b7eae',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 11,
    symbol0: 'WFTM',
    symbol1: 'LINK',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    uniswapV2PairAddress: '0xd061c6586670792331e14a80f3b3bb267189c681',
    collateralAddress: '0xd65cc1a261c371bd0cdab755c311726e4c4b50fc',
    borrowableAddress0: '0xf2d3ae45f8775ba0a729df47210164f921edc306',
    borrowableAddress1: '0x356073f1f372463bda048891795e4f1d98463d46',
    farmingPoolAddress0: '0xf32ce5fa449a3391d7f79634b0a66586faffb33a',
    farmingPoolAddress1: '0x0ac3307cea3d14966f5a5a98fc00f3ca565bc9ac',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x77f6199a44ac3f83db953bd30896f22be0d72551': {
    lendingPoolAddress: '0x77f6199a44ac3f83db953bd30896f22be0d72551',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 8,
    symbol0: 'WFTM',
    symbol1: 'SUSHI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc',
    uniswapV2PairAddress: '0x9fe4c0ce5f533e96c2b72d852f190961ad5a7bb3',
    collateralAddress: '0x4b06d5ae9ec8e9445fee73daadfae96ab40481af',
    borrowableAddress0: '0x00fb23c7169e0378a63d9cfe50ef40f944653c69',
    borrowableAddress1: '0xcd0af9e83216255c3a4e48e9fc36f64d92bbfc72',
    farmingPoolAddress0: '0x248b585042b3f4b1279d380ed3ce1d688539dffb',
    farmingPoolAddress1: '0x24047f0af874005b9c182d801c7df2fa57640ef0',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xec5dd1fad6c4cbd57ef57a8b90bee37c3aa674f2': {
    lendingPoolAddress: '0xec5dd1fad6c4cbd57ef57a8b90bee37c3aa674f2',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 13,
    symbol0: 'WFTM',
    symbol1: 'YFI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    uniswapV2PairAddress: '0x4fc38a2735c7da1d71ccabf6dec235a7da4ec52c',
    collateralAddress: '0x9020aaa8c2c1f7f6a2fef837444be2e480e3af02',
    borrowableAddress0: '0x9cded654472788a143c2285a6b2a580392510688',
    borrowableAddress1: '0x0a41a6e2597a67f43069be63f312af6dbbbda037',
    farmingPoolAddress0: '0x9fc4df542239611a2eb41ab583623994b69c1267',
    farmingPoolAddress1: '0x405af7fd2c856fecb0a4deae18ad3e6a8aaeb744',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x1157d21ee6b01f51596df537e92f07634d826864': {
    lendingPoolAddress: '0x1157d21ee6b01f51596df537e92f07634d826864',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 20,
    symbol0: 'WFTM',
    symbol1: 'WOOFY',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 12,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd0660cd418a64a1d44e9214ad8e459324d8157f1',
    uniswapV2PairAddress: '0xfd0ab56b83130ce8f2b7a4f4d4532dee495c0794',
    collateralAddress: '0x5c7efa2149d337ce8a46076973001c78ff6ba2b4',
    borrowableAddress0: '0x0c60dbd5b78d1488f9f71163e598d90f8ede55e7',
    borrowableAddress1: '0xcb7c335d19d48571884840bab53897ce99b7ae83',
    farmingPoolAddress0: '0xbe1efe0a905bf7f1977570913342bd30b623b459',
    farmingPoolAddress1: '0xc63e735370ca1bc10f37ac3ea60ef7dc58c46541',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x45c0a8ace2d71e08c7e56850766e7fe8da584039': {
    lendingPoolAddress: '0x45c0a8ace2d71e08c7e56850766e7fe8da584039',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 10,
    symbol0: 'WFTM',
    symbol1: 'SUSHI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc',
    uniswapV2PairAddress: '0xf84e313b36e86315af7a06ff26c8b20e9eb443c3',
    collateralAddress: '0xc668b6f42c7d13591e5bea46b19ac48209229b3f',
    borrowableAddress0: '0x37f6cf24ba9e781344ae4ac8923d9a0a3910bc64',
    borrowableAddress1: '0x41392cf20f12ddadcc8a0ad2040cb6b376678e38',
    farmingPoolAddress0: '0xb95cbe6fdef0e8509b2d19efa30a782c2995c0f3',
    farmingPoolAddress1: '0xfdf86a79846343157923c1c0d5d1931d36a647e3',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xe9f0146be89aa82a4827425240e755be01b78c82': {
    lendingPoolAddress: '0xe9f0146be89aa82a4827425240e755be01b78c82',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 35,
    symbol0: 'BTC',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x321162cd933e2be498cd2267a90534a804051b11',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xec454eda10accdd66209c57af8c12924556f3abd',
    collateralAddress: '0x214812a0e2ad2f05ae9707c7680e079124e34cc5',
    borrowableAddress0: '0x967a31b5ad8d194cef342397658b1f8a7e40bcaa',
    borrowableAddress1: '0x9430cfd7cae4182b87ff8b21554ceb813f50da38',
    farmingPoolAddress0: '0x96546777246e774ef0113a155092238a5232bb16',
    farmingPoolAddress1: '0xe2e1e0b7f0ff1dce467b8e8ef1848ce588965a9c',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x0e5736c76229e8bd4bcbc355a216d79de9caa844': {
    lendingPoolAddress: '0x0e5736c76229e8bd4bcbc355a216d79de9caa844',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 38,
    symbol0: 'WFTM',
    symbol1: 'SPELL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x468003b688943977e6130f4f68f23aad939a1040',
    uniswapV2PairAddress: '0x78f82c16992932efdd18d93f889141ccf326dbc2',
    collateralAddress: '0xc8166fe1da4ad873b4c70fe8ab103a86ea73e79d',
    borrowableAddress0: '0xb2aae88836b08a0df09625a74a6d830561c4c904',
    borrowableAddress1: '0x702e6ca4972496572c407efcf841a1496b1cfc04',
    farmingPoolAddress0: '0x2de42765ab8a7d94681eaebe72d4e52b1fcc5dcc',
    farmingPoolAddress1: '0xe942dcdd2e9f340f49128589867fce4c66935fd7',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x0cda2b4cd087461010dadfa6718c680cfdafaada': {
    lendingPoolAddress: '0x0cda2b4cd087461010dadfa6718c680cfdafaada',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 57,
    symbol0: 'MIM',
    symbol1: 'wMEMO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    tokenAddress1: '0xddc0385169797937066bbd8ef409b5b3c0dfeb52',
    uniswapV2PairAddress: '0xc9b98e4a4e306dfc24bc5b5f66e271e19fd74c5a',
    collateralAddress: '0xaf8cdbd1a5ea3e51b35b3425d932b1c0942e58b6',
    borrowableAddress0: '0xc6caf3e4ab97f37ae78cfe6a3cc84bd75400cee9',
    borrowableAddress1: '0xf062999692a9934c5832aa627c58fd9f6db4d998',
    farmingPoolAddress0: '0x6aab91f35700672d0ea69951cf159af1c4668eef',
    farmingPoolAddress1: '0x8991aa5a934f0ebaefba249d4c765d225ec31b69',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x0360e5a5195cfa681262a7612e655a81dd08b1a9': {
    lendingPoolAddress: '0x0360e5a5195cfa681262a7612e655a81dd08b1a9',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 60,
    symbol0: 'SPELL',
    symbol1: 'sSPELL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x468003b688943977e6130f4f68f23aad939a1040',
    tokenAddress1: '0xbb29d2a58d880af8aa5859e30470134deaf84f2b',
    uniswapV2PairAddress: '0x4f41d03631ea4dc14016ccf90690d6d22b24c12d',
    collateralAddress: '0x19d6ccbac61860dd74610c24ef23c7c874b183fc',
    borrowableAddress0: '0x6e7dbf4c14f28db91a8c468031b7ce6a7eec675e',
    borrowableAddress1: '0x5ad2aab6fc1d5064690076e84e73763169a65cf7',
    farmingPoolAddress0: '0x05599b76d0c8843abc3fab9749744942b4c3ddf9',
    farmingPoolAddress1: '0x7cabdff2550c08748888f965468efb547260b8c9',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x197244b7212a309a26d8f4ed21f6aa7a8634be00': {
    lendingPoolAddress: '0x197244b7212a309a26d8f4ed21f6aa7a8634be00',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 18,
    symbol0: 'WFTM',
    symbol1: 'ANY',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xddcb3ffd12750b45d32e084887fdf1aabab34239',
    uniswapV2PairAddress: '0x26d583028989378cc1b7cbc023f4ae049d5e5899',
    collateralAddress: '0x05e16b501c68ac6d5a7240c4a5fad48fdc3fb452',
    borrowableAddress0: '0xca578d5753dc419cfa14e7711208003043b6e5b6',
    borrowableAddress1: '0x8cd512af981c66bee0c3997807cbfda281553dd8',
    farmingPoolAddress0: '0x8a44fa968df97d22134dcf57760adeb5ba72e10e',
    farmingPoolAddress1: '0xb79bbe39892737433a43d7016118fdff978439e2',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x0f1e427036478968cb707cb903bab75af0f075ff': {
    lendingPoolAddress: '0x0f1e427036478968cb707cb903bab75af0f075ff',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 22,
    symbol0: 'WFTM',
    symbol1: 'ANY',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xddcb3ffd12750b45d32e084887fdf1aabab34239',
    uniswapV2PairAddress: '0x5c021d9cfad40aafc57786b409a9ce571de375b4',
    collateralAddress: '0x2fc4141e8f9ee0e482ba2d03c65b9793512b43eb',
    borrowableAddress0: '0x114fb353ad085d1a4044f41988d0c212b859fbe2',
    borrowableAddress1: '0x873e78c836f94b04a50014ce616b6ce319a1912a',
    farmingPoolAddress0: '0xb7428982d46b3b2459ccbca152e6b934fecc9873',
    farmingPoolAddress1: '0x5d5df119fa6a13f126c2c1440bcdabb10ecbacfd',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xaaafc7d4fc6d9b6b216d4dc5a87f1236c958ac7f': {
    lendingPoolAddress: '0xaaafc7d4fc6d9b6b216d4dc5a87f1236c958ac7f',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 42,
    symbol0: 'USDC',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x4de9f0ed95de2461b6db1660f908348c42893b1a',
    collateralAddress: '0x6cd5bfae1feb317c6d978299c375d723dacd91c1',
    borrowableAddress0: '0xd8339e66eeb1762e699b3f0ef694269658e2421f',
    borrowableAddress1: '0x03289938cd5496e1dc7a4285457f712eb5e63a18',
    farmingPoolAddress0: '0xc27f8e00c28d9dc5f24873f8001af60ae0a79db4',
    farmingPoolAddress1: '0x2daad37a5186e3af14651edae227ff14e337a0d1',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x33ab4fb570509fb102bc51433c3d78226c2c7440': {
    lendingPoolAddress: '0x33ab4fb570509fb102bc51433c3d78226c2c7440',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 44,
    symbol0: 'WFTM',
    symbol1: 'JOE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x9f47f313acfd4bdc52f4373b493eae7d5ac5b765',
    uniswapV2PairAddress: '0xd518737ff601c2a7c67f55ebbeb0a4e3ff5c0c35',
    collateralAddress: '0x828a69f6cbcf9b0049b539fd6e52b09735698695',
    borrowableAddress0: '0x5a88f89fcc6827a1572d0efa32b82c69700aa7a0',
    borrowableAddress1: '0xe5214c9f166245c3b5b01f711ef25dcb23f0a893',
    farmingPoolAddress0: '0x9f1289d363aca2a65a37973aae68ff1b55b4b6d9',
    farmingPoolAddress1: '0x5e2b5709fe814e9c0037dd0f1f5842aa2df5c582',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xc439220b52abf7641348896950d5a69511c18243': {
    lendingPoolAddress: '0xc439220b52abf7641348896950d5a69511c18243',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.TOMB,
    pid: 0,
    symbol0: 'WFTM',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0x2a651563c9d3af67ae0388a5c8f89b867038089e',
    collateralAddress: '0x8e4c0929606540985aa55dddbe20729eea595d1c',
    borrowableAddress0: '0x3da659961cfb2b3e7b167b6d0761d6ecb5926422',
    borrowableAddress1: '0xdbd846a0fc27e1f7749140f945fe7b28b9ac6a48',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x9cf1fc04e64df6691f40c00a187049b8320323bc': {
    lendingPoolAddress: '0x9cf1fc04e64df6691f40c00a187049b8320323bc',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.TOMB,
    pid: 1,
    symbol0: 'WFTM',
    symbol1: 'TSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    uniswapV2PairAddress: '0x4733bc45ef91cf7ccecaeedb794727075fb209f2',
    collateralAddress: '0x40435ead2b5da9980f0511af454c7f73bddf9108',
    borrowableAddress0: '0x7b501aa0ffb85171c94366db113119cdfaf7b5f5',
    borrowableAddress1: '0xdb8b0449fe89cf8251c9029827fda3f11ed7150e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x2f7b62ec34019956061b06c7c14966dcad425718': {
    lendingPoolAddress: '0x2f7b62ec34019956061b06c7c14966dcad425718',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 0,
    gaugeAddress: '0xefe02cb895b6e061fa227de683c04f3ce19f3a62',
    symbol0: 'WFTM',
    symbol1: 'SPIRIT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
    uniswapV2PairAddress: '0x30748322b6e34545dbe0788c421886aeb5297789',
    collateralAddress: '0xabccf1bf4b58d8dc55a6104971694c30ad6b0204',
    borrowableAddress0: '0x09961762eee83644f0a36eb937246382f100cd4e',
    borrowableAddress1: '0xc5eb7db755a870b91d18adcbbf60549a294b4130',
    farmingPoolAddress0: '0x224fb7073d6c8c2abe04a326f7ebe20742695eb0',
    farmingPoolAddress1: '0x0a98d9c789acf590c448eff1705d892f936c5c8f',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x9550cd12e14c7675ed0c8573fbbaaaf9b08a2ffb': {
    lendingPoolAddress: '0x9550cd12e14c7675ed0c8573fbbaaaf9b08a2ffb',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 1,
    gaugeAddress: '0xe86cee843a5ce2f40575544b1ffc43cb1701d9ae',
    symbol0: 'WFTM',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x613bf4e46b4817015c01c6bb31c7ae9edaadc26e',
    collateralAddress: '0xa3a479e8961fc5a7476f4684cc0ab14cf1a91b47',
    borrowableAddress0: '0x93b2cd2f0e3d5a1f5f9955a84b8e1223bac614aa',
    borrowableAddress1: '0x84097529a78121549a75e7b4136d7680aa50502a',
    farmingPoolAddress0: '0xc816b56cf688e44a2da23b3d6b431e919eca774e',
    farmingPoolAddress1: '0xecc1e5b307739723a561d2b0ba32afbf246310c6',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x2edc3b58646b5183ccfea216e97fa4973817d74c': {
    lendingPoolAddress: '0x2edc3b58646b5183ccfea216e97fa4973817d74c',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 2,
    gaugeAddress: '0xdccafce93e6e57f0464b4639d4afd7b9ad006f61',
    symbol0: 'WFTM',
    symbol1: 'BTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x321162cd933e2be498cd2267a90534a804051b11',
    uniswapV2PairAddress: '0x279b2c897737a50405ed2091694f225d83f2d3ba',
    collateralAddress: '0xaffd63137a17e9fd7b6644c48b36f565125814c4',
    borrowableAddress0: '0x577bccf20972fd13bf4749df12a7616be9c8b249',
    borrowableAddress1: '0x1a932bbad60d390dbdb7596e4fed9c215830d9d1',
    farmingPoolAddress0: '0x6110f55d3cc4b9e3d9f13a375f7626da967e872a',
    farmingPoolAddress1: '0x76ffd462954cf46cffae8a0633f540527e0f59d7',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xba4181d1b6574305a803ab7fd88c4a05073b0d69': {
    lendingPoolAddress: '0xba4181d1b6574305a803ab7fd88c4a05073b0d69',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 6,
    gaugeAddress: '0x0b905475bea057060d066f3d1f85e6902ae62557',
    symbol0: 'WFTM',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0xb32b31dfafbd53e310390f641c7119b5b9ea0488',
    collateralAddress: '0xc5f8bce448b5b90e48d9458c1228d04d4c472485',
    borrowableAddress0: '0x44de772e41e7ec1d20f47d0c293b5c895d973b51',
    borrowableAddress1: '0xbc1d2c9426c7ec8a0efc825725608fb1e9bc3704',
    farmingPoolAddress0: '0xd5fa760a650b2bcb62399dbca86c4bd42824c086',
    farmingPoolAddress1: '0x11f116e2a2efcecf8fe097d1c9b97b81e4779a82',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x576628396526c8ef7dd84d3a8d4e50bb15db1c86': {
    lendingPoolAddress: '0x576628396526c8ef7dd84d3a8d4e50bb15db1c86',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 7,
    gaugeAddress: '0x27dc7cc7175f8ac26dc7421a3a92dacdc1a9ef0d',
    symbol0: 'WFTM',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x51eb93ecfeffbb2f6fe6106c4491b5a0b944e8bd',
    collateralAddress: '0x077f05a81e00636fb246c71011e79262b4e4f045',
    borrowableAddress0: '0x56d62514f2598fcff97c64caa236f9eaeb536f0f',
    borrowableAddress1: '0xb5dd3f64b8b00d81aa2110b681d72ae43c150c61',
    farmingPoolAddress0: '0x8310b224728c2a65ace36cbbcf236d6e6348b33a',
    farmingPoolAddress1: '0x0f2d229a4360da33901a051912092e2c3522d8cc',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x76615a1a91f425a96e73d1d2a178e01f74026a48': {
    lendingPoolAddress: '0x76615a1a91f425a96e73d1d2a178e01f74026a48',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 9,
    gaugeAddress: '0xa6a6f26426fb5fe15b33fae65d1335b02dc54372',
    symbol0: 'WFTM',
    symbol1: 'ICE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xf16e81dce15b08f326220742020379b855b87df9',
    uniswapV2PairAddress: '0x936d23c83c2469f6a14b9f5beaec13879598a5ac',
    collateralAddress: '0x4b7685045ea9720637f8e2e7d628b6106970539a',
    borrowableAddress0: '0x48b931fbcddccfab67fe718fe567b1f67f49ccf2',
    borrowableAddress1: '0x2536dcb0dbd745cc9c8ca3bbd7825448b0e4e1e8',
    farmingPoolAddress0: '0xd3670e37b8a66e8ad809e27cf62b386934ed59e9',
    farmingPoolAddress1: '0xc1282abb02afb856b3facf24aeb2c38a6c13d612',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xde2c8022982acba527080bb863384b8f9bcd9117': {
    lendingPoolAddress: '0xde2c8022982acba527080bb863384b8f9bcd9117',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 11,
    gaugeAddress: '0xa3c6d55397dcddaf9f600b082f7a6a918f2f4a5c',
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xe7e90f5a767406eff87fdad7eb07ef407922ec1d',
    collateralAddress: '0xdd044e921ab56e4f1c14cc62c49f54c63e69aaa6',
    borrowableAddress0: '0xb91e78e239ddf33de24e32424decffa036e770e4',
    borrowableAddress1: '0x7078183318adb7088f97d5884f35b1321a34224b',
    farmingPoolAddress0: '0x70b4df260d8f68127099c514526e9da90e44400a',
    farmingPoolAddress1: '0x596dcc230df117d19772183a03ee4329363c0533',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x5790c009a59daa9b5b859803188a8d1877ca56cc': {
    lendingPoolAddress: '0x5790c009a59daa9b5b859803188a8d1877ca56cc',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 12,
    gaugeAddress: '0xed912897138f8af455b8f95f75850b11979806d8',
    symbol0: 'fUSDT',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xd14dd3c56d9bc306322d4cea0e1c49e9ddf045d4',
    collateralAddress: '0xd92fd5558706fe12137cd8499081672f0aec9f74',
    borrowableAddress0: '0x1891069c9ca5cebca29ba34d626a4aa766e9370f',
    borrowableAddress1: '0x2e5d02fb402670d76c3f31fb23e8f396e73d5252',
    farmingPoolAddress0: '0xcc40461271545e9c613aeb1e9488102cc5bc16ea',
    farmingPoolAddress1: '0xa066454937de53de952aea9a951e050a1d99a5a9',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xb779ea18b24abf78ae8c1689d9b9c02eb1a2a15d': {
    lendingPoolAddress: '0xb779ea18b24abf78ae8c1689d9b9c02eb1a2a15d',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 13,
    gaugeAddress: '0x1b6ca59bf8a911ee56e58eb5e5a97f69356ec6c3',
    symbol0: 'WFTM',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0xdbc490b47508d31c9ec44afb6e132ad01c61a02c',
    collateralAddress: '0xc688eabdfd698fe3796806b98d2f85b0da5a067a',
    borrowableAddress0: '0x2d8c65844018e0b46f58ce8c70e01f1f21a8eac5',
    borrowableAddress1: '0xc0c9316d611dd939033aa3f1e3e9d79b2a8bf58d',
    farmingPoolAddress0: '0xee0e9bed2c744064ef051bad4cb66afb05a6aa5e',
    farmingPoolAddress1: '0x89511ed81bbd4a3a06176ec544747a107320ad97',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x6a0c5792e58e4af845f273d4f6dd52c0987e11cf': {
    lendingPoolAddress: '0x6a0c5792e58e4af845f273d4f6dd52c0987e11cf',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 14,
    gaugeAddress: '0x02adc9b582e39dc4cb727a64d8584830cf1bb9bc',
    symbol0: 'WFTM',
    symbol1: 'SPELL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x468003b688943977e6130f4f68f23aad939a1040',
    uniswapV2PairAddress: '0x19d4092635740699b6e4735701742740e235165a',
    collateralAddress: '0xb014f8d403eae7d311b07cf5ec14b119f6444314',
    borrowableAddress0: '0xda6225b507c9f43314396e9d27e4cb3c1f5be106',
    borrowableAddress1: '0xf83d6bb90c85a0ed42c64f78fc89612756a5fef6',
    farmingPoolAddress0: '0x7c2eb547e7378a6587d4dd8dfee325cb22faf6c4',
    farmingPoolAddress1: '0x5e349d24b17be79d0378a8cd1a2fe68c4d927dc5',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xacf6f1f1e92109baf25ee220c4ddc6464741704e': {
    lendingPoolAddress: '0xacf6f1f1e92109baf25ee220c4ddc6464741704e',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 15,
    gaugeAddress: '0x237e7e20bf10a61c8ded780398aa0d5e69ddff9c',
    symbol0: 'WFTM',
    symbol1: 'YFI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    uniswapV2PairAddress: '0x4fc38a2735c7da1d71ccabf6dec235a7da4ec52c',
    collateralAddress: '0x401ece057bd310bccf071712a08e2224afac5956',
    borrowableAddress0: '0x96e33d1ecf87d27a4aee5331f452f0213914ebdc',
    borrowableAddress1: '0xbb085b957ba2f0250a2c7a89e5deaebf0974bf5b',
    farmingPoolAddress0: '0x9c7634242e0d28619877e3f07a39852c39eb0362',
    farmingPoolAddress1: '0x6d86a1bca19917ff5a7a7ba565daca6d543bcb13',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x0cbabbbb050088fcc7162b5d4fa962c74c8fc24e': {
    lendingPoolAddress: '0x0cbabbbb050088fcc7162b5d4fa962c74c8fc24e',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 16,
    gaugeAddress: '0x3fd04eeb74204f8faa5ea539cd5275ec1a3aa70c',
    symbol0: 'WFTM',
    symbol1: 'SUSHI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc',
    uniswapV2PairAddress: '0x9fe4c0ce5f533e96c2b72d852f190961ad5a7bb3',
    collateralAddress: '0x7812e99fb1a2a5b313b0968608ad690e71d0c29b',
    borrowableAddress0: '0xcddc0d347ca41b88742489ef778354b35c5139f5',
    borrowableAddress1: '0xfec280602e428db567903174becb66ac703a8585',
    farmingPoolAddress0: '0xc8333347b30b5431aff0a3b7dc36eac77744ac84',
    farmingPoolAddress1: '0xdebb0e8807255cfe49e0bed42011c424848bc73b',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x6332c721c1420e2376a5236ae94bc05a62f0babc': {
    lendingPoolAddress: '0x6332c721c1420e2376a5236ae94bc05a62f0babc',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 17,
    gaugeAddress: '0x1360e082c01c897339b82ef098ab4e8b271252c8',
    symbol0: 'WFTM',
    symbol1: 'LINK',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    uniswapV2PairAddress: '0xd061c6586670792331e14a80f3b3bb267189c681',
    collateralAddress: '0xb34afc2ce99bf7017f875fc12d55350b3397ef9e',
    borrowableAddress0: '0x5b31487dad21d780d62d87d5568961c59f47948b',
    borrowableAddress1: '0x8d137de87a92c613405aca40cebfcd7b8afd5eff',
    farmingPoolAddress0: '0x3bc6797853e945c839ab91b8930ed6c29cb648a6',
    farmingPoolAddress1: '0xd98d3c429e8e29985c97a0d369b086fecf10bd1d',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xc0662300c35226a284d65ca3616f9555586d475f': {
    lendingPoolAddress: '0xc0662300c35226a284d65ca3616f9555586d475f',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 18,
    gaugeAddress: '0x73ecaad4fff43619f31d47d66d841de41a933488',
    symbol0: 'CRV',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1e4f97b9f9f913c46f1632781732927b9019c68b',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x374c8acb146407ef0ae8f82baafcf8f4ec1708cf',
    collateralAddress: '0xb5ef813b7dfa8d96dbe2de1e9a5e3228b0a58c21',
    borrowableAddress0: '0x29d68861fd8cdb9f6c64b3b3384a12b1d1e80e09',
    borrowableAddress1: '0xad760cd61f06c44b3c10dc067ded0d6621a68526',
    farmingPoolAddress0: '0xd8a6db2ed6f9e5acdbe2f81ae18ffc3b245b418f',
    farmingPoolAddress1: '0xe32ec4cfee5da58666d1c8fcf5db62a2fb59ee6e',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xc7186b46c0a6e9930950fd113336fb45972dcd91': {
    lendingPoolAddress: '0xc7186b46c0a6e9930950fd113336fb45972dcd91',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 26,
    gaugeAddress: '0xf7d3de134c9d09998f94a3de5e0d7f3317dd97be',
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0xf050133847bb537c7476d054b8be6e30253fbd05',
    collateralAddress: '0xca5235d3b9dbcac46f0c8455cd83d5edaac0acd9',
    borrowableAddress0: '0x21f5007180057a354dff1713aa7e3ccb8b6144a8',
    borrowableAddress1: '0xda803d259204a2189a4e7448b1874e0f68b52206',
    farmingPoolAddress0: '0xe2c6c65ea63ce3eca9331bcd2816f5fc017b33dc',
    farmingPoolAddress1: '0x89d08d1ac4c327be63e8c84e30c677326ad979b6',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x92518ebe1ecc052815d1dda817396dd762eb1e64': {
    lendingPoolAddress: '0x92518ebe1ecc052815d1dda817396dd762eb1e64',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 27,
    gaugeAddress: '0x86762289ffb97f8db441a4faf5ecd335165e8e08',
    symbol0: 'MIM',
    symbol1: 'wMEMO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    tokenAddress1: '0xddc0385169797937066bbd8ef409b5b3c0dfeb52',
    uniswapV2PairAddress: '0xc9b98e4a4e306dfc24bc5b5f66e271e19fd74c5a',
    collateralAddress: '0xcb0919ca2e7d2354f775bb532014e640b150c246',
    borrowableAddress0: '0x31d9cac933c928f8647288b25fc84e8d2f29af67',
    borrowableAddress1: '0x931708e4971a1690c8f7dab764405dd6811f61a5',
    farmingPoolAddress0: '0x20d678690510c1f0c1af006376f08bf12a476129',
    farmingPoolAddress1: '0x35007e7acb28f147c444c5de85679cb85dcc7d60',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xca7743efb8a9c9986fb88d9f47aa6072d8faf3da': {
    lendingPoolAddress: '0xca7743efb8a9c9986fb88d9f47aa6072d8faf3da',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.OXD_V1,
    pid: 0,
    symbol0: 'USDC',
    symbol1: 'OXD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xc165d941481e68696f43ee6e99bfb2b23e0e3114',
    uniswapV2PairAddress: '0xd5fa400a24eb2ea55bc5bd29c989e70fbc626fff',
    collateralAddress: '0x5673b1ee6e5550b53bee81f5782da6128a3895ef',
    borrowableAddress0: '0xd8c8dab764a03e1df3162b560402498a4d4361c4',
    borrowableAddress1: '0xeb3723d9f817368fa7bfcdbfe2d9d2870dbc1259',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xa3695a0c2424c60fba36aaccf52957c446c17dab': {
    lendingPoolAddress: '0xa3695a0c2424c60fba36aaccf52957c446c17dab',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 59,
    symbol0: 'USDC',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0x484237bc35ca671302d19694c66d617142fbc235',
    collateralAddress: '0xdae9e6a740c2f3d2d56917169064cd2c22fbf18c',
    borrowableAddress0: '0x07642848c3564e8e1566f8e55317b43375a66382',
    borrowableAddress1: '0x36baa3db4d14f062c879fa41af556b6f42db6b48',
    farmingPoolAddress0: '0x3a726457c84d28c8c2f396f0af41e9eb5393d272',
    farmingPoolAddress1: '0xd986709c5e10942d32c4412fec9c7fbdd5abe51a',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xe4f0a4ff687fd567eb9075c543935e706a49b96f': {
    lendingPoolAddress: '0xe4f0a4ff687fd567eb9075c543935e706a49b96f',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 3,
    gaugeAddress: '0xb3afa9cb6c53d061bc2263ce15357a691d0d60d4',
    symbol0: 'WFTM',
    symbol1: 'gOHM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x91fa20244fb509e8289ca630e5db3e9166233fdc',
    uniswapV2PairAddress: '0xae9bba22e87866e48ccacff0689afaa41eb94995',
    collateralAddress: '0x47b3c2375b846d0e13b19673c7658bfb99eedb3d',
    borrowableAddress0: '0x5516fe3b0f0d620496b784c43877de6d0d722c28',
    borrowableAddress1: '0x3d9219e8089ac7cde166ab533e19792b985c8f9c',
    farmingPoolAddress0: '0x7b579bb44fdb8338452daef3441bb3383049ceb2',
    farmingPoolAddress1: '0x8907b461c46c5305076a55df4775a05736aae2d9',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xf59a41477a7e54274156f32f935b8b1632b3dc92': {
    lendingPoolAddress: '0xf59a41477a7e54274156f32f935b8b1632b3dc92',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 4,
    gaugeAddress: '0x805f756d7b2592637725a1b797088c29c9d6a1f8',
    symbol0: 'WFTM',
    symbol1: 'FRAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
    uniswapV2PairAddress: '0x7ed0cddb9bb6c6dfea6fb63e117c8305479b8d7d',
    collateralAddress: '0xa0005f8d7581a30a48960278b10d6fcbbf04d742',
    borrowableAddress0: '0x26f10d9627cc177c6b7d774c1fbc79f18a86d426',
    borrowableAddress1: '0xfca7ae9316637588dccc687117f6b40c2b96f402',
    farmingPoolAddress0: '0x95c5fe6b843e7dd7d846aa38f3caeb289586f2e7',
    farmingPoolAddress1: '0x420427f4cf2b878ae4d39eb66cadea5b94f986bb',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x488f1f90ab0ab463ceb3534612a051858c22a5a6': {
    lendingPoolAddress: '0x488f1f90ab0ab463ceb3534612a051858c22a5a6',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 10,
    gaugeAddress: '0x717bde1aa46a0fcd937af339f95361331412c74c',
    symbol0: 'LQDR',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x4fe6f19031239f105f753d1df8a0d24857d0caa2',
    collateralAddress: '0x694a273fe35d11e182a9de90ad25e9a99d1c90e4',
    borrowableAddress0: '0x7a5a79b287a527f234d6e2c5454de2fc38f1f12d',
    borrowableAddress1: '0x725e21976b57cfc22ecdcec306d2c353313c7998',
    farmingPoolAddress0: '0xe028a3016221e37a2ee70eecbea00157aa6ad4cc',
    farmingPoolAddress1: '0xef52c47254255582477b5c5cbe66ca7340efb35e',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x1fe1ea0d4d8f0df19c5c088b20af8f8977769096': {
    lendingPoolAddress: '0x1fe1ea0d4d8f0df19c5c088b20af8f8977769096',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 62,
    symbol0: 'USDC',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0x9606d683d03f012dda296ef0ae9261207c4a5847',
    collateralAddress: '0xad9788d18e5cce209442126201ed64ba3b374c7b',
    borrowableAddress0: '0xb564899ba911c4f6a25a7aecc4b8808a487dc8c2',
    borrowableAddress1: '0xa8f3eba35c95a56cbcf506204bf47523c290936e',
    farmingPoolAddress0: '0xd3f1c4b71d29bd7af9e63bace25be74f9336a17c',
    farmingPoolAddress1: '0xaeacf08bcec0776f80b32a735439d8214d9b63d0',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x9bb4f82b821b63c5d912578b7f93548f5f25e749': {
    lendingPoolAddress: '0x9bb4f82b821b63c5d912578b7f93548f5f25e749',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 65,
    symbol0: 'USDC',
    symbol1: 'fUSDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    uniswapV2PairAddress: '0xe7f86cef8fef60ce5050899d1f8e465c00d04a79',
    collateralAddress: '0x0b9a5ec57875c7ebea2094d8dc8b9f0b65f3f96f',
    borrowableAddress0: '0x74aa611d3b13c13574327733862146858d80c6fe',
    borrowableAddress1: '0xc805a375369ed22fa9d0a77ee66151efc8c7763a',
    farmingPoolAddress0: '0x0eca1934a22be3262387c71af2b1e841f7fd9d3e',
    farmingPoolAddress1: '0x3bd27485dfe0b15e4784105eebbb23d1957eb82a',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xff85dc4afe552a3475f04ae260b9575f586d7225': {
    lendingPoolAddress: '0xff85dc4afe552a3475f04ae260b9575f586d7225',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 66,
    symbol0: 'USDC',
    symbol1: 'FRAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
    uniswapV2PairAddress: '0x1478aec7896e40ae5fb858c77d389f0b3e6cbc5d',
    collateralAddress: '0x33cbbee97742cab1ad2ef03560ef030ce9441835',
    borrowableAddress0: '0xafee6d51c75704bc38e40778ae99e546d5562881',
    borrowableAddress1: '0x6e243b412f91a4b8808e267fdfdaf2bdee500654',
    farmingPoolAddress0: '0x05ae47f4f69c4e6f6aca84a2f9f8866e888b3b53',
    farmingPoolAddress1: '0x38c7e7b245d6f54cbd458631d441de2f699894c7',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x909eb9d5d00c810bb2a1c93ebba3d9dada4ad50e': {
    lendingPoolAddress: '0x909eb9d5d00c810bb2a1c93ebba3d9dada4ad50e',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    pid: 67,
    symbol0: 'USDC',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0xc19c7615237f770179ed93d89126478c60742664',
    collateralAddress: '0x8793f2d5862aaf4ba94634a7ada3c365fa7d7b9e',
    borrowableAddress0: '0x93d3701ffa4dcb5d30bcaf5cf897b08e0deca6dd',
    borrowableAddress1: '0x1fa3f3f1397ef9af4195841687b4341ac0105d95',
    farmingPoolAddress0: '0xc03be6d548131f3f0bb719f30a300780d8fd41ae',
    farmingPoolAddress1: '0x157eb62560d5c636094a08215ba525a89b19f049',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x553ae9592cd4d73d4fb67ce4c891e7043d6fc19b': {
    lendingPoolAddress: '0x553ae9592cd4d73d4fb67ce4c891e7043d6fc19b',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 37,
    symbol0: 'LQDR',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x506ddcc751c7d500f983ffda6ddefbe458ba2c33',
    collateralAddress: '0x76a40408550421b275df554b08b9aae3100e0954',
    borrowableAddress0: '0x741c96fcf88aacc2788f921ac4a62b2a0627f21c',
    borrowableAddress1: '0x7e9a1c333cab2081583b74964ff82696706bba8b',
    farmingPoolAddress0: '0xb2d80109f98079d1d82d19bfb98287c84325d68a',
    farmingPoolAddress1: '0x01b1107d15a1bebab1fedd2f577bfc468de6058a',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x41560b5ea2924190506cbdbdba767f848a395c9e': {
    lendingPoolAddress: '0x41560b5ea2924190506cbdbdba767f848a395c9e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 57,
    symbol0: 'WFTM',
    symbol1: 'BRUSH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x85dec8c4b2680793661bca91a8f129607571863d',
    uniswapV2PairAddress: '0x4ee115137ac73a3e5f99598564905465c101b11f',
    collateralAddress: '0x89c17164cb8f9c1e556fa960303e6a158073290e',
    borrowableAddress0: '0xeeb34feb8dca9a17be17da3184851fafadba62b0',
    borrowableAddress1: '0xafbf06ebbeea01b229d58017ab9afd37d36c9b7a',
    farmingPoolAddress0: '0xaa7685ce9f5897b99d8b68ebe892733c194f1f01',
    farmingPoolAddress1: '0x79728d2de5c752433f7965f567defc8f31f8af1c',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x7c9c8834f8e8a51a15243af2fedb0b91b45cd648': {
    lendingPoolAddress: '0x7c9c8834f8e8a51a15243af2fedb0b91b45cd648',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 45,
    symbol0: 'USDC',
    symbol1: 'TUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x9879abdea01a879644185341f7af7d8343556b7a',
    uniswapV2PairAddress: '0x12692b3bf8dd9aa1d2e721d1a79efd0c244d7d96',
    collateralAddress: '0xcc908a1f512dee9677b79a2e5cbb20a6e06f76ed',
    borrowableAddress0: '0x3536f53757679c8f31aa2dfb2534fbcc51650e25',
    borrowableAddress1: '0xc939ba5fcb0f1c03cb138197d86458cf8b3e19aa',
    farmingPoolAddress0: '0x285dc9aa8344e92556ba2662255cf28e210c5402',
    farmingPoolAddress1: '0x5f057a79c0cb794b5b0de6a827d305c8753c811b',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xfa7092af55474ec37bfde6947170792083fdf40f': {
    lendingPoolAddress: '0xfa7092af55474ec37bfde6947170792083fdf40f',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 54,
    symbol0: 'WFTM',
    symbol1: 'MATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x40df1ae6074c35047bff66675488aa2f9f6384f3',
    uniswapV2PairAddress: '0x7051c6f0c1f1437498505521a3bd949654923fe1',
    collateralAddress: '0x048d8948fb326754b177cc5f6f9c873402f66e05',
    borrowableAddress0: '0x4f04f1d467de9172a88c67f845f08d6961f39e6c',
    borrowableAddress1: '0x61b4ba643e952464e7b3b0b6a44973e2a342508e',
    farmingPoolAddress0: '0xeb238a070141232e108d839273fdd019c66a065e',
    farmingPoolAddress1: '0x68bad952978b2adc8f6b95942b943269cf48364b',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xf8a2f66a4dd770cb3ee7ba810992eb478964b96f': {
    lendingPoolAddress: '0xf8a2f66a4dd770cb3ee7ba810992eb478964b96f',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 52,
    symbol0: 'WFTM',
    symbol1: 'AVAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x511d35c52a3c244e7b8bd92c0c297755fbd89212',
    uniswapV2PairAddress: '0x5df809e410d9cc577f0d01b4e623c567c7ad56c1',
    collateralAddress: '0xf9cb063fbca0b8f3ae855b5f4fd104ee471f1d2a',
    borrowableAddress0: '0xa7e140cadd68aeed5874b3417741c0176f85ace4',
    borrowableAddress1: '0xf8a8965044027812572969e98ddafb1a29ab705c',
    farmingPoolAddress0: '0xcd2ff1ce9558f551502d0383218c9c5b2c879eac',
    farmingPoolAddress1: '0x81a35a3544dc27f1aa206023e327b215e98c6ecf',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x2e0b129d3dd14fcd676695c0a7ddef9fcc13e74e': {
    lendingPoolAddress: '0x2e0b129d3dd14fcd676695c0a7ddef9fcc13e74e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 58,
    symbol0: 'WFTM',
    symbol1: 'BEETS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e',
    uniswapV2PairAddress: '0x648a7452da25b4fb4bdb79badf374a8f8a5ea2b5',
    collateralAddress: '0xc50ec5f995f76aee07ebf0f66c0e73c39d0ce79b',
    borrowableAddress0: '0x0bcc954cfb4dbbcd808e84529aae1e1834a0c2c2',
    borrowableAddress1: '0xdf0148de898f3a229ecd7ef414eeef54fb8fe841',
    farmingPoolAddress0: '0xc9e87920b46117a5f6735e5d0415a85fc43891e4',
    farmingPoolAddress1: '0x249b8042d536d8e74f00d41979e845065e0877df',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xa60b150392509636346eeb76d828ec765eff8e27': {
    lendingPoolAddress: '0xa60b150392509636346eeb76d828ec765eff8e27',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 56,
    symbol0: 'HND',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10010078a54396f62c96df8532dc2b4847d47ed3',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x16b6deee778bbcf427e40ffa3c14c4840be10e6a',
    collateralAddress: '0x384c28410c766a009358e56532e74d495b9e6512',
    borrowableAddress0: '0x5501a370fd23615575aa03a2dc4ea606d3ada88c',
    borrowableAddress1: '0xeb0908108ff291c8b4e51dfd90af0d7ab9884b1f',
    farmingPoolAddress0: '0xfa7d3ff16f478618aa8b332c657308f27ace3067',
    farmingPoolAddress1: '0xa52a96198462a6b593c8b4fe0016de3273c284c9',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x4e890227a33fdd230de6b369aac0b397cc60a0e6': {
    lendingPoolAddress: '0x4e890227a33fdd230de6b369aac0b397cc60a0e6',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.VEDAO,
    pid: 9,
    symbol0: 'USDC',
    symbol1: 'WeVE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x911da02c1232a3c3e1418b834a311921143b04d7',
    uniswapV2PairAddress: '0xff85fc809e760a09e5940546ce66dde579144a37',
    collateralAddress: '0x29607b783011abdda5eaf393151465322902104c',
    borrowableAddress0: '0xffaaad83e45a6ae6b6ae52a5159ebe083dcdcaf0',
    borrowableAddress1: '0xd30c1342248a5a08edfdc22d6d096584f59a22b2',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x2d4f1bdf1b2fd05aa879d9cd3228f0e80a0bf20f': {
    lendingPoolAddress: '0x2d4f1bdf1b2fd05aa879d9cd3228f0e80a0bf20f',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.T2OMB,
    pid: 0,
    symbol0: 'WFTM',
    symbol1: '2OMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae',
    uniswapV2PairAddress: '0xbdc7dfb7b88183e87f003ca6b5a2f81202343478',
    collateralAddress: '0xa9fa893fe8eef3d011c3bcf15c8b36588e673383',
    borrowableAddress0: '0x612fe8cbf116141bb34eef8efee0bc17d3a82d86',
    borrowableAddress1: '0x8d1103706c98d8abafb3030cb0877a3d78dabad3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x3c3e863f2e956ba14ff7fb5ec9e4d6d3ea868927': {
    lendingPoolAddress: '0x3c3e863f2e956ba14ff7fb5ec9e4d6d3ea868927',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.T2OMB,
    pid: 1,
    symbol0: 'WFTM',
    symbol1: '2SHARES',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc54a1684fd1bef1f077a336e6be4bd9a3096a6ca',
    uniswapV2PairAddress: '0x6398acbbab2561553a9e458ab67dcfbd58944e52',
    collateralAddress: '0x51084b9e086ffce96189ceaa817c1d094fa5c89c',
    borrowableAddress0: '0xbb2c39bfc2764ed8600eda0db56ea67a7b249477',
    borrowableAddress1: '0x1fcbaed61092d5df8416eaff1f9b968a47fed505',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xaa21293e9734569fe8264a9a789240a4ba4716bf': {
    lendingPoolAddress: '0xaa21293e9734569fe8264a9a789240a4ba4716bf',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.T2OMB,
    pid: 2,
    symbol0: '2OMB',
    symbol1: '2SHARES',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae',
    tokenAddress1: '0xc54a1684fd1bef1f077a336e6be4bd9a3096a6ca',
    uniswapV2PairAddress: '0xd9b5f00d183df52d717046521152303129f088dd',
    collateralAddress: '0x78a92bebef6d06fb3cf0f66bf9e16307c8d2c08a',
    borrowableAddress0: '0x850c404597673d81b1e7d4644be628accb976cc7',
    borrowableAddress1: '0xa906bb21d5f11f502e93080ab1b4642b8b80931f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xb7a079e3e2a6b44983a7206dcf4f392acd55a70e': {
    lendingPoolAddress: '0xb7a079e3e2a6b44983a7206dcf4f392acd55a70e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.T3OMB,
    pid: 0,
    symbol0: '3OMB',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x14def7584a6c52f470ca4f4b9671056b22f4ffde',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x83a52eff2e9d112e9b022399a9fd22a9db7d33ae',
    collateralAddress: '0x8b927c1007807afd5bdb01751b1aba7fc845b62b',
    borrowableAddress0: '0x92130b1676c3864d15d382b4a406923f617fd484',
    borrowableAddress1: '0xa3b3ffdb3a7ec27c4b9d93d388edb94ce42095f2',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x99e4b37aacdd79347406528dd3994243b39c5709': {
    lendingPoolAddress: '0x99e4b37aacdd79347406528dd3994243b39c5709',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.T3OMB,
    pid: 1,
    symbol0: 'WFTM',
    symbol1: '3SHARES',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x6437adac543583c4b31bf0323a0870430f5cc2e7',
    uniswapV2PairAddress: '0xd352dac95a91afefb112dbbb3463ccfa5ec15b65',
    collateralAddress: '0xbcbe87db961ee6e7ab974f4a37a1da64855fcd2c',
    borrowableAddress0: '0xcde8e796038373ff030b56c9717757d293b703eb',
    borrowableAddress1: '0x9061c8471e208e8205a40eecb86040d7730e58f2',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xd7badb2621b3741b645a35675abf176dfe4957bb': {
    lendingPoolAddress: '0xd7badb2621b3741b645a35675abf176dfe4957bb',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.T3OMB,
    pid: 2,
    symbol0: 'WFTM',
    symbol1: '2SHARES',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc54a1684fd1bef1f077a336e6be4bd9a3096a6ca',
    uniswapV2PairAddress: '0x6398acbbab2561553a9e458ab67dcfbd58944e52',
    collateralAddress: '0x1ae697ce7854390c6ced5de8d006e899d2820c8a',
    borrowableAddress0: '0xecebba2f1df5b4d39c9fac123cc60bc8bf735f1d',
    borrowableAddress1: '0xd5cceaa44627f7a7ae63345744877bb656d9ccbb',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xf609fcbbe80ddc8df4a0312ce8c4c1dd95e0868f': {
    lendingPoolAddress: '0xf609fcbbe80ddc8df4a0312ce8c4c1dd95e0868f',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.T3OMB,
    pid: 3,
    symbol0: 'WFTM',
    symbol1: '2OMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae',
    uniswapV2PairAddress: '0xbdc7dfb7b88183e87f003ca6b5a2f81202343478',
    collateralAddress: '0x64db48a35a5d906f1a52ae1fb84e982c91a3e6b2',
    borrowableAddress0: '0x35893e3907c82663688b4965ed2458f9f01ccc6e',
    borrowableAddress1: '0x9ec85bc28c781c6f7eb79a5ee7229d7c3b7a02c6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x7e2ad210fa1d69f799fe45b45d92ec43ba5ef33e': {
    lendingPoolAddress: '0x7e2ad210fa1d69f799fe45b45d92ec43ba5ef33e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 61,
    symbol0: 'WFTM',
    symbol1: 'MULTI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2000000000000',
    adjustSpeed1: '2000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
    uniswapV2PairAddress: '0x297c8990134bf1ee08ae5d8805042fbac8781201',
    collateralAddress: '0x81aac2a04bd238874882bc5ab3ff53215f32280d',
    borrowableAddress0: '0x133e827dfcd415213584363f95b1c686be5dc27e',
    borrowableAddress1: '0xc1a13a6eef5876fbe8fdf0c0b12dac5130a29999',
    farmingPoolAddress0: '0xa373c7e1f82493addbc3dbe8e5359b824ecd9d6e',
    farmingPoolAddress1: '0x7e1978b9001b17f79fca8549d8d122606df023d3',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0x7b05d4f56f617b4ee75886c770cc4277811a34d0': {
    lendingPoolAddress: '0x7b05d4f56f617b4ee75886c770cc4277811a34d0',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 65,
    symbol0: 'WFTM',
    symbol1: 'SOLID',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '5000000000000',
    adjustSpeed1: '5000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x888ef71766ca594ded1f0fa3ae64ed2941740a20',
    uniswapV2PairAddress: '0xc8cc1b89820791665b6f26b00b3111b00e021f19',
    collateralAddress: '0x17f3b2a2ebe6676744fa34387df4517140adc9c2',
    borrowableAddress0: '0x84069262f02a95f5fe8f9f2889003f256c3c5849',
    borrowableAddress1: '0xfbc27b5797667ed94fc381a74c226d444f25ec66',
    farmingPoolAddress0: '0x9ee6db7f952823ed47d4e3f51aa93d95595b5633',
    farmingPoolAddress1: '0xdba325315ec241734feadf4ad44d165f0512a07f',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x35c052bbf8338b06351782a565aa9aad173432ea',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xd0ad79e5acc51afdf4693d8304f40a1a221abe9e': {
    lendingPoolAddress: '0xd0ad79e5acc51afdf4693d8304f40a1a221abe9e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.BASED,
    pid: 0,
    symbol0: 'TOMB',
    symbol1: 'BASED',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0x8d7d3409881b51466b483b11ea1b8a03cded89ae',
    uniswapV2PairAddress: '0xab2ddcbb346327bbdf97120b0dd5ee172a9c8f9e',
    collateralAddress: '0x3aa7e4128f84a605ac136ba56289d50b8e3cfa09',
    borrowableAddress0: '0x62fad47ed7cf0d88806c4985863b706584350e05',
    borrowableAddress1: '0xe9108e10723cbe6e64f3f55159e45974b4029859',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x2a42314f7b4b43ec588f709580350288a64a5c71': {
    lendingPoolAddress: '0x2a42314f7b4b43ec588f709580350288a64a5c71',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.BASED,
    pid: 1,
    symbol0: 'WFTM',
    symbol1: 'BSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x49c290ff692149a4e16611c694fded42c954ab7a',
    uniswapV2PairAddress: '0x6f607443dc307dcbe570d0ecff79d65838630b56',
    collateralAddress: '0x32f502d7273cde0c877de08782cfc8d221478655',
    borrowableAddress0: '0x923d731f0a75e4af9000646da301b78fe33d71e9',
    borrowableAddress1: '0xb0b71b193b0f1a86c813738567c4448713e23ca9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x09b8bb15e7706c4df8a1a9a3e02659f37b07542b': {
    lendingPoolAddress: '0x09b8bb15e7706c4df8a1a9a3e02659f37b07542b',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 2,
    symbol0: 'TOMB',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x45f4682b560d4e3b8ff1f1b3a38fdbe775c7177b',
    collateralAddress: '0xa745b8365c34b9631a3151f8657d1600f82b7e60',
    borrowableAddress0: '0xba36f108d499804d0d434a2f302aec4b0ab6f40b',
    borrowableAddress1: '0xcdbd99ce5b6b8caa87ab7332e59eed2a5cdf99f0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xdadb090017ba6d5d320872d96b823531f6b80a44': {
    lendingPoolAddress: '0xdadb090017ba6d5d320872d96b823531f6b80a44',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 23,
    symbol0: 'WFTM',
    symbol1: 'BIFI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd6070ae98b8069de6b494332d1a1a81b6179d960',
    uniswapV2PairAddress: '0x1656728af3a14e1319f030dc147fabf6f627059e',
    collateralAddress: '0x9a3221a8bbd6c2bc318a6410a0e96f428aee9522',
    borrowableAddress0: '0x02abd28ac6c7161aa556a2e5dc9bea54896695c9',
    borrowableAddress1: '0xd0ea500a1219ef9339058748a82cf625f31b586b',
    farmingPoolAddress0: '0xbf70ba3299a2b1d4426a9079e800136616179cf0',
    farmingPoolAddress1: '0x263199057f1a606a2c05a1ee4fbeeff4908f3ed0',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xffa854ab1aec94c39494e20dc0b326e1fe81aec0': {
    lendingPoolAddress: '0xffa854ab1aec94c39494e20dc0b326e1fe81aec0',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 69,
    symbol0: 'USDC',
    symbol1: 'BOO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    uniswapV2PairAddress: '0xf8cb2980120469d79958151daa45eb937c6e1ed6',
    collateralAddress: '0x4938681cc05ba234947c2353db4a47ddf89b3752',
    borrowableAddress0: '0xfea97187c7d46a1e29d4f35e7314d37859902a2c',
    borrowableAddress1: '0x0af723efb0c4fd494054bf58e1cd757c26028735',
    farmingPoolAddress0: '0x137ffffb1a2b79adc4137f1495c9d10b755b2bfb',
    farmingPoolAddress1: '0xdb8cd3f464a4eaa13e3c624391aca94252ef6dcb',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x0e4e825977e168f97148fe5656fb4c774ca2ff9a': {
    lendingPoolAddress: '0x0e4e825977e168f97148fe5656fb4c774ca2ff9a',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 70,
    symbol0: 'WFTM',
    symbol1: 'APE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x2d72a97a31dc920db03330780d30074626e39c8a',
    uniswapV2PairAddress: '0xc9fb686f14bda7e2653cf8f605dc8551b6a53fd3',
    collateralAddress: '0xa98cb22784d80316281867d35b80e3b5dc2621aa',
    borrowableAddress0: '0xbc05b4834fedb1c74df54777c4439023c0df4534',
    borrowableAddress1: '0xf57271445f1a2fd0061fb2e9a91243ef71385561',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xf0fdd57186359208aa2ba0175436c86372208f18': {
    lendingPoolAddress: '0xf0fdd57186359208aa2ba0175436c86372208f18',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 29,
    gaugeAddress: '0x7a91957097e85bb933828d4cc7db287f573d0b2f',
    symbol0: 'WFTM',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0x2599eba5fd1e49f294c76d034557948034d6c96e',
    collateralAddress: '0x0f72d6d19c87555af68dffb71f83c3910af90761',
    borrowableAddress0: '0xa7f40a6cbc2626bcc097f179b4d23e4525e4bf1b',
    borrowableAddress1: '0x7585fe31fb3521a352f88db90c6ab142a1221f2a',
    farmingPoolAddress0: '0xa860f48176fce7bde1dc298706a95b8752c0a104',
    farmingPoolAddress1: '0x37ab40ef5dbe7ccf3beab3117bf49a7d1eae1ae6',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x98220747eef09c849fda1961c018a6c949464010': {
    lendingPoolAddress: '0x98220747eef09c849fda1961c018a6c949464010',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 30,
    gaugeAddress: '0x6cb0ca6635027623684ebd3387a6f5188fe90ea2',
    symbol0: 'USDC',
    symbol1: 'DEI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3',
    uniswapV2PairAddress: '0x8efd36aa4afa9f4e157bec759f1744a7febaea0e',
    collateralAddress: '0x3f37739646b3eefbd8b1769b78b96e3fdde3de88',
    borrowableAddress0: '0x23013fd32b980ce78acebdaa9dfd8e79390b175a',
    borrowableAddress1: '0x2945ac0fc468bcb91950eea2372c1253c704ba23',
    farmingPoolAddress0: '0xcdd5b7941b4138eb62b604604656ba1e7b4e8798',
    farmingPoolAddress1: '0x8ec877da515b7aa5ad7d5d6a0624ca4022ff4e7f',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x300295c6ef497ba622a869b847f2b40ee379452f': {
    lendingPoolAddress: '0x300295c6ef497ba622a869b847f2b40ee379452f',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 32,
    gaugeAddress: '0xdcd990038d9cbe98b84a6ad9dbc880e3d4b06599',
    symbol0: 'WFTM',
    symbol1: 'CRE8R',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x2ad402655243203fcfa7dcb62f8a08cc2ba88ae0',
    uniswapV2PairAddress: '0x459e7c947e04d73687e786e4a48815005dfbd49a',
    collateralAddress: '0x69edbca1cb658f056523a1efa23a1beaf8bdbe61',
    borrowableAddress0: '0x2a697a95219ff80315510d351dc1985401ce3de2',
    borrowableAddress1: '0x6cc71989cf3440c11f7aab4ec25aaaba16d81943',
    farmingPoolAddress0: '0xa975f642167fe18cd0d864405c71f91577a28f93',
    farmingPoolAddress1: '0x910caf033b3bc457bcb706b528268c1944de71e4',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x7c630afa6255a80915932f9921ba12576db54a63': {
    lendingPoolAddress: '0x7c630afa6255a80915932f9921ba12576db54a63',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 41,
    gaugeAddress: '0x356169bea8c58c3b59e83c650a1608fc54d0c44a',
    symbol0: 'WFTM',
    symbol1: 'BIFI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd6070ae98b8069de6b494332d1a1a81b6179d960',
    uniswapV2PairAddress: '0xc28cf9aebfe1a07a27b3a4d722c841310e504fe3',
    collateralAddress: '0xe9c4b53c4ea943398080ba6680baad71e6355dab',
    borrowableAddress0: '0xfa0c5884aa1c98d27c10b34488fe840438be3190',
    borrowableAddress1: '0x5500781137e7dd0f18bf13fdc42f53bef707ddd3',
    farmingPoolAddress0: '0x7b669ed4806fab903680c2fb2bbbd746e2416ea3',
    farmingPoolAddress1: '0xd4f8795b28c73f52384124dd6d6564381aff81bd',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xc0d477f3429d629208a390cbf123aecb534cc8eb': {
    lendingPoolAddress: '0xc0d477f3429d629208a390cbf123aecb534cc8eb',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 47,
    gaugeAddress: '0xab9f86efd519eb9b110542faf984780e3d99e697',
    symbol0: 'WFTM',
    symbol1: 'beFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x7381ed41f6de418dde5e84b55590422a57917886',
    uniswapV2PairAddress: '0xe3d4c22d0543e050a8b3f713899854ed792fc1bd',
    collateralAddress: '0x459f52b2f79824dc606caa639e44ce18a2009611',
    borrowableAddress0: '0xcea57ecccd53a96054b1b03573cf87c7788d48ed',
    borrowableAddress1: '0x1696c05acdf942a4d60ee85c7808d90695f8a166',
    farmingPoolAddress0: '0x459662b66f944c902f228b6bbdcff1ed4b5710f0',
    farmingPoolAddress1: '0xb1d474a181146618f18ca9aba52a3e3a37c9e78a',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x016ddef7abbe7f9e8da8c2bd655922a9b8986d5b': {
    lendingPoolAddress: '0x016ddef7abbe7f9e8da8c2bd655922a9b8986d5b',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 52,
    gaugeAddress: '0xb1bc9455db839655b1a3c1c128e6ab094190b724',
    symbol0: 'WFTM',
    symbol1: 'UST',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x846e4d51d7e2043c1a87e0ab7490b93fb940357b',
    uniswapV2PairAddress: '0xfa84ced3dc4bffaf93d21b9e3a4750f5c2a42886',
    collateralAddress: '0x250bcc829fa1908cea5e74ca07d3a75fde2bb3b4',
    borrowableAddress0: '0x47bd57dae9d57d3de292b236bc0c748335488327',
    borrowableAddress1: '0xa059cca4f26a14a79a67435571733e08df9b4afe',
    farmingPoolAddress0: '0x63b22461e2eb8bcf5230d32e4309aa8ebbecd613',
    farmingPoolAddress1: '0x246abef96e6102aff8eed8c39829e53613a199e1',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x04804e6f84e0c7e4660b7323127871030b87687a': {
    lendingPoolAddress: '0x04804e6f84e0c7e4660b7323127871030b87687a',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 3,
    symbol0: 'WFTM',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0xfca12a13ac324c09e9f43b5e5cfc9262f3ab3223',
    collateralAddress: '0x89e2edd65bb05351f5b8d3e5d245bfb38747e991',
    borrowableAddress0: '0xcb61e66a8a6a62afb14858965d887952984587b9',
    borrowableAddress1: '0xbdea9419f069001907c13808b4f68282e013e118',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe6d31ab5607eb7618a16b5923b67314d16bd350f': {
    lendingPoolAddress: '0xe6d31ab5607eb7618a16b5923b67314d16bd350f',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 4,
    symbol0: 'TSHARE',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '990000000000000000',
    kinkUtilizationRate1: '990000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x67b2faf48c1710ff1d2a9ac429b726b8f63ee83c',
    collateralAddress: '0x73151cca9e1fb165ab4e527a3ebc4d22a66e8980',
    borrowableAddress0: '0xc14f09b04c99f73a6c7ef5513763dabf54a1cc58',
    borrowableAddress1: '0x280dc734aa2592a2f0d4582200cfd6d8067b72a8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x4601ad6ffd55f15dadc639b8704b19dc4b7dfc91': {
    lendingPoolAddress: '0x4601ad6ffd55f15dadc639b8704b19dc4b7dfc91',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.BASED,
    pid: 6,
    symbol0: 'BASED',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x8d7d3409881b51466b483b11ea1b8a03cded89ae',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x7b5b3751550be4ff87ac6bda89533f7a0c9825b3',
    collateralAddress: '0x975fae1e67390a2a40b333808a65faf468880607',
    borrowableAddress0: '0x6dd61797dabf84985c1ca3941c5abc0fe254aa5f',
    borrowableAddress1: '0x4fba508ce473ac808ca742e37c60ef9a9e3531ad',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x6d368f3f94601cce3f9806381a6132feb0dd6272': {
    lendingPoolAddress: '0x6d368f3f94601cce3f9806381a6132feb0dd6272',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.BASED,
    pid: 7,
    symbol0: 'TOMB',
    symbol1: 'BASED',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0x8d7d3409881b51466b483b11ea1b8a03cded89ae',
    uniswapV2PairAddress: '0x172bfaa8991a54abd0b3ee3d4f8cbdab7046ff79',
    collateralAddress: '0x6f192c6c441c06200ae26060fb22f8a42e92b954',
    borrowableAddress0: '0xe5f57902b2a6f2607855f1707fd3bec9b74796c4',
    borrowableAddress1: '0x38425f311aa93241f5f0d606b8967c2900af0173',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe841cd70e83ddee17f5432e06b12b77914fff4b8': {
    lendingPoolAddress: '0xe841cd70e83ddee17f5432e06b12b77914fff4b8',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.BASED,
    pid: 6,
    symbol0: 'BASED',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x8d7d3409881b51466b483b11ea1b8a03cded89ae',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x7b5b3751550be4ff87ac6bda89533f7a0c9825b3',
    collateralAddress: '0x8bdfd042ce29fb43b0b0b7dbceeab99169964700',
    borrowableAddress0: '0x78d8336f9046e45a374fff00278794d615987213',
    borrowableAddress1: '0xc1ae31030b419920e8b14376830082bda0c967f2',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xc69552bf7cd99aa3faf2bbbd5b4aa5b2857513b5': {
    lendingPoolAddress: '0xc69552bf7cd99aa3faf2bbbd5b4aa5b2857513b5',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.BASED,
    pid: 7,
    symbol0: 'TOMB',
    symbol1: 'BASED',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0x8d7d3409881b51466b483b11ea1b8a03cded89ae',
    uniswapV2PairAddress: '0x172bfaa8991a54abd0b3ee3d4f8cbdab7046ff79',
    collateralAddress: '0x2f0b5e091cc1e36636cb077d623c87a770e12511',
    borrowableAddress0: '0x445f69a4a1e6a5f15980a560bf9deb444ee51ac1',
    borrowableAddress1: '0x44e0f499176ad70bde076d209a5ff3b7acd6fc0d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xf96e636366c672447d40585b076459412f0fadb2': {
    lendingPoolAddress: '0xf96e636366c672447d40585b076459412f0fadb2',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 75,
    symbol0: 'WFTM',
    symbol1: 'LUNA',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x593ae1d34c8bd7587c11d539e4f42bff242c82af',
    uniswapV2PairAddress: '0xd8934c58de27e7b6342924b185da0b3ab009f959',
    collateralAddress: '0x95f3c7edfbd3da7f2cd75f04e0cea6186bf6fd55',
    borrowableAddress0: '0x5990ddc40b63d90d3b783207069f5b9a8b661c1c',
    borrowableAddress1: '0xae352a9a76ca357563f6d59d892551c207da41f0',
    farmingPoolAddress0: '0xe6b49bb5968c51726d949f5f544ce9532b648ed2',
    farmingPoolAddress1: '0xe973b3684e382d03d73e88f8ede79daff0978a63',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x675c8dacc134b28c5cb256ff26bbde10f5f27586': {
    lendingPoolAddress: '0x675c8dacc134b28c5cb256ff26bbde10f5f27586',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 76,
    symbol0: 'WFTM',
    symbol1: 'UST',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x846e4d51d7e2043c1a87e0ab7490b93fb940357b',
    uniswapV2PairAddress: '0x656b3264695690322acbad95f994b51c5a8c8edf',
    collateralAddress: '0xce73d9fa32494103dee3dd9d56da939d84f58c8d',
    borrowableAddress0: '0x6bc4c41b4c38c28f36d0b6a650fa93569a69bc5c',
    borrowableAddress1: '0x89bead6ae53c37a2341aaffaab1cacf94c4ba2e6',
    farmingPoolAddress0: '0x6ad1256310b885140d48a7d4632dee1883b71c0c',
    farmingPoolAddress1: '0x5452fccfdd16df26c2ca79ba27723ca3b39e9ff9',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x18fc05a12ce30d7514e8c59c035b7b5cc68a98e0': {
    lendingPoolAddress: '0x18fc05a12ce30d7514e8c59c035b7b5cc68a98e0',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 1,
    symbol0: 'USDC',
    symbol1: 'DEI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3',
    uniswapV2PairAddress: '0xd343b8361ce32a9e570c1fc8d4244d32848df88b',
    collateralAddress: '0xe9353160cad51ef2ae83f71e56283b62c61ed2ad',
    borrowableAddress0: '0x893beb893baf18d18050ec2de71ad4d70f9998f4',
    borrowableAddress1: '0x00ca688f69870a68bd80cb11adf8237a8c594aad',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x86fbecb2e655102e97019358855d0aad9adff26b': {
    lendingPoolAddress: '0x86fbecb2e655102e97019358855d0aad9adff26b',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 2,
    symbol0: 'WFTM',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0xaf918ef5b9f33231764a5557881e6d3e5277d456',
    collateralAddress: '0xa583e23e1d6219d6570a71197471432ac006368b',
    borrowableAddress0: '0x7b0d24741d85c6733d10ed59b22335fbb3ada32e',
    borrowableAddress1: '0xf7c9f2e8df56d3898deaaabe8e35bb98aaf8f749',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x92eb021eda4c3c841d48475a3fa0310d9d5c307e': {
    lendingPoolAddress: '0x92eb021eda4c3c841d48475a3fa0310d9d5c307e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 4,
    symbol0: 'WFTM',
    symbol1: 'sFTMX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd7028092c830b5c8fce061af2e593413ebbc1fc1',
    uniswapV2PairAddress: '0xe67980fc955fecfda8a92bbbfbcc9f0c4be60a9a',
    collateralAddress: '0xb50f9faea66539d845094627f1acae09ee314e88',
    borrowableAddress0: '0xbc96dd6347c261ffe54bb3f0b2504b1be4410e38',
    borrowableAddress1: '0x297ebf6b7179d3fceb69cc0d02ed60c322dfcaf0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x18829eb4d371e2bf93b6d7cb807122cab4577dd7': {
    lendingPoolAddress: '0x18829eb4d371e2bf93b6d7cb807122cab4577dd7',
    isTarotVault: true,
    dex: DEX.SPIRIT,
    vaultType: VaultType.SPIRIT_BOOSTED,
    pid: 55,
    gaugeAddress: '0x3a3c0449fdd642dd9bb714b5b8f90d7f198a0024',
    symbol0: 'WFTM',
    symbol1: 'sFTMX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd7028092c830b5c8fce061af2e593413ebbc1fc1',
    uniswapV2PairAddress: '0x72133bbff5072616e165237e69b3f4c87c1a94e8',
    collateralAddress: '0x456c1086acdbb567a6cbc917ab91e1c2290aaea1',
    borrowableAddress0: '0x661fc67b5c00dfff84646e80de9efc0312d7e18b',
    borrowableAddress1: '0x18392d7112f533540b18bae28af46aa2acef09e6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x22b20d4c5ea37f9227915e57cdeebf75e40eebf4': {
    lendingPoolAddress: '0x22b20d4c5ea37f9227915e57cdeebf75e40eebf4',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 6,
    symbol0: 'BTC',
    symbol1: 'TSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x321162cd933e2be498cd2267a90534a804051b11',
    tokenAddress1: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    uniswapV2PairAddress: '0xa1f4a9ee0d06115376dff357d34c3f5eb4107398',
    collateralAddress: '0xbd9f91ca626b230d0c95fc4a12237c0ce0a8ebf6',
    borrowableAddress0: '0xf0763274fd6578077ce687f2c0abe92a1cfa3b1d',
    borrowableAddress1: '0x6fd968c8a09f3653524f746a99ccb6da7bd46b12',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xff31d4850943291033de5d531f1844bf21e923d3': {
    lendingPoolAddress: '0xff31d4850943291033de5d531f1844bf21e923d3',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 7,
    symbol0: 'TSHARE',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xd702d7495b010936ebc53a1efee42d97996ca5ee',
    collateralAddress: '0x4a3d8d1fc8b01f0c8216cb4a73b0b34255253419',
    borrowableAddress0: '0x9487ce2f8e6c0cae2cdc272c097da0dcfbb24f2c',
    borrowableAddress1: '0xa7861cb21e3181864760e247ce23d33d5dc72ae2',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x3fa4226faa9cc45c9b1317377ec450c58e54d2c1': {
    lendingPoolAddress: '0x3fa4226faa9cc45c9b1317377ec450c58e54d2c1',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 8,
    symbol0: 'USDC',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0xedf6595e2ae78a4e5c8725d7ed2a28ad974f1b8e',
    collateralAddress: '0x85caf116d763eff267e8ff21d2a865bbbb37bd4e',
    borrowableAddress0: '0xdb1afbc52eaacde3b5d4964fe344a6a5b9ce1338',
    borrowableAddress1: '0x165993286dd83ea4e366735116b0316d6a7f026d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xc27f2bc83b5e82a89810f368363f021b2fc056fc': {
    lendingPoolAddress: '0xc27f2bc83b5e82a89810f368363f021b2fc056fc',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 9,
    symbol0: 'USDC',
    symbol1: 'fUSDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    uniswapV2PairAddress: '0x3486011e2e18ccf4558c4c84d5cbbccfdbf16c03',
    collateralAddress: '0x9ab2a4c5a5d3e7f170885a0ad367713141537b23',
    borrowableAddress0: '0xa04e4bb66b686afc4750fc0e0ce4db3b9664e3b2',
    borrowableAddress1: '0xe75370b44075a2c441d04c4cc0c85443974908a3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe41451cf4f75cefda4a9d78f9a6ace46a5115897': {
    lendingPoolAddress: '0xe41451cf4f75cefda4a9d78f9a6ace46a5115897',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 10,
    symbol0: 'USDC',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0xd840af68b35469ec3478c9b0cbcddc6dc80dd98c',
    collateralAddress: '0xa287ddd1f60297990e56a4fcf82b265eb71d5aa8',
    borrowableAddress0: '0x18d40342042aa36eb4a2c03744d7c9114ec2a281',
    borrowableAddress1: '0x38131fce386d6fe5acba4d7fdac84737b036a41b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x28fea858ec73548c0308f7b9356a4e6911a589c5': {
    lendingPoolAddress: '0x28fea858ec73548c0308f7b9356a4e6911a589c5',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 11,
    symbol0: 'USDC',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0xaa9be68d990d5e56870b2e0544f96ffb0b1da8f7',
    collateralAddress: '0x5565b216c112ac69f21193da9d2eac1a583ab10e',
    borrowableAddress0: '0xd5e0c06535c3a698278e7736c3d4179dbf631b44',
    borrowableAddress1: '0x6f266ead5389a2f2febde3e6fac95abd3da4e65d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x30aac38a9527d82e0cc7105b4be7871c36daabe3': {
    lendingPoolAddress: '0x30aac38a9527d82e0cc7105b4be7871c36daabe3',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 12,
    symbol0: 'USDC',
    symbol1: 'TSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    uniswapV2PairAddress: '0xdec1259188e6c5273acd1e84d5b4b58897ca013e',
    collateralAddress: '0x553728d83d89924d10c09c400d8f9be325e70443',
    borrowableAddress0: '0x0acb77118cd570a95068f7dea9c9ce59d88bbc25',
    borrowableAddress1: '0x1f615aa66bff3384d28f67a1db6527f95c4e2cf0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x45de440a365209a14f252734b77d9be58c96c510': {
    lendingPoolAddress: '0x45de440a365209a14f252734b77d9be58c96c510',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 13,
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x8c853ce1561a2c2cd2e857670e3ccd04ba4cb27b',
    collateralAddress: '0x8aa5937dcf5162a51c273145ddf209270b396b4c',
    borrowableAddress0: '0x3ca8e0675a4436acc6a29b9f28ed2fbe94cab5ce',
    borrowableAddress1: '0x98c0d6fbf237afe3691808e356b2e0adfd52ced1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xd06214f62d4624c0c531c8c27bf3263e72d868d4': {
    lendingPoolAddress: '0xd06214f62d4624c0c531c8c27bf3263e72d868d4',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 78,
    symbol0: 'USDC',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x35a60b8c750b4d834a27443f35269a84d06de391',
    collateralAddress: '0xb47b46b0464b363e45ba62773bccbff80accf387',
    borrowableAddress0: '0xc81b902d10a6de1bf150c9261ba931e734ae7bb5',
    borrowableAddress1: '0x9bb784e77b69a36ce7a4f16cb16d25bbe56925a5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xc9624a1f71592eed1df7169562e73af50cd49c43': {
    lendingPoolAddress: '0xc9624a1f71592eed1df7169562e73af50cd49c43',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 14,
    symbol0: 'USDC',
    symbol1: 'FUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xad84341756bf337f5a0164515b1f6f993d194e1f',
    uniswapV2PairAddress: '0x38ff5377a42d0a45c829de45801481869087d22c',
    collateralAddress: '0x56940874608bcb219a0c69af440ea36b9be5aae2',
    borrowableAddress0: '0x44b79e8483db8699400f3849725ba9c0f9ae3bd7',
    borrowableAddress1: '0xb9c6bdaf41ef4a04f0f6874eb64035ff8de361e4',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xf0843e749ac88ba7d20660c61a81101280798b0e': {
    lendingPoolAddress: '0xf0843e749ac88ba7d20660c61a81101280798b0e',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 16,
    symbol0: 'ZOO',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 0,
    decimals1: 18,
    tokenAddress0: '0x09e145a1d53c0045f41aeef25d8ff982ae74dd56',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0x67019e7b4a233cc2e875e5c713042333d879aace',
    collateralAddress: '0x3c80ec3112bcc46846e4d81021fb22a7da589d2c',
    borrowableAddress0: '0x15481370ba9abae14c5d853a27644bdf6d38cd11',
    borrowableAddress1: '0x7e41321788ed396d1756c0770dfb03c59cb81e28',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x9f5ff4c6c040c9e39f50ba92cae2de28f67fbc1e': {
    lendingPoolAddress: '0x9f5ff4c6c040c9e39f50ba92cae2de28f67fbc1e',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.TOMB,
    pid: 17,
    symbol0: 'TOMB',
    symbol1: 'TREEB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0xc60d7067dfbc6f2caf30523a064f416a5af52963',
    uniswapV2PairAddress: '0x801d17c21d0808bc00d46e2f081214c9d82f4fbf',
    collateralAddress: '0x4a07fdc332254bdd36075d622ad75a2033976608',
    borrowableAddress0: '0x1a34e3be20a966d046ff43ab3b6f5d8f5c7978d4',
    borrowableAddress1: '0x0d2d6208ae09a68628d1f1ba0ff8b5d4a6c11e26',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe8087d14c53ea14c860973d2c64c69c011925a1c': {
    lendingPoolAddress: '0xe8087d14c53ea14c860973d2c64c69c011925a1c',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    pid: 74,
    symbol0: 'WFTM',
    symbol1: 'beFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x7381ed41f6de418dde5e84b55590422a57917886',
    uniswapV2PairAddress: '0xb5512e3fa8304d33cdae4a40c21f1d3f70eba45a',
    collateralAddress: '0x4b14daea4b33deacc68ef92077fa17e0fff814a1',
    borrowableAddress0: '0xd60d8e6a0cdb12357bb3fcd332cf97135a21f38b',
    borrowableAddress1: '0x47d5f54f6274299e2a569e7f5e7642d6967aa9ea',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xcc675d69563774feb559834d90064bbff8acd787': {
    lendingPoolAddress: '0xcc675d69563774feb559834d90064bbff8acd787',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 0,
    symbol0: 'WFTM',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0xfca12a13ac324c09e9f43b5e5cfc9262f3ab3223',
    collateralAddress: '0xc383465564864565ca1741d08192cde9c5beb74a',
    borrowableAddress0: '0xf5099a10ca1b80757777507c784c49688c1f560a',
    borrowableAddress1: '0xdbf394be52302987d65ed591e00c12791a6e66f6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xc6879cc07960d9862d3a3d942ab8c5ee4fc9e430': {
    lendingPoolAddress: '0xc6879cc07960d9862d3a3d942ab8c5ee4fc9e430',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 1,
    symbol0: 'USDC',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0xaa9be68d990d5e56870b2e0544f96ffb0b1da8f7',
    collateralAddress: '0x89fc27dacb92e4f149c32762fbb69e39f8a52ba5',
    borrowableAddress0: '0x7c66609513b5189d76c0cd34dc695311ad05873f',
    borrowableAddress1: '0xeb4dedb9a9eed1d87e2bb4c0ec31a77af865b20a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xd8d4a4738e285c33a2890fb2e225c692b84c55ca': {
    lendingPoolAddress: '0xd8d4a4738e285c33a2890fb2e225c692b84c55ca',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 2,
    symbol0: 'ZOO',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 0,
    decimals1: 18,
    tokenAddress0: '0x09e145a1d53c0045f41aeef25d8ff982ae74dd56',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0x67019e7b4a233cc2e875e5c713042333d879aace',
    collateralAddress: '0x84bbff4bf4eb3bab3095eae43d871789054cf884',
    borrowableAddress0: '0x602a1fe1dfdc645831952cf29bef8676b80b3349',
    borrowableAddress1: '0x826ac6017c7c9003e54c78ad33bb71624a9b6d9b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe9ee1370f81439e522ffd244e5ee1cc3ad7a7f01': {
    lendingPoolAddress: '0xe9ee1370f81439e522ffd244e5ee1cc3ad7a7f01',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 3,
    symbol0: 'TOMB',
    symbol1: 'TREEB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0xc60d7067dfbc6f2caf30523a064f416a5af52963',
    uniswapV2PairAddress: '0x801d17c21d0808bc00d46e2f081214c9d82f4fbf',
    collateralAddress: '0x5f5d4300bc335b85febcb2968878e5a6b44b1a47',
    borrowableAddress0: '0x3867f13f9dd4492bebc32e0dd429eb739b79c6b1',
    borrowableAddress1: '0xdc5bfd89b1521556701bbf8c2e50114a3f472238',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x8bc3fa22095fff2461321475031c231ae76c42dc': {
    lendingPoolAddress: '0x8bc3fa22095fff2461321475031c231ae76c42dc',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 4,
    symbol0: 'BTC',
    symbol1: 'TSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x321162cd933e2be498cd2267a90534a804051b11',
    tokenAddress1: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    uniswapV2PairAddress: '0xa1f4a9ee0d06115376dff357d34c3f5eb4107398',
    collateralAddress: '0xe918c1714e5460922742914c6ede1285c28119ba',
    borrowableAddress0: '0x6c998cbc0d5d2869883a4c58bc8da39e1f5a19f4',
    borrowableAddress1: '0xd5f59774c061fa9d395c58a89951e7d8315c598b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xf85fe698a3368a565149a26bd262690b5c428472': {
    lendingPoolAddress: '0xf85fe698a3368a565149a26bd262690b5c428472',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 5,
    symbol0: 'TSHARE',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xd702d7495b010936ebc53a1efee42d97996ca5ee',
    collateralAddress: '0x90a51e88004524feb89e546b34d787bfe3cb81a8',
    borrowableAddress0: '0x0c49e21768f4ad0f7090f8dd24eeb6e0f868ef57',
    borrowableAddress1: '0x1bf9556d0a7e0c52f413afd788cad34508ab51f5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x5e24cb79d406668994397f5b5f5c554f1a347c4a': {
    lendingPoolAddress: '0x5e24cb79d406668994397f5b5f5c554f1a347c4a',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 6,
    symbol0: 'USDC',
    symbol1: 'TSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    uniswapV2PairAddress: '0xdec1259188e6c5273acd1e84d5b4b58897ca013e',
    collateralAddress: '0x6330297993b1a36cf16e1c5d5c6842c677640991',
    borrowableAddress0: '0x56f8e038e226531b67b1e249555c3e2433493ca1',
    borrowableAddress1: '0x0eebdb3f18f6360beb7a98cd6635f1fe33b0f6cb',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xd67b9d3a6fc711fb8b7ab4b6d64cb7949d47deb4': {
    lendingPoolAddress: '0xd67b9d3a6fc711fb8b7ab4b6d64cb7949d47deb4',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 7,
    symbol0: 'USDC',
    symbol1: 'fUSDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    uniswapV2PairAddress: '0x3486011e2e18ccf4558c4c84d5cbbccfdbf16c03',
    collateralAddress: '0xf8090c8a88ead97676ffe5430009db2f0bc4dc5c',
    borrowableAddress0: '0xd275cba9cd0d5157734984de340cb8d26822e8ec',
    borrowableAddress1: '0x0d5ff347c1d80d1f1a40dfa5a86826fa180e0fcf',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe51bb9fe17b66a7944fda7a6690b4c4694baea57': {
    lendingPoolAddress: '0xe51bb9fe17b66a7944fda7a6690b4c4694baea57',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 8,
    symbol0: 'USDC',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0xd840af68b35469ec3478c9b0cbcddc6dc80dd98c',
    collateralAddress: '0x7df381a7eb8aa26d61a0eb45c0fa86e0301b2806',
    borrowableAddress0: '0xf67cc1cebd0673b0b4879de19297c4e5cf8e5ff6',
    borrowableAddress1: '0x7b2a39c89514ce12c132f5fb3fae0be657b33a9a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x6e18bbc32a0d67955d28ec7113679cfb541e0159': {
    lendingPoolAddress: '0x6e18bbc32a0d67955d28ec7113679cfb541e0159',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 9,
    symbol0: 'USDC',
    symbol1: 'FUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xad84341756bf337f5a0164515b1f6f993d194e1f',
    uniswapV2PairAddress: '0x38ff5377a42d0a45c829de45801481869087d22c',
    collateralAddress: '0xbe57417861ca29b5d022c1076251e88c82ab800d',
    borrowableAddress0: '0x33a4d51a88e3e7f844e87da8c1ead314befa284b',
    borrowableAddress1: '0x4e4c8374fd171e6c4280528ee80b1c0c609329f9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xcfbb62bb8777709966f81f7d58aedd6b8d0747fd': {
    lendingPoolAddress: '0xcfbb62bb8777709966f81f7d58aedd6b8d0747fd',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 10,
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x8c853ce1561a2c2cd2e857670e3ccd04ba4cb27b',
    collateralAddress: '0xfb8a78682fa0714547f1b9fe145bf0a6f415fd24',
    borrowableAddress0: '0xd8ca234c9b7c6843070ac477b48e48c8af1d2184',
    borrowableAddress1: '0xca9e494a2ad0c15fef58b3cf21e2617d8a5efb31',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x4d3b315994b5fd2bbd93506ea5db8f4b59561e9e': {
    lendingPoolAddress: '0x4d3b315994b5fd2bbd93506ea5db8f4b59561e9e',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 11,
    symbol0: 'WFTM',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0xb89486a030075b42d589008da7877dd783af968f',
    collateralAddress: '0x2210597b655bad7968fa33f43e87ea105f57a051',
    borrowableAddress0: '0x968624225ad173d984286393b37c2b93b9e5e54c',
    borrowableAddress1: '0xf1b4293b9928ac10dfb1ada86c368121cbf3a27a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xfdcaf82160e4f06a6b011ad3ed4b6e61c653ed4a': {
    lendingPoolAddress: '0xfdcaf82160e4f06a6b011ad3ed4b6e61c653ed4a',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 12,
    symbol0: 'WFTM',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x8e49c8fbf6128356019d8a7d34e9b92f03bc2803',
    collateralAddress: '0xdf6f280d2cb74d7280be82b457223a159d12046b',
    borrowableAddress0: '0x270790722be3acdcd392cdc2022700bed6d390f2',
    borrowableAddress1: '0xcedd29d2df5e65109be434820319fb140de71b24',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xd78d3cc53ab902a210734bfc11565d7be96230c0': {
    lendingPoolAddress: '0xd78d3cc53ab902a210734bfc11565d7be96230c0',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 13,
    symbol0: 'fUSDT',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x681d32c8b374c2dd83064775dbb48ea97db2c506',
    collateralAddress: '0xee1e2f879b754fb07ac28c4568b7c02bfb98377c',
    borrowableAddress0: '0x115cba4efaa7415933b1a9e85d6ba8b649404485',
    borrowableAddress1: '0xd63fb7ef6258924004128496fed26097dc214162',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x5f3f4b1f08fcd9f35872b71a71f2170a2ad3b8d6': {
    lendingPoolAddress: '0x5f3f4b1f08fcd9f35872b71a71f2170a2ad3b8d6',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 14,
    symbol0: 'WFTM',
    symbol1: 'BTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x321162cd933e2be498cd2267a90534a804051b11',
    uniswapV2PairAddress: '0x5063c79e377332fb98cb6c8db414d752dc7c478e',
    collateralAddress: '0xf56a7ea5c422f9a07780a96f47e2d0d354f46503',
    borrowableAddress0: '0x7fa3dac37374acc101aaee0468b8e14ad183e02b',
    borrowableAddress1: '0xf01b8da1a6110a399370e767b55bb6548489664a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xfd5c2ac90b12b9014b9a6c826687689384842593': {
    lendingPoolAddress: '0xfd5c2ac90b12b9014b9a6c826687689384842593',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 15,
    symbol0: 'WFTM',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0xa7c86fc1b87830b8abfa623571405e03560a8326',
    collateralAddress: '0xfd1267c004b65ba58e5c440abcf4c079bdd9effd',
    borrowableAddress0: '0xe364547482e0a3eaff1164f636ba6835fcc9cdc4',
    borrowableAddress1: '0x5b8a3de3031aa0d0441bdc88ad2bee9713f4e979',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xc155a158cd66c030d87007870bf144ec05d3ffb9': {
    lendingPoolAddress: '0xc155a158cd66c030d87007870bf144ec05d3ffb9',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 16,
    symbol0: 'WFTM',
    symbol1: 'BNB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',
    uniswapV2PairAddress: '0xb5f1b98693c4894880dd90c1d3e7a517b9c16ae3',
    collateralAddress: '0x89026c5f1d104a126e6501cdea364f9f61615da6',
    borrowableAddress0: '0x96903b187d60675d5738f1471f06efed1d16cad5',
    borrowableAddress1: '0x7cca03b86b125b63fc7ca0029ea271e66419d78c',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xfd97ad406bc7743d10a745ffcde11b1242a38f7d': {
    lendingPoolAddress: '0xfd97ad406bc7743d10a745ffcde11b1242a38f7d',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 17,
    symbol0: 'WFTM',
    symbol1: 'AVAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x511d35c52a3c244e7b8bd92c0c297755fbd89212',
    uniswapV2PairAddress: '0xc753d5aa76f90b7057cc2b6766fe67faf68bf6e3',
    collateralAddress: '0xe2e9696222e12484638819c6195d1f0351e9c42e',
    borrowableAddress0: '0xdef4f5cf53b18a18265380279ec274e306fce1cd',
    borrowableAddress1: '0xd227e55848a9f24c0fa1d8f8d6996a98d0989087',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe5d699f95d6a2e3e7129f2e03a5faf5f35cb34da': {
    lendingPoolAddress: '0xe5d699f95d6a2e3e7129f2e03a5faf5f35cb34da',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 18,
    symbol0: 'WFTM',
    symbol1: 'LINK',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    uniswapV2PairAddress: '0x51e1b9b1ec411a2258f674c26a0a0ac78cd81478',
    collateralAddress: '0x833930c08fc627550a5490f91fd81628887494ad',
    borrowableAddress0: '0xf560f93ef320134bb5a532fdbee61ece0f41ab1f',
    borrowableAddress1: '0x0c3fe1c8de98cc3d683efa7547b54e9fa8475e6f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xf727e8d6512c1bf9b0553f114158da913f9bdcfd': {
    lendingPoolAddress: '0xf727e8d6512c1bf9b0553f114158da913f9bdcfd',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 19,
    symbol0: 'CRV',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1e4f97b9f9f913c46f1632781732927b9019c68b',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xff17e19feeaf403e8ed9d79a5b78b8f4ded38df3',
    collateralAddress: '0x62a5042c439bde8983581ead0103b4f493f16bda',
    borrowableAddress0: '0xec93a592fe0b216626a7c3fe1d146b9894031455',
    borrowableAddress1: '0xb77327d9b81b576735b507821b5aa7d2245986ec',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x1f2bff0e37c592c7de6393c9dd3c0f7933408228': {
    lendingPoolAddress: '0x1f2bff0e37c592c7de6393c9dd3c0f7933408228',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 20,
    symbol0: 'BTC',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x321162cd933e2be498cd2267a90534a804051b11',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x3f468804d133894a73b54cfc07d5886e5195255f',
    collateralAddress: '0xfd0bd84efe63dcf7aeabd1f9f644536fdb269137',
    borrowableAddress0: '0x9b2e19859bbbb0e916ab25da1eb9d86a9ba6ae0f',
    borrowableAddress1: '0x29e43ac0d45aff4fa1ab15af9f77d429e5a45f08',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x16904332af97a2e28ce2284e4c0c5078049b958c': {
    lendingPoolAddress: '0x16904332af97a2e28ce2284e4c0c5078049b958c',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 21,
    symbol0: 'TOMB',
    symbol1: 'LIF3',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0xbf60e7414ef09026733c1e7de72e7393888c64da',
    uniswapV2PairAddress: '0xcc3d9921302dbbc72171eed1b10fd45f9e83ad8c',
    collateralAddress: '0x36ac0effea7fda41ec7280dbb35663131bb7ac99',
    borrowableAddress0: '0xa171da288495da742df32b6164d95a9835ceeba9',
    borrowableAddress1: '0x0fc5188f44f53ee902f5ef49789d28c3bf9fb00d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xd916ab627631ba859029eaaaf2729af987a011f4': {
    lendingPoolAddress: '0xd916ab627631ba859029eaaaf2729af987a011f4',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 22,
    symbol0: 'TSHARE',
    symbol1: 'LSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    tokenAddress1: '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45',
    uniswapV2PairAddress: '0x9fc1cfb778864d319ebe35afd475f869ed8e34a9',
    collateralAddress: '0xde30fa651c3511963c51d779e79b89d765ecdc93',
    borrowableAddress0: '0x5da4525595249558458dfe8e1070bec943afd742',
    borrowableAddress1: '0x3988735cc7f8e21a3bdeebaa85218bd4fbbb677e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x6d1bec43f50eacf00bd81335a4e61f67f09d95c1': {
    lendingPoolAddress: '0x6d1bec43f50eacf00bd81335a4e61f67f09d95c1',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 23,
    symbol0: 'USDC',
    symbol1: 'LIF3',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xbf60e7414ef09026733c1e7de72e7393888c64da',
    uniswapV2PairAddress: '0x502eeeb06c2e01fd151cfc6624f0b98420bd1291',
    collateralAddress: '0x312189368d8db0edc74ee9237b09fdd0e7695ce7',
    borrowableAddress0: '0x171bf5558f83d1a96ca3513f5676f41b8d9cd08c',
    borrowableAddress1: '0xec2f3ed6b87fe58b35938a06f2e4269b33a697e6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xa811752e01b691f4de336f6db49ddc7232b9527f': {
    lendingPoolAddress: '0xa811752e01b691f4de336f6db49ddc7232b9527f',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 24,
    symbol0: 'USDC',
    symbol1: 'LSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45',
    uniswapV2PairAddress: '0x0621d9c22fad25bf5b88735defb419fa60f118f7',
    collateralAddress: '0xf2d1fb89c7fb964f46efc3d8f97e3584098a3e51',
    borrowableAddress0: '0x4a7fe4da206df8be0419098cefa0e011db3d1741',
    borrowableAddress1: '0xaa566d6fd0a2d7b45fecf543d24d4cf91dbc9c16',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x0a1fd2286f38bb75950fa90fb9b56c824648f7d6': {
    lendingPoolAddress: '0x0a1fd2286f38bb75950fa90fb9b56c824648f7d6',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 25,
    symbol0: 'WFTM',
    symbol1: 'LIF3',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xbf60e7414ef09026733c1e7de72e7393888c64da',
    uniswapV2PairAddress: '0xd62cacdb69000fed31bb348e9c0e073bb8ad7caf',
    collateralAddress: '0x3a03b9ce0a4d2ffef33e77f9fd30150b3050ff06',
    borrowableAddress0: '0x0be3fcfd8b1a5e878daf0d07784725b3cfab9883',
    borrowableAddress1: '0x0eea78976675dd05c56744847f289b08f730de16',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x1af3c714928fe9cb8e997d59dfa2594fe4cf4ddc': {
    lendingPoolAddress: '0x1af3c714928fe9cb8e997d59dfa2594fe4cf4ddc',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 0,
    symbol0: 'WFTM',
    symbol1: 'BOO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    uniswapV2PairAddress: '0xec7178f4c41f346b2721907f5cf7628e388a7a58',
    collateralAddress: '0x274de5dd8824009006410ab034e157d349d908d5',
    borrowableAddress0: '0x3d216d1086deadd6bf361f270584711859a64102',
    borrowableAddress1: '0x8d5fe826e222df813e68f0aa4626f3e5d5b1e007',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x939dff08be101d86d91048c7f56ccbc713ea5a95': {
    lendingPoolAddress: '0x939dff08be101d86d91048c7f56ccbc713ea5a95',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 9,
    symbol0: 'fUSDT',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x5965e53aa80a0bcf1cd6dbdd72e6a9b2aa047410',
    collateralAddress: '0xea8f4d3aa4c7d0b4b8c4dae27a724440f3b48914',
    borrowableAddress0: '0x01b61b3d1fdfb46d6093d8c102bc424cb4befd8d',
    borrowableAddress1: '0x06e37d44d85f72fcab3fe743a129c704d21bad6f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x01a621fe05fe5aab97c3a9841383230a96d8392b': {
    lendingPoolAddress: '0x01a621fe05fe5aab97c3a9841383230a96d8392b',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 10,
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    collateralAddress: '0xc7c091ad588afde099f17e0e225585f289ff51a2',
    borrowableAddress0: '0xee234eb2919a1dc4b597de618240ec0c14ef11ce',
    borrowableAddress1: '0xed0e1b57eebdf538434b521e47fb3df0a94f2343',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xfee40d4f2c8526ae02b654b7f348fb82aa9531c4': {
    lendingPoolAddress: '0xfee40d4f2c8526ae02b654b7f348fb82aa9531c4',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 11,
    symbol0: 'WFTM',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428',
    collateralAddress: '0x0f3b7b10ec9263fe389d732b52efe9f4442eed5a',
    borrowableAddress0: '0x3221c43a4e354cece2fc8e0fdda2a663e052022d',
    borrowableAddress1: '0xd24772601db6d3387a404e41468f839d1485c2ea',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x0fc03567c78b43211fff2c17f792c8b2f41811a2': {
    lendingPoolAddress: '0x0fc03567c78b43211fff2c17f792c8b2f41811a2',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 12,
    symbol0: 'WFTM',
    symbol1: 'BTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x321162cd933e2be498cd2267a90534a804051b11',
    uniswapV2PairAddress: '0xfdb9ab8b9513ad9e419cf19530fee49d412c3ee3',
    collateralAddress: '0x07bfb45a86d202a77d6e60a82b343164d9beb5b0',
    borrowableAddress0: '0x5eae455dd945a743f6c17faef55126eebf4b0308',
    borrowableAddress1: '0x7c934bb5363a4a160ac4bd8fdb260f6070527c96',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x82a4ef6d28f390e3eb0348d18d1bd71927c185e0': {
    lendingPoolAddress: '0x82a4ef6d28f390e3eb0348d18d1bd71927c185e0',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 13,
    symbol0: 'WFTM',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xf0702249f4d3a25cd3ded7859a165693685ab577',
    collateralAddress: '0x4b0d1ce6159b1e36498ffe89424ec5933268fd64',
    borrowableAddress0: '0xf5b6992019cdb92b8cdcb5a17022e852e4cdec02',
    borrowableAddress1: '0x0fb2ff53721418f668f93fcaadb3b73d699e41df',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x49d5df1766120daace799f4aa384c52909131d30': {
    lendingPoolAddress: '0x49d5df1766120daace799f4aa384c52909131d30',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 14,
    symbol0: 'WFTM',
    symbol1: 'LINK',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    uniswapV2PairAddress: '0x89d9bc2f2d091cfbfc31e333d6dc555ddbc2fd29',
    collateralAddress: '0x3d1cc4f52696da9d65a15edb5ff161f4c3eb6eff',
    borrowableAddress0: '0x555ed3c399d0ca6e05028e601e37456cdce9a29b',
    borrowableAddress1: '0x301ebf81ca3f77bc29e705fc889f4ead2de5f43e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xacfea2a2441bc9d8087c958028d97be588bf3c33': {
    lendingPoolAddress: '0xacfea2a2441bc9d8087c958028d97be588bf3c33',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 15,
    symbol0: 'WFTM',
    symbol1: 'SUSHI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc',
    uniswapV2PairAddress: '0xf84e313b36e86315af7a06ff26c8b20e9eb443c3',
    collateralAddress: '0x4eca3ca3f0d00adfd1f51de8fdc0c091b2f64e34',
    borrowableAddress0: '0x307ea46e4e6cf7b3bd94be61c46ef7c517ef6201',
    borrowableAddress1: '0x36e8830e8480e4efb277d769e6290ab17df167aa',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xddb870e27469e842e18182101bd1c59e746ec9b4': {
    lendingPoolAddress: '0xddb870e27469e842e18182101bd1c59e746ec9b4',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 16,
    symbol0: 'CRV',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1e4f97b9f9f913c46f1632781732927b9019c68b',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xb471ac6ef617e952b84c6a9ff5de65a9da96c93b',
    collateralAddress: '0x2e2c1d6507d722c7fedb7ef4d5514069beb741b2',
    borrowableAddress0: '0x7f0f0361e5c2b062793f525efb407451edaed850',
    borrowableAddress1: '0xaa00256c599b23b1f7d5b3a132200cd329075f56',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x0f4998fe47ad783329714612b083681f4901f339': {
    lendingPoolAddress: '0x0f4998fe47ad783329714612b083681f4901f339',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 17,
    symbol0: 'WFTM',
    symbol1: 'BNB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',
    uniswapV2PairAddress: '0x956de13ea0fa5b577e4097be837bf4ac80005820',
    collateralAddress: '0xc11d3f68b89ce53deafefbc8a5d77683546ad220',
    borrowableAddress0: '0x9b02d0d6643646ef13416530e2f67f32d9c26080',
    borrowableAddress1: '0x1a563a2f35b67e95cdd57e3ad1d783b750703d23',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x0fd2947895fcd9fd4fe5c66d255998c2d7e56ce6': {
    lendingPoolAddress: '0x0fd2947895fcd9fd4fe5c66d255998c2d7e56ce6',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 18,
    symbol0: 'WFTM',
    symbol1: 'BIFI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd6070ae98b8069de6b494332d1a1a81b6179d960',
    uniswapV2PairAddress: '0x1656728af3a14e1319f030dc147fabf6f627059e',
    collateralAddress: '0xb9e70e8524a6df697b9dd93d644a9d3b8e0f149b',
    borrowableAddress0: '0x1bcfb40f6c8372d3526c2f2b36081332c9c659ce',
    borrowableAddress1: '0xd7da80ee161b1c04fffb401b7318de1347c305eb',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x1616e02f66ddd35e6cf4c7818e452227215b37fe': {
    lendingPoolAddress: '0x1616e02f66ddd35e6cf4c7818e452227215b37fe',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 19,
    symbol0: 'WFTM',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0x6f86e65b255c9111109d2d2325ca2dfc82456efc',
    collateralAddress: '0xdb7eb67c4d1ff380bc677abdb207e692f6f7c3bb',
    borrowableAddress0: '0x416b8d5a3dd8bb1426dbe6f86317e22951a6d124',
    borrowableAddress1: '0xdeb93bbac39b84df99cd4ed6051f99e1683e6c21',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe786dc3efa7f4338dcf20331f64f41763ce3d735': {
    lendingPoolAddress: '0xe786dc3efa7f4338dcf20331f64f41763ce3d735',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 21,
    symbol0: 'YFI',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x0845c0bfe75691b1e21b24351aac581a7fb6b7df',
    collateralAddress: '0x274534ad3aa3b3fdce328cec520a2084f18f7212',
    borrowableAddress0: '0x5d8960078d745e2987a5a02078a4028489b5b4a6',
    borrowableAddress1: '0xe8685152922edea8f6dbfcc7886f13b09e0b9dd8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x1d1b8da1826cc7624b91c4c616ecd3fd1ca04b71': {
    lendingPoolAddress: '0x1d1b8da1826cc7624b91c4c616ecd3fd1ca04b71',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 22,
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x11d90ea9d16e1ee5879b299a819f6d618816d70f',
    collateralAddress: '0xd206277d475bc882c6a7496525739ab5fefc2b89',
    borrowableAddress0: '0xffb9260808e327432350fc9c06d639f7ee9c90f6',
    borrowableAddress1: '0x1f8b52ed3cd22f5e4275aaf68301566bb6739f22',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x3a1a62b2736ebb210f0c7574d227352087b19791': {
    lendingPoolAddress: '0x3a1a62b2736ebb210f0c7574d227352087b19791',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 23,
    symbol0: 'WFTM',
    symbol1: 'SCREAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
    uniswapV2PairAddress: '0x30872e4fc4edbfd7a352bfc2463eb4fae9c09086',
    collateralAddress: '0x39760abfb8d1e8b441d9ca822b76eebc2ffaf9c5',
    borrowableAddress0: '0x23691ab8c86370926748f5f405838749514fbaf2',
    borrowableAddress1: '0x629a6e543749c49ff09de99ee2a087f4560cae8f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xdcc2de17d86b66a39f1da54e7a6afe1053cafc52': {
    lendingPoolAddress: '0xdcc2de17d86b66a39f1da54e7a6afe1053cafc52',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 25,
    symbol0: 'BTC',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x321162cd933e2be498cd2267a90534a804051b11',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xec454eda10accdd66209c57af8c12924556f3abd',
    collateralAddress: '0x3421c585b8b1b7e92f214ae516d1c1237201d912',
    borrowableAddress0: '0xe9d7575f900592cef8a6583196763953d9bed9f2',
    borrowableAddress1: '0x5b09669c26301033be4d418aefe753d085a4c8b1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xb0fc78e37ced369ed57a07677f80e6dd35084f3a': {
    lendingPoolAddress: '0xb0fc78e37ced369ed57a07677f80e6dd35084f3a',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 26,
    symbol0: 'LQDR',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x506ddcc751c7d500f983ffda6ddefbe458ba2c33',
    collateralAddress: '0x90403c69d8a4b81152d4a3c816cfa7731805c4b7',
    borrowableAddress0: '0xe10210cf9da2fd2ba5ac91ca25895bdace011500',
    borrowableAddress1: '0x0fa8f0c3bc60251a6cca65d610e19b29d445c6d3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xabd5c48b5342c47d78625006f67d70d911f8601d': {
    lendingPoolAddress: '0xabd5c48b5342c47d78625006f67d70d911f8601d',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 27,
    symbol0: 'WFTM',
    symbol1: 'SPELL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x468003b688943977e6130f4f68f23aad939a1040',
    uniswapV2PairAddress: '0x78f82c16992932efdd18d93f889141ccf326dbc2',
    collateralAddress: '0x893d356b79047b9fa8a95a839f13c7417ec35242',
    borrowableAddress0: '0x1e979bb99f37a3533b12093038977092ac527a62',
    borrowableAddress1: '0xf4ef1814044286dfb84af8654cdf149c3d864fc3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x66e14d95f9f16557fdd44891306114a3e23fd5a1': {
    lendingPoolAddress: '0x66e14d95f9f16557fdd44891306114a3e23fd5a1',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 28,
    symbol0: 'USDC',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x4de9f0ed95de2461b6db1660f908348c42893b1a',
    collateralAddress: '0x9df0c9a8912ff769f579d3b75a9d92d27e015211',
    borrowableAddress0: '0xc2b71db3e843dbe0615bc8fcefc8b28d34f265c6',
    borrowableAddress1: '0xb320fa815a18bf186c8894930aa787b381f30b8a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xf11d495bbaea1e37bc2220604d83fc9a7ca38de9': {
    lendingPoolAddress: '0xf11d495bbaea1e37bc2220604d83fc9a7ca38de9',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 29,
    symbol0: 'USDC',
    symbol1: 'TUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x9879abdea01a879644185341f7af7d8343556b7a',
    uniswapV2PairAddress: '0x12692b3bf8dd9aa1d2e721d1a79efd0c244d7d96',
    collateralAddress: '0x14431c8b513128188a43e2726502fcb6f683219b',
    borrowableAddress0: '0xadf5c4115fba294d208dc708c1dd20025d07ea9e',
    borrowableAddress1: '0xe5e9253c867ac7bb2e54bb57eb55e150bc3fff60',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x3a1e79415cd46f2a508c8dc160d68d7f9009e1d2': {
    lendingPoolAddress: '0x3a1e79415cd46f2a508c8dc160d68d7f9009e1d2',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 32,
    symbol0: 'WFTM',
    symbol1: 'AVAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x511d35c52a3c244e7b8bd92c0c297755fbd89212',
    uniswapV2PairAddress: '0x5df809e410d9cc577f0d01b4e623c567c7ad56c1',
    collateralAddress: '0xd9d4c6c9cf0c78eeee45a88607a12587651040e1',
    borrowableAddress0: '0x807d781a1f2fe3ed67ec2edd5d945e1666282ff2',
    borrowableAddress1: '0x0bee491043289eff7bb65ad529fc64e5aa5fcaac',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x17ff855bf4f301f502298f30720b6604b861d27e': {
    lendingPoolAddress: '0x17ff855bf4f301f502298f30720b6604b861d27e',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 34,
    symbol0: 'WFTM',
    symbol1: 'MATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x40df1ae6074c35047bff66675488aa2f9f6384f3',
    uniswapV2PairAddress: '0x7051c6f0c1f1437498505521a3bd949654923fe1',
    collateralAddress: '0x65c97e95042608865aacb57b70dcbac7a8ef9fb7',
    borrowableAddress0: '0xdaf86a099ff129857dff818ffb07aa65512a83ce',
    borrowableAddress1: '0xae7f25fcca11564b0016e1f7787259294b4e18ba',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x847e8f3b438540acb8146c12bd84a97efff4fd2d': {
    lendingPoolAddress: '0x847e8f3b438540acb8146c12bd84a97efff4fd2d',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 35,
    symbol0: 'WFTM',
    symbol1: 'BRUSH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x85dec8c4b2680793661bca91a8f129607571863d',
    uniswapV2PairAddress: '0x4ee115137ac73a3e5f99598564905465c101b11f',
    collateralAddress: '0x5a8377eba9b964659fa0b1d8946e52a48e3e1bf3',
    borrowableAddress0: '0x0a9617cf6d3debcf65633eebca25bcac5daad9c4',
    borrowableAddress1: '0x66fafe368d8f5b3e7eb2d56602a282c0660acf1f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe4b58eda17f7261c6e2bf8bee87f68a18eff5739': {
    lendingPoolAddress: '0xe4b58eda17f7261c6e2bf8bee87f68a18eff5739',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 36,
    symbol0: 'WFTM',
    symbol1: 'BEETS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e',
    uniswapV2PairAddress: '0x648a7452da25b4fb4bdb79badf374a8f8a5ea2b5',
    collateralAddress: '0xdf741d5ff44a3e354fbc30547c2719c10ec9c4b0',
    borrowableAddress0: '0x1b0ba9f68ceaf9455206e4ea6066eb6333bf7d5a',
    borrowableAddress1: '0xe3f201d4676d1aec0baa8c70f8f07f14b73b3aec',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x7ab024addf7095cca03172e1df16dfacafbb88bc': {
    lendingPoolAddress: '0x7ab024addf7095cca03172e1df16dfacafbb88bc',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 37,
    symbol0: 'WFTM',
    symbol1: 'MULTI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
    uniswapV2PairAddress: '0x297c8990134bf1ee08ae5d8805042fbac8781201',
    collateralAddress: '0x98db9e233c77782ca029d413efa3d0559bcb07c9',
    borrowableAddress0: '0x9242169d580e09f41b8d37bc344d6bdcfe9f2ced',
    borrowableAddress1: '0x5c3947689acc68c3ab5c9b80f8ec43e24c724ef6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xe95b744444d019dfee6a98969b82b061a574194a': {
    lendingPoolAddress: '0xe95b744444d019dfee6a98969b82b061a574194a',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 39,
    symbol0: 'USDC',
    symbol1: 'BOO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    uniswapV2PairAddress: '0xf8cb2980120469d79958151daa45eb937c6e1ed6',
    collateralAddress: '0x5b136e32867bb564c608daa7c21ef92b2ec45edb',
    borrowableAddress0: '0x9f2f80ea74f44c923910781d858eccbcc3a14e5d',
    borrowableAddress1: '0x4f533dc378e3d5dc769bd1f7c0a1e55c771dd7f0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xb6b8c26915c7f812edf82fcd68db4a7d0f8e4986': {
    lendingPoolAddress: '0xb6b8c26915c7f812edf82fcd68db4a7d0f8e4986',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 40,
    symbol0: 'WFTM',
    symbol1: 'APE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x2d72a97a31dc920db03330780d30074626e39c8a',
    uniswapV2PairAddress: '0xc9fb686f14bda7e2653cf8f605dc8551b6a53fd3',
    collateralAddress: '0xaf94d802195996a80af2307ad7b9fd7fd0cfac80',
    borrowableAddress0: '0x6e1091bc2eca3236eb79c9d21061e9e319f1c759',
    borrowableAddress1: '0xe43767e6ac0f91e7d4497f7e2d07e08f50ecbf88',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xa3f6fa09aa7f959e7a917d0677a7be9ae7446c09': {
    lendingPoolAddress: '0xa3f6fa09aa7f959e7a917d0677a7be9ae7446c09',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 41,
    symbol0: 'WFTM',
    symbol1: 'beFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x7381ed41f6de418dde5e84b55590422a57917886',
    uniswapV2PairAddress: '0xb5512e3fa8304d33cdae4a40c21f1d3f70eba45a',
    collateralAddress: '0xbcffa579ba5d2301fbfd54fe9d78c1046c3cf7e5',
    borrowableAddress0: '0x43ea7c17f3232a3308fb5675433c8355a3595f20',
    borrowableAddress1: '0xb0a87f3368a448cf61ff7f1dc370792428bdddd5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xec51a9f0dc97563147fb89176047283b9ae4cca9': {
    lendingPoolAddress: '0xec51a9f0dc97563147fb89176047283b9ae4cca9',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V2,
    pid: 42,
    symbol0: 'USDC',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x35a60b8c750b4d834a27443f35269a84d06de391',
    collateralAddress: '0xf454df5ff5b89b9d469bed1b0584d926ef5d5ef9',
    borrowableAddress0: '0x6ed9d143a5a2d87796441915410a6617b02d7935',
    borrowableAddress1: '0x61e8ee0bde02cad88010e3a2d4e7821d60d39bc7',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x71d4b6e663083530fe4339472d3fcc45669fdda3': {
    lendingPoolAddress: '0x71d4b6e663083530fe4339472d3fcc45669fdda3',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 26,
    symbol0: 'TBOND',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x24248cd1747348bdc971a5395f4b3cd7fee94ea0',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0x2b2703716d3234b787c42e89950653688c012dea',
    collateralAddress: '0x0f14ea43a97f43e0247947aa43ea5e3ce589c354',
    borrowableAddress0: '0xca2eb06491486bbd060f40696a45e26995d3c4c6',
    borrowableAddress1: '0x371c72d09f5fa7aa76214e4d68692835ebd73bab',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x1c669f6caaf59dbfe86e9d8b9fb694d4d06611d5': {
    lendingPoolAddress: '0x1c669f6caaf59dbfe86e9d8b9fb694d4d06611d5',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 27,
    symbol0: 'WFTM',
    symbol1: 'LSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45',
    uniswapV2PairAddress: '0x06623fba85e66cb93e7802cdfea8e5f1d70c38a9',
    collateralAddress: '0xe7ff80ed4d81a8bc6db292c312078b22aa389054',
    borrowableAddress0: '0x7b86775334b68231f0e42e882230399fc8a3bf9f',
    borrowableAddress1: '0x17bfb48d6d71b2948c913215a66e865e77ceca05',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x9bf544e9e96033d1c8b667824844a40aa6c2132a': {
    lendingPoolAddress: '0x9bf544e9e96033d1c8b667824844a40aa6c2132a',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 28,
    symbol0: 'TOMB',
    symbol1: 'LSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45',
    uniswapV2PairAddress: '0x355e1045daaf4877927322803ca0ccc7df3721d5',
    collateralAddress: '0x8b82714dcd902ff8226433a3ee092d85dcc9d283',
    borrowableAddress0: '0xbe4310d08d92ec4c8700b16601b45250fd4906d4',
    borrowableAddress1: '0x6dff9ddff23befbaadcf634d08341f14b3a4e45b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0xa40e34a38d960e3fbb970a6dac64220de13d7fc7': {
    lendingPoolAddress: '0xa40e34a38d960e3fbb970a6dac64220de13d7fc7',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 29,
    symbol0: 'LIF3',
    symbol1: 'LSHARE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbf60e7414ef09026733c1e7de72e7393888c64da',
    tokenAddress1: '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45',
    uniswapV2PairAddress: '0x82045cbaf366a30f8888628fb94628806c700da2',
    collateralAddress: '0x78ef9fb58bd2c2247bac7126687cd91c185bace3',
    borrowableAddress0: '0x603ea57f34c68bf19eacc750a9f6b6903e8a60b6',
    borrowableAddress1: '0xe0a87d49e4d1ec944205ef195cd6ec36e96d8928',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x526b38991627c509a570ac18a46f7ac7aabc7e4a': {
    lendingPoolAddress: '0x526b38991627c509a570ac18a46f7ac7aabc7e4a',
    isTarotVault: true,
    dex: DEX.TOMB,
    vaultType: VaultType.LIF3,
    pid: 30,
    symbol0: 'TSHARE',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0x9d2f00f684960ec83a3d042f3eadd70e105f83cb',
    collateralAddress: '0xf6186c5f2cb84560e61291cc482d29d34f25fda5',
    borrowableAddress0: '0x19eb22ed0e8fbc44a8cd73f86224916450f3c395',
    borrowableAddress1: '0x59a0a86011156d9bf70b5609f6b411cb5c5f18ba',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x6f694a3dc87a739e0fd84620eb2bebe2fca5af7d': {
    lendingPoolAddress: '0x6f694a3dc87a739e0fd84620eb2bebe2fca5af7d',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x04f12d5eb29edc7241de831e582c3b7814844fcb',
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x783f1edbe336981dfcb74bd0b803655f55aadf48',
    collateralAddress: '0xb49e1ca89f4619051cc67aad23e41bacd11f11c2',
    borrowableAddress0: '0x86637914fcf928c665eb61cdd500bafec35e5bd6',
    borrowableAddress1: '0x3e8e748ad4b302e4f9d2d207ac268f21f2663a6d',
    farmingPoolAddress0: '0xd4119e06214b9d38598c45b9ae16a905631a1939',
    farmingPoolAddress1: '0xb193b09493f89454ee7677d2961f442bcdf845a4',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xb54cda66e287ff85e2de2433154969a1afcc7208': {
    lendingPoolAddress: '0xb54cda66e287ff85e2de2433154969a1afcc7208',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x1d1a1871d1830d4b5087212c820e5f1252379c2c',
    symbol0: 'WFTM',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0x60a861cd30778678e3d613db96139440bd333143',
    collateralAddress: '0x544f13effc5d23180e8c7d9831f67ec7a8421abc',
    borrowableAddress0: '0x70dd33ad26ea769dc65a96425741c667c2bce123',
    borrowableAddress1: '0x474e8204fb41dbfa18a7c96edec725a358ffc438',
    farmingPoolAddress0: '0xc900dc5ad63751b913ac70c305782aa99380c37a',
    farmingPoolAddress1: '0xc4016771f87dd6de4f0a6dd64cf6646740aa1075',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xf1234dbdce04c8f26e53e33d53010862dd775d28': {
    lendingPoolAddress: '0xf1234dbdce04c8f26e53e33d53010862dd775d28',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xa0813bccc899589b4e7d9ac42193136d0313f4eb',
    symbol0: 'xTAROT',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x74d1d2a851e339b8cb953716445be7e8abdf92f4',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x4fe782133af0f7604b9b89bf95893adde265fefd',
    collateralAddress: '0xb98b00ed7c4f68c0d6218298748b78296b03945f',
    borrowableAddress0: '0x0a25c2f5b958a92a96fbb57b2f3bdc45cd2fe464',
    borrowableAddress1: '0x272263ecafbbf852910efe1043b9c8cf3c9f7fea',
    farmingPoolAddress0: '0x13bbbd25b7a7a8ff19a23a834c10be30b500bf02',
    farmingPoolAddress1: '0xe6345830b5ec891e9d82896b47bc67b19332a770',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x3469099c7fc07be112f6c9e7516beac1266a4871': {
    lendingPoolAddress: '0x3469099c7fc07be112f6c9e7516beac1266a4871',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xa0ce41c44c2108947e7a5291fe3181042affdae7',
    symbol0: 'USDC',
    symbol1: 'WeVE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x911da02c1232a3c3e1418b834a311921143b04d7',
    uniswapV2PairAddress: '0xd9a4108cbb40a12de16dffdc54ae5065878816d7',
    collateralAddress: '0xf598d1ab0cd1ecbd77ae3601341537be7864497a',
    borrowableAddress0: '0x5341cdea1f6783d1aec016d86a79f30874b509c0',
    borrowableAddress1: '0x622156a1829ce0cf0c7736d29e9c92dd8046f5d9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x38c52f8bc14331bfde62ea3cfc35cde71f7cb3d7': {
    lendingPoolAddress: '0x38c52f8bc14331bfde62ea3cfc35cde71f7cb3d7',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x8e81ec0d9c184e73746446dfb83f86c1156744d5',
    symbol0: 'USDC',
    symbol1: 'OXD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xc165d941481e68696f43ee6e99bfb2b23e0e3114',
    uniswapV2PairAddress: '0xeafb5ae6eea34954ee5e5a27b068b8705ce926a6',
    collateralAddress: '0x199a0be8096c523a5e329818a099a546eecfe1ab',
    borrowableAddress0: '0x9fa828c5f9b09a8ccbc2608578c2d3947ecc6a22',
    borrowableAddress1: '0xe54fe59d4ad6122d21e41bc1b69253c3e349382b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xafc4d6c798c5bfe7acb987ca546dc1f1b8aa3aab': {
    lendingPoolAddress: '0xafc4d6c798c5bfe7acb987ca546dc1f1b8aa3aab',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x51b6b39816cbe47a74ec3154208f86199f9225a4',
    symbol0: 'OATH',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21ada0d2ac28c3a5fa3cd2ee30882da8812279b6',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x6b987e02ca5eae26d8b2bcac724d4e03b3b0c295',
    collateralAddress: '0x18b972b6a4f2ab3fcdb7a2ae5703c533c8c49759',
    borrowableAddress0: '0xef6f72d4f053c2d6af6bbd92f2247f3864b33389',
    borrowableAddress1: '0x0afdcfa51e42dbc84d679e9dc5678a40afc665a6',
    farmingPoolAddress0: '0x3093f7042f10b0c241e1d0abc4f1c6052e9a6cea',
    farmingPoolAddress1: '0xf6fe5762b34af0a3590e8a8ad3a7d3678b2347ad',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x25e37b2f78c78ba9123f5c48578706bf0d8329ad': {
    lendingPoolAddress: '0x25e37b2f78c78ba9123f5c48578706bf0d8329ad',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x5990734584bf21c76cbc721858843e9574c8446f',
    symbol0: 'WFTM',
    symbol1: 'SOLID',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x888ef71766ca594ded1f0fa3ae64ed2941740a20',
    uniswapV2PairAddress: '0xe4bc39fdd4618a76f6472079c329bdfa820afa75',
    collateralAddress: '0x79adbb6b4c028ef8b9be11fe4e5fd9a4556063dc',
    borrowableAddress0: '0x90f38a3f144912726e09f5be7d30d094c49a8850',
    borrowableAddress1: '0xc0155d822a89d9029cd577f566c374be97019af6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xcacf5b0958fb5e989646a64081c9f56bdabd0ce3': {
    lendingPoolAddress: '0xcacf5b0958fb5e989646a64081c9f56bdabd0ce3',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x3cbd867cf1d37d9b5c3cedf28b8a41d71f6807d6',
    symbol0: 'WFTM',
    symbol1: 'SEX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd31fcd1f7ba190dbc75354046f6024a9b86014d7',
    uniswapV2PairAddress: '0xfcec86af8774d69e2e4412b8de3f4abf1f671ecc',
    collateralAddress: '0x6cc6ffabade9139ae73411e8ee81ff326bac72c1',
    borrowableAddress0: '0x28be2d9cc5b8a1b0a21c4fcd1941ff7cc882c797',
    borrowableAddress1: '0xf77238fc06af35a72be8c0be006c9301fe4d8332',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x19560566d9db6fd6925e1ea0defbd857985e8d1e': {
    lendingPoolAddress: '0x19560566d9db6fd6925e1ea0defbd857985e8d1e',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x8015c2ca86f9833486f31782f43d7a8991c4c895',
    symbol0: 'WFTM',
    symbol1: 'SOLIDsex',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x41adac6c1ff52c5e27568f27998d747f7b69795b',
    uniswapV2PairAddress: '0xa66901d1965f5410deeb4d0bb43f7c1b628cb20b',
    collateralAddress: '0x66339a6a884056e1fea887dda7ecb23596117c82',
    borrowableAddress0: '0xe3f9df9479085eb8b4835dfd2ccd391898c817b7',
    borrowableAddress1: '0x4ffec8f8d4c40fb25efbaef48e1ca62ef4d5527c',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x30b39cc2067d204d975f7ef34014d04c3aa8370e': {
    lendingPoolAddress: '0x30b39cc2067d204d975f7ef34014d04c3aa8370e',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x1d1a1871d1830d4b5087212c820e5f1252379c2c',
    oxUserProxyAddress: '0xb94dbdbd456579c8fb617661b2eacbd40ef49e6c',
    oxStakingAddress: '0x69a693329ac7a2c416b18fed8fe85f276a3da82d',
    symbol0: 'WFTM',
    symbol1: 'TOMB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    uniswapV2PairAddress: '0x60a861cd30778678e3d613db96139440bd333143',
    collateralAddress: '0x73b24f01ce92546b00555192332c67a5bf50e3ad',
    borrowableAddress0: '0xdffb193d3242ea5f6d23261e61823b240f44e1a1',
    borrowableAddress1: '0x2343258a2781ca9494256178e251919b162d1de0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x969a0057a06e36e3d4d2603c6d4e938ca79de36e': {
    lendingPoolAddress: '0x969a0057a06e36e3d4d2603c6d4e938ca79de36e',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xa0ce41c44c2108947e7a5291fe3181042affdae7',
    oxUserProxyAddress: '0x1c9a0d3b2f4c7e83806b660750f6a88b90f09553',
    oxStakingAddress: '0x234f69918f43b30ef2adf5f743cd729e40e14ecd',
    symbol0: 'USDC',
    symbol1: 'WeVE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x911da02c1232a3c3e1418b834a311921143b04d7',
    uniswapV2PairAddress: '0xd9a4108cbb40a12de16dffdc54ae5065878816d7',
    collateralAddress: '0x97a8a8cec00409d97d3cdd1129a1d9bb36fb2837',
    borrowableAddress0: '0x78e44df86327e79cb431e7e8bf17e0601e3f3ad1',
    borrowableAddress1: '0xc1eb875e33fa3a846beafb3c5d0492d67683db2d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x0d1562f1041062b47929be6768fd0af08a816ca0': {
    lendingPoolAddress: '0x0d1562f1041062b47929be6768fd0af08a816ca0',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x8e81ec0d9c184e73746446dfb83f86c1156744d5',
    oxUserProxyAddress: '0x958af968e10a345fb8d00fe47bcb6f7a5e2d8483',
    oxStakingAddress: '0xf09d4a95ce6ece4b01d7ed9e585db3721ba44052',
    symbol0: 'USDC',
    symbol1: 'OXD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xc165d941481e68696f43ee6e99bfb2b23e0e3114',
    uniswapV2PairAddress: '0xeafb5ae6eea34954ee5e5a27b068b8705ce926a6',
    collateralAddress: '0x5604e6050c94593db93441ed190c037b2d3846f5',
    borrowableAddress0: '0x74891bf20912f056a97e418b83f6a46bf751b45c',
    borrowableAddress1: '0xe4f323d1fd118245f49fa2182b6fecd3fba872e4',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xd6b9ffe84a1aac669d55b12f983f78028445c7aa': {
    lendingPoolAddress: '0xd6b9ffe84a1aac669d55b12f983f78028445c7aa',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x51b6b39816cbe47a74ec3154208f86199f9225a4',
    oxUserProxyAddress: '0xd0d202199897bea721754bec5df8cd994eda735d',
    oxStakingAddress: '0x7ab6f7848658363a2d771ae67e5bf31cf4c55655',
    symbol0: 'OATH',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21ada0d2ac28c3a5fa3cd2ee30882da8812279b6',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x6b987e02ca5eae26d8b2bcac724d4e03b3b0c295',
    collateralAddress: '0x11ba6291af2a90d72eb50b07dd1ee8e19d0c0d2f',
    borrowableAddress0: '0x6a38ee4f5f07ffd21ce6deb5e82167c41dca0166',
    borrowableAddress1: '0xb8b2f156368b09b3c5cdce1057e20deb75a4778d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x7bc34e4aae7842637b4815f90bd50ce32cfaa58a': {
    lendingPoolAddress: '0x7bc34e4aae7842637b4815f90bd50ce32cfaa58a',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x04f12d5eb29edc7241de831e582c3b7814844fcb',
    oxUserProxyAddress: '0x25eaa5f2b6ce371fc50f03f0a0b3cf55b3e33883',
    oxStakingAddress: '0x7b44fec85895b138255e7490c62c323e458386fb',
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x783f1edbe336981dfcb74bd0b803655f55aadf48',
    collateralAddress: '0x998e45fd73d0ee358bc93e548f5eb26be2eb2cc2',
    borrowableAddress0: '0x4dfa40824f7493a17f9bad352425d4c066314378',
    borrowableAddress1: '0x262665aa97632b4b39be282750888d6b69ab63dd',
    farmingPoolAddress0: '0xb2c19e8a519090d1d56959ceca4dee370a6b99a6',
    farmingPoolAddress1: '0x2ceff293f95ee813f55725608f991b4a555d096e',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x7a2edc2041e130d61e18eb93a32bb13c331067a0': {
    lendingPoolAddress: '0x7a2edc2041e130d61e18eb93a32bb13c331067a0',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xa0813bccc899589b4e7d9ac42193136d0313f4eb',
    oxUserProxyAddress: '0xaf73d1afb44ea75db5124748d17a41deb1523c4d',
    oxStakingAddress: '0xe28ad06576546c1d5953ab2871f22cc6c13285fb',
    symbol0: 'xTAROT',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x74d1d2a851e339b8cb953716445be7e8abdf92f4',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x4fe782133af0f7604b9b89bf95893adde265fefd',
    collateralAddress: '0x6f488a8743b390c893345edcdfbd670d4527841e',
    borrowableAddress0: '0x89bf74aa60a2f3b530004a4a9e3404bd4ca444c7',
    borrowableAddress1: '0xd7736b59614d5fdcb35cf910d7302b3e51650380',
    farmingPoolAddress0: '0x7e3b7cb22ea21393edd51b928e182e2bb1e9dbde',
    farmingPoolAddress1: '0x85ba4ddfa4d434b436a95d3e468556511510a61c',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x48c7f3aa92fd340077d890706bfa93c644bd6828': {
    lendingPoolAddress: '0x48c7f3aa92fd340077d890706bfa93c644bd6828',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x5990734584bf21c76cbc721858843e9574c8446f',
    oxUserProxyAddress: '0xd7ff56920169e989282522836f1237925299c080',
    oxStakingAddress: '0x3f6696b06f9479c9e538351f260e0dee109f4490',
    symbol0: 'WFTM',
    symbol1: 'SOLID',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x888ef71766ca594ded1f0fa3ae64ed2941740a20',
    uniswapV2PairAddress: '0xe4bc39fdd4618a76f6472079c329bdfa820afa75',
    collateralAddress: '0xa83581ba4f18ebbfb71f9f6fb1d8f9c8dffd09f1',
    borrowableAddress0: '0x2387a9ec987f58a7298fd21af9eb2dbfc3c37b8c',
    borrowableAddress1: '0x184dbba12dd8800300254ab6ee6b460e15ab34bb',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x234c0af6f049a40fdc983b16b375c9d4fba4fe54': {
    lendingPoolAddress: '0x234c0af6f049a40fdc983b16b375c9d4fba4fe54',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x3cbd867cf1d37d9b5c3cedf28b8a41d71f6807d6',
    oxUserProxyAddress: '0x12f6b315ae0c8d42b288d5687b9bbe00a6cc20b8',
    oxStakingAddress: '0x9c056d8c85a185c1ef469a3e09f65021a5052c6d',
    symbol0: 'WFTM',
    symbol1: 'SEX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd31fcd1f7ba190dbc75354046f6024a9b86014d7',
    uniswapV2PairAddress: '0xfcec86af8774d69e2e4412b8de3f4abf1f671ecc',
    collateralAddress: '0xdadb23786315cde79c27665f4af97850cb574f2d',
    borrowableAddress0: '0x56e8f6791cd75361a158f76fe3346049675a1d3f',
    borrowableAddress1: '0x8700344aadad62c46bff3dd3839571dd1893a694',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xb5e6b68139aab9776d2cf40cc8ca7a178f97eda0': {
    lendingPoolAddress: '0xb5e6b68139aab9776d2cf40cc8ca7a178f97eda0',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x8015c2ca86f9833486f31782f43d7a8991c4c895',
    oxUserProxyAddress: '0x9aaae5341427a8076bcfea494f9d9e19b57efa69',
    oxStakingAddress: '0x8a88732ca17db0eb549f80fa716af53885960f18',
    symbol0: 'WFTM',
    symbol1: 'SOLIDsex',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x41adac6c1ff52c5e27568f27998d747f7b69795b',
    uniswapV2PairAddress: '0xa66901d1965f5410deeb4d0bb43f7c1b628cb20b',
    collateralAddress: '0x7919d965c11cf32c7e90ebdfaeeba3bd234cfe8b',
    borrowableAddress0: '0x6ee25d3314053ffb12451fd82fb10667f3b40261',
    borrowableAddress1: '0x1cde1cfb43bb77e0d7383e7ce118d04c78fbdb1d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x7e21f5aa96cc2ac329d94422094317d294220aa0': {
    lendingPoolAddress: '0x7e21f5aa96cc2ac329d94422094317d294220aa0',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x4905b9574d391c4c09bf73d0ebbd5cf71b015e2f',
    oxUserProxyAddress: '0x443d1ac026b3c0db65b2dd631317b18f25c5005d',
    oxStakingAddress: '0xadf51699539a3f37a7fcd213a4fbb1817d3d2a13',
    symbol0: 'WFTM',
    symbol1: 'MULTI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
    uniswapV2PairAddress: '0x94be7e51efe2a0c06c2281b6b385fcd12c84d6f9',
    collateralAddress: '0x38ca08a1561e91d3c6859b11a008b3c1db9fdc2b',
    borrowableAddress0: '0x71f2d43d1ed7c6873fd49f8ec44d731a07283e83',
    borrowableAddress1: '0x6cfe618214584d09ba393099f177d4754e2cc985',
    farmingPoolAddress0: '0x1e83fd2f9c05a747b7c1ff8444a4d008a70c9a76',
    farmingPoolAddress1: '0xaf113ad0489a5458724431dcbe6f21def1b37bb0',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x01bfde9576cc9410d917246a8496fc9115d5ad91': {
    lendingPoolAddress: '0x01bfde9576cc9410d917246a8496fc9115d5ad91',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x9a1f8adae1a0b79ed1263640ee78421e9a5c9b68',
    oxUserProxyAddress: '0xf420284921a4220895930a5f9da2630588c3fd28',
    oxStakingAddress: '0x77831ced767f0e24cc69ecfc137ba45305ebc415',
    symbol0: 'BOO',
    symbol1: 'xBOO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    tokenAddress1: '0xa48d959ae2e88f1daa7d5f611e01908106de7598',
    uniswapV2PairAddress: '0x5804f6c40f44cf7593f73cf3aa16f7037213a623',
    collateralAddress: '0xb057af778294d3bc28143e931b5adff0346a7512',
    borrowableAddress0: '0x18b4f21fa8d8fbd1a81a987cdb6509eaa8aaea97',
    borrowableAddress1: '0xd8fd5113a29309281e9b560d87016a36c3a9cef6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x96f76751ea2669e6bb013d244164a0728368d6fb': {
    lendingPoolAddress: '0x96f76751ea2669e6bb013d244164a0728368d6fb',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x6642c79bd7729ec7c56954b7fdd453991342f3d8',
    oxUserProxyAddress: '0xfb804b47ebebe28470f03ec6f77feda28a978c91',
    oxStakingAddress: '0x44c1c6292eba47fc73e44ad586dbe7925970c82c',
    symbol0: 'WFTM',
    symbol1: 'SCREAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
    uniswapV2PairAddress: '0x86dd79265814756713e631dde7e162bdd538b7b1',
    collateralAddress: '0xbb39b9ef9853165c9b1070f24b7b8e6be530f4c4',
    borrowableAddress0: '0x334017a61d850abc9fff3e5d2e91f7d22a220c8d',
    borrowableAddress1: '0xf887f61d08bb6b40139cf68258b7d72f85114586',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x96563f866bf30c64e6273706ac795ffc608b9de7': {
    lendingPoolAddress: '0x96563f866bf30c64e6273706ac795ffc608b9de7',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xd0aa0c876cf4a223845dd8e26af1f9894d4b0d66',
    oxUserProxyAddress: '0xcc1a362a23c856c2696b8d0441337874a660f834',
    oxStakingAddress: '0x6b854eed02eb5a2dfc6bea6f63738107af5d2214',
    symbol0: 'LQDR',
    symbol1: 'DEI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3',
    uniswapV2PairAddress: '0xd7c590a05c9bf78a51e5171613f83e6a88a6851a',
    collateralAddress: '0x5e68df898c372a5fd7e97e88bdb4455dbad974bd',
    borrowableAddress0: '0xe10daad8d16a5a016ac305cc600c66e2e73610d3',
    borrowableAddress1: '0x37c3b503a315fc4a6de437a6276aa0df111f4e85',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xeb172c44b914638aed21743623da7a0ae431a8d0': {
    lendingPoolAddress: '0xeb172c44b914638aed21743623da7a0ae431a8d0',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x4b356eb8992eccd97b276187141bc2305a163599',
    oxUserProxyAddress: '0xc792c62a6d78cea8799d4f14ce79884a9adbdde8',
    oxStakingAddress: '0xb454d836a391cd2857a07735e5bfaa9226ed9077',
    symbol0: 'DEI',
    symbol1: 'SCREAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3',
    tokenAddress1: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
    uniswapV2PairAddress: '0xd11e940c42e03d927cfd7426718bb4ca21d6015f',
    collateralAddress: '0x026bcd207f63bdf75849acd672becd51a72af56b',
    borrowableAddress0: '0x8e364310f091dd0e56c40ea802b16bbd7476ba39',
    borrowableAddress1: '0x74d241befc633d0e0c3e6ab805fe7f690133cd41',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x3a3d87c216d7401fde2c53ee4f8f33de333ff1b4': {
    lendingPoolAddress: '0x3a3d87c216d7401fde2c53ee4f8f33de333ff1b4',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x3de4c9648d97834b3e67bd1a63102814c2b8282f',
    oxUserProxyAddress: '0xd94355f7a44dd4ac4e9e10534352278cc695e000',
    oxStakingAddress: '0x413e8bcb492474f66a816a4f5fb6413a3ef18c56',
    symbol0: 'DEI',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0xf42dbcf004a93ae6d5922282b304e2aefdd50058',
    collateralAddress: '0x9f93abd08990eaa680108b06d9d21d1dd68c049a',
    borrowableAddress0: '0x38cb3f7c4f1de341d777654bf3ca4207ead76755',
    borrowableAddress1: '0x210347a8a55efcbe99de070b3d33bb2acfa197d9',
    farmingPoolAddress0: '0xc3d18268f2bc3a1d4c3104504c725ca27d654902',
    farmingPoolAddress1: '0xf37fb8fbd9f96c45ef8a409a006967ac4b78390c',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x5aee40f89d84ce767f1df112d61d487268299987': {
    lendingPoolAddress: '0x5aee40f89d84ce767f1df112d61d487268299987',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x2a6931b75ee4e062baabee7f297166871ee55837',
    oxUserProxyAddress: '0x7628878704854b2b5d7da6fe3f088123619d3b39',
    oxStakingAddress: '0xd6e666ec745990ec678342936446265198d5df62',
    symbol0: 'WFTM',
    symbol1: 'OXD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5a9848b9d145965d821aaec8fa32aaee026492d',
    uniswapV2PairAddress: '0xcb6eab779780c7fd6d014ab90d8b10e97a1227e2',
    collateralAddress: '0xb2b5d3326c6c74bc0f55eed80f0fd85724985899',
    borrowableAddress0: '0x88f7412aca3fa7c93ba5a4f2d9fcffc0a7b71030',
    borrowableAddress1: '0x44d87b59ce5a69968bfffd22e72d0a42bac83c48',
    farmingPoolAddress0: '0xedb47fe9a9448707835d7e23ddf12b55d369e4fc',
    farmingPoolAddress1: '0x5d594b469958b6f9017a84cbec012fdf08aec769',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xbc7dc91b8c1f2da435b8913d2b266ae96b2e5ec5': {
    lendingPoolAddress: '0xbc7dc91b8c1f2da435b8913d2b266ae96b2e5ec5',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x7b69eb796fa7d8ee40ecfb92c71788b679a3c560',
    oxUserProxyAddress: '0xe7f4756cf2f8783d7d1662651901d88c852dd5dd',
    oxStakingAddress: '0xd78da94ab7cca8b083c7c59e0ed81ec4e86a0d99',
    symbol0: 'SOLID',
    symbol1: 'oxSOLID',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x888ef71766ca594ded1f0fa3ae64ed2941740a20',
    tokenAddress1: '0xda0053f0befcbcac208a3f867bb243716734d809',
    uniswapV2PairAddress: '0xa3bf7336fdbce054c4b5bad4ff8d79539db2a2b3',
    collateralAddress: '0x6873dd094f4efe0df2f6afe549b0a4a9f55275ff',
    borrowableAddress0: '0xbe9965a8ff26f61dfd5b71c62759b5ccc5d07b7d',
    borrowableAddress1: '0xa4366c29811c5c045186b9beba0991b90600341f',
    farmingPoolAddress0: '0xef64ed62dc029294682c1d0f0e9dbd8cb3088232',
    farmingPoolAddress1: '0x670bbd4d35fd6ac76db7f78a7492a3cf8a9edc1e',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xb3dc4accfe37bd8b3c2744e9e687d252c9661bc7': {
    lendingPoolAddress: '0xb3dc4accfe37bd8b3c2744e9e687d252c9661bc7',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xbb6c3a2eda2edf495d695d4105f48d16c2da5d70',
    oxUserProxyAddress: '0x46341fc48c9463b35926bcedae2671b6135e376e',
    oxStakingAddress: '0x63378ed6d07091ec18bb80b450241a09851cc559',
    symbol0: 'USDC',
    symbol1: 'SYN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xe55e19fb4f2d85af758950957714292dac1e25b2',
    uniswapV2PairAddress: '0xb1b3b96cf35435b2518093acd50e02fe03a0131f',
    collateralAddress: '0xa82d53c7c6ef2a7282424a868faa057c848ea2d7',
    borrowableAddress0: '0xd053007fa1b12b8d740477c587a7a2a2321a40ad',
    borrowableAddress1: '0x25f889ca6c226da747ecaed30f115b3beb75f0e4',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x6707a811c7eb2582171ffebb33bc3b34247242f8': {
    lendingPoolAddress: '0x6707a811c7eb2582171ffebb33bc3b34247242f8',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x13782c5c1b3b53095c7894c39b76ab0430099e02',
    oxUserProxyAddress: '0x520307d4eba30197b5789ec95b4679424ff0a759',
    oxStakingAddress: '0xa26da4bf51741e4bc18223bbc247cc461c232a0d',
    symbol0: 'WFTM',
    symbol1: 'SYN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xe55e19fb4f2d85af758950957714292dac1e25b2',
    uniswapV2PairAddress: '0x8aa410d8b0cc3de48aac8eb5d928646a00e6ff04',
    collateralAddress: '0xf5cc5d2934e32c773bcb984807c722ad90aa1582',
    borrowableAddress0: '0x9ad764acd611ad8b70ca114a86c0c92f35412cc9',
    borrowableAddress1: '0x611706e94a690549aebcbe541670a5db148d114f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x430a123b4b359fc46cdad932327684466b50b32e': {
    lendingPoolAddress: '0x430a123b4b359fc46cdad932327684466b50b32e',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x6479352f9086474307b3fa465efc6fb550231f12',
    oxUserProxyAddress: '0x0d9985983f9fd4784e1414c0a2f89332a3ed2a5e',
    oxStakingAddress: '0x4c6e554c37c8a15095fa407bd83ee7d0497aaee5',
    symbol0: 'HND',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10010078a54396f62c96df8532dc2b4847d47ed3',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x6aae93f2915b899e87b49a9254434d36ac9570d8',
    collateralAddress: '0xd985702ecf985654b535a7fbd17d1ab01d99657f',
    borrowableAddress0: '0xa4514d0830bcd78ad3097ea77a987bd6f5caafa1',
    borrowableAddress1: '0xe343016794bdf44de8004dabe15dce4a351b7020',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x1a69a8d382a1b5b8e12a56e9e92b7f6e51180324': {
    lendingPoolAddress: '0x1a69a8d382a1b5b8e12a56e9e92b7f6e51180324',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xdf6f82eb0b596c720135c9a85960f54dd495ea8c',
    oxUserProxyAddress: '0x684a0087077b11d63c7604567d5591216c0d1371',
    oxStakingAddress: '0xa8b093d1b878646dd2b6f957bf4b0b6eee46a4e6',
    symbol0: 'LQDR',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x9861b8a9acc9b4f249981164bfe7f84202068bfe',
    collateralAddress: '0xe91c0ee3cc1477f427adbe9a15be255ee24961dd',
    borrowableAddress0: '0x79ccf06f6bbef48bb22d29a35ddbf5db039d838e',
    borrowableAddress1: '0x31d6f048aeac0485a53213e8f9fa2a72e2276707',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xefb573eba712d8b03ab60fea31626c0ca46a30a0': {
    lendingPoolAddress: '0xefb573eba712d8b03ab60fea31626c0ca46a30a0',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x6228f815d433293a4429d6ff055264fba60c6fb8',
    oxUserProxyAddress: '0xd846a8d69f367edf9bea058f027b273207736796',
    oxStakingAddress: '0x16c03f86fc4127ac8753470c526e0f0e0be74e6a',
    symbol0: 'BEETS',
    symbol1: 'fBEETS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e',
    tokenAddress1: '0xfcef8a994209d6916eb2c86cdd2afd60aa6f54b1',
    uniswapV2PairAddress: '0x5a3aa3284ee642152d4a2b55be1160051c5eb932',
    collateralAddress: '0x26f516b34bf72d9972385a7ff33efca7a83d12ad',
    borrowableAddress0: '0xf7a734c3738bb3a44de504fb1d017b1e904fbce4',
    borrowableAddress1: '0xe62f2142dfdd4151abd3118ca50465e08441798e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x2e4be477fd58a8409a671d15c7a91c2f98b81b39': {
    lendingPoolAddress: '0x2e4be477fd58a8409a671d15c7a91c2f98b81b39',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x4905b9574d391c4c09bf73d0ebbd5cf71b015e2f',
    symbol0: 'WFTM',
    symbol1: 'MULTI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
    uniswapV2PairAddress: '0x94be7e51efe2a0c06c2281b6b385fcd12c84d6f9',
    collateralAddress: '0x82ae22ad352d90113b820a94838d594c67fbbfe0',
    borrowableAddress0: '0xf20dd9117f2257b9f6baaee183c012f4bfce8bdf',
    borrowableAddress1: '0xb54d34ea4c0433172bd4270f10cbcd7fb7d3181b',
    farmingPoolAddress0: '0xcab448daecbd7cd753595499319f7775b7057fad',
    farmingPoolAddress1: '0xd200e3951d66d88ade3dca286ebe4ee1e1621598',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xa96703fa415acbfce853fb16841f8d1e8032569b': {
    lendingPoolAddress: '0xa96703fa415acbfce853fb16841f8d1e8032569b',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x9a1f8adae1a0b79ed1263640ee78421e9a5c9b68',
    symbol0: 'BOO',
    symbol1: 'xBOO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    tokenAddress1: '0xa48d959ae2e88f1daa7d5f611e01908106de7598',
    uniswapV2PairAddress: '0x5804f6c40f44cf7593f73cf3aa16f7037213a623',
    collateralAddress: '0x5be0af57b3dfb760b967966bf98a155782c211fe',
    borrowableAddress0: '0xe216f239da2b4b9ca86f112c10c7e99b32d549e3',
    borrowableAddress1: '0x04e8bd819398d93df9231067236a1598a71ec747',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x951dd713ba26dc922c00cdfa8ce2da97551f99d6': {
    lendingPoolAddress: '0x951dd713ba26dc922c00cdfa8ce2da97551f99d6',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x6642c79bd7729ec7c56954b7fdd453991342f3d8',
    symbol0: 'WFTM',
    symbol1: 'SCREAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
    uniswapV2PairAddress: '0x86dd79265814756713e631dde7e162bdd538b7b1',
    collateralAddress: '0x864186a616db214979589959666f280ca9b06076',
    borrowableAddress0: '0x71e83383ac325dfaac560b1b33be07978a25b319',
    borrowableAddress1: '0x5a6c4d053faa2044c493daecf034e25493172338',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x2e99df1ea51260589dc7cc5bde84ba6b48bddd89': {
    lendingPoolAddress: '0x2e99df1ea51260589dc7cc5bde84ba6b48bddd89',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xd0aa0c876cf4a223845dd8e26af1f9894d4b0d66',
    symbol0: 'LQDR',
    symbol1: 'DEI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3',
    uniswapV2PairAddress: '0xd7c590a05c9bf78a51e5171613f83e6a88a6851a',
    collateralAddress: '0x8256f092efecfee05ee2b5c8a3b27cb545b0f1eb',
    borrowableAddress0: '0x7884692c97457935b393fe946c962e2377164785',
    borrowableAddress1: '0xd5843632c5cc8ba08d2c2a3b58383e9088e0b37e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x7295faaeded70745063f1b31af6e21baccc2f915': {
    lendingPoolAddress: '0x7295faaeded70745063f1b31af6e21baccc2f915',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x4b356eb8992eccd97b276187141bc2305a163599',
    symbol0: 'DEI',
    symbol1: 'SCREAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3',
    tokenAddress1: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
    uniswapV2PairAddress: '0xd11e940c42e03d927cfd7426718bb4ca21d6015f',
    collateralAddress: '0x91b527cbc341ea82cebc553386cf780d97224f41',
    borrowableAddress0: '0xbf3e99cfae35ba8419b12a4b8dedd66c8964ccda',
    borrowableAddress1: '0x9fdb08128382110a7b28b4d29ce225ef7cabd658',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xe6e91ab350418a3223d035af5ab74ce599fbd614': {
    lendingPoolAddress: '0xe6e91ab350418a3223d035af5ab74ce599fbd614',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x3de4c9648d97834b3e67bd1a63102814c2b8282f',
    symbol0: 'DEI',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0xf42dbcf004a93ae6d5922282b304e2aefdd50058',
    collateralAddress: '0x55d29cfc8635127f19ed6c645bfdc9c16f180ead',
    borrowableAddress0: '0xeab2b2a1049b6e08520db65db08f2fd78d164d39',
    borrowableAddress1: '0x014c4bf6b842ada889cf629ca156964ce8d3be30',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x2c6ce73df1bd4a2b1a57895033068f3e8c413158': {
    lendingPoolAddress: '0x2c6ce73df1bd4a2b1a57895033068f3e8c413158',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x2a6931b75ee4e062baabee7f297166871ee55837',
    symbol0: 'WFTM',
    symbol1: 'OXD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5a9848b9d145965d821aaec8fa32aaee026492d',
    uniswapV2PairAddress: '0xcb6eab779780c7fd6d014ab90d8b10e97a1227e2',
    collateralAddress: '0x0babddfc39dbd6db4d059be43969c1b5d0def1da',
    borrowableAddress0: '0x21f90523c7c4c607c9e05bb089cc7ef5b5b94392',
    borrowableAddress1: '0x6bc4cbc23130cfe62ea3049f24313ed9cc4c26f3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xa5a877fc9d4c61b39443e8899fbb0277e02845d2': {
    lendingPoolAddress: '0xa5a877fc9d4c61b39443e8899fbb0277e02845d2',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x7b69eb796fa7d8ee40ecfb92c71788b679a3c560',
    symbol0: 'SOLID',
    symbol1: 'oxSOLID',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x888ef71766ca594ded1f0fa3ae64ed2941740a20',
    tokenAddress1: '0xda0053f0befcbcac208a3f867bb243716734d809',
    uniswapV2PairAddress: '0xa3bf7336fdbce054c4b5bad4ff8d79539db2a2b3',
    collateralAddress: '0x4082cd81cee247cc0e99283e5a7658200c239340',
    borrowableAddress0: '0xef036d4baf03c36c7c2337ff10c8cdbe2bd33331',
    borrowableAddress1: '0x05f277e74e8bc1f0fd38ab8c621eb7bbeb4a3212',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x38af189e2fc6fdfb0763904d9a19b272d2b352b1': {
    lendingPoolAddress: '0x38af189e2fc6fdfb0763904d9a19b272d2b352b1',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xbb6c3a2eda2edf495d695d4105f48d16c2da5d70',
    symbol0: 'USDC',
    symbol1: 'SYN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0xe55e19fb4f2d85af758950957714292dac1e25b2',
    uniswapV2PairAddress: '0xb1b3b96cf35435b2518093acd50e02fe03a0131f',
    collateralAddress: '0xa773ea05976146f272af577602a11ff5eb92c23b',
    borrowableAddress0: '0xddded636cdd2cf25b93aaa615c1ec8cdf3222e51',
    borrowableAddress1: '0x86beb793552b6459ae588439cf4b28978b4294bb',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xf57e2173cc253c0466530943f4b099b1d27a3a9c': {
    lendingPoolAddress: '0xf57e2173cc253c0466530943f4b099b1d27a3a9c',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x13782c5c1b3b53095c7894c39b76ab0430099e02',
    symbol0: 'WFTM',
    symbol1: 'SYN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xe55e19fb4f2d85af758950957714292dac1e25b2',
    uniswapV2PairAddress: '0x8aa410d8b0cc3de48aac8eb5d928646a00e6ff04',
    collateralAddress: '0x5eba46c2c8051f5788ea696e7849e0d03c1f69cb',
    borrowableAddress0: '0x26d102b170c383a5e87a91a6a8d4e5754256ae00',
    borrowableAddress1: '0xb430f6aaddeda089830c1e0714e0b4556ec01f1f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x19d56953bbf4e4336bb90fe38ebccc99534a522b': {
    lendingPoolAddress: '0x19d56953bbf4e4336bb90fe38ebccc99534a522b',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x6479352f9086474307b3fa465efc6fb550231f12',
    symbol0: 'HND',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10010078a54396f62c96df8532dc2b4847d47ed3',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x6aae93f2915b899e87b49a9254434d36ac9570d8',
    collateralAddress: '0x693e10b79634484abb7c0f493d325ee378445986',
    borrowableAddress0: '0xa98d5d59c6f46a2a5e75e5d93e6368a7af7c8649',
    borrowableAddress1: '0x25a699e2cb9a94a82fab16e2e648d8b728132918',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x609918b85712ef0448f6a4dc8d7f5986afd0ebf3': {
    lendingPoolAddress: '0x609918b85712ef0448f6a4dc8d7f5986afd0ebf3',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xdf6f82eb0b596c720135c9a85960f54dd495ea8c',
    symbol0: 'LQDR',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x9861b8a9acc9b4f249981164bfe7f84202068bfe',
    collateralAddress: '0xc0d79f43c88b925da4b1a020b48423cc08505d65',
    borrowableAddress0: '0xa587d96bc77501a2c133b886644c4f238d4b6009',
    borrowableAddress1: '0x237c961037d5795f8a0508de72a57303e77f8989',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xa860d4c99dc21582b1c4431a5a067ca97706c5a0': {
    lendingPoolAddress: '0xa860d4c99dc21582b1c4431a5a067ca97706c5a0',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x6228f815d433293a4429d6ff055264fba60c6fb8',
    symbol0: 'BEETS',
    symbol1: 'fBEETS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e',
    tokenAddress1: '0xfcef8a994209d6916eb2c86cdd2afd60aa6f54b1',
    uniswapV2PairAddress: '0x5a3aa3284ee642152d4a2b55be1160051c5eb932',
    collateralAddress: '0x7eb93604ff06860f3e568acc222df2867ad1305e',
    borrowableAddress0: '0xa957422d3103189e6fd0d7ae187bea0e1e0a2d02',
    borrowableAddress1: '0x149dd3a2aa9574d27042e7a05599f5ffdb366c95',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xd2916e1fdf0cbbf919a025003a8059d5e50f6686': {
    lendingPoolAddress: '0xd2916e1fdf0cbbf919a025003a8059d5e50f6686',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xabf0eaf72086dca7226ba44adf85091cea238789',
    oxUserProxyAddress: '0x1625a30c04d4f71af2ccc47c350354f228b70e08',
    oxStakingAddress: '0x88fc52d698294997fc35bdbce4b809d57f342f94',
    symbol0: 'SCREAM',
    symbol1: 'xSCREAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
    tokenAddress1: '0xe3d17c7e840ec140a7a51aca351a482231760824',
    uniswapV2PairAddress: '0x74b61f876fb49c7e73dbdd3b2185390bfdc11ff5',
    collateralAddress: '0x87c8dc9082cf28e5da001a12de88344e46ed03ad',
    borrowableAddress0: '0x25fbd8f0e63477bb8a38f6c4b26cc8634a07dd8e',
    borrowableAddress1: '0xfc35532900ab01de750f5871d82a41b1d2b82015',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x45933032bca4f793bf185d02ba6a154bdbdf93a7': {
    lendingPoolAddress: '0x45933032bca4f793bf185d02ba6a154bdbdf93a7',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xdafacf5182437311c1f88cd6b42f881b062e2888',
    oxUserProxyAddress: '0x29723918607309a73e71e38cf18d64d69e3b38d9',
    oxStakingAddress: '0x06e19eab30c1e0c3fe8c6f8c8bce401d26ed60b0',
    symbol0: 'IB',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x00a35fd824c717879bf370e70ac6868b95870dfb',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x304b61f3481c977ffbe630b55f2abeee74792664',
    collateralAddress: '0xb0717a749613f04bfe73f2d19d709e235bf8445f',
    borrowableAddress0: '0xf2488a11e74fe4869a46554a186e0e1e74bace69',
    borrowableAddress1: '0x5b39ee4e653f7162425dbbc1d2035a94e1c2f808',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x328caceb07022f910eb0eef7b1bcce19d54eaca0': {
    lendingPoolAddress: '0x328caceb07022f910eb0eef7b1bcce19d54eaca0',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xeb569abd7f3dd906461fcf74d4e4495871f02d32',
    oxUserProxyAddress: '0x0ca3511d056b8eda2b9aefc81ec049fc15fed778',
    oxStakingAddress: '0xb4fc94e6420b6e421a023eb30c46d2dfa3304942',
    symbol0: 'FXS',
    symbol1: 'FRAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x7d016eec9c25232b01f23ef992d98ca97fc2af5a',
    tokenAddress1: '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
    uniswapV2PairAddress: '0x4bbd8467ccd49d5360648ce14830f43a7feb6e45',
    collateralAddress: '0xd0be590064fbf6ab791033eebb1cbdd9356aeec0',
    borrowableAddress0: '0x347f7be6d88ae2755ac945453a992784937851ab',
    borrowableAddress1: '0xc779d63809b0b9f5bbfe01b846adbbf3bbd058f3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xc64cdbc64060196a3e0f663cac484d2a5270634c': {
    lendingPoolAddress: '0xc64cdbc64060196a3e0f663cac484d2a5270634c',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x9d1a7eb2cfb99a9a1806d14366514bfb43d4f254',
    oxUserProxyAddress: '0x7e6e88757651e544bdc90cecbfb6bd2703710c9a',
    oxStakingAddress: '0xe0a25297fd593877207c918a541158591b7fb6c2',
    symbol0: 'WFTM',
    symbol1: 'RDL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x79360af49edd44f3000303ae212671ac94bb8ba7',
    uniswapV2PairAddress: '0x5ef8f0bd4f071b0199603a28ec9343f3651999c0',
    collateralAddress: '0xdb03d8e47117d75cdc17029734baea76c317be56',
    borrowableAddress0: '0xe7628ff9380a7722acd3b2a1017d21de741faef3',
    borrowableAddress1: '0xec495088ceb26a141837996508327358f77c2d56',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xbdecb0def3795214f3a1cc119cb4d711e42bd181': {
    lendingPoolAddress: '0xbdecb0def3795214f3a1cc119cb4d711e42bd181',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x46e0f8acb83bdc5fd6cb6d888d285ccf17aacbf4',
    oxUserProxyAddress: '0x7691eb2d90b28835dd63d5a014c530c8a409479c',
    oxStakingAddress: '0x2799e089550979d5e268559bebca3990dcbed18b',
    symbol0: 'YFI',
    symbol1: 'WOOFY',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 12,
    tokenAddress0: '0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    tokenAddress1: '0xd0660cd418a64a1d44e9214ad8e459324d8157f1',
    uniswapV2PairAddress: '0x4b3a172283ecb7d07ab881a9443d38cb1c98f4d0',
    collateralAddress: '0xd05ef44c8c1a47d31a82b36dcca0ef45e53f44b9',
    borrowableAddress0: '0xadb016b3650d01c69292cba63daa999d89667462',
    borrowableAddress1: '0xda10a0d871f52d6c455e189ec3d4e8182812231d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xc45ba1007e00c47055ae8c9619442e91ff7168ba': {
    lendingPoolAddress: '0xc45ba1007e00c47055ae8c9619442e91ff7168ba',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0xf66eaea3f1f90eecae3c865686136b3e8ffbaabc',
    oxUserProxyAddress: '0x69f9a9f4f4e4efe57bb36c71e7e7cb9aff99eb0e',
    oxStakingAddress: '0x107b45bc35b7ae0fa56bf39ef91787a3a66f9be1',
    symbol0: 'TOMB',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x29a1780be39c1911e55179f3ead358767203cecc',
    collateralAddress: '0x43196d55d6d7bdfb4bc72393cb0627ef386bcf55',
    borrowableAddress0: '0x83faf1f8a7e6e516cf0349c1eaf16dad727e2e72',
    borrowableAddress1: '0x2eb0af61964011b8e792880f0910af032c88cda1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x6aa1d3223a0571e63b8a5d4a5671024c4cdbac30': {
    lendingPoolAddress: '0x6aa1d3223a0571e63b8a5d4a5671024c4cdbac30',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xabf0eaf72086dca7226ba44adf85091cea238789',
    symbol0: 'SCREAM',
    symbol1: 'xSCREAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
    tokenAddress1: '0xe3d17c7e840ec140a7a51aca351a482231760824',
    uniswapV2PairAddress: '0x74b61f876fb49c7e73dbdd3b2185390bfdc11ff5',
    collateralAddress: '0x3cdad887459247e0068014402b2bf629df948933',
    borrowableAddress0: '0x04b8c8920a0b27f7ead647bb31f23bf55e8c1246',
    borrowableAddress1: '0x56dc6dd26c409aaa74f42e2dc9457e0641fccd32',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x11402425f12285c89bb16524a953c2de37f1268c': {
    lendingPoolAddress: '0x11402425f12285c89bb16524a953c2de37f1268c',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xdafacf5182437311c1f88cd6b42f881b062e2888',
    symbol0: 'IB',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x00a35fd824c717879bf370e70ac6868b95870dfb',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x304b61f3481c977ffbe630b55f2abeee74792664',
    collateralAddress: '0x069b99fcb55da376ac70333f8c46921bf94dfad0',
    borrowableAddress0: '0x706521742fe064457c878df380ae435cf68fb372',
    borrowableAddress1: '0x7be27ec00239ca28ceb3fd71fcc52152028826d5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xadb8d29ecb0224d1113a5d2223f7c513d7666985': {
    lendingPoolAddress: '0xadb8d29ecb0224d1113a5d2223f7c513d7666985',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xeb569abd7f3dd906461fcf74d4e4495871f02d32',
    symbol0: 'FXS',
    symbol1: 'FRAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x7d016eec9c25232b01f23ef992d98ca97fc2af5a',
    tokenAddress1: '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
    uniswapV2PairAddress: '0x4bbd8467ccd49d5360648ce14830f43a7feb6e45',
    collateralAddress: '0x2c275c1e57654cf6715d59659cf8742695ab918e',
    borrowableAddress0: '0xa1d7f9517cb8c0da2d17b5b76600f712d16b2755',
    borrowableAddress1: '0x6a59dec247056ab9b1be0b061ac1465b285d6bb8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x008e5bfb67d6cf1ae5bcc39feceb6b49cd4714ab': {
    lendingPoolAddress: '0x008e5bfb67d6cf1ae5bcc39feceb6b49cd4714ab',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x9d1a7eb2cfb99a9a1806d14366514bfb43d4f254',
    symbol0: 'WFTM',
    symbol1: 'RDL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x79360af49edd44f3000303ae212671ac94bb8ba7',
    uniswapV2PairAddress: '0x5ef8f0bd4f071b0199603a28ec9343f3651999c0',
    collateralAddress: '0xc8438653199186e5c693942567d999ed870fcdf3',
    borrowableAddress0: '0x75235d63bac661d77fd3d834ea49624c15c04d19',
    borrowableAddress1: '0x68bbc08abe51f2c7470fc473ecf03178156668dd',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x4f1cbeb5cff89e24a31dbc046fd7f59d25a2843d': {
    lendingPoolAddress: '0x4f1cbeb5cff89e24a31dbc046fd7f59d25a2843d',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x46e0f8acb83bdc5fd6cb6d888d285ccf17aacbf4',
    symbol0: 'YFI',
    symbol1: 'WOOFY',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1322875650000000000',
    decimals0: 18,
    decimals1: 12,
    tokenAddress0: '0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    tokenAddress1: '0xd0660cd418a64a1d44e9214ad8e459324d8157f1',
    uniswapV2PairAddress: '0x4b3a172283ecb7d07ab881a9443d38cb1c98f4d0',
    collateralAddress: '0xe9f4726b9c3f68fa7b58b666ea910156125cda61',
    borrowableAddress0: '0x02742fba9c84847517b8f7996d128d11be5e04dd',
    borrowableAddress1: '0x77601b5d64962f25d054412d86cb0d61018d0d79',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xc799ce73ede5c2fd58df7432c7028f82ac3f0de7': {
    lendingPoolAddress: '0xc799ce73ede5c2fd58df7432c7028f82ac3f0de7',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0xf66eaea3f1f90eecae3c865686136b3e8ffbaabc',
    symbol0: 'TOMB',
    symbol1: 'miMATIC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    tokenAddress1: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    uniswapV2PairAddress: '0x29a1780be39c1911e55179f3ead358767203cecc',
    collateralAddress: '0x29e4498ad756b9302bff579ba864c41c11242c05',
    borrowableAddress0: '0x4e49d9d2b967445af1c131b90668c7d83995c90d',
    borrowableAddress1: '0x15eca3f722bade7053f0989f8f1e2c970ce47770',
    farmingPoolAddress0: '0x0181f09b9803bb11817afcdd884b1ca400a74d67',
    farmingPoolAddress1: '0x26d6f09c253ffede5f40c8e6e35640cb7f3d9a51',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xa65709aece302ed478b7d3171cfd577bbe4706a4': {
    lendingPoolAddress: '0xa65709aece302ed478b7d3171cfd577bbe4706a4',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.OXD,
    pid: undefined,
    gaugeAddress: '0x3e21a9254070730935c27a07b8a85eb262d0ec4e',
    oxUserProxyAddress: '0xedfd66f1749350fe4ef35ecbbeba4bff8f4b55ed',
    oxStakingAddress: '0x106e126f7b26aad7d0f3c7edd1da2ddeab056d07',
    symbol0: 'wpc',
    symbol1: 'WeVE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x0589073b62217f8196fa668a3fdf81df45726236',
    tokenAddress1: '0x911da02c1232a3c3e1418b834a311921143b04d7',
    uniswapV2PairAddress: '0x759d12bef107e51a711a502cb81f3dbd2748ebf1',
    collateralAddress: '0xc4dd56e0e111253a64114a6a924a67c14a0b20f8',
    borrowableAddress0: '0xc23cf7426cc5a8d8911173dfa46aee2544d6bf82',
    borrowableAddress1: '0xf04463a43235793dcecf72df93770505a4919af3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x543bdf468abe8f367b42646ea24177569d25985d': {
    lendingPoolAddress: '0x543bdf468abe8f367b42646ea24177569d25985d',
    isTarotVault: true,
    dex: DEX.SOLIDLY,
    vaultType: VaultType.SOLIDEX,
    pid: undefined,
    gaugeAddress: '0x3e21a9254070730935c27a07b8a85eb262d0ec4e',
    symbol0: 'wpc',
    symbol1: 'WeVE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x0589073b62217f8196fa668a3fdf81df45726236',
    tokenAddress1: '0x911da02c1232a3c3e1418b834a311921143b04d7',
    uniswapV2PairAddress: '0x759d12bef107e51a711a502cb81f3dbd2748ebf1',
    collateralAddress: '0xf4f399fdb7aa04c0a3fdc60a90c5a692e5666397',
    borrowableAddress0: '0x30e5c36fb34e6d869eb8790b769e1fc7e368b0c9',
    borrowableAddress1: '0xd0fee07f490772f8ceb0ce318b9f33e309d2eb25',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x2bf265d72ba32660bd291cbfd3b1b1691b1492e4': {
    lendingPoolAddress: '0x2bf265d72ba32660bd291cbfd3b1b1691b1492e4',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0x87cae38ecb34ff4d3239a789f7709f285484f7e1',
    symbol0: 'WFTM',
    symbol1: 'SPIRIT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
    uniswapV2PairAddress: '0x912b333ddafc925f63c9746e5115a2cd5290b59e',
    collateralAddress: '0x6c18188b8117c17e165f73b4cd15d296695c5a95',
    borrowableAddress0: '0x3516bb5687aeea067a05f9f15e22a935d04333b5',
    borrowableAddress1: '0xf4c4b11081e326963f59702aaf9defb2ea68c5bc',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x1cd6a52417ac1ec8a061c05d16c8a0fb7d6873a0': {
    lendingPoolAddress: '0x1cd6a52417ac1ec8a061c05d16c8a0fb7d6873a0',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0x6e149f7687d4c8077d4e57d1dbc5f923a06395c2',
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x772bc1196c357f6e9c80e1cc342e29b3a5f05ef3',
    collateralAddress: '0x79a74fb7ac17b2d5ed141c527340b13491333b23',
    borrowableAddress0: '0x2c68b48ee1514281c7902f2d55a5783aeb26f68d',
    borrowableAddress1: '0xfa3a9f4a11e95cc6d397d3173146a1284ae1085a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xcf904889197fea5250ef0e66cb75be96841d0a5b': {
    lendingPoolAddress: '0xcf904889197fea5250ef0e66cb75be96841d0a5b',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0x20c993fb64c536a67ba7ffe199cf7067d1103548',
    symbol0: 'WFTM',
    symbol1: 'BTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x321162cd933e2be498cd2267a90534a804051b11',
    uniswapV2PairAddress: '0xbaa9fce81a12bd2aef29aea6b7047dacfa20f96e',
    collateralAddress: '0x781c814bcf2dc3a8699f4429d314a7af52072c70',
    borrowableAddress0: '0xac0777d9ac2c59917529fc9214af7df8f8fbc02e',
    borrowableAddress1: '0xd1657adec1d47f3562a812b9a2b60a8a03dabec3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x05b2bcb2295a6f07c5d490128b6b4787c8c4464e': {
    lendingPoolAddress: '0x05b2bcb2295a6f07c5d490128b6b4787c8c4464e',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0x32472a7c49e66ee53d98c54e55886c44885c4cb9',
    symbol0: 'WFTM',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xf97eeeccd070ceeca8d0dd80fc78cc15dd66b0e5',
    collateralAddress: '0x44567a34a8338e2b4315c052599fd0d0977e5815',
    borrowableAddress0: '0x3ba902ecbb6dce179392057ecfe5e18bb52a5995',
    borrowableAddress1: '0xdb762b2fd35f0d6b67397d049d121470318c0cf8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x8706dc2067d64651620d66052bc065da1c81327f': {
    lendingPoolAddress: '0x8706dc2067d64651620d66052bc065da1c81327f',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0x401ea5e5aad28e7d4d21620308521165bb9ef4b9',
    symbol0: 'fUSDT',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x842c44870ed021f070938d077ca2cf2dc474eca6',
    collateralAddress: '0xbcaaf77bc9e80a921edfef3ad7ba2222a62197cd',
    borrowableAddress0: '0xe68fcd7162dc8a61d74baacd0986e06e88c05714',
    borrowableAddress1: '0xf8b86d87cfe478a498653085e7b7c11753babebe',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x63d26bc2c050cf91b5049b6b549d27e320bb6ee1': {
    lendingPoolAddress: '0x63d26bc2c050cf91b5049b6b549d27e320bb6ee1',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0xb197d4e08b8b811cea42a7918ca4cd5566747737',
    symbol0: 'WFTM',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0x1c8dd14e77c20eb712dc30bbf687a282cff904a2',
    collateralAddress: '0x649cfa59c518d95a66bcd0ede6cfac0c4e8c1499',
    borrowableAddress0: '0x25dea7f66255d7468ec517cc9c74eae17d412ef6',
    borrowableAddress1: '0x4a9796a232d95a216175641dd69e3a51b3c4da0f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x55d0fd03076de5d368aec713248ea765a9625830': {
    lendingPoolAddress: '0x55d0fd03076de5d368aec713248ea765a9625830',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0x3d2a59d742db51d15c6d4618ab94565af877f99d',
    symbol0: 'WFTM',
    symbol1: 'MIM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    uniswapV2PairAddress: '0xa2b529d9ea1a3ddea837af19fea3a45a3b304b8f',
    collateralAddress: '0xd6e6ccc38f39c0c8e343274db8cccbd3d3d8fd8d',
    borrowableAddress0: '0x418f8b6b18d6f5473440699cb083355c5da21282',
    borrowableAddress1: '0x328f162536c9a367de3233fb757cd116a5794343',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x14df1c98e7885063aa9c8ab33879f573a859f652': {
    lendingPoolAddress: '0x14df1c98e7885063aa9c8ab33879f573a859f652',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0x2656f0b597e896beee0315cecf81bc4216296206',
    symbol0: 'WFTM',
    symbol1: 'FRAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
    uniswapV2PairAddress: '0x2cfc5f833299fefd7744358cfedfa8ca1320dc3f',
    collateralAddress: '0x8b2ab231a7ee610f070e530878e7194f1fa21bd4',
    borrowableAddress0: '0x74cf2a1687f61655db71ddf86ffbd84be15a25f1',
    borrowableAddress1: '0x78f4a5671249a53d0a322b87bfdb9530219b75d5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x817b04726c9110ea5bfe6aed9c9748c4abd14a3e': {
    lendingPoolAddress: '0x817b04726c9110ea5bfe6aed9c9748c4abd14a3e',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0xa0a102f7bee1b53fcbf4ad7c5a0ba32f3356e3f4',
    symbol0: 'LQDR',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xe42bb367c958e0e624c164f2491c37d8fd713515',
    collateralAddress: '0xbdf5a18612cdd38a856da89074c59e19b26ab061',
    borrowableAddress0: '0x06cd1b9eda7d446e48dcded83144585ee3d295da',
    borrowableAddress1: '0xe3b70b54920f3e6a2a353db465d5130878bb32ca',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xb08f8f4f6b04d65c4e05a83490cf9c3bdadabde5': {
    lendingPoolAddress: '0xb08f8f4f6b04d65c4e05a83490cf9c3bdadabde5',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0x0dc81c6440f4ace2606ad13fcf2b33a462a0f04a',
    symbol0: 'WFTM',
    symbol1: 'BIFI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd6070ae98b8069de6b494332d1a1a81b6179d960',
    uniswapV2PairAddress: '0xe72077036e01fd50b39c521cf5af7200c611faa5',
    collateralAddress: '0x47afff87e517ccca7e687517ccfad29325d1cf1e',
    borrowableAddress0: '0xe75b059ad895c6972aab2e621d0777b0e099d62a',
    borrowableAddress1: '0x2c063b0cfd292b7075755aa19b086ef6bf1696f7',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xe46bf36bdcad0dce55bac4fa24aac9e8428a4f25': {
    lendingPoolAddress: '0xe46bf36bdcad0dce55bac4fa24aac9e8428a4f25',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0xbbb4259c3344109ba2f6e8b773ce7e65d5eaf4d0',
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0x2af5ffe6b448f3d2f73c6b6a6fe5e678b5720fb1',
    collateralAddress: '0x90cdaa4bb773bfc86dbaadd854c26811645c5499',
    borrowableAddress0: '0x6be8b2425433db4389ed02c671773e0d254ebe7f',
    borrowableAddress1: '0x0fab73d5889006da502c836f6a1499128aa3cd1d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xef0c35cbddd248d78cdebe5ea6eb762d2fc689b7': {
    lendingPoolAddress: '0xef0c35cbddd248d78cdebe5ea6eb762d2fc689b7',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0xf6481024a9e8b02dcb0dab05f7167aed86e4ce20',
    symbol0: 'WFTM',
    symbol1: 'CRE8R',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x2ad402655243203fcfa7dcb62f8a08cc2ba88ae0',
    uniswapV2PairAddress: '0x15bd6dd84153c5ca140b36afa96ebbb53b4c0bdb',
    collateralAddress: '0xaaf80ee23d6787f35ddc65a11350391441cf36ae',
    borrowableAddress0: '0x944bbc4796b5b40845f4f2a77ad17dab7e9dfc89',
    borrowableAddress1: '0xc5c25ca73b6fb24f5dbde82e8aaf57280072946f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xae4e9d286db81c66b1fc00e3568139840e833d90': {
    lendingPoolAddress: '0xae4e9d286db81c66b1fc00e3568139840e833d90',
    isTarotVault: true,
    dex: DEX.SPIRIT_V2,
    vaultType: VaultType.SPIRIT_V2,
    pid: undefined,
    gaugeAddress: '0xaae52f19863b5bf62488bce901f7aadde604ab58',
    symbol0: 'WFTM',
    symbol1: 'FXS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x7d016eec9c25232b01f23ef992d98ca97fc2af5a',
    uniswapV2PairAddress: '0x6c6359c598a1653b3a46ba80d44edc7225b8a18b',
    collateralAddress: '0x710bc23d05f838cb64ea419e270ddec2fef9d93d',
    borrowableAddress0: '0x8eb59ccca1813edda346df2a00a249b0e575510f',
    borrowableAddress1: '0x10adeac999fd1b2978a8003acd8218905d8e891a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x86bca884d42dacafc4e362377462ccd20f7fa039': {
    lendingPoolAddress: '0x86bca884d42dacafc4e362377462ccd20f7fa039',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V3,
    pid: 3,
    symbol0: 'WFTM',
    symbol1: 'sFTMX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xd7028092c830b5c8fce061af2e593413ebbc1fc1',
    uniswapV2PairAddress: '0xe67980fc955fecfda8a92bbbfbcc9f0c4be60a9a',
    collateralAddress: '0x3e7346094598230998d7a8352c63db31958cf88e',
    borrowableAddress0: '0x7c97644c6c09609c699165d4d091383f2d22b11b',
    borrowableAddress1: '0x6da7774600558968fed128604dfb1708b6084c22',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x9c1af4d09a1625d88ffcba7e2851acb273b4096d': {
    lendingPoolAddress: '0x9c1af4d09a1625d88ffcba7e2851acb273b4096d',
    isTarotVault: true,
    dex: DEX.SPOOKY,
    vaultType: VaultType.SPOOKY_V3,
    pid: 4,
    symbol0: 'USDC',
    symbol1: 'OATH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21ada0d2ac28c3a5fa3cd2ee30882da8812279b6',
    uniswapV2PairAddress: '0x9ba499753ea5407d5578718a8d37453ecbf609f9',
    collateralAddress: '0xdc62d75eb25834050cf54af6afbe246a86b330b9',
    borrowableAddress0: '0x52753666fd69790d9a59f23b0f1cb0e722e3658c',
    borrowableAddress1: '0x3183d1e9c170a9c29f5f1dd8ed630f5636bb35ae',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xf6d943c8904195d0f69ba03d97c0baf5bbdcd01b',
    tarotRouterAddress: '0x3f7e61c5dd29f9380b270551e438b65c29183a7c'
  },
  '0x05e9815d6a636544ca312b53250c9afadbea6102': {
    lendingPoolAddress: '0x05e9815d6a636544ca312b53250c9afadbea6102',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0x5e689d7fb26ffc4bd615c98c8517a18ef1f5e68d',
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x7547d05dff1da6b4a2ebb3f0833afe3c62abd9a1',
    collateralAddress: '0x7a6512f5a86725a6c37972d3b312dc9d09a50cfa',
    borrowableAddress0: '0xdb68f7eb74ddaab1db4045a071e48e5ba9777d6c',
    borrowableAddress1: '0x47c7b3f5fa0d52dfd51bb04977235adbe32a3002',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x2d97f04a7d09b81e4483f33a146871433144fd49': {
    lendingPoolAddress: '0x2d97f04a7d09b81e4483f33a146871433144fd49',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0x7d8f38845c74ee380fa20a950cbed4ed9194c0be',
    symbol0: 'USDC',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x515d84c494454835443b303a914a341e80f67278',
    collateralAddress: '0xf1f9e6d6325531c29d80482b4173859016f4d782',
    borrowableAddress0: '0xd66eb10609bd56dc2b4082bddfe668d3ac5fd061',
    borrowableAddress1: '0x92bc0527d30667d5ddafb6cc50dd5c825c0e0b61',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xbdc944b662ffbc3344d16b63b8119c06e07026c7': {
    lendingPoolAddress: '0xbdc944b662ffbc3344d16b63b8119c06e07026c7',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0x47e901fd66ad41173aec8896201260b45dcee682',
    symbol0: 'WFTM',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xe3bd349bdb8203c15426b2d273f57568e658f843',
    collateralAddress: '0xd6b5ca0e1c8f130afd29f25e678d45c083a0b8c3',
    borrowableAddress0: '0x0feec300a8c3faee5de4925bae909f1e0a87c496',
    borrowableAddress1: '0x48c6c032cdedb2c6ca99f6689bb30c13d9872e09',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x39a5319c84e97ab4ae63e0891cbfdd1c344bf3fb': {
    lendingPoolAddress: '0x39a5319c84e97ab4ae63e0891cbfdd1c344bf3fb',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0xd2a3830f6e456ada65f5816378992e84c86aaa0a',
    symbol0: 'WFTM',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0x0f03efdce7a8ce08c238b1a0b5425fb63bd44b38',
    collateralAddress: '0x8bccca695cb90aff28a0baa704bb09aaba9878e7',
    borrowableAddress0: '0x0a09c62b10e02882dea69d84641861292e9ba1d1',
    borrowableAddress1: '0xad9c6dd86be65b444a217c6d4701a5e2fd53330d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x75e1a38ccaf856de76586f2a5a54741eca47419b': {
    lendingPoolAddress: '0x75e1a38ccaf856de76586f2a5a54741eca47419b',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0xd1ee0dc8ae761a0900ecd3b629dfa6c3cee99eed',
    symbol0: 'WFTM',
    symbol1: 'EQUAL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x3fd3a0c85b70754efc07ac9ac0cbbdce664865a6',
    uniswapV2PairAddress: '0x3d6c56f6855b7cc746fb80848755b0a9c3770122',
    collateralAddress: '0xb0cb264a610e2548e84fe3a3664ca41b01ab239a',
    borrowableAddress0: '0x090d4ea9623c18e0db854b8ebf1a21195b05f37b',
    borrowableAddress1: '0x243d4755ffa28d922c97d6488a4ba1c3cacf6086',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xf5f2071e10a3d4fb0b34c5edf894f93e7a910057': {
    lendingPoolAddress: '0xf5f2071e10a3d4fb0b34c5edf894f93e7a910057',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0xcdd56e66d7bd326b55cc23ba49f53262d7382555',
    symbol0: 'WFTM',
    symbol1: 'BTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x321162cd933e2be498cd2267a90534a804051b11',
    uniswapV2PairAddress: '0xda3e21477f872f30794551d908de244b8839f723',
    collateralAddress: '0x5701026689dcd2f24d9b554baf6255e7b0fc1fce',
    borrowableAddress0: '0xbeee7779809db6ffaa59fbbc3d0f16e917911279',
    borrowableAddress1: '0x0b2acf00319ef79ff1aeab9931b5f5624d72d2ed',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x74a1e0822823082fc299526862827ad815410c0c': {
    lendingPoolAddress: '0x74a1e0822823082fc299526862827ad815410c0c',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0x41c681dfcbb6b736272eb0add029d926c21745ef',
    symbol0: 'BTC',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x321162cd933e2be498cd2267a90534a804051b11',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x97426aa7a2a9a1d9e02e76afaa4be7518be064ee',
    collateralAddress: '0xaf1e6cb375db5785ccefdb47768c345b8e9ccccf',
    borrowableAddress0: '0x60158399562c76524afe9015cb3a546d2132fee8',
    borrowableAddress1: '0x9885d8b9d40955ef18a6bee55633e3afc25d4c60',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xe5746622e641f081a176569982ee945c2029fc41': {
    lendingPoolAddress: '0xe5746622e641f081a176569982ee945c2029fc41',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0x1057b34397b938364ab478fa93d74a7615c3ae05',
    symbol0: 'fUSDT',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x783a3fb9f88df5a89fb3a5e595254fa8f7b97b48',
    collateralAddress: '0x13c4a59e827848431ab6d1f6a6e583383e41c01f',
    borrowableAddress0: '0x1bb2448ffc4263fc6e388ad32ca976190a4bdcc8',
    borrowableAddress1: '0x2a3fc09669d014691ae4524c992f11d4f3f9b8cd',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x232cc9c8c27492775fb7d4532eedb774d0070764': {
    lendingPoolAddress: '0x232cc9c8c27492775fb7d4532eedb774d0070764',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0x693b0515091c30564725753c832a05ed22edf1bd',
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0xd3c3e365d84fc4e7e66244e00e5e2271176ff61e',
    collateralAddress: '0xcb613b1a48e81ccebda62ab11a7e411319e8c243',
    borrowableAddress0: '0x9f27ff8b8c661aad80ff0606c2849d15582f5b63',
    borrowableAddress1: '0xf5926f833d8332cb551a8ae8c6fe952a5d3ea271',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x13a1651b5b0fd2b558c6ee513a69ad2fb1dd8137': {
    lendingPoolAddress: '0x13a1651b5b0fd2b558c6ee513a69ad2fb1dd8137',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0xa48d9584b9332c6a040f81443cd6baf23893b8e8',
    symbol0: 'OATH',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21ada0d2ac28c3a5fa3cd2ee30882da8812279b6',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x558dca97c224851cf428cbf244bec0b897642efc',
    collateralAddress: '0xce0ad559150b378122899fe6715fb8e51f6c6f9d',
    borrowableAddress0: '0x93b5576655fce671cc60a2ee81bd40bca098fb82',
    borrowableAddress1: '0xd2994becbf2b0713df9a142d1f25a302c7881bd0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0x9c51073ba6501e23c3676f56163401937cc2b2a7': {
    lendingPoolAddress: '0x9c51073ba6501e23c3676f56163401937cc2b2a7',
    isTarotVault: true,
    dex: DEX.EQUALIZER,
    vaultType: VaultType.EQUALIZER,
    pid: undefined,
    gaugeAddress: '0x804883c01104f91beb9588ea78514439ec903356',
    symbol0: 'USDC',
    symbol1: 'EQUAL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x3fd3a0c85b70754efc07ac9ac0cbbdce664865a6',
    uniswapV2PairAddress: '0x76fa7935a5afef7feff1c88ba858808133058908',
    collateralAddress: '0xd8ca9dd96f9a94176c67be5165a3d638cc09642c',
    borrowableAddress0: '0xcc32c37c22715224a908d1ea0479a7d13cce2ff4',
    borrowableAddress1: '0xa251f7a95aa3c498efcf1af1bc800a4140a888ff',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xbf76f858b42bb9b196a87e43235c2f0058cf7322',
    tarotRouterAddress: '0x26b21e8cd033ec68e4180dc5fc14446905e94572'
  },
  '0xa0f1ebc8534d852cb65f88c1b4c6b76c86c57879': {
    chainId: 250,
    lendingPoolAddress: '0xa0f1ebc8534d852cb65f88c1b4c6b76c86c57879',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x48afe4b50aadbc09d0bceb796d9e956ea90f15b4',
    symbol0: 'USDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x7547d05dff1da6b4a2ebb3f0833afe3c62abd9a1',
    collateralAddress: '0x4b1bbae5f37d63943298c74467bc81dc3938c1f8',
    borrowableAddress0: '0x681d87d4074961789bd42bc3114105645c1cf996',
    borrowableAddress1: '0xe29469dbc294e8db201dd1fe8bc60372d3e6250e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0xbebb3a8a1e01ee54cfbebbe34ffeb61c90002d6d': {
    chainId: 250,
    lendingPoolAddress: '0xbebb3a8a1e01ee54cfbebbe34ffeb61c90002d6d',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x863730009c8e1a460e244ce8cf71f56783f517c3',
    symbol0: 'WFTM',
    symbol1: 'EQUAL',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x3fd3a0c85b70754efc07ac9ac0cbbdce664865a6',
    uniswapV2PairAddress: '0x3d6c56f6855b7cc746fb80848755b0a9c3770122',
    collateralAddress: '0xa7dee5990fd61eac3dbcc1fde233d99b57b3378e',
    borrowableAddress0: '0xab0de337fe170bb8c8d88664641402b1da410cd7',
    borrowableAddress1: '0xe973d8d7c4fd47cecd5ffe616536d88ff05830ab',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0xf961f6e783566dae9f7a23f891b520e7832d7f4e': {
    chainId: 250,
    lendingPoolAddress: '0xf961f6e783566dae9f7a23f891b520e7832d7f4e',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0xe331d5c5e09450f1e8e6e738b4763ee3dc12def8',
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    uniswapV2PairAddress: '0xd3c3e365d84fc4e7e66244e00e5e2271176ff61e',
    collateralAddress: '0xb68dea1db3ad3afac5c4dc7fb03d248933f36206',
    borrowableAddress0: '0x4631bec646604c3f1282f72e924f35bcdf78e572',
    borrowableAddress1: '0xb97f58ee3ab3d88a2f17f79dec8178862505f2bf',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x3d9d97f854fd8fdf2dcb687524d49aa454b127ad': {
    chainId: 250,
    lendingPoolAddress: '0x3d9d97f854fd8fdf2dcb687524d49aa454b127ad',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0xedb8b44b955df135f15e9d0696f4d19614c99c22',
    symbol0: 'fUSDT',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x783a3fb9f88df5a89fb3a5e595254fa8f7b97b48',
    collateralAddress: '0xa279468e49f1e44669d4edd69e6a922e503a8fe3',
    borrowableAddress0: '0x3f500d9fc74aef353bb0c46cbe88a8938c7114c5',
    borrowableAddress1: '0x160e51718d1d16e944cadb44760205434ee0fa7b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0xdf23e623e14958545a6c42bb19714afc24916c1e': {
    chainId: 250,
    lendingPoolAddress: '0xdf23e623e14958545a6c42bb19714afc24916c1e',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x468f1cf2c4bcb971206364254435f41da3b74203',
    symbol0: 'WFTM',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    uniswapV2PairAddress: '0x0f03efdce7a8ce08c238b1a0b5425fb63bd44b38',
    collateralAddress: '0xb7fd5f1d70979a0ce786fdb1e34a84efebfc70bf',
    borrowableAddress0: '0x445505ef74efb4c41a1c6603185f900251e188f2',
    borrowableAddress1: '0x49fa92ed4212e5db9ed22d0d3e44e79ea12a98a6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x57fe7274669ab8286cfa4ac6c54bb70d01f35ac2': {
    chainId: 250,
    lendingPoolAddress: '0x57fe7274669ab8286cfa4ac6c54bb70d01f35ac2',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0xf1ed146da5a7b21eaf3ea0f1db54c8ac25f1c585',
    symbol0: 'WFTM',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0xe3bd349bdb8203c15426b2d273f57568e658f843',
    collateralAddress: '0x704f1a09bf3800e0309e581e8619d466574772cc',
    borrowableAddress0: '0x9560941d7b708699bd2fe4970d5b85d2ae8863bd',
    borrowableAddress1: '0xa24d7bb29601f8932f206f0a775c9a412c09fdb3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0xdb0c8bee2ae8631a7554e99c2add3a963b475b1f': {
    chainId: 250,
    lendingPoolAddress: '0xdb0c8bee2ae8631a7554e99c2add3a963b475b1f',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0xd6a09102df97c5dbf7fa8a05cc26890ab8564470',
    symbol0: 'WFTM',
    symbol1: 'BTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x321162cd933e2be498cd2267a90534a804051b11',
    uniswapV2PairAddress: '0xda3e21477f872f30794551d908de244b8839f723',
    collateralAddress: '0x8c41b271b3343160ef511373093b7981507e4228',
    borrowableAddress0: '0x331b24bb66cf2bca632a0d93f313ab9803040af8',
    borrowableAddress1: '0xf2d548304ecaba24eb7e4a7e423355d81a3db8ba',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x523d8fdd59fbb9e14c40942717ecbe4aebe6aefa': {
    chainId: 250,
    lendingPoolAddress: '0x523d8fdd59fbb9e14c40942717ecbe4aebe6aefa',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x3d309676409a008aa7dbd5713e1053c481be09c3',
    symbol0: 'BTC',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x321162cd933e2be498cd2267a90534a804051b11',
    tokenAddress1: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    uniswapV2PairAddress: '0x97426aa7a2a9a1d9e02e76afaa4be7518be064ee',
    collateralAddress: '0x27d425edf01900451368acb08de9f629e6c44515',
    borrowableAddress0: '0xf751d1f70c1dffeb6cfd89d2434276a90bbe6533',
    borrowableAddress1: '0xde4656e9bc002a8115db7ea933be39f91f9f4934',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x501405ccc117c7467cef3e0fb352c08081d82cb2': {
    chainId: 250,
    lendingPoolAddress: '0x501405ccc117c7467cef3e0fb352c08081d82cb2',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x5d13ca79343e935ef836c22556c1ecb53172a5cf',
    symbol0: 'LQDR',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x0272a69b9f7d12489e0e0c756cd645e538a00dde',
    collateralAddress: '0xb7b2bbe35578cd9e66c297693a596db9683cafed',
    borrowableAddress0: '0xc86b98c0f14757b3669dbb97c7ee103928b3a9d0',
    borrowableAddress1: '0x370670a72a05ba65772db217438be820f1c5ef63',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0xa25e5160bf3e23738e008ddd7100ecfba2b0c48c': {
    chainId: 250,
    lendingPoolAddress: '0xa25e5160bf3e23738e008ddd7100ecfba2b0c48c',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x333365ab6e79a16bdadcdc9da34b2d12d7f44af5',
    symbol0: 'OATH',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21ada0d2ac28c3a5fa3cd2ee30882da8812279b6',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x558dca97c224851cf428cbf244bec0b897642efc',
    collateralAddress: '0x0e262d96f4655477d3ab8666ebe312be4bec79a7',
    borrowableAddress0: '0x230717240ad5b26e4b150d457accab7196d4e98f',
    borrowableAddress1: '0xabee7c289f3114df064abf3170d0760e2f68a244',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x6c53ddf40bd58f38883ed1f8ddca44131429a409': {
    chainId: 250,
    lendingPoolAddress: '0x6c53ddf40bd58f38883ed1f8ddca44131429a409',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0xb42d237b033dca3b5f02a170e239ab5205acd26f',
    symbol0: 'WFTM',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0xffe9c27f0a12dabe5c5bd46d144a5c0140725566',
    collateralAddress: '0x623c78193e2f70a34bb7a7df1c2f002f3cb8562a',
    borrowableAddress0: '0x0b709c6acb1209b653b6e0341244301e922e2226',
    borrowableAddress1: '0xc91a9d92f9fe9587f34a847063e148afb0530a32',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x0805816029f8cb626d4b89e556058c75cbe0d560': {
    chainId: 250,
    lendingPoolAddress: '0x0805816029f8cb626d4b89e556058c75cbe0d560',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x27f7cf5e918311aaf5e7185b5bcdac158dfacf53',
    symbol0: 'WFTM',
    symbol1: 'MPX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x66eed5ff1701e6ed8470dc391f05e27b1d0657eb',
    uniswapV2PairAddress: '0xde26e98d868fe02fffb6df26e638995124d3ca13',
    collateralAddress: '0x9e31424c45d35de528238b341a5c9a0f59449d4d',
    borrowableAddress0: '0x37618806f36602c78c21f08dc7a1637b1d64bcfb',
    borrowableAddress1: '0x931ba9a403315f818ccc73b6d9750369605d1026',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x08558d2e719fba54ca916ae563f5a799ec7d27c8': {
    chainId: 250,
    lendingPoolAddress: '0x08558d2e719fba54ca916ae563f5a799ec7d27c8',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0xebeca3b0d17b4ffaddcd6aac130d20bf8c68589f',
    symbol0: 'MMY',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x01e77288b38b416f972428d562454fb329350bac',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0xdc935ffe9f9c972b7b304e0b7e61eb774037e394',
    collateralAddress: '0x9375f14adcfd75f1d8bf6d2e19a4440ee7fa48a7',
    borrowableAddress0: '0x22c8c98983b997a0aa571f6a4fa3093e75931c0f',
    borrowableAddress1: '0x93a633a0650facd74db5ba83684052dbeb914caa',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x182b070ff21c363b70cf1058e75af2cc57fa0ab0': {
    chainId: 250,
    lendingPoolAddress: '0x182b070ff21c363b70cf1058e75af2cc57fa0ab0',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x1f8c2411429b58855fd70a8cdd41eb4c1c865110',
    symbol0: 'USDC',
    symbol1: 'RING',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x582423c10c9e83387a96d00a69ba3d11ee47b7b5',
    uniswapV2PairAddress: '0x8418bab773abf430907b39b7db6a6f8318a9210e',
    collateralAddress: '0x6cec6ebfd94c4e754b769656b334feeff95f569a',
    borrowableAddress0: '0xf83a7fc3196b91854ca1aa553b46d096b95218d6',
    borrowableAddress1: '0xe2db122d1afc6ceb81a7567d48581f3d2451db97',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x222222597d3ece305d007c448122be5e03a653bf': {
    chainId: 250,
    lendingPoolAddress: '0x222222597d3ece305d007c448122be5e03a653bf',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x05a12f3fa72cd9a0aa17a4061a371efa870c4c38',
    symbol0: 'WFTM',
    symbol1: 'RAVE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0x88888a335b1f65a79ec56a610d865b8b25b6060b',
    uniswapV2PairAddress: '0xe117a5f40a5d28d3b2f103301c629617bfba5f61',
    collateralAddress: '0x80a2fd674f188c1103985fdcd6aa4614d6ec5e52',
    borrowableAddress0: '0xfcf859324183ddba27d3eab78206eed1499511a5',
    borrowableAddress1: '0xe9f1fee8ca431189c08044d5c3ec5542d3124ba6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x596e70eb304bf48554e5810ff8e595032acf2ede': {
    chainId: 250,
    lendingPoolAddress: '0x596e70eb304bf48554e5810ff8e595032acf2ede',
    stable: true,
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0xc9323883a82ae0087087d39a4b7b82df4ebec07b',
    symbol0: 'USDC',
    symbol1: 'fUSDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    tokenAddress1: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    uniswapV2PairAddress: '0x0995f3932b4aca1ed18ee08d4b0dcf5f74b3c5d3',
    collateralAddress: '0x014c300816ba8e51e65e321e2a393c55b2b5e3ab',
    borrowableAddress0: '0xe7e283e81c6c706fd703292f019ca331ff131bba',
    borrowableAddress1: '0x9266bb0d3d23b7fc7f440b83e60a613d6da74101',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xe034c865299da16a429dad26bff5468c2689f7d8',
    tarotRouterAddress: '0x628868d937bf43f8288f703f70750fb859e14fd6'
  },
  '0xc13ffc5d64019d3b76f7284d6227d40f4ffffe84': {
    chainId: 250,
    lendingPoolAddress: '0xc13ffc5d64019d3b76f7284d6227d40f4ffffe84',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x2cefc62fa6959867b34d417b1b1d714ec6529148',
    symbol0: 'axlUSDC',
    symbol1: 'WFTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x1b6382dbdea11d97f24495c9a90b7c88469134a4',
    tokenAddress1: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    uniswapV2PairAddress: '0x77cfee25570b291b0882f68bac770abf512c2b5c',
    collateralAddress: '0x7db7c59df817979eace422bc49d50d5fa57e7947',
    borrowableAddress0: '0xc54b86cfeb22f19c673c5b3281fdc36949985620',
    borrowableAddress1: '0x7a3c737368d9ab8f00e19fe58cdad7aed586cd49',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x58a491537870473d1b88c746dc0497c012554728': {
    chainId: 250,
    lendingPoolAddress: '0x58a491537870473d1b88c746dc0497c012554728',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0xfd24913559c1c4d664cbc19d11eb9236ca0e7593',
    symbol0: 'WFTM',
    symbol1: 'ERN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xce1e3cc1950d2aaeb47de04de2dec2dc86380e0a',
    uniswapV2PairAddress: '0x7089d775c2ec3febfbd86e8bd96209cbe258780c',
    collateralAddress: '0x267f1c0f5b707c19b62f4b508b61fea56aac6d00',
    borrowableAddress0: '0xa42bedce617bd6300a24e4169d6eb6a5283b2c98',
    borrowableAddress1: '0x5945b110759f8590317bf9691bbeb79b6dab562a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },
  '0x7e635a33b48e3b61bc99fa33466568bc16eebfbf': {
    chainId: 250,
    lendingPoolAddress: '0x7e635a33b48e3b61bc99fa33466568bc16eebfbf',
    isTarotVault: true,
    dex: DEX.EQUALIZERV2,
    vaultType: VaultType.EQUALIZERV2,
    pid: undefined,
    gaugeAddress: '0x2f059b4093d808c95afc49b3d27a0c19a8f80b1c',
    symbol0: 'WFTM',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    tokenAddress1: '0xb7c2ddb1ebac1056231ef22c1b0a13988537a274',
    uniswapV2PairAddress: '0x99b7daaf2468edcfbadd67df9bcea14d1a030675',
    collateralAddress: '0xae309d8588ba01decf514bc1e5527954ba89252e',
    borrowableAddress0: '0x388e0f873ea0d301223d027394f2e8272a38437a',
    borrowableAddress1: '0xe1c73676604ffd889fa7ab43a613f0ce0c03df91',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xa90092a6bfc100e32777b257af46b3ec2675d876',
    tarotRouterAddress: '0x38c2853e569125fc9af310ab145fcefb2a07a322'
  },

  // Optimism Pools
  '0xaaa7ce97cf8f8487354824d0df41f2ac864a4790': {
    chainId: 10,
    lendingPoolAddress: '0xaaa7ce97cf8f8487354824d0df41f2ac864a4790',
    isTarotVault: true,
    dex: DEX.ZIP,
    vaultType: VaultType.ZIP,
    pid: 0,
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x1a981daa7967c66c3356ad044979bc82e4a478b9',
    collateralAddress: '0x1db39b72721e1c4f8532346d861c273af00db443',
    borrowableAddress0: '0xd1e303e68ad59d067c775ee5f307191af7f9a696',
    borrowableAddress1: '0x7e101818f24bb084cf55f343a7c6a7c92e405b60',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x1d90fdac4dd30c3ba38d53f52a884f6e75d0989e',
    tarotRouterAddress: '0xd4a6a05081fd270dc111332845a778a49fe01741',
    poolDeactivated: true
  },
  '0xe8181e911f62c4ddfffa7c02de8824aee42461e5': {
    chainId: 10,
    lendingPoolAddress: '0xe8181e911f62c4ddfffa7c02de8824aee42461e5',
    isTarotVault: true,
    dex: DEX.ZIP,
    vaultType: VaultType.ZIP,
    pid: 1,
    symbol0: 'WETH',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    uniswapV2PairAddress: '0x53790b6c7023786659d11ed82ee03079f3bd6976',
    collateralAddress: '0x9de331d1b50cfb25a1c863b3300bf68f17f6d44c',
    borrowableAddress0: '0xe25c6ce3f2ded8c57e33628c9241ce2cb96e4b9b',
    borrowableAddress1: '0x40e3c5b417bf016672a6ed86e4f12dd0782104ba',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x1d90fdac4dd30c3ba38d53f52a884f6e75d0989e',
    tarotRouterAddress: '0xd4a6a05081fd270dc111332845a778a49fe01741',
    poolDeactivated: true
  },
  '0xd839b87aff846daef263190086b82896af5038e2': {
    chainId: 10,
    lendingPoolAddress: '0xd839b87aff846daef263190086b82896af5038e2',
    isTarotVault: true,
    dex: DEX.ZIP,
    vaultType: VaultType.ZIP,
    pid: 2,
    symbol0: 'WETH',
    symbol1: 'WBTC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 8,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x68f180fcce6836688e9084f035309e29bf0a2095',
    uniswapV2PairAddress: '0x251de0f0368c472bba2e1c8f5db5ac7582b5f847',
    collateralAddress: '0xaa04c5ff42e87b440e4900ee8e3e2c6cd88b04c6',
    borrowableAddress0: '0x3c335a34fcf13777691535017d47d1da07a20e89',
    borrowableAddress1: '0xe3516f617ae4be7670f40cb1488a934a6addac39',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x1d90fdac4dd30c3ba38d53f52a884f6e75d0989e',
    tarotRouterAddress: '0xd4a6a05081fd270dc111332845a778a49fe01741',
    poolDeactivated: true
  },
  '0x7eac79383c42bc16e33cd100008ee6d5e491680f': {
    chainId: 10,
    lendingPoolAddress: '0x7eac79383c42bc16e33cd100008ee6d5e491680f',
    isTarotVault: true,
    dex: DEX.ZIP,
    vaultType: VaultType.ZIP,
    pid: 3,
    symbol0: 'WETH',
    symbol1: 'ZIP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xfa436399d0458dbe8ab890c3441256e3e09022a8',
    uniswapV2PairAddress: '0xd7f6ecf4371eddbd60c1080bfaec3d1d60d415d0',
    collateralAddress: '0x317dd3a4d48b41d0ad4c9af0d720f7fc97090315',
    borrowableAddress0: '0x14f8570c3d7e739a304c9ae3f29a1f3732bf7b0a',
    borrowableAddress1: '0x7ddee8c66f838380e2cbd89c814e5f25ccacf185',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x1d90fdac4dd30c3ba38d53f52a884f6e75d0989e',
    tarotRouterAddress: '0xd4a6a05081fd270dc111332845a778a49fe01741',
    poolDeactivated: true
  },
  '0x01b62c75f10eab716612d358226db4d25bb262d6': {
    chainId: 10,
    lendingPoolAddress: '0x01b62c75f10eab716612d358226db4d25bb262d6',
    isTarotVault: true,
    dex: DEX.ZIP,
    vaultType: VaultType.ZIP,
    pid: 5,
    symbol0: 'gOHM',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x0b5740c6b4a97f90ef2f0220651cca420b868ffb',
    tokenAddress1: '0x4200000000000000000000000000000000000006',
    uniswapV2PairAddress: '0x3f6da9334142477718be2ecc3577d1a28dceaae1',
    collateralAddress: '0xc4923d1b6110a233870f67cbbe85d156c19bb04b',
    borrowableAddress0: '0x6f4f0168e3be337c4821f6b04abc37a4daa0009d',
    borrowableAddress1: '0x7ce6ca0b512280c442f33d9fc824d5d425495642',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x1d90fdac4dd30c3ba38d53f52a884f6e75d0989e',
    tarotRouterAddress: '0xd4a6a05081fd270dc111332845a778a49fe01741',
    poolDeactivated: true
  },
  '0x164426c091e0d5801ce86404cb49e9435dba4569': {
    chainId: 10,
    lendingPoolAddress: '0x164426c091e0d5801ce86404cb49e9435dba4569',
    isTarotVault: true,
    dex: DEX.ZIP,
    vaultType: VaultType.ZIP,
    pid: 7,
    symbol0: 'WETH',
    symbol1: 'OP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x4200000000000000000000000000000000000042',
    uniswapV2PairAddress: '0x167dc49c498729223d1565df3207771b4ee19853',
    collateralAddress: '0x38585c27c99dfff859cc33f19dcd03f897f5156f',
    borrowableAddress0: '0x6cfca68b32bdb5b02039ccd03784cdc96de7fb87',
    borrowableAddress1: '0x53a5267d2ddd35fe80651e038d5f82cad2df9751',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x1d90fdac4dd30c3ba38d53f52a884f6e75d0989e',
    tarotRouterAddress: '0xd4a6a05081fd270dc111332845a778a49fe01741',
    poolDeactivated: true
  },
  '0x3cd9f7912b6b04b702232fbb3f12f94145b8a0e4': {
    chainId: 10,
    lendingPoolAddress: '0x3cd9f7912b6b04b702232fbb3f12f94145b8a0e4',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0xf686424d4f3572e4624bddf8a00fb77fe748237c',
    symbol0: 'TAROT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x1f514a61bcde34f94bc39731235690ab9da737f7',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x2e931de1fcb2681dc98601e0b513308da9c5ceae',
    collateralAddress: '0x8900cb225992e99749af222095a75c22343bede1',
    borrowableAddress0: '0xa76ab78453164da65923ffcbe6fa3c171c219459',
    borrowableAddress1: '0xb6e8c7d12df9e2d6784f661b63466c4d77f57a2e',
    farmingPoolAddress0: '0x93dd5de2d81e6cf9b2389ef69a6eb47dacae86a4',
    farmingPoolAddress1: '0x5afbefb9f113416e84c5cb4f4f48b90c4f59791b',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x3b749be6ca33f27e2837138ede69f8c6c53f9207': {
    chainId: 10,
    lendingPoolAddress: '0x3b749be6ca33f27e2837138ede69f8c6c53f9207',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x1239c54d9fd91e6ecec8eaad80df0fed43c47673',
    symbol0: 'TAROT',
    symbol1: 'OP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1f514a61bcde34f94bc39731235690ab9da737f7',
    tokenAddress1: '0x4200000000000000000000000000000000000042',
    uniswapV2PairAddress: '0xf2d42c46528116362aca448837a0236459b53c63',
    collateralAddress: '0xac181c3c33220f12f619dc4f5fa82937c8183d53',
    borrowableAddress0: '0x3bf5e17a8242d5f96e8cb3136750f135f8e889dd',
    borrowableAddress1: '0xfacdd4a72b110be8f193ebdb0ba66196955d919e',
    farmingPoolAddress0: '0x8dbde89349240604266917983ebcd52e2422cc2c',
    farmingPoolAddress1: '0xf465ef23a99148ba960c6fc20884f31c466b9bd7',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x80942a0066f72efff5900cf80c235dd32549b75d': {
    chainId: 10,
    lendingPoolAddress: '0x80942a0066f72efff5900cf80c235dd32549b75d',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x73d5c2f4eb0e4eb15b3234f8b880a10c553da1ea',
    symbol0: 'USDC',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
    tokenAddress1: '0x1f514a61bcde34f94bc39731235690ab9da737f7',
    uniswapV2PairAddress: '0x707ba27189e8bf89e43b2198e6b88aac4720124f',
    collateralAddress: '0xccb52c8c9efb06b9d5534127ba5362f4d8e9c0fe',
    borrowableAddress0: '0x388a16d05b5eb4bb4c6d6f841544c6138219df53',
    borrowableAddress1: '0x319193ff130285329cc5fd4a46694f969d23a275',
    farmingPoolAddress0: '0x4d791a8801e8c27f9749bfe748ddff829f134a1d',
    farmingPoolAddress1: '0x21ee3ec6cf8642bcb748f1e88377dba6a7bfcbb8',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x354d84b56885aaeccd20ccd20ae1019dcb14657b': {
    chainId: 10,
    lendingPoolAddress: '0x354d84b56885aaeccd20ccd20ae1019dcb14657b',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0xe7630c9560c59ccbf5eed8f33dd0cca2e67a3981',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x0493bf8b6dbb159ce2db2e0e8403e753abd1235b',
    collateralAddress: '0xccb6934345b27370833734f4b62f0b9cf1482c79',
    borrowableAddress0: '0xf57fcaacb6ac3f9b5a630c315e7fbd638914375c',
    borrowableAddress1: '0x261a84bb62a1d10006711746dd8a5cb7edc3f41d',
    farmingPoolAddress0: '0xd64a1ff982ad2d0a35c2f5e30df9822eb7cb718d',
    farmingPoolAddress1: '0xd883b9fc17e463658286938b5f635ba59c8f1742',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xd9c3e515e95c26cbaa3eeb5403699614728171b6': {
    chainId: 10,
    lendingPoolAddress: '0xd9c3e515e95c26cbaa3eeb5403699614728171b6',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0xc9fa5efa02852d57dd13d90d8835f9378b530a03',
    symbol0: 'TAROT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x375488f097176507e39b9653b88fdc52cde736bf',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x9c78da24f2843ccdd02085bdec2484106554ca48',
    collateralAddress: '0x0f035716785856f6dc9132f1f2f28b07a6e536cc',
    borrowableAddress0: '0xb7b224d17b57d892449be394770ca778de05655e',
    borrowableAddress1: '0x4757b4c5d13c0db2188ab361cde0eeb8db813d03',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x092f6830eef16685ffb26efb783832d1cc4075d3': {
    chainId: 10,
    lendingPoolAddress: '0x092f6830eef16685ffb26efb783832d1cc4075d3',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x691758a7ada860b2ed1e744e34a0a1aa2b150a15',
    symbol0: 'TAROT',
    symbol1: 'OP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x375488f097176507e39b9653b88fdc52cde736bf',
    tokenAddress1: '0x4200000000000000000000000000000000000042',
    uniswapV2PairAddress: '0xda0253a7cdccc4b4defbbef863dba1294bf5e3b1',
    collateralAddress: '0xcfcb32ae3e6d8452dfe1a7975184b290d59830b3',
    borrowableAddress0: '0x35d60f5edb2d2045df1055123163865aab4d9e0f',
    borrowableAddress1: '0x6df17173a1d1ac906fae38d2dc3da3b76acb316b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xd9bfa638e33e59dbddcf667de1813b6e9af50346': {
    chainId: 10,
    lendingPoolAddress: '0xd9bfa638e33e59dbddcf667de1813b6e9af50346',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x0f30716960f0618983ac42be2982ffec181af265',
    symbol0: 'OP',
    symbol1: 'VELO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000042',
    tokenAddress1: '0x9560e827af36c94d2ac33a39bce1fe78631088db',
    uniswapV2PairAddress: '0xe9581d0f1a628b038fc8b2a7f5a7d904f0e2f937',
    collateralAddress: '0xec21a442e1458137d6d3f9ae94f8bd82137b69c5',
    borrowableAddress0: '0x85861b726006ec8e2a276781741f25fb813beea4',
    borrowableAddress1: '0xb9d9de899425555355fdb0d3c70901e74b090115',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xbccdd9e6bc7fe6e59bbca6d8475572f2d0c48726': {
    chainId: 10,
    lendingPoolAddress: '0xbccdd9e6bc7fe6e59bbca6d8475572f2d0c48726',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x36691b39ec8fa915204ba1e1a4a3596994515639',
    symbol0: 'OP',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4200000000000000000000000000000000000042',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x0df083de449f75691fc5a36477a6f3284c269108',
    collateralAddress: '0xaab20692f900540aba6e4d817016cc1bd0229997',
    borrowableAddress0: '0x4cf9c7a195cb9df2063ef73e10c0d65239f957c8',
    borrowableAddress1: '0x31b422035b86ea6c98de3bf2c05de7fbe22da3e6',
    farmingPoolAddress0: '0x3c9de6a672ee835dc24a89170e4cdfd158644865',
    farmingPoolAddress1: '0x01d7e3bd5d8ea31cd1cee653576f185abdbf661c',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xcee476ea7e0bdca8f57b8a8822dc3e4eac89d2be': {
    chainId: 10,
    lendingPoolAddress: '0xcee476ea7e0bdca8f57b8a8822dc3e4eac89d2be',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0xcc53cd0a8ec812d46f0e2c7cc5aadd869b6f0292',
    symbol0: 'WETH',
    symbol1: 'OP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x4200000000000000000000000000000000000042',
    uniswapV2PairAddress: '0xd25711edfbf747efce181442cc1d8f5f8fc8a0d3',
    collateralAddress: '0xec6e3f756c4e2df15a39aa12e3527edfb05be752',
    borrowableAddress0: '0x583460f3b6ed8b20ed153b4fd20fd12efa7e3ee1',
    borrowableAddress1: '0x5f942831bf09f89c11838f35cfc11ada1c9d62cf',
    farmingPoolAddress0: '0xc319a18909c6709af79f3a0e6ec4e87b24c7ace2',
    farmingPoolAddress1: '0x378304a761fbf0549a158fc781eed538c40da606',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x5b0dce514b4aed993751d2cf7379b75df9860312': {
    chainId: 10,
    lendingPoolAddress: '0x5b0dce514b4aed993751d2cf7379b75df9860312',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x84195de69b8b131ddaa4be4f75633fcd7f430b7c',
    symbol0: 'USDC',
    symbol1: 'VELO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0x9560e827af36c94d2ac33a39bce1fe78631088db',
    uniswapV2PairAddress: '0x8134a2fdc127549480865fb8e5a9e8a8a95a54c5',
    collateralAddress: '0x554197e3593c2262a8ad0bfb22835f28f4bc384d',
    borrowableAddress0: '0xb842242c26747f46eba2528de19ff12faf576191',
    borrowableAddress1: '0x68ef7f9b5debce90f654dc988e17c504782ec76d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x74bef5ec6c2c2841466ee5a788998e1bb91d7c4a': {
    chainId: 10,
    lendingPoolAddress: '0x74bef5ec6c2c2841466ee5a788998e1bb91d7c4a',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x8329c9c93b63db8a56a3b9a0c44c2edabd6572a8',
    symbol0: 'WETH',
    symbol1: 'VELO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x9560e827af36c94d2ac33a39bce1fe78631088db',
    uniswapV2PairAddress: '0x58e6433a6903886e440ddf519ecc573c4046a6b2',
    collateralAddress: '0x8a5a6af18532e1d9f314a71513ad294a1b6f6440',
    borrowableAddress0: '0xbe06c97164d30950b6c2513dd1f402cb6bc03864',
    borrowableAddress1: '0x7a94d1ffceb9e0ab4bb7c547bcdb4e8e63354383',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xfcf05eacfa3f70df1fcc0248532103c77bb14476': {
    chainId: 10,
    lendingPoolAddress: '0xfcf05eacfa3f70df1fcc0248532103c77bb14476',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0xd2d304c2cdbf74be9cdcc6f7a4eb94c5a9b4f488',
    symbol0: 'SONNE',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x1db2466d9f5e10d7090e7152b68d62703a2245f0',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x4e60495550071693bc8bdffc40033d278157eac7',
    collateralAddress: '0x029f2c7bbf18aba66ccffe594f83c0fdfea87906',
    borrowableAddress0: '0x3688bc527f59e76be35335bbb303e98a297eddc6',
    borrowableAddress1: '0xa5df50b477eea8edc5287a8be668ca37bf0badc2',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xf6adba5eeb09cea4db9f707eb57a5a588524aaa7': {
    chainId: 10,
    lendingPoolAddress: '0xf6adba5eeb09cea4db9f707eb57a5a588524aaa7',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x121de0e978d117590588cb37533ef121c8826a8a',
    symbol0: 'WETH',
    symbol1: 'LUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xc40f949f8a4e094d1b49a23ea9241d289b7b2819',
    uniswapV2PairAddress: '0x6387765ffa609ab9a1da1b16c455548bfed7cbea',
    collateralAddress: '0xe4e7d74207c48a4498b8ac5742b58a5faf75611b',
    borrowableAddress0: '0xb853fa24b409ef4a347ef3d01fd8ac4d54c1b0bd',
    borrowableAddress1: '0x8152259291620216d4837a30e5b918d78d21e701',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x13b869ba6188be5068c2803cde9d077e02196e3f': {
    chainId: 10,
    lendingPoolAddress: '0x13b869ba6188be5068c2803cde9d077e02196e3f',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x5ae3a1d8fa88785adf7c9a43d2e60b63be3d4892',
    symbol0: 'EXTRA',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x2dad3a13ef0c6366220f989157009e501e7938f8',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x477fa18b063b7728409570d274f7a055e3da0d6e',
    collateralAddress: '0xdf299440b34d1bceef8610d0b3ce1f4c3e8eb19d',
    borrowableAddress0: '0xd5f027e18f84c844667feadc5df96cde9866ad54',
    borrowableAddress1: '0x712c94142b6f89c2ea384e6bea8de41008fc8dcd',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x53c575a316c77ecd03f78fd2f8d99ac57fd994c4': {
    chainId: 10,
    lendingPoolAddress: '0x53c575a316c77ecd03f78fd2f8d99ac57fd994c4',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0xec9df85f362d3ebc4b9ca0ed7d7fdecf8cfbdeb8',
    symbol0: 'RED',
    symbol1: 'VELO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x3417e54a51924c225330f8770514ad5560b9098d',
    tokenAddress1: '0x9560e827af36c94d2ac33a39bce1fe78631088db',
    uniswapV2PairAddress: '0x7a7f1187c4710010db17d0a9ad3fce85e6ecd90a',
    collateralAddress: '0x03dae6a3b13820298706161458aef4be39757433',
    borrowableAddress0: '0x47ab377c511b316878566e898c9992d1adf2ac5c',
    borrowableAddress1: '0xdeaec1c1766de8bc6f0d04a464c5d914d6a2b96c',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xe3641f7ef419fa65ec1cb331c905e5d15e214887': {
    chainId: 10,
    lendingPoolAddress: '0xe3641f7ef419fa65ec1cb331c905e5d15e214887',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x8166f06d50a65f82850878c951fca29af5ea7db2',
    symbol0: 'USDC',
    symbol1: 'BLU',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0xa50b23cdfb2ec7c590e84f403256f67ce6dffb84',
    uniswapV2PairAddress: '0x615b9dd61f1f9a80f5bcd33a53eb79c37b20addc',
    collateralAddress: '0xb4cfc88397e4c4dc3c3a69095bf34b9d88a9ae4f',
    borrowableAddress0: '0x165eec7b43cd346ff502449b6b9a82fe309e56fe',
    borrowableAddress1: '0x06285108265aebe68edda0fbdf578999ecc07db8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xdacfe48201baf610ea716c8ce891153ed05b3db2': {
    chainId: 10,
    lendingPoolAddress: '0xdacfe48201baf610ea716c8ce891153ed05b3db2',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x31d553e63a92d5dc89fce8c2e753ba422faa2b93',
    symbol0: 'UNIDX',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x28b42698caf46b4b012cf38b6c75867e0762186d',
    tokenAddress1: '0x4200000000000000000000000000000000000006',
    uniswapV2PairAddress: '0x9269f5199b01b7b7dd38321b85c3f34ee3f28f7f',
    collateralAddress: '0xe3e7bfa483c8bacfd9c0a6cd70dee646b588e68a',
    borrowableAddress0: '0xdf45014128cb140cc178b0830ac2ef328a9138e4',
    borrowableAddress1: '0x139bfa123754fcf2f6e127d42b30507f3d8eebad',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xacc55dba7231cf26b5179ce2f128e5afbe4d005a': {
    chainId: 10,
    lendingPoolAddress: '0xacc55dba7231cf26b5179ce2f128e5afbe4d005a',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0xcc141aabb6c05b025118edec0dbcd34f2193425b',
    symbol0: 'UNIDX',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x28b42698caf46b4b012cf38b6c75867e0762186d',
    tokenAddress1: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    uniswapV2PairAddress: '0xfc233ad094c8c9c245bdea891dfee2d7f9e632c4',
    collateralAddress: '0xde967e5c8b9f08abece418bcd855bb834c6bac97',
    borrowableAddress0: '0x0e8e93a02cd7e3c57b3797340eb23f86746b0d6d',
    borrowableAddress1: '0xe7228ebd9a7d59fb9e39c868a96fa12bea53c686',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0xfc86047445c5d5a39daeacea592d87a98758dc25': {
    chainId: 10,
    lendingPoolAddress: '0xfc86047445c5d5a39daeacea592d87a98758dc25',
    isTarotVault: true,
    dex: DEX.VELODROME_V2,
    vaultType: VaultType.VELODROME_V2,
    pid: undefined,
    gaugeAddress: '0x9647bc2cd383dec17d2a0b154c48c7a24db72640',
    symbol0: 'WETH',
    symbol1: 'ERN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xc5b001dc33727f8f26880b184090d3e252470d45',
    uniswapV2PairAddress: '0xfff37730744930cb61be34c0014068f4f1ec28cf',
    collateralAddress: '0x1af99e550da92b60282c292e117d6afafdd0f0eb',
    borrowableAddress0: '0xdcf96d1f246aeac23fcd0c32a40f719dd5fb189c',
    borrowableAddress1: '0x7a9f7c2a60f5afda513eb348b5903a25b40c6127',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xba47316035e6c95b31cb55bfb93458ad41e4da04',
    tarotRouterAddress: '0x9761d46ef36e07131e8c56af06e35cac23b9a91e'
  },
  '0x287f9681af590354d6722ac51e6935beef631941': {
    chainId: 10,
    lendingPoolAddress: '0x287f9681af590354d6722ac51e6935beef631941',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x6b8edc43de878fd5cd5113c42747d32500db3873',
    symbol0: 'VELO',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0xe8537b6ff1039cb9ed0b71713f697ddbadbb717d',
    collateralAddress: '0x1b7b202d44193f6af293bad8ef773791cfd24ee1',
    borrowableAddress0: '0xcc538d478369336fe18311bec9edddca8587967b',
    borrowableAddress1: '0x1635353098ffb668e049c41e4d1546af682bc798',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xb4a2520be6c12d06f56959b90578fb57c25fd54b': {
    chainId: 10,
    lendingPoolAddress: '0xb4a2520be6c12d06f56959b90578fb57c25fd54b',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x1f36f95a02c744f2b3cd196b5e44e749c153d3b9',
    symbol0: 'VELO',
    symbol1: 'OP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05',
    tokenAddress1: '0x4200000000000000000000000000000000000042',
    uniswapV2PairAddress: '0xffd74ef185989bff8752c818a53a47fc45388f08',
    collateralAddress: '0xe7dad0fbb8c865791dda8d098581bc0f55fbfd3b',
    borrowableAddress0: '0xe9e2be7f7538300d9bcffb5f208dc0ab78ace6d5',
    borrowableAddress1: '0x720de187caea20a39befcf1803d1f4d99e23464a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x6cfe820ec919a4accd651ac336197ce8a19539c7': {
    chainId: 10,
    lendingPoolAddress: '0x6cfe820ec919a4accd651ac336197ce8a19539c7',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xe2cec8ab811b648ba7b1691ce08d5e800dd0a60a',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x79c912fef520be002c2b6e57ec4324e260f38e50',
    collateralAddress: '0x28fa49da55a54f02bc4ff103adbd843b50b556f4',
    borrowableAddress0: '0xabcc0531d4cf0b4d6a92f1e5668696033a96f6d2',
    borrowableAddress1: '0xebe70ea3ff5fe44a22185870f46a9d092958db69',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x2585d58367c9faccddecc7df05006cf7f0f3d18e': {
    chainId: 10,
    lendingPoolAddress: '0x2585d58367c9faccddecc7df05006cf7f0f3d18e',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x0299d40e99f2a5a1390261f5a71d13c3932e214c',
    symbol0: 'OP',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4200000000000000000000000000000000000042',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x47029bc8f5cbe3b464004e87ef9c9419a48018cd',
    collateralAddress: '0x2f707cfb7b6c1ef2fe01b13f13379ab0d6961189',
    borrowableAddress0: '0x0af2fdfde652310677ddf3b0bb6cd903476c4342',
    borrowableAddress1: '0xdb37a76ffc5aaf97a40c0d542ff7a4eca33380ab',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x0d7ba80188b908e110757338da125b28fa4c2a6b': {
    chainId: 10,
    lendingPoolAddress: '0x0d7ba80188b908e110757338da125b28fa4c2a6b',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xb62814b9ec209959dffd36d6130349c2fbae9050',
    symbol0: 'FRAX',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x2e3d870790dc77a83dd1d18184acc7439a53f475',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x587233ce63d7c1e081ce9d94d9940544758f6d01',
    collateralAddress: '0x732ed35a0ee78992ed33e12c72eb106c49047db0',
    borrowableAddress0: '0xcf722e010bebbb375f72f9f8aba3242aa379a22c',
    borrowableAddress1: '0xf36049b664157b9369c8bf68f98c9254c85c8a8d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xeef5cfe8b9bda6ff4f37f30af93079209825b863': {
    chainId: 10,
    lendingPoolAddress: '0xeef5cfe8b9bda6ff4f37f30af93079209825b863',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x461ba7fa5c2e94eb93e881b7c7e3a7dc4c1cd6b4',
    symbol0: 'LYRA',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0xdee1856d7b75abf4c1bdf986da4e1c6c7864d640',
    collateralAddress: '0xfca2208f3a2aca17aba88e9461916f9456e3fd16',
    borrowableAddress0: '0x5b374b3b9e2706303201a336a59cc4b4fa9961fb',
    borrowableAddress1: '0x2ec051e30abb7123d4919b37b73194baf8dcf450',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xecbdddf4358c530df890bf1973700780dd8fb5b3': {
    chainId: 10,
    lendingPoolAddress: '0xecbdddf4358c530df890bf1973700780dd8fb5b3',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x05ef41da0b0c76b6e17be79bdaacf66306cbebb5',
    symbol0: 'OP',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000042',
    tokenAddress1: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    uniswapV2PairAddress: '0x43c3f2d0aa0ebc433d654bb6ebf67f0c03f8d8d9',
    collateralAddress: '0x6aed49274d4cd3ea10ed3d4121e2b3f2881bd350',
    borrowableAddress0: '0xe0264679ebb55d9438cf40d612ff88b1ae4e88f8',
    borrowableAddress1: '0xb37dcdfa3b6e4994cef2a2887c710e4a3263a953',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xaff67afde8fc7910a04e8461ebeaaee6aea851ac': {
    chainId: 10,
    lendingPoolAddress: '0xaff67afde8fc7910a04e8461ebeaaee6aea851ac',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x0aaf1de71910d9f2be10e6c75b3eb6eca377cbf2',
    symbol0: 'VELO',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05',
    tokenAddress1: '0x4200000000000000000000000000000000000006',
    uniswapV2PairAddress: '0x06141423dcf1a5a4c137039063ac873cdc1e363a',
    collateralAddress: '0x978d6913feec200bac83e5568dfc2ff1c12641b0',
    borrowableAddress0: '0x4e4c12bf1b6e74767edb9a9ba9de03c8c20eca24',
    borrowableAddress1: '0x7629411bd395e197fbc51ba11909cd0ff8986dda',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xc5aacc98ec32b86da4f30eff732d702623d0b5cf': {
    chainId: 10,
    lendingPoolAddress: '0xc5aacc98ec32b86da4f30eff732d702623d0b5cf',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x055ee7ddc298dca46172a7a9a43e28b76c17ad26',
    symbol0: 'THALES',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x217d47011b23bb961eb6d93ca9945b7501a5bb11',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x9355292f66552ea5717b274d27eefc8254011d83',
    collateralAddress: '0xbb478ac34bc2869b263bc1ee0579614e629a0881',
    borrowableAddress0: '0x40585d56682a2e77caab7e3c4ef0f79484b411f9',
    borrowableAddress1: '0x8b811cc824f527985dfdea869eb044e6c10dad88',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x1b59389ab4d77eb51048fc8ee5b0cda59a561de1': {
    chainId: 10,
    lendingPoolAddress: '0x1b59389ab4d77eb51048fc8ee5b0cda59a561de1',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x6af999d0cb99773398ddb265adf0243fbf5125df',
    symbol0: 'USDC',
    symbol1: 'PERP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0x9e1028f5f1d5ede59748ffcee5532509976840e0',
    uniswapV2PairAddress: '0x9c8a59934fba9af82674eff5d13a24e7c7e7a1f1',
    collateralAddress: '0x4e5acc5d171faa9e376089afbf6caedd2c2416eb',
    borrowableAddress0: '0xad4c0cd0a8c220e9a55593966177be33f741e69d',
    borrowableAddress1: '0xa514558c9ed596e69d7a42b8bc5e3c0a03ccc980',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xffba812d4b41f0bfadae9dc55b8319bb786e9a67': {
    chainId: 10,
    lendingPoolAddress: '0xffba812d4b41f0bfadae9dc55b8319bb786e9a67',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x2f733b00127449fcf8b5a195bc51abb73b7f7a75',
    symbol0: 'WETH',
    symbol1: 'OP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x4200000000000000000000000000000000000042',
    uniswapV2PairAddress: '0xcdd41009e74bd1ae4f7b2eecf892e4bc718b9302',
    collateralAddress: '0x64c6309e65358ff14efa378b6812d881cb7c1f5d',
    borrowableAddress0: '0x2b0e6675365e6070a7012dbd19e49c5f9248f77f',
    borrowableAddress1: '0xb34c6d7b14e3e835b1b75896d4526040314f68ef',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x07e7f9ea38256f808d86471ac0c6b3097c84182f': {
    chainId: 10,
    lendingPoolAddress: '0x07e7f9ea38256f808d86471ac0c6b3097c84182f',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x77b9a432b23ff5fc798c92a1435b0e51772bc538',
    symbol0: 'FRAX',
    symbol1: 'OP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x2e3d870790dc77a83dd1d18184acc7439a53f475',
    tokenAddress1: '0x4200000000000000000000000000000000000042',
    uniswapV2PairAddress: '0x986d353a3700530be4e75794830f57e657bc68cb',
    collateralAddress: '0x1a7452fe7787516d9f0dd36022d50be4ba898582',
    borrowableAddress0: '0x48fb8f137a2a55a5d28dda478cfdb375de53cb02',
    borrowableAddress1: '0x0e8e9a109d1b25f5e772d658c06312134337c09e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x0d1c6da0eb5539f9f790ad106b8f74eababf46d9': {
    chainId: 10,
    lendingPoolAddress: '0x0d1c6da0eb5539f9f790ad106b8f74eababf46d9',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x3a8883381e4416488db94a8e0469394ecfa8a024',
    symbol0: 'FRAX',
    symbol1: 'FXS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x2e3d870790dc77a83dd1d18184acc7439a53f475',
    tokenAddress1: '0x67ccea5bb16181e7b4109c9c2143c24a1c2205be',
    uniswapV2PairAddress: '0xe2ea57fdf87624f4384ef6da5f3844e8e9e5d878',
    collateralAddress: '0xb548bb4c852ca44f6f28a878e93c13213621da37',
    borrowableAddress0: '0xea1ecb4fb0ac93ab9d0eb4e740cfaa01c562ad3b',
    borrowableAddress1: '0xdc47970ab04da3da18b5e808c42f7dfd58a7cd26',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x78c46b737003055327cd36f311b125eeacb1a7ea': {
    chainId: 10,
    lendingPoolAddress: '0x78c46b737003055327cd36f311b125eeacb1a7ea',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x49ab32dc8c870ab033ca87df2b954c4c24405e64',
    symbol0: 'WETH',
    symbol1: 'DOLA',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x8ae125e8653821e851f12a49f7765db9a9ce7384',
    uniswapV2PairAddress: '0x43ce87a1ad20277b78cae52c7bcd5fc82a297551',
    collateralAddress: '0x0fb14ec4a5980d5c74cf95e34f408d7ce352033b',
    borrowableAddress0: '0xe56cae2c06e986099e5dd6459f3aa2c17df11710',
    borrowableAddress1: '0x8d7fea26e61027d33d2cd479114446a1c453defb',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x632a464393eaede5d1242017afeef266e2c3256e': {
    chainId: 10,
    lendingPoolAddress: '0x632a464393eaede5d1242017afeef266e2c3256e',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xb4d9036b81b9b6a7de1c70887c29938ec8df6048',
    symbol0: 'OP',
    symbol1: 'L2DAO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000042',
    tokenAddress1: '0xd52f94df742a6f4b4c8b033369fe13a41782bf44',
    uniswapV2PairAddress: '0xfc77e39de40e54f820e313039207dc850e4c9e60',
    collateralAddress: '0xc122a9fde60b01888fb9b56a365ce72e29dc67fd',
    borrowableAddress0: '0xbd378fb15daf7a1f3eb3cd797ab3f93a7b703f4f',
    borrowableAddress1: '0xecd4c02ae701996be2d5611c1edc7199e97253cb',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x6d94ef6aaa28a01a52387cb51253e3b903fa8278': {
    chainId: 10,
    lendingPoolAddress: '0x6d94ef6aaa28a01a52387cb51253e3b903fa8278',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xaf307d86b08c54bb840ab17ef66abbba87c6aabe',
    symbol0: 'WETH',
    symbol1: 'AELIN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x61baadcf22d2565b0f471b291c475db5555e0b76',
    uniswapV2PairAddress: '0x3eec44e94ee86ce79f34bb26dc3cdbbee18d6d17',
    collateralAddress: '0xce7495b40afdfe2b7e15db8229d9bdf4f6100ab0',
    borrowableAddress0: '0xffaecdfb9ab0b9dd8a029f34fda06dd04c09c0cc',
    borrowableAddress1: '0x30845cddb42ad6234964b0108f02564b22776a28',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x6cce00972bff06ec4fed6602bd22f65214e14d1f': {
    chainId: 10,
    lendingPoolAddress: '0x6cce00972bff06ec4fed6602bd22f65214e14d1f',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x97f7884c1e57ca991b949b9ac2c6a04599e8f988',
    symbol0: 'USDC',
    symbol1: 'agEUR',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0x9485aca5bbbe1667ad97c7fe7c4531a624c8b1ed',
    uniswapV2PairAddress: '0x7866c6072b09539fc0fde82963846b80203d7beb',
    collateralAddress: '0xc5ca00fb11dcda882109fc520aebf723930b2db2',
    borrowableAddress0: '0x9d29a054496fcdaed2b87e887a4e05f583c6e556',
    borrowableAddress1: '0xfa29ffdcfaa1c82de7b59253ac0dae9f7ddadb42',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xc5820f74d6bcaf3a2e44ed1bc6661cadee29734d': {
    chainId: 10,
    lendingPoolAddress: '0xc5820f74d6bcaf3a2e44ed1bc6661cadee29734d',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x883c6d437d45b7ce61c07606fb390e6c28be27b8',
    symbol0: 'HND',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x10010078a54396f62c96df8532dc2b4847d47ed3',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x588443c932b45f47e936b969eb5aa6b5fd4f3369',
    collateralAddress: '0x94fcfa90f21810d332e2eccaf405508d0617fbfa',
    borrowableAddress0: '0x9e254991db95404737ab4ce7f60b71f8f80af916',
    borrowableAddress1: '0xcb22df226cc34b2a282d02c7f77b9ca2afe07c1e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x1f3cd67eed9da261646e4687526ac5e22b69c994': {
    chainId: 10,
    lendingPoolAddress: '0x1f3cd67eed9da261646e4687526ac5e22b69c994',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x2414d571e490981b4dd8f554d0e719aed114525b',
    symbol0: 'WETH',
    symbol1: 'sUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9',
    uniswapV2PairAddress: '0x58f98be02c4ffb675af8857013a9c0dfa5750d04',
    collateralAddress: '0xc40c037ae545aa5ace049b1c5e5eecec29070749',
    borrowableAddress0: '0xda59161d3fc389dd054fbb9396e0759ba6649482',
    borrowableAddress1: '0x1d6af2d7b8f81fe928d4474ce750bc2677587cdb',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xad9800f58c61c3dc6b3aa55e24645c67ba542183': {
    chainId: 10,
    lendingPoolAddress: '0xad9800f58c61c3dc6b3aa55e24645c67ba542183',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xfc4b6dea9276d906ad36828dc2e7dbacfc01b47f',
    symbol0: 'SNX',
    symbol1: 'sUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x8700daec35af8ff88c16bdf0418774cb3d7599b4',
    tokenAddress1: '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9',
    uniswapV2PairAddress: '0x85ff5b70de43fee34f3fa632addd9f76a0f6baa9',
    collateralAddress: '0xa5258e1208574a6884629e76b0d55821007fe7c0',
    borrowableAddress0: '0x91ae00b11cd72b8c25e128b0bae5fdab4f3de7a0',
    borrowableAddress1: '0xa7db9f0602d28941dd2690bae56d1a7f79007ccf',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x2c174d9408710c1ab5594ba54c0fbb0f2f6f29cb': {
    chainId: 10,
    lendingPoolAddress: '0x2c174d9408710c1ab5594ba54c0fbb0f2f6f29cb',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xc8f650131f160da1f362777e3d5d7162ae3ac077',
    symbol0: 'WBTC',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 6,
    tokenAddress0: '0x68f180fcce6836688e9084f035309e29bf0a2095',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x4c8b195d33c6f95a8262d56ede793611ee7b5aad',
    collateralAddress: '0x7df1cdafecfe3e2fc94c3a7d8724e2cb0fca62fd',
    borrowableAddress0: '0xf011fb6811642dcae7424b5c3a24c42d1d35486d',
    borrowableAddress1: '0x5b4afcbc6cd40127c5a015774c7ccaba9c418188',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x9bf904e1ee73583490fab76bfae8ae0ad80c8762': {
    chainId: 10,
    lendingPoolAddress: '0x9bf904e1ee73583490fab76bfae8ae0ad80c8762',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x3dac764b79acdbc9fc4d3f6a58c15260ed3db230',
    symbol0: 'OP',
    symbol1: 'sUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000042',
    tokenAddress1: '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9',
    uniswapV2PairAddress: '0x53bea2d15efe344b054e73209455d2b6aa1c9462',
    collateralAddress: '0x8783489e280ef69f3b3dcf4db8080fe116383cbe',
    borrowableAddress0: '0x75caed68833475d888582d2dc7fdbb1cd8b7ca5e',
    borrowableAddress1: '0x26828c8dd4c0ae785dc42ec2209406839d88105d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x8a06163824f29d54807773b91f15259e528c9450': {
    chainId: 10,
    lendingPoolAddress: '0x8a06163824f29d54807773b91f15259e528c9450',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x8b57098ee6eafbe85fc3977cb974b5ab929e63f2',
    symbol0: 'VELO',
    symbol1: 'sUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05',
    tokenAddress1: '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9',
    uniswapV2PairAddress: '0x46b8a98d72820e28465a172687af920cc167e587',
    collateralAddress: '0xd3237b2e5c6a20617f4f428b52c311654974f209',
    borrowableAddress0: '0xa8bdf098cb52e2e386a17cb1bcf8fc190b1bbe70',
    borrowableAddress1: '0xee760c93ba108f8e13af6f5d3c8b31b263773863',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xc73adf1da8847f0d8a4d1c17d2d2b3861ea577ae': {
    chainId: 10,
    lendingPoolAddress: '0xc73adf1da8847f0d8a4d1c17d2d2b3861ea577ae',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x99f5d706218a95b4d20d222e8b184b30084fdcfb',
    symbol0: 'TAROT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x375488f097176507e39b9653b88fdc52cde736bf',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x3bbde95b1ed19f8d76252d0b48854d9eaaec3cef',
    collateralAddress: '0xb5639e9efa70b6d70a5f1fed3c850b14d3cc66d3',
    borrowableAddress0: '0xce38d8514b234bab4df7ec682ec3419f6c228d8c',
    borrowableAddress1: '0x75ddb769e76f7d30340357f4810300eb3a4d9a87',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f'
  },
  '0x2518fb1d6073907eca8b9df213cc069279265f2c': {
    chainId: 10,
    lendingPoolAddress: '0x2518fb1d6073907eca8b9df213cc069279265f2c',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x1c5472efdf8ce67259d6f44ef548c68703797fa2',
    symbol0: 'WETH',
    symbol1: 'SNX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x8700daec35af8ff88c16bdf0418774cb3d7599b4',
    uniswapV2PairAddress: '0xffb6c35960b23989037c8c391facebc8a17de970',
    collateralAddress: '0x0c62df33c0d00e6d70a4a36f6022feb7a32bfd26',
    borrowableAddress0: '0xb6e6cc55eda908eb560128243f72878343e7a7c1',
    borrowableAddress1: '0xdae1e69df606e4c08c849c60f4540af8cc3362c9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xefd068aac9b0bcb4232ca33b5beb5aa546563a9e': {
    chainId: 10,
    lendingPoolAddress: '0xefd068aac9b0bcb4232ca33b5beb5aa546563a9e',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x099b3368eb5bbe6f67f14a791ecaef8bc1628a7f',
    symbol0: 'USDC',
    symbol1: 'SNX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0x8700daec35af8ff88c16bdf0418774cb3d7599b4',
    uniswapV2PairAddress: '0x9056eb7ca982a5dd65a584189994e6a27318067d',
    collateralAddress: '0xb2e12198d617a86e4c1e9b3e4ed942bb1bec68bb',
    borrowableAddress0: '0xa3dabf80c30f2f474fd7da4d99f2b00bbeef87e6',
    borrowableAddress1: '0x22ccce5b7ba08cfd3767cf02e847595e5d4460d4',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x31ab35018a205d434c31fda7fc6cfab5d58e4ff9': {
    chainId: 10,
    lendingPoolAddress: '0x31ab35018a205d434c31fda7fc6cfab5d58e4ff9',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x4f06748dd36fb9b90c6db8e98eb8283cdb23a6c6',
    symbol0: 'WETH',
    symbol1: 'OPP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x676f784d19c7f1ac6c6beaeaac78b02a73427852',
    uniswapV2PairAddress: '0x9e0fed4f8284b5b81601b4c7fa50f68dbf958a86',
    collateralAddress: '0xbdd2918329648fae059015c1b907dab6d5311134',
    borrowableAddress0: '0x0843646743c6cb64a24793a28cdaedbda520cf5a',
    borrowableAddress1: '0xbc886d0e4a9a86b26799706577cae1ce8ba62522',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xec774b29665cf608f6bc8dc5ec1ea2951d1f16f0': {
    chainId: 10,
    lendingPoolAddress: '0xec774b29665cf608f6bc8dc5ec1ea2951d1f16f0',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xfa3f4061622bf8ed257366373765f87ca3c1947a',
    symbol0: 'OP',
    symbol1: 'MAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000042',
    tokenAddress1: '0xdfa46478f9e5ea86d57387849598dbfb2e964b02',
    uniswapV2PairAddress: '0x8fbc13969b03b58e0ac50b19e4aedd4dff8554a1',
    collateralAddress: '0x4a7c1e6b93cc1775f5f268405a8acf080297625e',
    borrowableAddress0: '0x6ac6391854313cc47a0a598258c12929d0436971',
    borrowableAddress1: '0x7c063e2de38779c2a27e0d4a619e603fc2f62757',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x6e3060553b937a0da45eae73de5fafb38c8c04d2': {
    chainId: 10,
    lendingPoolAddress: '0x6e3060553b937a0da45eae73de5fafb38c8c04d2',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x5061300a35d8497c91583f9211aa83ee19c98c80',
    symbol0: 'OP',
    symbol1: 'BIFI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000042',
    tokenAddress1: '0x4e720dd3ac5cfe1e1fbde4935f386bb1c66f4642',
    uniswapV2PairAddress: '0x81f638e5d063618fc5f6a976e48e9b803b3240c0',
    collateralAddress: '0x9cb9d5233428ef457e1564996046808a5213a5ba',
    borrowableAddress0: '0xa2267104119040d60408bcc2d9fa36e0c797de3c',
    borrowableAddress1: '0x8e9272ff761f06ed58cc228231038d0350c6872e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xddf4604563f282b86c9d823a1f08726297ba1699': {
    chainId: 10,
    lendingPoolAddress: '0xddf4604563f282b86c9d823a1f08726297ba1699',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x2ee6587e32bed1ceddac42618320c4839d4a3f9c',
    symbol0: 'agEUR',
    symbol1: 'MAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x9485aca5bbbe1667ad97c7fe7c4531a624c8b1ed',
    tokenAddress1: '0xdfa46478f9e5ea86d57387849598dbfb2e964b02',
    uniswapV2PairAddress: '0x389d9aea762fd5f9fbd4434d8e11295f15097b67',
    collateralAddress: '0x2786fabede30b8adae93f6aaf58e307f9d9ea6f0',
    borrowableAddress0: '0x13f6706933f860ffea5e48ffec7873cb21de27a3',
    borrowableAddress1: '0xa5739c867cd1a48fd6bfc06e9ad1ad078bb1de57',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x18189eefe40eac313490309de28487058ebf6ca6': {
    chainId: 10,
    lendingPoolAddress: '0x18189eefe40eac313490309de28487058ebf6ca6',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x3786d4419d6b4a902607ceb2bb319bb336735df8',
    symbol0: 'SONNE',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x1db2466d9f5e10d7090e7152b68d62703a2245f0',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0xc899c4d73ed8df2ead1543ab915888b0bf7d57a2',
    collateralAddress: '0xb46524334cbf4980fe77f18e7f4196dc5d4ba964',
    borrowableAddress0: '0x71abcdbb9af523d40424b708532b8c843a37566c',
    borrowableAddress1: '0xfabc1cb5b4e3bf9558a6ead2d662e39369ccb9b1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x4b963b19075331b02d725a19131342322ad166c4': {
    chainId: 10,
    lendingPoolAddress: '0x4b963b19075331b02d725a19131342322ad166c4',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x22c568d702e3c117f14b1826fc8a927af935c4b4',
    symbol0: 'OATH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x39fde572a18448f8139b7788099f0a0740f51205',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x08da5205e832a2d2c5d88153e2ec1d176e0aab06',
    collateralAddress: '0x0bdca996f73783e2bdeddb9f50f7c7ce14a79855',
    borrowableAddress0: '0x73c3593f50d65faa9acb4d13c03c8b50dc349e7e',
    borrowableAddress1: '0xe3bf823ef50b987f4c0324646bf09119cb60f652',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x3b212589c31e43745986ca2bc893784038460798': {
    chainId: 10,
    lendingPoolAddress: '0x3b212589c31e43745986ca2bc893784038460798',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x131ae347e654248671afc885f0767cb605c065d7',
    symbol0: 'wstETH',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1120000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb',
    tokenAddress1: '0x4200000000000000000000000000000000000006',
    uniswapV2PairAddress: '0xbf205335de602ac38244f112d712ab04cb59a498',
    collateralAddress: '0x95914038d8c4da8e4fda7ece46c4b52b6e9a9513',
    borrowableAddress0: '0x61ae5d5865509b17bdecd087623911bfe075b1cc',
    borrowableAddress1: '0x3af9b056f9953ea7a3001d2e51a6d26f9cc1651b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8b2e286afa241307261622abd2878ad8ec9f0723',
    tarotRouterAddress: '0x8a3b1b9f4e3fabb80623c215aa88f97bcaf53d8c'
  },
  '0xcbadf4976b05b5e8ce5fe9cc59961d61dec056e4': {
    chainId: 10,
    lendingPoolAddress: '0xcbadf4976b05b5e8ce5fe9cc59961d61dec056e4',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x101d5e5651d7f949154258c1c7516da1ec273476',
    symbol0: 'WETH',
    symbol1: 'sETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1120000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xe405de8f52ba7559f9df3c368500b6e6ae6cee49',
    uniswapV2PairAddress: '0xfd7fddfc0a729ecf45fb6b12fa3b71a575e1966f',
    collateralAddress: '0x2b20b61a2f45a288ab81b87ef4d155d8beb9d7ab',
    borrowableAddress0: '0x65355d875c1003a91e7ad5c0fba94b9ec734cdc5',
    borrowableAddress1: '0x35bab39737acc39fab8f9b58c3dc5ec5e8365bd7',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8b2e286afa241307261622abd2878ad8ec9f0723',
    tarotRouterAddress: '0x8a3b1b9f4e3fabb80623c215aa88f97bcaf53d8c'
  },
  '0x46d168daaa9fa3020f0aaaa892337db2b8a0b242': {
    chainId: 10,
    lendingPoolAddress: '0x46d168daaa9fa3020f0aaaa892337db2b8a0b242',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xdf479e13e71ce207ce1e58d6f342c039c3d90b7d',
    symbol0: 'USDC',
    symbol1: 'MAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0xdfa46478f9e5ea86d57387849598dbfb2e964b02',
    uniswapV2PairAddress: '0xd62c9d8a3d4fd98b27caaefe3571782a3af0a737',
    collateralAddress: '0x7acc06e3106e81344a5161b8e91935cb96ccead0',
    borrowableAddress0: '0x5a65fd0736c5fdd7c3f3332ae04062e77baf0fe2',
    borrowableAddress1: '0xb0ca17ce95c9690716993b07166fbb47c78991e0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8b2e286afa241307261622abd2878ad8ec9f0723',
    tarotRouterAddress: '0x8a3b1b9f4e3fabb80623c215aa88f97bcaf53d8c'
  },
  '0x833449604cfeb8cca5a9f58298d7931b51dacba8': {
    chainId: 10,
    lendingPoolAddress: '0x833449604cfeb8cca5a9f58298d7931b51dacba8',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xdf479e13e71ce207ce1e58d6f342c039c3d90b7d',
    symbol0: 'USDC',
    symbol1: 'MAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0xdfa46478f9e5ea86d57387849598dbfb2e964b02',
    uniswapV2PairAddress: '0xd62c9d8a3d4fd98b27caaefe3571782a3af0a737',
    collateralAddress: '0x5a2b8f702838db4f43ea764cd7f58cc286c88ace',
    borrowableAddress0: '0xb937b1829804dd1fa47976cecf3d546a96ce7bfa',
    borrowableAddress1: '0x36f54048d41c37242b29e446c8bc22554a89005f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x49df1fe24caf1a7dcbb2e2b1793b93b04edb62bf',
    tarotRouterAddress: '0x88c81e6b9f14580ece9729ec699dd800c206172e',
    poolDeactivated: true
  },
  '0x2176212d5cf5d5b317c04a875b7fbc756c5e62c8': {
    chainId: 10,
    lendingPoolAddress: '0x2176212d5cf5d5b317c04a875b7fbc756c5e62c8',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x101d5e5651d7f949154258c1c7516da1ec273476',
    symbol0: 'WETH',
    symbol1: 'sETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1120000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xe405de8f52ba7559f9df3c368500b6e6ae6cee49',
    uniswapV2PairAddress: '0xfd7fddfc0a729ecf45fb6b12fa3b71a575e1966f',
    collateralAddress: '0x7cb9bd3afab01d0bee358d75a8a93ca40f2eef32',
    borrowableAddress0: '0x6b1113b9a0158c49e0056ac2ff9b7ab7034b6aa9',
    borrowableAddress1: '0x30b46f0643b12e050feed4de594783b24e7da114',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x49df1fe24caf1a7dcbb2e2b1793b93b04edb62bf',
    tarotRouterAddress: '0x88c81e6b9f14580ece9729ec699dd800c206172e',
    poolDeactivated: true
  },
  '0xa4b6a840476984185cbbc08853db0926d9ec4cea': {
    chainId: 10,
    lendingPoolAddress: '0xa4b6a840476984185cbbc08853db0926d9ec4cea',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x131ae347e654248671afc885f0767cb605c065d7',
    symbol0: 'wstETH',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1120000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb',
    tokenAddress1: '0x4200000000000000000000000000000000000006',
    uniswapV2PairAddress: '0xbf205335de602ac38244f112d712ab04cb59a498',
    collateralAddress: '0xac4dd56652e7d32713f7b085e5f6db1138a4e9f1',
    borrowableAddress0: '0xd1b43663426f5aeb7e93d13e27975f9c1a058b09',
    borrowableAddress1: '0x1800b0700b81a16293d445e7bd67d2c699310bff',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x49df1fe24caf1a7dcbb2e2b1793b93b04edb62bf',
    tarotRouterAddress: '0x88c81e6b9f14580ece9729ec699dd800c206172e',
    poolDeactivated: true
  },
  '0x43934bbc3d96f3263800d1da6c9bc2d4e0f3b44e': {
    chainId: 10,
    lendingPoolAddress: '0x43934bbc3d96f3263800d1da6c9bc2d4e0f3b44e',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xafd2c84b9d1cd50e7e18a55e419749a6c9055e1f',
    symbol0: 'USDC',
    symbol1: 'DOLA',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0x8ae125e8653821e851f12a49f7765db9a9ce7384',
    uniswapV2PairAddress: '0x6c5019d345ec05004a7e7b0623a91a0d9b8d590d',
    collateralAddress: '0xee970a3c4af4ad76fdd429b1aa4c938dc00e019e',
    borrowableAddress0: '0xd9589b20dfb54ec956d498fb3244de41d9a27dea',
    borrowableAddress1: '0x245bf90871cdcc1dbe9533e5f3efa68fc2dab417',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x49df1fe24caf1a7dcbb2e2b1793b93b04edb62bf',
    tarotRouterAddress: '0x88c81e6b9f14580ece9729ec699dd800c206172e',
    poolDeactivated: true
  },
  '0x50e9630c7dbae822c402def7190b8bf3cfdd18bb': {
    chainId: 10,
    lendingPoolAddress: '0x50e9630c7dbae822c402def7190b8bf3cfdd18bb',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x150dc0e12d473347becd0f7352e9dae6cd30d8ab',
    symbol0: 'wstETH',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb',
    tokenAddress1: '0x4200000000000000000000000000000000000006',
    uniswapV2PairAddress: '0xc6c1e8399c1c33a3f1959f2f77349d74a373345c',
    collateralAddress: '0x5c2d65dfc8c7e382350cdc151d07ede8f3531204',
    borrowableAddress0: '0x62f8ce8b35c2eb43cdc2675689284b901f2ab7b0',
    borrowableAddress1: '0x62be5d9554209bf58a820b572cd244870807fc6c',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x9bf7a6d96aa00cec238b60ba31b344b9bdf3ec6f': {
    chainId: 10,
    lendingPoolAddress: '0x9bf7a6d96aa00cec238b60ba31b344b9bdf3ec6f',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x7dd032b7f6cc924110cb3ceb0ae4338376e7d31d',
    symbol0: 'wstETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb',
    tokenAddress1: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    uniswapV2PairAddress: '0x794299137282e5d3af56616624ab918d1b2becf3',
    collateralAddress: '0x518855bf713186e2c98a76cdd9fb4fb14a768843',
    borrowableAddress0: '0x59f1f6ae701302b8506dea5e22dcfb65b4d9cf5b',
    borrowableAddress1: '0x550bcb84e93384645c19365f91e34dc7dfc01301',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xd09b5c8642e3c93926179d9acb2366826741a3e0': {
    chainId: 10,
    lendingPoolAddress: '0xd09b5c8642e3c93926179d9acb2366826741a3e0',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x212cedc5c942304d0f8e139b5bfa4e78196b37ca',
    symbol0: 'wstETH',
    symbol1: 'OP',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb',
    tokenAddress1: '0x4200000000000000000000000000000000000042',
    uniswapV2PairAddress: '0x3905870e647c97cb9c8d99db24384f480531b5b9',
    collateralAddress: '0x7411a1c8011e5985e8b8b60968838f06d897eb44',
    borrowableAddress0: '0xc1842cd21f2047cfb640ee3313894ec0413007fc',
    borrowableAddress1: '0xa5ea8a70d6520eda17bfe0bc0f82689c301b6ece',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x3c8be93fe8ae004bee8f56e6a2a7a57174688e0a': {
    chainId: 10,
    lendingPoolAddress: '0x3c8be93fe8ae004bee8f56e6a2a7a57174688e0a',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0xdfc1ecf1dcb82b1daea91fdda2023973447c4309',
    symbol0: 'WETH',
    symbol1: 'KWENTA',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x920cf626a271321c151d027030d5d08af699456b',
    uniswapV2PairAddress: '0xd65c1120ddf79f827b925c505949e02c5a0d6236',
    collateralAddress: '0xe1e18f730b106e035aec1e17b5fcb5835619128b',
    borrowableAddress0: '0xac826c67debd10917d7d9f91c297a919ded99887',
    borrowableAddress1: '0xeb1630004a2717a89c2a58a406b40028dff5965f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x6faff8d064fe5303efbf2ab9f43a8a981afd4522': {
    chainId: 10,
    lendingPoolAddress: '0x6faff8d064fe5303efbf2ab9f43a8a981afd4522',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x89c1a33011fab92e497963a6fa069aee5c1f5d44',
    symbol0: 'WETH',
    symbol1: 'rETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x9bcef72be871e61ed4fbbc7630889bee758eb81d',
    uniswapV2PairAddress: '0x985612ff2c9409174fedcff23d4f4761af124f88',
    collateralAddress: '0x9c1874b804d9ff34927972be6bbf6ce6ad220a23',
    borrowableAddress0: '0x7175bf125e3294d19c33b30f256cff90f7414990',
    borrowableAddress1: '0xf4b0f3d5e104df8d6d59931fb926c52429db0539',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0xff16ae2a498791d91ed96a9f23068c1d79f25dfb': {
    chainId: 10,
    lendingPoolAddress: '0xff16ae2a498791d91ed96a9f23068c1d79f25dfb',
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x070a26a2a090b3c4e450d093540e3008faa8c230',
    symbol0: 'VELO',
    symbol1: 'DOLA',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05',
    tokenAddress1: '0x8ae125e8653821e851f12a49f7765db9a9ce7384',
    uniswapV2PairAddress: '0x1ebe6427f037385ddcb95aa688c18272415e3f46',
    collateralAddress: '0xaa6628c9d52936bbfd40db6ff01b2ed0b1634a36',
    borrowableAddress0: '0x3b32b4ad62e89002b3dac98b0cdf8321b9c4fe69',
    borrowableAddress1: '0xd6f565dda0e379b44da91a19c14f33787917e307',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c',
    tarotRouterAddress: '0xa516b9c7378799799e6dfadbdabf45d5b584405f',
    poolDeactivated: true
  },
  '0x8fd4daf9a6e429c0a8c50a7784895d4bcdf5f8ce': {
    chainId: 10,
    lendingPoolAddress: '0x8fd4daf9a6e429c0a8c50a7784895d4bcdf5f8ce',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x53668535d3709e597a4f26d2575635980d646dd7',
    symbol0: 'VELO',
    symbol1: 'opxveVELO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05',
    tokenAddress1: '0x46f21fda29f1339e0ab543763ff683d399e393ec',
    uniswapV2PairAddress: '0x383573178ba5b9dbbd6b322728c24aa0cbb289de',
    collateralAddress: '0x1d5f9fdbb5408c5e2238e9d3a826a3e00c91a9e8',
    borrowableAddress0: '0x1191133bcdf92ed1618a23378180753a26bbc4aa',
    borrowableAddress1: '0xc9fedaa7d32cb90b93c6f0addddc729f60e3e594',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x49df1fe24caf1a7dcbb2e2b1793b93b04edb62bf',
    tarotRouterAddress: '0x88c81e6b9f14580ece9729ec699dd800c206172e',
    poolDeactivated: true
  },
  '0x706f100efdc3c067433eb79c9f16ea5a4dbe05ba': {
    chainId: 10,
    lendingPoolAddress: '0x706f100efdc3c067433eb79c9f16ea5a4dbe05ba',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELODROME,
    vaultType: VaultType.VELODROME,
    pid: undefined,
    gaugeAddress: '0x50a0059a2a9fe69174b78b0182fb38725f4cb7f2',
    symbol0: 'USDC',
    symbol1: 'ERN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    tokenAddress1: '0xc5b001dc33727f8f26880b184090d3e252470d45',
    uniswapV2PairAddress: '0x55624dc97289a19045b4739627beaeb1e70ab64c',
    collateralAddress: '0x4fe0356420db47a2fb2c4a7b0072f7c50ab1bde9',
    borrowableAddress0: '0xe3443c8a3e3774055449901c729f88ca7c0ee133',
    borrowableAddress1: '0xda32d0f6b1e8034f02e078c20f4c2827c8c849ed',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x49df1fe24caf1a7dcbb2e2b1793b93b04edb62bf',
    tarotRouterAddress: '0x88c81e6b9f14580ece9729ec699dd800c206172e',
    poolDeactivated: true
  },

  // Arbitrum Pools
  '0x51d19e7fdd0cc6433c8cefc33e912504e2722d2b': {
    chainId: 42161,
    lendingPoolAddress: '0x51d19e7fdd0cc6433c8cefc33e912504e2722d2b',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0xdb74ae9c3d1b96326bdab8e1da9c5e98281d576e',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xa2f1c1b52e1b7223825552343297dc68a29abecc',
    collateralAddress: '0x92401ae33640050ad69786ea6dfa7c5c599126a5',
    borrowableAddress0: '0x0ecd5290a710a73b5b2f2ded93865a881ce37941',
    borrowableAddress1: '0x852ed1513150b5c383925875a28cbd139b591756',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xc9ac5e76bfd13704b50ca735cbb0ad595149e4b4': {
    chainId: 42161,
    lendingPoolAddress: '0xc9ac5e76bfd13704b50ca735cbb0ad595149e4b4',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0x3d34022f552362a25a529ec6af7b9cb226b9c22d',
    symbol0: 'CHR',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x15b2fb8f08e4ac1ce019eadae02ee92aedf06851',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x20585bfbc272a9d58ad17582bcda9a5a57271d6a',
    collateralAddress: '0xab49b3be086467e361ef0e62f3214f349511a6a0',
    borrowableAddress0: '0x266ee2623e10ace5a52358a10fba19c2c68e370f',
    borrowableAddress1: '0x49484ee67240e0fb3490f0fc19143c6b4db1e0ba',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xb26c6b2b2c739d040513507df2bd5887786d1044': {
    chainId: 42161,
    lendingPoolAddress: '0xb26c6b2b2c739d040513507df2bd5887786d1044',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0xc9362ab98975bbe520366c7a21f198d24defa9d8',
    symbol0: 'TAROT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x6688b00f0c23a4a546beaae51a7c90c439895d48',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x33633b88782a4dd0b46349e3f60a71690ae9e0b5',
    collateralAddress: '0x9bf3873b17ea2c7dbc5d55e9295678dc53cfbd7c',
    borrowableAddress0: '0x63375ee628e7d91fa6fac049d92edabff67ff863',
    borrowableAddress1: '0xb5385dd54772ac6db3ad433c49827b5374fb4741',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x8be4c9079a1cb00d1f12cc1389fa2998d853a055': {
    chainId: 42161,
    lendingPoolAddress: '0x8be4c9079a1cb00d1f12cc1389fa2998d853a055',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0x2497ec308ca75201b6f59f6970f33f9aeb63ba6c',
    symbol0: 'WETH',
    symbol1: 'ARB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    uniswapV2PairAddress: '0xafe909b1a5ed90d36f9ee1490fcb855645c00eb3',
    collateralAddress: '0x0339012bf8133b3b7e326a77dd492281228eaed5',
    borrowableAddress0: '0x0f23dab2abd7127d351b9b6de43a2806b8f99722',
    borrowableAddress1: '0x50c0002793381972a740ab2071908a9ee1466fe7',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x47b14e0255f49911f61aa6de67e7c17c34d93678': {
    chainId: 42161,
    lendingPoolAddress: '0x47b14e0255f49911f61aa6de67e7c17c34d93678',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0x9b27ef426e6618e32ddd68628f939f620649bd9a',
    symbol0: 'WBTC',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x5b69430721b580bb502af14d07001ac5de10ac6a',
    collateralAddress: '0x908a92d388a656bb5c1dd1dd85fe1ab0ece52396',
    borrowableAddress0: '0x815407a17d1062c669c764e01956b6771d23d4fa',
    borrowableAddress1: '0xc9274eddee31fc972f15691aa47713bef397eaa9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xce3b2a0499d9677d662cf847d24b971fe589ac08': {
    chainId: 42161,
    lendingPoolAddress: '0xce3b2a0499d9677d662cf847d24b971fe589ac08',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0x52810c48997f96e51b993bec73c02e29364fe413',
    symbol0: 'CHR',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x15b2fb8f08e4ac1ce019eadae02ee92aedf06851',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xe478af0a1e3ccb4afce648a9360c7ab730cd2e81',
    collateralAddress: '0x2c46f268374e63ea825d5c97b246f05e5621fbc4',
    borrowableAddress0: '0x2ee983c14112fb4d04f4c7129bb1531f932f147d',
    borrowableAddress1: '0xa6287dfb439d2770673c831014e3a5765a9eff6b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x2677c727ae98fc258f92a3d68ba991a13302b12f': {
    chainId: 42161,
    lendingPoolAddress: '0x2677c727ae98fc258f92a3d68ba991a13302b12f',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0x1b4ec2ba8a788227730271349278fdde4ecd41a7',
    symbol0: 'WETH',
    symbol1: 'OATH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xa1150db5105987cec5fd092273d1e3cbb22b378b',
    uniswapV2PairAddress: '0x74d777e93e04933f32a1e96e6bebead8feac5cc2',
    collateralAddress: '0xdfe187a08a445775a32cf41cea521c26d3d46971',
    borrowableAddress0: '0x74c076e5e168cd6df4b382a5fc25efa67711cf5a',
    borrowableAddress1: '0x3ffd0976d8bbc5369f3c5984d65a6f28925f3e10',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x40ed648d72749e1b007a8607cbf98f8f6f92a1d1': {
    chainId: 42161,
    lendingPoolAddress: '0x40ed648d72749e1b007a8607cbf98f8f6f92a1d1',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0x8b640b50889b86b68b113144a0b3a38770a5fb02',
    symbol0: 'LQDR',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x03e8d118a1864c7dc53bf91e007ab7d91f5a06fa',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xddbec81b3f3a6bbef804ad37087b54dac70983c2',
    collateralAddress: '0x66fc7b45fe9350a10c8fd64a26b03cb0f8a9ce9f',
    borrowableAddress0: '0x783a8990a7dd31ed3c4445a6b10ec777c47c4077',
    borrowableAddress1: '0x606c618b9321812a88a227e9a4a0c1508facadb8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x6f7445c5ab0d5bc30d6b4bfdd584a6eccb34a1be': {
    chainId: 42161,
    lendingPoolAddress: '0x6f7445c5ab0d5bc30d6b4bfdd584a6eccb34a1be',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0xc9c992cfef28952bbe2e7de94040c822d4abdf0d',
    symbol0: 'GRAIN',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x80bb30d62a16e1f2084deae84dc293531c3ac3a1',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x94bc3d5dd121d6fbc07ad18bb2cc50c3b337c599',
    collateralAddress: '0xa644a7329645aeb7e905955f5054720d4e1babf1',
    borrowableAddress0: '0xc3b09587005e7308e5b1cdbc4ecb4c9df3785d65',
    borrowableAddress1: '0x1c35949bb96a37a1c774bd3c584e53621580bcf9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x3f23cdbfde67647aed4a0a8bc28dd108627026e9': {
    chainId: 42161,
    lendingPoolAddress: '0x3f23cdbfde67647aed4a0a8bc28dd108627026e9',
    isTarotVault: true,
    dex: DEX.CHRONOS,
    vaultType: VaultType.CHRONOS,
    pid: undefined,
    gaugeAddress: '0x559102b8cfbe64551177a9be1e5e754a73c2bee8',
    symbol0: 'ARB',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xd302119b5c46d504d0b3534312e56ec321976251',
    collateralAddress: '0x2a830903d682f0fce5026342d0b3ec877123ca3d',
    borrowableAddress0: '0xb2eded902bf61db4ac68a87d4678c27f6d48818f',
    borrowableAddress1: '0x3b8402b40927936df97bf47305409fadf08e994b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xf17bfc17096ce2cb345adb1b5477330ac9688372': {
    chainId: 42161,
    lendingPoolAddress: '0xf17bfc17096ce2cb345adb1b5477330ac9688372',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x69a3de5f13677fd8d7aaf350a6c65de50e970262',
    symbol0: 'WETH',
    symbol1: 'ARB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    uniswapV2PairAddress: '0x275f7112e3900fdf3c9532d749dd4985790e7933',
    collateralAddress: '0xc5ef181b9ba880945baf65aded2f33d578845fb3',
    borrowableAddress0: '0x4061f89c7eb035edc5dc12a84255019348c4b627',
    borrowableAddress1: '0xedd0ee00c1dc54500d9ed2b0c881b292814217d7',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x3008824334a4803967c187dccf49dd01159d4f91': {
    chainId: 42161,
    lendingPoolAddress: '0x3008824334a4803967c187dccf49dd01159d4f91',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0xebf119c0e5fc1d226defc4bb873ac1a9a232ef3e',
    symbol0: 'ARB',
    symbol1: 'USDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    tokenAddress1: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    uniswapV2PairAddress: '0x159d1e96a39b3bd85a08d0e852c9e0560b268ecd',
    collateralAddress: '0x0d2067aec10476de984d0858153496d9a62fca4c',
    borrowableAddress0: '0x05926ad9885298a64a58949b6668687a041e7d96',
    borrowableAddress1: '0x71da2b0029416a7f8a988b06eb4226b026990384',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x8e92ad95141f7c4a5e74864dc9d9679ae14998b0': {
    chainId: 42161,
    lendingPoolAddress: '0x8e92ad95141f7c4a5e74864dc9d9679ae14998b0',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x2951ffb9e7d54c9ca547901d482f2896e7d9a0ed',
    symbol0: 'ARB',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xba9f17ca67d1c8416bb9b132d50232191e27b45e',
    collateralAddress: '0x64a0d98e985633020dca06e9d85fc886b2431f24',
    borrowableAddress0: '0x6959ff1250e77e8a08e07d73361eacebde1419d5',
    borrowableAddress1: '0xb495d45698e49a4f2d3a952adb41586af71f7927',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xd66b77502577be6bba069227b5c30839bc862379': {
    chainId: 42161,
    lendingPoolAddress: '0xd66b77502577be6bba069227b5c30839bc862379',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0xdba865f11bb0a9cd803574edd782d8b26ee65767',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x5513a48f3692df1d9c793eeab1349146b2140386',
    collateralAddress: '0xe6855e36ae50dc00f711f086539c37d43e0c2bbb',
    borrowableAddress0: '0x3c8021f4e6795b0673cc5052e9a1b9ee9e7f79aa',
    borrowableAddress1: '0xae0d40e2bc42c64bb947521302105ab5ca946224',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x58d24bf3b3ad0cc3124a595dcec24b3962c5d338': {
    chainId: 42161,
    lendingPoolAddress: '0x58d24bf3b3ad0cc3124a595dcec24b3962c5d338',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0xafe267681312ed76f0b7aedfe54c8b200ec32cfa',
    symbol0: 'WETH',
    symbol1: 'RAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xaaa6c1e32c55a7bfa8066a6fae9b42650f262418',
    uniswapV2PairAddress: '0x1e50482e9185d9dac418768d14b2f2ac2b4daf39',
    collateralAddress: '0xd0410e85d2ef3dc7c9e4e9590d499b5f3d07b74e',
    borrowableAddress0: '0x91346833ef869c838e597401b00b7edad809b932',
    borrowableAddress1: '0x2598008b973d4280774530314c44d17e198d138d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x80f8eecc6e164b16b1bc168ee0eb0faec6d57b17': {
    chainId: 42161,
    lendingPoolAddress: '0x80f8eecc6e164b16b1bc168ee0eb0faec6d57b17',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0xc4a4689c697baa8e9613e7e5b31a376488e56ad2',
    symbol0: 'RAM',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xaaa6c1e32c55a7bfa8066a6fae9b42650f262418',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xb4752b59dce5f1fe0f45ef5aa9e1ad1eef9ab927',
    collateralAddress: '0x65dff8666ac32f60480d7e23c0e687e0011372ac',
    borrowableAddress0: '0x51b4e03b6a99bc02e47bfb7d2d5187961255ea1d',
    borrowableAddress1: '0x3d52b20ff54a47f51040d52d31081f7a2e662d4c',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x1e9844ff825910d12e0c6f2ae36a07358328f23c': {
    chainId: 42161,
    lendingPoolAddress: '0x1e9844ff825910d12e0c6f2ae36a07358328f23c',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x7d426063e910ed5c4427d63d24643bb2e2155ff6',
    symbol0: 'WETH',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0x93d98b4caac02385a0ae7caaeadc805f48553f76',
    collateralAddress: '0xdea34af2ee1f9dcf461b2adae7710cae788b3741',
    borrowableAddress0: '0x609df8aae01cbfc23d03a35def35bbffa475a6f0',
    borrowableAddress1: '0xc6fb8afdb0cfc190146129c1f6e415ccd68cc9d9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x73b01ffe26362f85092551f15be497ff1415f6ff': {
    chainId: 42161,
    lendingPoolAddress: '0x73b01ffe26362f85092551f15be497ff1415f6ff',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x2fe2ecd5673d3408908827cd413c9dfb89ce46ba',
    symbol0: 'WETH',
    symbol1: 'LQTY',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xfb9e5d956d889d91a82737b9bfcdac1dce3e1449',
    uniswapV2PairAddress: '0x8e78f0f6d116f94252d3bcd73d8ade63d415c1bf',
    collateralAddress: '0xe270bb1ab18a85ad58057257fe2e7f4642d757ea',
    borrowableAddress0: '0x44393c27cf871045979deb2fa5923cf2b1d571e4',
    borrowableAddress1: '0xcb68c8f94f49d124a3a47770fedc4c1dc4fa6b47',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xa432fd77d30bb1dc91f377c15ae2aea0b5fa4315': {
    chainId: 42161,
    lendingPoolAddress: '0xa432fd77d30bb1dc91f377c15ae2aea0b5fa4315',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x8e45c00e12cff27d4db9f71edb06a6588f046a35',
    symbol0: 'LQDR',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x03e8d118a1864c7dc53bf91e007ab7d91f5a06fa',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xce63c58c83ed2aff21c1d5bb85bad93869c632f7',
    collateralAddress: '0xe2ac2288d7673ecce6170bf3ec7f4bbe71f5b757',
    borrowableAddress0: '0x2f59f3a0cc671bbbfba2b55f581d840e999dd8ce',
    borrowableAddress1: '0xceb41dcd6ee2a9b2aaef6bf1b67b7fe5d3d61025',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x8d793311101468e3550b6a014bfbeeb7a8a8a3f1': {
    chainId: 42161,
    lendingPoolAddress: '0x8d793311101468e3550b6a014bfbeeb7a8a8a3f1',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x2acc06e6e7ce869ee558b3ef7a59072401543d50',
    symbol0: 'TAROT',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6688b00f0c23a4a546beaae51a7c90c439895d48',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x8ac36fbce743b632d228f9c2ea5e3bb8603141c7',
    collateralAddress: '0xa9026518b15449ed8fc5999b0147b9ba3cf33331',
    borrowableAddress0: '0x611625f633402076bb74d68d0a3631505dbf50dd',
    borrowableAddress1: '0x19bd44ce36deecb835aa12b1c043d1b8e9fd52e1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xc9272da64605c34bf609fa37b9e858e9066ae4e3': {
    chainId: 42161,
    lendingPoolAddress: '0xc9272da64605c34bf609fa37b9e858e9066ae4e3',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0xe5f0383b9c4732ec8876a25f3249b9ceaddcc004',
    symbol0: 'WETH',
    symbol1: 'OATH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xa1150db5105987cec5fd092273d1e3cbb22b378b',
    uniswapV2PairAddress: '0x92a248663e9a8bb602fc18898eb91cc30c92a387',
    collateralAddress: '0xe166de1c76bc78f57195b45cff2eca0930d7478a',
    borrowableAddress0: '0xec49040c3d14150571950a04c87ffed078ba6d42',
    borrowableAddress1: '0x8ce17bb7a29c92953b961862173a9517cbba8be8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x4d7d168b71a335e5ea8aa838378bad9bcfd3a083': {
    chainId: 42161,
    lendingPoolAddress: '0x4d7d168b71a335e5ea8aa838378bad9bcfd3a083',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x7d6dab76a2bd674b5129dbd479b7e3815279483f',
    symbol0: 'MAGIC',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x539bde0d7dbd336b79148aa742883198bbf60342',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x040da64a9347c9786069eee1d191a1b9062edc0f',
    collateralAddress: '0xe834122e7c9b3f8f53dbc2ececb0ac2cbd237f7c',
    borrowableAddress0: '0x6161d5540c0a3bf883824134acb3d90f3cbd8255',
    borrowableAddress1: '0xb32f0af794c9107c2472d9fb901063a9e9bef90b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xe8efcf508be17efeae6de9eb2ee5ff028bde471e': {
    chainId: 42161,
    lendingPoolAddress: '0xe8efcf508be17efeae6de9eb2ee5ff028bde471e',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x2a73a90c144967cd620bc714cdba0914936daf8c',
    symbol0: 'RDNT',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x3082cc23568ea640225c2467653db90e9250aaa0',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xb1406b8344cd65dbe9f304938c0ecf2209f54d18',
    collateralAddress: '0x9f2a45778b7572ff011d5ad2a523d6a8eef37cc7',
    borrowableAddress0: '0x46f2538067ec9c06a52e4ae7a31b8291c2b25cae',
    borrowableAddress1: '0xbdcc144756d936ded309b26548455f1d927e7413',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xfe1ab36ea0508ca7860f39b0264e6b576417a70b': {
    chainId: 42161,
    lendingPoolAddress: '0xfe1ab36ea0508ca7860f39b0264e6b576417a70b',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.RAMSES,
    pid: undefined,
    gaugeAddress: '0x6736060b4c392a71c2a66d2f1895fcf44a3ba830',
    symbol0: 'RAM',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xaaa6c1e32c55a7bfa8066a6fae9b42650f262418',
    tokenAddress1: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
    uniswapV2PairAddress: '0x4eec5b230e2f2f83dbac174b0bd6d406d13710b4',
    collateralAddress: '0xdaed0c917f616138697c3769939c71b2ab847984',
    borrowableAddress0: '0x821a6b94862c06645964a07cb0d0878b05096e5a',
    borrowableAddress1: '0x1da61f8c5494c86fee2ba5d02791730217d89b15',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x5f263ceba3d31506bb597f458c9718186e3cb895': {
    chainId: 42161,
    lendingPoolAddress: '0x5f263ceba3d31506bb597f458c9718186e3cb895',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.ENNEAD,
    pid: undefined,
    gaugeAddress: '0xdba865f11bb0a9cd803574edd782d8b26ee65767',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x5513a48f3692df1d9c793eeab1349146b2140386',
    collateralAddress: '0x7076c8846983ecbfd0c58febd6854a3672d17fae',
    borrowableAddress0: '0xfc0a4089815ac53aa7c0ab7daa341a02c1b81d9a',
    borrowableAddress1: '0x1706c0306ace6684bac851e4054dd7b13c233386',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x8b2b6e2b091a0eb11f2cc45500d185261a2d36a8': {
    chainId: 42161,
    lendingPoolAddress: '0x8b2b6e2b091a0eb11f2cc45500d185261a2d36a8',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.ENNEAD,
    pid: undefined,
    gaugeAddress: '0x2acc06e6e7ce869ee558b3ef7a59072401543d50',
    symbol0: 'TAROT',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6688b00f0c23a4a546beaae51a7c90c439895d48',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x8ac36fbce743b632d228f9c2ea5e3bb8603141c7',
    collateralAddress: '0xe93a545bcaceab48e1ac6171bac988903c8bb60f',
    borrowableAddress0: '0x71c655bed811535afb9dfb224f574675cff2c968',
    borrowableAddress1: '0x91e46ea5286b11577faf3fd9762312bd71bff6e3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x8b7465c8acb36accf4be4474cf2f4e82fd706e23': {
    chainId: 42161,
    lendingPoolAddress: '0x8b7465c8acb36accf4be4474cf2f4e82fd706e23',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.ENNEAD,
    pid: undefined,
    gaugeAddress: '0xafe267681312ed76f0b7aedfe54c8b200ec32cfa',
    symbol0: 'WETH',
    symbol1: 'RAM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xaaa6c1e32c55a7bfa8066a6fae9b42650f262418',
    uniswapV2PairAddress: '0x1e50482e9185d9dac418768d14b2f2ac2b4daf39',
    collateralAddress: '0xe5b76c74fb78ef96d202ea67e9b0cabc5b4cf8b2',
    borrowableAddress0: '0x1e5ed139a49d6a45dafd2d0e4d0cf72d38bf0b58',
    borrowableAddress1: '0xc461fd3034784fbef8611f13942d3460689ed8a6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x4661abac568216f0154e2b9fbc40ac0cb53e2a6c': {
    chainId: 42161,
    lendingPoolAddress: '0x4661abac568216f0154e2b9fbc40ac0cb53e2a6c',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.ENNEAD,
    pid: undefined,
    gaugeAddress: '0x21798560d203d5127e2ff443ed0ce388907be5de',
    symbol0: 'neadRAM',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x40301951af3f80b8c1744ca77e55111dd3c1dba1',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x1542d005d7b73c53a75d4cd98a1a6bf3dc27842b',
    collateralAddress: '0xec319c3a4b592c796ce4f2a2eb8513efe41424e5',
    borrowableAddress0: '0x48f28c04df43fdc3f49022dd45524d3590783b59',
    borrowableAddress1: '0xc32ce7093bcbc9512bb36b363778aab0dcb9ed29',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x46068c3d8091a78caed26367ab40c0389e580630': {
    chainId: 42161,
    lendingPoolAddress: '0x46068c3d8091a78caed26367ab40c0389e580630',
    isTarotVault: true,
    dex: DEX.RAMSES,
    vaultType: VaultType.ENNEAD,
    pid: undefined,
    gaugeAddress: '0xd07b6eb41c2099c4a4ae57feea4b79c94c3117ce',
    symbol0: 'UNIDX',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5429706887fcb58a595677b73e9b0441c25d993d',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xf0eab4513cd5671604eef90761b8bcb209e64df1',
    collateralAddress: '0x037fd5e47e6df6046b9d518665fe09f50c81992f',
    borrowableAddress0: '0x66b79a7db7a454f5ed04258d90ae329936fb1186',
    borrowableAddress1: '0x66072aa79f3ac5fd1e65e8c9d366a7837cb4c5c5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x7bf75d1cd96c2b44e3a4d692a5fe861df8ef2397': {
    chainId: 42161,
    lendingPoolAddress: '0x7bf75d1cd96c2b44e3a4d692a5fe861df8ef2397',
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0xe9f9614b2bbbf591f6e25b1429ef040305af46e8',
    symbol0: 'WETH',
    symbol1: 'ARB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    uniswapV2PairAddress: '0xce32017f3ad9914289012e439234998ffbdd7644',
    collateralAddress: '0xc3f6edd03d268fb5c398bb4a4367db4038e85e00',
    borrowableAddress0: '0x1900e23d80fa904557aa98603425c0c31bf94beb',
    borrowableAddress1: '0x263b1575a63db286e1dcc5c56f1fbef2c137d2f1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x7a46f227bad90df30edf09d72af87a2d5527b35f': {
    chainId: 42161,
    lendingPoolAddress: '0x7a46f227bad90df30edf09d72af87a2d5527b35f',
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0x4755f1c00a81f2bc61b26cf77b0959f13a7357aa',
    symbol0: 'STR',
    symbol1: 'ARB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5db7b150c5f38c5f5db11dcbdb885028fcc51d68',
    tokenAddress1: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    uniswapV2PairAddress: '0x6df1ae046146c623f91225620fa7d2dcf771f601',
    collateralAddress: '0x3f033978cb0d2feb4dbed8098e7f4fd9945d0586',
    borrowableAddress0: '0xa131f03f5635b34c13d198feb2643e96d5e34b26',
    borrowableAddress1: '0x42726f6bb40be4661fc25abcb34df800113a87fe',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x95c51f87ca3446ab2183f9a05e4182396b40d37a': {
    chainId: 42161,
    lendingPoolAddress: '0x95c51f87ca3446ab2183f9a05e4182396b40d37a',
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0xec2e4a5e42c2b582b30612cd21f8d911be6f58c8',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xe726c8007c4f2aeb827d6e7f034a24e0b77beed0',
    collateralAddress: '0xe8b15da3c8d9b2f660c43c44dfcd164d3da19dc5',
    borrowableAddress0: '0x5cdf2a9b4497c9b772b4de08cab5bd5618de3a0f',
    borrowableAddress1: '0x4b77edd289c09a0de3f776a798148ac38da011f0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xacafcd3ccf3e45ebdb2170b16ec403dccf038610': {
    chainId: 42161,
    lendingPoolAddress: '0xacafcd3ccf3e45ebdb2170b16ec403dccf038610',
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0x3135559269c61b5d91103ad3c98cee84f4afc3ff',
    symbol0: 'STR',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x5db7b150c5f38c5f5db11dcbdb885028fcc51d68',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xb30d2814fada91c97c2ffbb27c9187a099617e3f',
    collateralAddress: '0xfc71059aca875be60397cfa63d808be58bc0a256',
    borrowableAddress0: '0x39ad6d1fbc5bce58455b7cbb14065265c720b7c5',
    borrowableAddress1: '0x61c51fbdb18f4a0cd1a0b0980005dacc44db5edd',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x4221ba94411b3a2ae2c97df0fdb176c65572ede4': {
    chainId: 42161,
    lendingPoolAddress: '0x4221ba94411b3a2ae2c97df0fdb176c65572ede4',
    stable: true,
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0x4ac87e9a7b4f43766a08c879c068273ea05aca1c',
    symbol0: 'USDT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x839d9fd27be3f18280ba8e77794d157fe90ffeed',
    collateralAddress: '0xdcffebc622d0cdd726525b4cb2b53470d7241226',
    borrowableAddress0: '0xe7be453ffaf40b490b2e8713832123be9fda8156',
    borrowableAddress1: '0xd4c70ec570e6a589016a9987ad96d98eda0782c1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0xfaf333614584320bdbf503f4c17f75258481a349': {
    chainId: 42161,
    lendingPoolAddress: '0xfaf333614584320bdbf503f4c17f75258481a349',
    stable: true,
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0xff20d4f408c0163aeb73bc666fb0583dcdcc6fb4',
    symbol0: 'wstETH',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5979d7b546e38e414f7e9822514be443a4800529',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x70717296353f0cdaf1b9a5626455def6b6cf63a9',
    collateralAddress: '0x3d4b3c03f64b6e142343e996e45f7bd4117e8fe9',
    borrowableAddress0: '0x1f6effc63b5622fb360f957842ebadc6ebd86620',
    borrowableAddress1: '0x49db456fea7b002d5c0bc90b36bfa839838e6309',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0xf10f8b290fe1703e11cc4e64874333a7e2b61ab0': {
    chainId: 42161,
    lendingPoolAddress: '0xf10f8b290fe1703e11cc4e64874333a7e2b61ab0',
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0xad9e2c1571f6ab1a07f63958adbd1f4d83271a0b',
    symbol0: 'STR',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5db7b150c5f38c5f5db11dcbdb885028fcc51d68',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x53083ef192f7c232b8c2b32b83067690bc6eee04',
    collateralAddress: '0xc92c93b09ac16712cde271a7a1fb74ec36956429',
    borrowableAddress0: '0xbd88517d6c3697b447c793606ca1d7f238979d3b',
    borrowableAddress1: '0x0334f7db681c2266f894d5c11aafd9c63357cecd',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xa7d8b30859408400c849abe1d5ccc7bff5c6c3c0': {
    chainId: 42161,
    lendingPoolAddress: '0xa7d8b30859408400c849abe1d5ccc7bff5c6c3c0',
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0xa3cb464b21da68dfcbfca80386792444083b6074',
    symbol0: 'TAROT',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x6688b00f0c23a4a546beaae51a7c90c439895d48',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xa8f55e54eff0db5a4e813a9bf67fe908c2ad183c',
    collateralAddress: '0xe61a9f2ea62c1dc6b3cd8161630010798b0b8613',
    borrowableAddress0: '0x1961533c61f1bee11e81af518d59bbf4018ef724',
    borrowableAddress1: '0x035ca1f71d5638a64608bf8613bbf71e910948be',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x24bc87a6dec6bfee90a742a7e22a3f36451ba6b6': {
    chainId: 42161,
    lendingPoolAddress: '0x24bc87a6dec6bfee90a742a7e22a3f36451ba6b6',
    isTarotVault: true,
    dex: DEX.STERLING,
    vaultType: VaultType.STERLING,
    pid: undefined,
    gaugeAddress: '0x300090afec491c8683f519ea581408b9976e4136',
    symbol0: 'wstETH',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5979d7b546e38e414f7e9822514be443a4800529',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x387305dc10a95bab5a05aa285cc940fd10f27ed3',
    collateralAddress: '0x1e234d79eec348a356a0294a17c797f1539fe376',
    borrowableAddress0: '0x72c7d6d50b8c20cd8f6761c63f8c5e5b7156e82d',
    borrowableAddress1: '0xd75695c5e8f12b0b76708923d0dfa1aa6304e5f5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x529a51f335d27477e423338bb45a9b66b6ded556': {
    chainId: 42161,
    lendingPoolAddress: '0x529a51f335d27477e423338bb45a9b66b6ded556',
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0xb8f188d85b6a01897635c8a10382aab83a048cdc',
    symbol0: 'SLIZ',
    symbol1: 'opxveSLIZ',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x463913d3a3d3d291667d53b8325c598eb88d3b0e',
    tokenAddress1: '0xd29f8a9e76ef42ba9f749583ed07cae7bfaec389',
    uniswapV2PairAddress: '0x33e6904d852d12d918fabf60be14f844bb93b0a2',
    collateralAddress: '0x0be9996cefd7edf1dbea6ff9cbfd6b23a0b75286',
    borrowableAddress0: '0x81c9239acd6f8c0dc8ab587e3a82ddae57fe676a',
    borrowableAddress1: '0x8b75ede8b14432e3afbae7196d66d8eb3bc6de72',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x84f664a4a3d0ca3467cca3831c7019d47fc61603': {
    chainId: 42161,
    lendingPoolAddress: '0x84f664a4a3d0ca3467cca3831c7019d47fc61603',
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x0322cebacf1f235913be3fce407f9f81632ede8b',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xe20f93279ff3538b1ad70d11ba160755625e3400',
    collateralAddress: '0x0b48385ea9e20f87efe6ce6ebc503b50984bcd9a',
    borrowableAddress0: '0xb65c4070552d8097b8c61e24d42d53504a5b7696',
    borrowableAddress1: '0x9fe48fea27334ffa99a5ea216d41595c755d5432',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x1faad52dbc09a8dffdf28619d56056734c1c828f': {
    chainId: 42161,
    lendingPoolAddress: '0x1faad52dbc09a8dffdf28619d56056734c1c828f',
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x27024dbe7fbbf827cd917954addc5ab21b2f9994',
    symbol0: 'WBTC',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xd9d611c6943585bc0e18e51034af8fa28778f7da',
    collateralAddress: '0x4f5a23bb86de4756bb92efd06b46b16da8b06e38',
    borrowableAddress0: '0x559b0be89ff3abd33af8aae8247a38bf8e51502a',
    borrowableAddress1: '0x11a9997cc9c7e8125600f18ff6642bbf4643fe7e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0xf4ffe5e59f0b29b77b71ebfb1135f138403aca2a': {
    chainId: 42161,
    lendingPoolAddress: '0xf4ffe5e59f0b29b77b71ebfb1135f138403aca2a',
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x549855586afc1283e90295088ba5eb51e7236ae8',
    symbol0: 'SLIZ',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x463913d3a3d3d291667d53b8325c598eb88d3b0e',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x751f3b8ca139bc1f3482b193297485f14208826a',
    collateralAddress: '0xd0332e181f7bd1efedab55906476ed399fde7533',
    borrowableAddress0: '0x343d2cea657ef898276086adfdb43c0dff34b9c0',
    borrowableAddress1: '0xfeb606803204811737201761a55b12558bf4333b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x3654ad9d576dee12601b4a89c6d8379cc59a3129': {
    chainId: 42161,
    lendingPoolAddress: '0x3654ad9d576dee12601b4a89c6d8379cc59a3129',
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x07c26decaa281779ed50fc3481cf1d52d5aa088a',
    symbol0: 'SLIZ',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x463913d3a3d3d291667d53b8325c598eb88d3b0e',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x9e7fb82fdf08a6aaba760a81f0a64602af045d2a',
    collateralAddress: '0xbeb2ccb19553255169e6dcb17a5a637e488f22fc',
    borrowableAddress0: '0x3a0ff397319de2ac90d06846a1b007e88542f0c4',
    borrowableAddress1: '0x99f6b21240a4a4be97c5e4a4c3a7956b05cc7c82',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x7b41d1bf1cc0ac84ad5e9685fb5e8d68bf405402': {
    chainId: 42161,
    lendingPoolAddress: '0x7b41d1bf1cc0ac84ad5e9685fb5e8d68bf405402',
    stable: true,
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x7c57641d7e4713c9a502e0373679a91cb5c589ea',
    symbol0: 'USDT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xc9df93497b1852552f2200701ce58c236cc0378c',
    collateralAddress: '0xf2a53ca506bfe54727dd189130bdb401f827538d',
    borrowableAddress0: '0xbf2aa7a2b39236faa2a9e1d23700218bff6edc84',
    borrowableAddress1: '0x54f64bac6d40161b271e786c6ab9b11894acc0b9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0x159656d0c8c2123465aae4dd73605616cf11e426': {
    chainId: 42161,
    lendingPoolAddress: '0x159656d0c8c2123465aae4dd73605616cf11e426',
    stable: true,
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x884c28296b6ec728ac27bfe7579419709514d154',
    symbol0: 'DAI',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x07d7f291e731a41d3f0ea4f1ae5b6d920ffb3fe0',
    collateralAddress: '0xbe8952e9070b4762c5f82b5e43d030bd9d27d909',
    borrowableAddress0: '0x313f18e86e62c5a884546d1687f1e1336eeac805',
    borrowableAddress1: '0xeaa885b81a841eea0af4c3e3965f858f803818b9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0xba412fb97cb5ba70102fcffc1dc7cdc1e4a9c906': {
    chainId: 42161,
    lendingPoolAddress: '0xba412fb97cb5ba70102fcffc1dc7cdc1e4a9c906',
    stable: true,
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x637dcef6f06a120e0cca5bca079f6cf6da9264e8',
    symbol0: 'DAI',
    symbol1: 'USDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    tokenAddress1: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    uniswapV2PairAddress: '0x15b9d20bcaa4f65d9004d2bebac4058445fd5285',
    collateralAddress: '0xc51c5f82247288d89d2f82e4c35203705b830833',
    borrowableAddress0: '0x76b2fd20c36e2afa2b7423e009643d0940e54989',
    borrowableAddress1: '0x03fdb1b5daf939b1fe422939b111a7b78246117f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0xafc2ba3bb5b804fd70693d879faf807888890a38': {
    chainId: 42161,
    lendingPoolAddress: '0xafc2ba3bb5b804fd70693d879faf807888890a38',
    stable: true,
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x66217e92278731bdf249ad298e844f80c8be2b58',
    symbol0: 'LUSD',
    symbol1: 'DAI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x93b346b6bc2548da6a1e7d98e9a421b42541425b',
    tokenAddress1: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    uniswapV2PairAddress: '0x99fe59026a627e03ff34b9f2fa08ba70d7a703f9',
    collateralAddress: '0x7afc21adc6907e50fb4be6671d410b2e011a4cde',
    borrowableAddress0: '0xd389ce9c8727c621a9bceb08f314aea0c5fffa5c',
    borrowableAddress1: '0xd280237390023dfe5683a036a1f0b8d1b6524b58',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0x4f3ecbd5b77d6ec8b9f0182a63265c6c2c3f1586': {
    chainId: 42161,
    lendingPoolAddress: '0x4f3ecbd5b77d6ec8b9f0182a63265c6c2c3f1586',
    stable: true,
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0xd920bd357bd0f5ff9185a295c9c299b331d5e4ea',
    symbol0: 'LUSD',
    symbol1: 'USDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x93b346b6bc2548da6a1e7d98e9a421b42541425b',
    tokenAddress1: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    uniswapV2PairAddress: '0x76306492d938ae46df657c006e642b4d31dc6bd9',
    collateralAddress: '0xd2b7f820635b04b794816b3e192e9fa6f1b6b3c8',
    borrowableAddress0: '0xc39ac9c897839d42dfe88b6a3a5f5ca2166e692d',
    borrowableAddress1: '0x6d3b2d168fba1c6d4cefade730a72d24a675e4e9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0xb19c02a1b97665a19fc7fc0f828c608d01cb66df': {
    chainId: 42161,
    lendingPoolAddress: '0xb19c02a1b97665a19fc7fc0f828c608d01cb66df',
    stable: true,
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0xa4f536393e277dc63ecfa869d901b4f81cc5462c',
    symbol0: 'LUSD',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x93b346b6bc2548da6a1e7d98e9a421b42541425b',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xb1e9b823295b3c69ac651c05d987b67189ff20ad',
    collateralAddress: '0x25006ea73bc2c9096c90c3b32abc57d439b9add4',
    borrowableAddress0: '0xc07f52b6c6cd1c2305335234228381368b5d7c82',
    borrowableAddress1: '0x06daa8f11a6bd40474df28287f6cb88207e6489f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0x54c3ef83c1941334c80db459399c398b20e02d4c': {
    chainId: 42161,
    lendingPoolAddress: '0x54c3ef83c1941334c80db459399c398b20e02d4c',
    isTarotVault: true,
    dex: DEX.XCAL,
    vaultType: VaultType.XCAL,
    pid: undefined,
    gaugeAddress: '0x308315464e9d82cf995caa05738e4d14dba81105',
    symbol0: 'XCAL',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xd2568accd10a4c98e87c44e9920360031ad89fcb',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x2cc6ac1454490afa83333fabc84345fad751285b',
    collateralAddress: '0x68a80fc6d73d80c75b78c627dc5c12c1c7d0f6a1',
    borrowableAddress0: '0x14bd62e8cf097d17cbc80154cd105315f821b682',
    borrowableAddress1: '0xaed173ec432830bb3d35e37b1f5abbe08a6fa50d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x46fcde1b89d61f5cbfaab05c2914c367f8301f30'
  },
  '0xc52cd7727920f0af088378d7192e2f19a22b861e': {
    chainId: 42161,
    lendingPoolAddress: '0xc52cd7727920f0af088378d7192e2f19a22b861e',
    isTarotVault: true,
    dex: DEX.XCAL,
    vaultType: VaultType.XCAL,
    pid: undefined,
    gaugeAddress: '0xde2601e4ed4ad7dcf7ecaf32c79fdb7025fc7026',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x3c94d5abcf49a2980d55721c35093210699c1493',
    collateralAddress: '0x7af58bb8038d7cf978e27c2479fcfb024f146baa',
    borrowableAddress0: '0x38b00d3ffb648f443c3daf16f215f9e99c74baa8',
    borrowableAddress1: '0x074e82153303ec002cbd996febd148321e1302ce',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x46fcde1b89d61f5cbfaab05c2914c367f8301f30'
  },
  '0x7d4701aba4019185c0059970f84d7aaa65bb440b': {
    chainId: 42161,
    lendingPoolAddress: '0x7d4701aba4019185c0059970f84d7aaa65bb440b',
    stable: true,
    isTarotVault: true,
    dex: DEX.XCAL,
    vaultType: VaultType.XCAL,
    pid: undefined,
    gaugeAddress: '0xa848d59dd782b0c77d0b5c53103c329319fd2606',
    symbol0: 'DAI',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x6345ccf4b1d4411789cd11411f146bfe3de14e8a',
    collateralAddress: '0xd3aae3d708976531c4f28e38ae124598131c70d2',
    borrowableAddress0: '0xf64a9ebc4c986735ad318ab58baa5e10b749b3b6',
    borrowableAddress1: '0xbc831b33fbaca77252abb620a7842347d29b90af',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0xc866b40a00cf0ce3794b68c29f76d67b5074f601': {
    chainId: 42161,
    lendingPoolAddress: '0xc866b40a00cf0ce3794b68c29f76d67b5074f601',
    isTarotVault: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 11,
    symbol0: 'WETH',
    symbol1: 'ARB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    uniswapV2PairAddress: '0x39511b74722afe77d532eb70632b4b59c559019b',
    collateralAddress: '0x965f9d20652813e85e18f75c2898b585ef940a1e',
    borrowableAddress0: '0x37067b53f7ad00cbbc4e2949e555365a5104e2a9',
    borrowableAddress1: '0xf5c0bb27a8bee14c5ed9e794e4e82b620b6d3648',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x1df812b1d8591b51c239f2d9774023aad1954b3c': {
    chainId: 42161,
    lendingPoolAddress: '0x1df812b1d8591b51c239f2d9774023aad1954b3c',
    isTarotVault: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 9,
    symbol0: 'FRAX',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x17fc002b466eec40dae837fc4be5c67993ddbd6f',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x9d47610d6cfc4719d05f4e84344a655fb4fd3f57',
    collateralAddress: '0x30ac016de40288852b6849f3ad8063c9e1a2eec7',
    borrowableAddress0: '0xa1cb35774411d36a65e0cdee4c337e6cc262a645',
    borrowableAddress1: '0x957f3cf00d9259730e8e8ee6f6fb323b57d8f614',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0xc22241616d9337e2e99e4c4df06b062d8f026399': {
    chainId: 42161,
    lendingPoolAddress: '0xc22241616d9337e2e99e4c4df06b062d8f026399',
    isTarotVault: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 10,
    symbol0: 'WETH',
    symbol1: 'sfrxETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0x95ab45875cffdba1e5f451b950bc2e42c0053f39',
    uniswapV2PairAddress: '0x00cabe722790e27ca6154e8fc34281384aa7052c',
    collateralAddress: '0x953cb3ae1d96a6ef63041625bc1368875d4811dc',
    borrowableAddress0: '0xbe1bfa6450689634c051a56df5f091e072e0fb14',
    borrowableAddress1: '0xa96c200ce8d11fed67e43ca0d2574d2ceebf71e6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x34804640990a6c158066fd774b6e899015dae637': {
    chainId: 42161,
    lendingPoolAddress: '0x34804640990a6c158066fd774b6e899015dae637',
    isTarotVault: true,
    isArbidexV1: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 11,
    symbol0: 'WETH',
    symbol1: 'ARB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    uniswapV2PairAddress: '0x39511b74722afe77d532eb70632b4b59c559019b',
    collateralAddress: '0xf1189c653c59d8360e42354548ec06bae134ca3c',
    borrowableAddress0: '0xb59946b53fab539d5e484ab2028537db2d1fb0ae',
    borrowableAddress1: '0xdef5ec020a649cdbe4b624e27511ba7da6054cbe',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0xdd574610eee3387b3aed593e2325f8d075a0af32': {
    chainId: 42161,
    lendingPoolAddress: '0xdd574610eee3387b3aed593e2325f8d075a0af32',
    isTarotVault: true,
    isArbidexV1: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 12,
    symbol0: 'ARB',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xd65ef54b1ff5d9a452b32ac0c304d1674f761061',
    collateralAddress: '0x0169bb600c188c9ef75b2892f6237f58e774336e',
    borrowableAddress0: '0xbc8b5a48b26239ca66c56800474c0a13fe18ee28',
    borrowableAddress1: '0xd2598c8c5e7d15491eb42e7bdd6cbee31b957847',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x66db4dbf398bb84e4c365144fb502236e7524f00': {
    chainId: 42161,
    lendingPoolAddress: '0x66db4dbf398bb84e4c365144fb502236e7524f00',
    isTarotVault: true,
    isArbidexV1: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 1,
    symbol0: 'ARX',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xd5954c3084a1ccd70b4da011e67760b8e78aee84',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xa6efae0c9293b4ee340de31022900ba747eaa92d',
    collateralAddress: '0x392fd31b7ea7df34bb95107a7b3191a9e74141eb',
    borrowableAddress0: '0xbae8d73f63388af563f7279be7d3a49177c059a9',
    borrowableAddress1: '0x3e23532683f251c304ef4e2c15b05cca63662553',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x487da352d9cf5e9b180abb134bc2dd8b95348c2d': {
    chainId: 42161,
    lendingPoolAddress: '0x487da352d9cf5e9b180abb134bc2dd8b95348c2d',
    isTarotVault: true,
    isArbidexV1: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 2,
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x4c42fa9ecc3a17229edf0fd6a8eec3f11d7e00d3',
    collateralAddress: '0x783d7c979fb66fce6317999f5a727c391741a0d5',
    borrowableAddress0: '0x5191715b2c5c0df5a96c51d347770436b968a89a',
    borrowableAddress1: '0xa91c4bf4aa8f83999b4b003a62f71279dbbefa20',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0xc903277c42adb62f9ab103c0ff2069ddbfc7ad0b': {
    chainId: 42161,
    lendingPoolAddress: '0xc903277c42adb62f9ab103c0ff2069ddbfc7ad0b',
    isTarotVault: true,
    isArbidexV1: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 3,
    symbol0: 'USDT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xd082d6e0af69f74f283b90c3cda9c35615bce367',
    collateralAddress: '0xb7f8e1ec228a0104ed2317d212bad5019f538b69',
    borrowableAddress0: '0x7d40cb48708554920e94b343a77355ec5f060111',
    borrowableAddress1: '0x123b9401555c8fe151a7bebd1654c27e7a96f85f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x006680c69e36b035a810e830221cd7b062489c54': {
    chainId: 42161,
    lendingPoolAddress: '0x006680c69e36b035a810e830221cd7b062489c54',
    isTarotVault: true,
    isArbidexV1: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 4,
    symbol0: 'WBTC',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 6,
    tokenAddress0: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0xa6a6090749b9e3010802c5bff3845aa6a4ac321b',
    collateralAddress: '0xc0c4cf815f661ae2f5c95642e1bef1d2733531d5',
    borrowableAddress0: '0x927e96f56a4aedb88d13db409b2ca0b1c7fe5376',
    borrowableAddress1: '0x98e5d8bb510ea6cf03b318e5304963d82bec4f94',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x22139d250702a62dd579a8a091de32c1f28aba28': {
    chainId: 42161,
    lendingPoolAddress: '0x22139d250702a62dd579a8a091de32c1f28aba28',
    isTarotVault: true,
    isArbidexV1: true,
    dex: DEX.ARBIDEX,
    vaultType: VaultType.ARBIDEX,
    pid: 6,
    symbol0: 'WBTC',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0x10a12127867d3885ac64b51cc91a67c907ee51db',
    collateralAddress: '0x6e72a33d3cc46f1bce5f22d40600f1ea7fe66d54',
    borrowableAddress0: '0x735162124f94fc71f032936fae7be7ad8dfc9c10',
    borrowableAddress1: '0x22f6d1ee261f9475bf76fdc1bb86797a52266527',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0xe40d18c3fc61d571a0d01ee58823dabd750ac34f': {
    chainId: 42161,
    lendingPoolAddress: '0xe40d18c3fc61d571a0d01ee58823dabd750ac34f',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 6,
    symbol0: 'USDT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x106ae154e4c24b6e11e70cfee7e075b14a182244',
    collateralAddress: '0x6753795f62d32fd9ee0344c7ed332cefd3b6ea19',
    borrowableAddress0: '0xa7113e03028e67ba9e85762adc1bf841ef5dbf77',
    borrowableAddress1: '0x1454fc536fc2fa3ecf79953335dbe2f6c7cf62a9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0xeeef7422ced792cddf0e3317c56b784deef46c5c': {
    chainId: 42161,
    lendingPoolAddress: '0xeeef7422ced792cddf0e3317c56b784deef46c5c',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 5,
    symbol0: 'DAI',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x978de910e7645fa7861af89a156d3c86ad09db79',
    collateralAddress: '0x5bca0334e9a1da9007d9902230fe7bc34757d20d',
    borrowableAddress0: '0xb8e8df0272ec8dcd8e4b55c90db40c1a033e8b55',
    borrowableAddress1: '0x598d45fdf5c81d4e5fa670b0c7b28efc52c24513',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0xed363cee156ba1d6ec7f0f2cce97c6760371684f': {
    chainId: 42161,
    lendingPoolAddress: '0xed363cee156ba1d6ec7f0f2cce97c6760371684f',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 12,
    symbol0: 'FRAX',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x17fc002b466eec40dae837fc4be5c67993ddbd6f',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x85710698eb1fb588af3996a268e73938795bf558',
    collateralAddress: '0xba768f2ff96ec73fa8ac75f5992c2bec629570f3',
    borrowableAddress0: '0xbbf999d7ca4225b577b181ddf5f930c77e9afb16',
    borrowableAddress1: '0x0430b9cc442675b867183dd8864524c0f2f3cd4d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x23024179ac60bf762ff7728773e2942d6b94544c': {
    chainId: 42161,
    lendingPoolAddress: '0x23024179ac60bf762ff7728773e2942d6b94544c',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 2,
    symbol0: 'wstETH',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5979d7b546e38e414f7e9822514be443a4800529',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xe263353986a4638144c41e44cebac9d0a76ecab3',
    collateralAddress: '0xeaf37161a224a120fcc06be4afa89d9f3dbab63e',
    borrowableAddress0: '0x5356aed125c32329e19b3a616fa8a7adbc165cb1',
    borrowableAddress1: '0x4d62719b177b7b26a5ba0e2fad37dc9c8dcb194a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x739140be0f2bf29d7479a13505144b23df0d3a5f': {
    chainId: 42161,
    lendingPoolAddress: '0x739140be0f2bf29d7479a13505144b23df0d3a5f',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 3,
    symbol0: 'WBTC',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    tokenAddress1: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    uniswapV2PairAddress: '0xf7c6ffa90e8f240481234fb3fe9e8f60df74ed87',
    collateralAddress: '0xf2eed208567a21bd355e0fef58cd1db7741510a0',
    borrowableAddress0: '0x67bdd34466d6815bb6c4ba350e100837be26a818',
    borrowableAddress1: '0x61375755f162e46e198caf9c1bc98f7dc35dd7df',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x72010be59a67cc996d24a8c03bd7825d96e86a98': {
    chainId: 42161,
    lendingPoolAddress: '0x72010be59a67cc996d24a8c03bd7825d96e86a98',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 4,
    symbol0: 'MIM',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '800000000000000000',
    kinkUtilizationRate1: '800000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1224744870000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x23d7cb985b5b23d83e5c2eb04074649af1d488bf',
    collateralAddress: '0x92a15004a7b6218427dc2af26e7781b04287d3b2',
    borrowableAddress0: '0x84c0fa30f643be9cd4708d1bf7a428ec9e42de35',
    borrowableAddress1: '0x119395c0582279d755677a27c25e08d8ecf5203f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0xee174bdb8c40ad8421eae87b2e90d784b198f88e': {
    chainId: 42161,
    lendingPoolAddress: '0xee174bdb8c40ad8421eae87b2e90d784b198f88e',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 1,
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x8bc2cd9dab840231a0dab5b747b8a6085c4ea459',
    collateralAddress: '0x9a798a444a6de2b02983f990581d94a7d28ba5e3',
    borrowableAddress0: '0x379d9a08d1dbb4b54876abf6bc388e87f6aaa81e',
    borrowableAddress1: '0x91d1c950d500127e99f49f559daea2553074bcb3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x43eaaf40c1ea6134127b2532f3bb20e4ac1b8952': {
    chainId: 42161,
    lendingPoolAddress: '0x43eaaf40c1ea6134127b2532f3bb20e4ac1b8952',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 9,
    symbol0: 'FISH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xb348b87b23d5977e2948e6f36ca07e1ec94d7328',
    tokenAddress1: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    uniswapV2PairAddress: '0x570eab8422160840ce3b7b2be4c71d657659868f',
    collateralAddress: '0x78b776c09d7ddb3eecadfd21888b3ae84d9b14ac',
    borrowableAddress0: '0xe36ce6817fa6d604bfef65d1dbef38448edf7ba9',
    borrowableAddress1: '0x68e10f2d94a47426834bf74495b130ea36182b48',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0x18216a5f55e84b0df3c5b162fd4dc25ab2fa637e': {
    chainId: 42161,
    lendingPoolAddress: '0x18216a5f55e84b0df3c5b162fd4dc25ab2fa637e',
    isTarotVault: true,
    dex: DEX.SWAPFISH,
    vaultType: VaultType.SWAPFISH,
    pid: 13,
    symbol0: 'WETH',
    symbol1: 'FISH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xb348b87b23d5977e2948e6f36ca07e1ec94d7328',
    uniswapV2PairAddress: '0xe0a0ede95abe2072cc2091d5c193fd86f740e057',
    collateralAddress: '0xa91bf48ba2cda34a9fe6ee97eea533036c6e24e2',
    borrowableAddress0: '0x4370ab134049c348064d7ee3a0f2dc08e57103cd',
    borrowableAddress1: '0x4e4bdd567e75225498c888ecb8ebefa753ca05d3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x8cec723d81273b9ab1fc70441382701dfa2fe526',
    tarotRouterAddress: '0x19283dd283c31bf3920f7a530aa3a81a2792dc52'
  },
  '0xfaa72ca0edc96e9c80dedc05029a757ef98b5cdb': {
    chainId: 42161,
    lendingPoolAddress: '0xfaa72ca0edc96e9c80dedc05029a757ef98b5cdb',
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x61f6b1f1e1f281b726ea7a8eee8d6e27edc99248',
    symbol0: 'SLIZ',
    symbol1: 'USDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x463913d3a3d3d291667d53b8325c598eb88d3b0e',
    tokenAddress1: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    uniswapV2PairAddress: '0x5d6bfc8f54a2d9196904e44c3a4fbcfd62cb8ce5',
    collateralAddress: '0xb063e39f822c94842bbbf04e3d76baab0aac9a41',
    borrowableAddress0: '0x97b9818190432d1f74f842c10d5f9720f9fbe854',
    borrowableAddress1: '0x9f76e0e01faf84b15c0bb2c5b7956d475931ee02',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },
  '0x6e0e4033563089b12125416b467ceb886ff7f909': {
    chainId: 42161,
    lendingPoolAddress: '0x6e0e4033563089b12125416b467ceb886ff7f909',
    isTarotVault: true,
    dex: DEX.SOLIDLIZARD,
    vaultType: VaultType.SOLIDLIZARD,
    pid: undefined,
    gaugeAddress: '0x85cadb2841d0ca2de4ec2a0a07ab0b7c151bb343',
    symbol0: 'WETH',
    symbol1: 'USDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    tokenAddress1: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    uniswapV2PairAddress: '0xc2573d86eca05173300cf68d5519f444a76dfeb9',
    collateralAddress: '0x0e736b669a90b2cbbba51ee06f255af7081ad723',
    borrowableAddress0: '0x23add79f02e66051d36112d765ad985358b3d35c',
    borrowableAddress1: '0x8223167cc60cc4994e550b7242bd18d444fe5379',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1bbd5637421a83b00c5cd549b9c3721b28553f80',
    tarotRouterAddress: '0x11876c56204b2e1e9763a802b7f15fc942ebf1ac'
  },

  // BNB Pools
  '0xd7257417ab6d9ca707dc214e63e355e7ac9f25a3': {
    chainId: 56,
    lendingPoolAddress: '0xd7257417ab6d9ca707dc214e63e355e7ac9f25a3',
    isTarotVault: true,
    dex: DEX.THENAV2,
    vaultType: VaultType.THENAV2,
    pid: undefined,
    gaugeAddress: '0xf1fa952c2e3b2fab77f0e243544c035f14ada63e',
    symbol0: 'USDT',
    symbol1: 'THE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x55d398326f99059ff775485246999027b3197955',
    tokenAddress1: '0xf4c8e32eadec4bfe97e0f595add0f4450a863a11',
    uniswapV2PairAddress: '0xa051ef9a6fbea340bb734d022e7b6a3ad9fd9b06',
    collateralAddress: '0xc3ebfeb55db4278ed83b26526ecea1642806e086',
    borrowableAddress0: '0xab1ce729a2feead2afd8e39ca8bfb34694ff93b5',
    borrowableAddress1: '0x890a96475dd8848b14a9070af0b6281192739f9a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0xaba34ce7221995a5dcc38f29068737aea7dfc9eb': {
    chainId: 56,
    lendingPoolAddress: '0xaba34ce7221995a5dcc38f29068737aea7dfc9eb',
    isTarotVault: true,
    dex: DEX.THENAV2,
    vaultType: VaultType.THENAV2,
    pid: undefined,
    gaugeAddress: '0xf11ac842b1e7f2ab24f2166e874cd6cb8bc3f32f',
    symbol0: 'USDT',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x55d398326f99059ff775485246999027b3197955',
    tokenAddress1: '0xa8cd6e4bf45724d3ac27f9e31e47ba4e399a7b52',
    uniswapV2PairAddress: '0x8efeeae90e67051c4f9130dd4f27d5d443e58a8b',
    collateralAddress: '0x8ccbd41bec132805c252174c5f4ae6ad20685b68',
    borrowableAddress0: '0xf92d9b71ef48b96a581019acd29b9ebb190bbdf5',
    borrowableAddress1: '0x9648e2c546b4878b8f62d2916ccdebbabf2cfd7c',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x3c136a055ed06f974565d1ea0188c83501e8ebf8': {
    chainId: 56,
    lendingPoolAddress: '0x3c136a055ed06f974565d1ea0188c83501e8ebf8',
    isTarotVault: true,
    dex: DEX.THENAV2,
    vaultType: VaultType.THENAV2,
    pid: undefined,
    gaugeAddress: '0x586cf0ce829e39c3b37944e183d2dfb280191b50',
    symbol0: 'WBNB',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0xc8da40f8a354530f04ce2dde98ebc2960a9ea449',
    collateralAddress: '0x0e26f261700f6408229ce7f4232694e014541bde',
    borrowableAddress0: '0x0936021b5384ec82775f5e098ed328ce1c10fa4c',
    borrowableAddress1: '0x13c6f92f3064ac930d66f6d03191dc214387dd72',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x373ed1655dd38ff1d74f2baf2a2fb55f82b6f2ab': {
    chainId: 56,
    lendingPoolAddress: '0x373ed1655dd38ff1d74f2baf2a2fb55f82b6f2ab',
    isTarotVault: true,
    dex: DEX.THENAV2,
    vaultType: VaultType.THENAV2,
    pid: undefined,
    gaugeAddress: '0x2531af65168757d595925e2a6a64cc231940a5c4',
    symbol0: 'WBNB',
    symbol1: 'LQDR',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xe5c6155ed2924e50f998e28eff932d9b5a126974',
    uniswapV2PairAddress: '0xec4484c7f7671914e897b1aeb8418fe12e320f21',
    collateralAddress: '0x3c6c3b94457ea4da67338d0dcc1e31c042e9323b',
    borrowableAddress0: '0x82b6061f6b50956453c6e1d96b1b24b39ee456c0',
    borrowableAddress1: '0x922745e0ced18dd51be198b61bfd033c77ca4a4a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x27dc00b48110faf99ea7d9e60e6ddb90e3be422e': {
    chainId: 56,
    lendingPoolAddress: '0x27dc00b48110faf99ea7d9e60e6ddb90e3be422e',
    isTarotVault: true,
    dex: DEX.THENAV2,
    vaultType: VaultType.THENAV2,
    pid: undefined,
    gaugeAddress: '0x6e7fd7e78b672720f2c01b47d1d8269cd9844057',
    symbol0: 'WBNB',
    symbol1: 'OATH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xd3c6ceedd1cc7bd4304f72b011d53441d631e662',
    uniswapV2PairAddress: '0xaf6389e0281337cf810b5843ebae12dbf5a0eaf4',
    collateralAddress: '0x423ebf53dcf3122d334d331a3a5ddc0ed471ab68',
    borrowableAddress0: '0xf2ec4a61ce4b4c0a32ca770696e60088f1f51680',
    borrowableAddress1: '0x640f49758a1cc08f5eb67cf701f1527e727a8cae',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x63aa8566dc62ae89ce7efd8940636fc347cff61c': {
    chainId: 56,
    lendingPoolAddress: '0x63aa8566dc62ae89ce7efd8940636fc347cff61c',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0xda6fc5422475c424928f3a21e9b8d9f80b1db190',
    symbol0: 'USDT',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x55d398326f99059ff775485246999027b3197955',
    tokenAddress1: '0xa8cd6e4bf45724d3ac27f9e31e47ba4e399a7b52',
    uniswapV2PairAddress: '0x8efeeae90e67051c4f9130dd4f27d5d443e58a8b',
    collateralAddress: '0xf8f238ef0b55f34402e8b220bf433de5ea32eaca',
    borrowableAddress0: '0x2a7000074c4674254adaa9c35c655bf56c115d0d',
    borrowableAddress1: '0x249bb5f7168bfcbb424b75a0ea688c3bcfb9f10a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x1c59e2aed38d1a81e46625b721c33755b73ee679': {
    chainId: 56,
    lendingPoolAddress: '0x1c59e2aed38d1a81e46625b721c33755b73ee679',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0xf49bff8fb6a0ad43475d28be955e62c10c50a998',
    symbol0: 'TAROT',
    symbol1: 'WBNB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xa8cd6e4bf45724d3ac27f9e31e47ba4e399a7b52',
    tokenAddress1: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    uniswapV2PairAddress: '0xb2604b72b3aa4af8d0419c736de2d261b40ec755',
    collateralAddress: '0x3ad63037eecac370d0bdebd0b86f9de444cd5c96',
    borrowableAddress0: '0x2483ed94c2aa533b8972facc59d3e33391628ff5',
    borrowableAddress1: '0x499ae5baf67cf199fd484cafe685ce6635778bcf',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0xb30f0927b96aaba8024d98e03e12483cb517c7cd': {
    chainId: 56,
    lendingPoolAddress: '0xb30f0927b96aaba8024d98e03e12483cb517c7cd',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x7144851e51523a88ea6bec9710cc07f3a9b3baa7',
    symbol0: 'WBNB',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    uniswapV2PairAddress: '0x483653bcf3a10d9a1c334ce16a19471a614f4385',
    collateralAddress: '0x318b8b9dd830e4e96292d426984bb0ead2593e00',
    borrowableAddress0: '0x4ac2c0a57fb8f51458173e89e7cfe7f25317f317',
    borrowableAddress1: '0x1ea1bf9945ad59a18e29a5ca0e17c208c2830961',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0xa4696d9d00a9a2db4966cf32c0e76ba423eeed0c': {
    chainId: 56,
    lendingPoolAddress: '0xa4696d9d00a9a2db4966cf32c0e76ba423eeed0c',
    stable: true,
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x41ada56dd5702906549a71666541a39b0dbceb12',
    symbol0: 'USDT',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x55d398326f99059ff775485246999027b3197955',
    tokenAddress1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    uniswapV2PairAddress: '0x6321b57b6fdc14924be480c54e93294617e672ab',
    collateralAddress: '0xc1d851ae31675793602080faa23b7eb2c6efa20b',
    borrowableAddress0: '0x66c5b650d9e531667eb6f12f237edbac973eedcb',
    borrowableAddress1: '0x3fc616445beb579130e065dfbdd1d4401d493e6d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xc20099a3f0728634c1136489074508be7b406d3a',
    tarotRouterAddress: '0xb6ed26b8551dfa864cd565cd28027af22ca97131'
  },
  '0xdcbfb164c8a5cf5eb64d7cbec9bba93d54b53ca0': {
    chainId: 56,
    lendingPoolAddress: '0xdcbfb164c8a5cf5eb64d7cbec9bba93d54b53ca0',
    stable: true,
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x11e79bc17cb1ff3d4f6a025412ac84960b20ba81',
    symbol0: 'USDC',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    tokenAddress1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    uniswapV2PairAddress: '0x7e61c053527a7af0c700ad9d2c8207e386273222',
    collateralAddress: '0x3f808c5ad84b6d5f853fb1a50c1a4140b23ba3a3',
    borrowableAddress0: '0x4cf99a09b284dddba35eb31285884ed58f2e6fb3',
    borrowableAddress1: '0x11bed118be24bafbcb4c56b795da21cb4f2f6f50',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xc20099a3f0728634c1136489074508be7b406d3a',
    tarotRouterAddress: '0xb6ed26b8551dfa864cd565cd28027af22ca97131'
  },
  '0x2f28d2a4edc4ec4c4570686d8b608c973fd0d911': {
    chainId: 56,
    lendingPoolAddress: '0x2f28d2a4edc4ec4c4570686d8b608c973fd0d911',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x638b0cc37ffe5a040079f75ae6c50c9a386ddcaf',
    symbol0: 'WBNB',
    symbol1: 'THE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xf4c8e32eadec4bfe97e0f595add0f4450a863a11',
    uniswapV2PairAddress: '0x63db6ba9e512186c2faadacef342fb4a40dc577c',
    collateralAddress: '0xbf573ae091e2f720114a03bb2a9b8febc30a3557',
    borrowableAddress0: '0xc2e82b26819987f62635fbd92b759f8b3ea1d102',
    borrowableAddress1: '0x020490305e142ac2c064f45e3205044bbeaeac7a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x0930764998c372ee1720e7f1ba1a6efbe517cfdb': {
    chainId: 56,
    lendingPoolAddress: '0x0930764998c372ee1720e7f1ba1a6efbe517cfdb',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x8a8ec422fc51b2a88dd5be489c40aaf1e1fa73d0',
    symbol0: 'BUSD',
    symbol1: 'THE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    tokenAddress1: '0xf4c8e32eadec4bfe97e0f595add0f4450a863a11',
    uniswapV2PairAddress: '0x34b897289fccb43c048b2cea6405e840a129e021',
    collateralAddress: '0xb226e2be938ddff36b6c29189a1788c2bce29d8c',
    borrowableAddress0: '0x771b35c06824a26e220d6d979cbc547f30966989',
    borrowableAddress1: '0x17a43c3bd77896861ba8d6826bdc78291e2d1938',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0xa3f46f00d2bf1011fcc8431d8f71ad6b68b7a0f8': {
    chainId: 56,
    lendingPoolAddress: '0xa3f46f00d2bf1011fcc8431d8f71ad6b68b7a0f8',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x9a0c07d7b9d0e0a82ccb12049876e2fb6bf96d0f',
    symbol0: 'ETH',
    symbol1: 'WBNB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    tokenAddress1: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    uniswapV2PairAddress: '0x1d168c5b5dea1c6da0e9fd9bf4b7607e4e9d8eec',
    collateralAddress: '0xeeacad1804a56fd57ac7202365573177fca106f3',
    borrowableAddress0: '0x26316c79b7a30f82f8ab0824b54cb466058ddb1c',
    borrowableAddress1: '0xc30752375b7b7ae534690795b7739dcfe8166e0c',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x8216c265c8faf22c48d9f4aeb3cb2e3939770dc8': {
    chainId: 56,
    lendingPoolAddress: '0x8216c265c8faf22c48d9f4aeb3cb2e3939770dc8',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0xbd3c24fbba57d5178994eacfda85985d311dafba',
    symbol0: 'OATH',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xd3c6ceedd1cc7bd4304f72b011d53441d631e662',
    tokenAddress1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    uniswapV2PairAddress: '0xc2dc97a4afe061186ad5ca5231f964a97ce305eb',
    collateralAddress: '0xaad8ebb324d20a1dc449c5e058559f295118357b',
    borrowableAddress0: '0xfe3fd746e5e97b5178531ca57a9191e5f3eb85b7',
    borrowableAddress1: '0xc7951d159ee0a570e06e8165c624aa6f3cee6724',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x7a19df855c350a4c7b78f56e169f464c3dba27c1': {
    chainId: 56,
    lendingPoolAddress: '0x7a19df855c350a4c7b78f56e169f464c3dba27c1',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x3b6321dc8e795ca8175656981e229b47f4f7c015',
    symbol0: 'WBNB',
    symbol1: 'LQDR',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xe5c6155ed2924e50f998e28eff932d9b5a126974',
    uniswapV2PairAddress: '0xec4484c7f7671914e897b1aeb8418fe12e320f21',
    collateralAddress: '0xc1d1a4d0982079a944db24e84e410825d83754ef',
    borrowableAddress0: '0x217fe95d9f56224c122805bd42c7bd39c1922f89',
    borrowableAddress1: '0xdc10ede018cb0179862fe9b4e5d8db8d83d38fa9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0xeb19a04142273ddc3d4eb14d360ad0bc5688bf6c': {
    chainId: 56,
    lendingPoolAddress: '0xeb19a04142273ddc3d4eb14d360ad0bc5688bf6c',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0xdd35503b77277a6d810fea6479c92bbce5bbb412',
    symbol0: 'BTCB',
    symbol1: 'WBNB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    tokenAddress1: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    uniswapV2PairAddress: '0xd328d129e46f9b978416599913102e8cd64593f3',
    collateralAddress: '0xde318222e72a769ac580402525cd8dde84ce4eef',
    borrowableAddress0: '0xb7cc7d8ad2c53f11d8408975e1cce4d0781fd0a6',
    borrowableAddress1: '0xb19644f25ef36fef851a47694efdebbefbd75a04',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x05c4db100f2878c94cec3b0acedf5af0751864ce': {
    chainId: 56,
    lendingPoolAddress: '0x05c4db100f2878c94cec3b0acedf5af0751864ce',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x9f3d718cd994627a78dcbe7e6c878462337ebd19',
    symbol0: 'WBNB',
    symbol1: 'ANKR',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xf307910a4c7bbc79691fd374889b36d8531b08e3',
    uniswapV2PairAddress: '0xe934278276c0274aac95dadb9783f02a27c351ff',
    collateralAddress: '0x875cbd8c598dab142dfdc78e6ab4e36e5cdc8d93',
    borrowableAddress0: '0xa19b1c8d07f9cb4c5288cf28afc781844bf7a9e3',
    borrowableAddress1: '0x5f88b17a0e37d94622f6e8cbdf6f1a63ad0002d8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x3a3036427d7b26c8f1b80ebdb9e5ea1139a42f48': {
    chainId: 56,
    lendingPoolAddress: '0x3a3036427d7b26c8f1b80ebdb9e5ea1139a42f48',
    stable: true,
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0xe43317c1f037cbbaf33f33c386f2caf2b6b25c9c',
    symbol0: 'HAY',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5',
    tokenAddress1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    uniswapV2PairAddress: '0x93b32a8dfe10e9196403dd111974e325219aec24',
    collateralAddress: '0x56b33db6f3616528338ac09bab3cf6b1bb92c0a3',
    borrowableAddress0: '0x6603d13bd52037ed5aca28f031117a7157f7b508',
    borrowableAddress1: '0x70d0da3e4ed0a05fcd2b78f77fcc88f30f3fa48b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xc20099a3f0728634c1136489074508be7b406d3a',
    tarotRouterAddress: '0xb6ed26b8551dfa864cd565cd28027af22ca97131'
  },
  '0x469bfa1790d8aac390df0ec9ead2432d2136acad': {
    chainId: 56,
    lendingPoolAddress: '0x469bfa1790d8aac390df0ec9ead2432d2136acad',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0xbcb7da4a4544cb748d7c85dc857408a35ac1c1aa',
    symbol0: 'TAROT',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xa8cd6e4bf45724d3ac27f9e31e47ba4e399a7b52',
    tokenAddress1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    uniswapV2PairAddress: '0x928fe97eee1f921d80f25dc2eea1df280704fd03',
    collateralAddress: '0xb7a8106045972920bb99e3e5914536e17f9bc027',
    borrowableAddress0: '0xaa4d622da5950494b57e82e6be4be90876583665',
    borrowableAddress1: '0xbed5fb0bea59a107b425f33cfaa0e63cafb54b98',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0xd89ddc3e1583a99042b48f6f1b003a82021e04b6': {
    chainId: 56,
    lendingPoolAddress: '0xd89ddc3e1583a99042b48f6f1b003a82021e04b6',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0xb45146843edc26d96b75f625d1298489e14a1a20',
    symbol0: 'WBNB',
    symbol1: 'OATH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xd3c6ceedd1cc7bd4304f72b011d53441d631e662',
    uniswapV2PairAddress: '0xaf6389e0281337cf810b5843ebae12dbf5a0eaf4',
    collateralAddress: '0x9b417e0f9c815de6d332d3a6bcbb3c180305ddb0',
    borrowableAddress0: '0x94d91d8e45305a2c906443c409a68005e5b2f50b',
    borrowableAddress1: '0xd3f03403a4bfc7b557dd0d27046add5988b37add',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x2cae9e78f2df97cfbfc7c113011b7b29678beb4d': {
    chainId: 56,
    lendingPoolAddress: '0x2cae9e78f2df97cfbfc7c113011b7b29678beb4d',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x0df5dfe92a0568373da2d705cdb5f68017c4b19a',
    symbol0: 'BNBx',
    symbol1: 'WBNB',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275',
    tokenAddress1: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    uniswapV2PairAddress: '0x6c83e45fe3be4a9c12bb28cb5ba4cd210455fb55',
    collateralAddress: '0x66458f70c2c08a0a955311a125e069f11203d50c',
    borrowableAddress0: '0x49664aa32e398b6be1abd07e4105a72c38d8d986',
    borrowableAddress1: '0x659c8bdc4d616b77cc6af94200889856e46b8ee9',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x66b7daaeeedc81f68d69778ce50bc0531b2c2209': {
    chainId: 56,
    lendingPoolAddress: '0x66b7daaeeedc81f68d69778ce50bc0531b2c2209',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x04034f879a737233bf0ef278b50fd06cc70c87e4',
    symbol0: 'WBNB',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0xc8da40f8a354530f04ce2dde98ebc2960a9ea449',
    collateralAddress: '0x3d5d676d905a832510dc032f882fb77a60ebaa38',
    borrowableAddress0: '0x13fdb8b86992c4c54cc20f77408ba241af1d76e9',
    borrowableAddress1: '0x43dfc205fb4af4bbc88b9ceba41563c95523aa02',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0x0cfa746a59612f1541ee81dc7ab6036f422708f0': {
    chainId: 56,
    lendingPoolAddress: '0x0cfa746a59612f1541ee81dc7ab6036f422708f0',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x937ba7a421c4b916f77c7cd2068d37d6f4cd3790',
    symbol0: 'USDC',
    symbol1: 'FTM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    tokenAddress1: '0xad29abb318791d579433d831ed122afeaf29dcfe',
    uniswapV2PairAddress: '0x535aeeab656c0fbb07da64fe4aa3a15dfc6834c8',
    collateralAddress: '0x8bbfa3081a1672d4e0d256c361bf8c82050022af',
    borrowableAddress0: '0xc518be21cad4f248623d4acf8423581aa9f78337',
    borrowableAddress1: '0x2feda373f6963fe4332378309cb8542e979a5304',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },
  '0xe5d2b623edcd1bc4a0bfab4d31bdb35f81afc617': {
    chainId: 56,
    lendingPoolAddress: '0xe5d2b623edcd1bc4a0bfab4d31bdb35f81afc617',
    stable: true,
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0xaff65f90e50c371481bef5f3815ef3a891c0a91b',
    symbol0: 'DEI',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xde1e704dae0b4051e80dabb26ab6ad6c12262da0',
    tokenAddress1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    uniswapV2PairAddress: '0x06589abd54582783142e3d1165722e7e10483de0',
    collateralAddress: '0x8b5c49abfdf0234dcd7752e462b3037caa3548f6',
    borrowableAddress0: '0xde6101f06664ad145c6bbbe88b949a3b89563942',
    borrowableAddress1: '0x5a5a5ca61c75090894351c03525a98793e14bfff',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xc20099a3f0728634c1136489074508be7b406d3a',
    tarotRouterAddress: '0xb6ed26b8551dfa864cd565cd28027af22ca97131'
  },
  '0x34fd3a692615481481759b69ae2cde7d9c0f1885': {
    chainId: 56,
    lendingPoolAddress: '0x34fd3a692615481481759b69ae2cde7d9c0f1885',
    isTarotVault: true,
    dex: DEX.THENA,
    vaultType: VaultType.THENA,
    pid: undefined,
    gaugeAddress: '0x8da503434f19af7e2eae955f60634b67651049a8',
    symbol0: 'MATIC',
    symbol1: 'BUSD',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
    tokenAddress1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    uniswapV2PairAddress: '0xeb58a4d07990ff50b9f8289701cbddf9052570b5',
    collateralAddress: '0x150b96d6c065d90015466e21457aa441aa652b35',
    borrowableAddress0: '0xcf8a2f418dcb2ba51ea9d1dfcc13397424641531',
    borrowableAddress1: '0x72c667009c597fb9dae8de3d67b5a2aaa15d9bf5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x8184fa7dd835efd2ddf90a3072efff4928405990'
  },

  // Mainnet pools
  '0x9c66a692d13b0ecc9f06f3b4a5794d79dac829ce': {
    chainId: 1,
    lendingPoolAddress: '0x9c66a692d13b0ecc9f06f3b4a5794d79dac829ce',
    isTarotVault: true,
    dex: DEX.SOLIDLY_V2,
    vaultType: VaultType.SOLIDLY_V2,
    pid: undefined,
    gaugeAddress: '0x84674cffb6146d19b986fc88ec70a441b570a45b',
    symbol0: 'SOLID',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '20000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x777172d858dc1599914a1c4c6c9fc48c99a60990',
    tokenAddress1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    uniswapV2PairAddress: '0x642431623ae5d73c19fc931aaea0d4677303880c',
    collateralAddress: '0x330adbac6f135871756d9514da3ebe4eb9e2f832',
    borrowableAddress0: '0x39d60dae634b0fd35be43919fdd544fb5d431c07',
    borrowableAddress1: '0xbb7ee7a62bfd1a87fcde31012386d935fb4d7659',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: false,
    tarotFactoryAddress: '0x1cafcb9f3b5a152b1553bc2c688ba6a18054b653',
    tarotRouterAddress: '0x3b47f0e57a6c537ecc198833728ccd5d02e29771'
  },
  '0x6ada7eb156077d83713f83262d33450e2b0558c6': {
    chainId: 1,
    lendingPoolAddress: '0x6ada7eb156077d83713f83262d33450e2b0558c6',
    stable: true,
    isTarotVault: true,
    dex: DEX.SOLIDLY_V2,
    vaultType: VaultType.SOLIDLY_V2,
    pid: undefined,
    gaugeAddress: '0x71e0e5f31a71062a08aa629afb8587c7a178dfd9',
    symbol0: 'USDC',
    symbol1: 'USDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    tokenAddress1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    uniswapV2PairAddress: '0x63a65a174cc725824188940255ad41c371f28f28',
    collateralAddress: '0x9e025127a391e0db4440842530bdca080b8e35ea',
    borrowableAddress0: '0x52bc3aa9d3b1b778c5efcad9c6a21ab19667199d',
    borrowableAddress1: '0x9d9dec28555a436c2b4d780a52fbb529eebdadda',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x4b6dae049a35196a773028b2e835cccce9dd4723',
    tarotRouterAddress: '0x0022358ebfd5261c325f8160c7c17bc8671d07b4'
  },
  '0x753aef0dba9db57ec0d5fb2a267dc39a8e36fa9f': {
    chainId: 1,
    lendingPoolAddress: '0x753aef0dba9db57ec0d5fb2a267dc39a8e36fa9f',
    isTarotVault: true,
    dex: DEX.SOLIDLY_V2,
    vaultType: VaultType.MONOLITH,
    pid: undefined,
    gaugeAddress: '0xab6777a4df5f4434cabdb42ebd6e116c757f7f0c',
    symbol0: 'USDC',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '20000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    tokenAddress1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    uniswapV2PairAddress: '0xcd452c162da7761f08f656b8e5ede3a385981378',
    collateralAddress: '0x3ab95c78207c35c77cbe7a6f97e2ba0f32a6da70',
    borrowableAddress0: '0x394d60f2cb183f96afdad8559e1e2e488f09e9e5',
    borrowableAddress1: '0x0f31f4bddf4ded51caf64e87edc429d0fbc9310b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1cafcb9f3b5a152b1553bc2c688ba6a18054b653',
    tarotRouterAddress: '0x3b47f0e57a6c537ecc198833728ccd5d02e29771'
  },
  '0xf7b5bab20877161a14b7caa1434ffa9b7a6705f4': {
    chainId: 1,
    lendingPoolAddress: '0xf7b5bab20877161a14b7caa1434ffa9b7a6705f4',
    isTarotVault: true,
    dex: DEX.SOLIDLY_V2,
    vaultType: VaultType.MONOLITH,
    pid: undefined,
    gaugeAddress: '0xdcc1a6b6ae3aafe2a0fa7fd8a2a181671433332a',
    symbol0: 'TAROT',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '20000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x837d904a3799c0769079be9ecbaddf1abd4ccd6e',
    tokenAddress1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    uniswapV2PairAddress: '0x5f4a0f647754842912724411ec426933d3df5ada',
    collateralAddress: '0x9ac1c7240aeeca3a424af0798b27698c040e5ccf',
    borrowableAddress0: '0x15c7d9f1112f26297133f0698f6efc60957f0e04',
    borrowableAddress1: '0xb665967618b34b2d5fae2c8329ec86c76c6eb45f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1cafcb9f3b5a152b1553bc2c688ba6a18054b653',
    tarotRouterAddress: '0x3b47f0e57a6c537ecc198833728ccd5d02e29771'
  },
  '0x6caba0a46d83b12f618c87dd1b0a1caa194e8e9a': {
    chainId: 1,
    lendingPoolAddress: '0x6caba0a46d83b12f618c87dd1b0a1caa194e8e9a',
    isTarotVault: true,
    dex: DEX.SOLIDLY_V2,
    vaultType: VaultType.MONOLITH,
    pid: undefined,
    gaugeAddress: '0x84674cffb6146d19b986fc88ec70a441b570a45b',
    symbol0: 'SOLID',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '20000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x777172d858dc1599914a1c4c6c9fc48c99a60990',
    tokenAddress1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    uniswapV2PairAddress: '0x642431623ae5d73c19fc931aaea0d4677303880c',
    collateralAddress: '0xae8e1d513a529987e7950a1a091cdb80bde60b8a',
    borrowableAddress0: '0xbdd4e2f6135539b96ccea8f8779bfc5f20c8635c',
    borrowableAddress1: '0x4739f6831d1a7c39516e6eba1178e5579a178a61',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1cafcb9f3b5a152b1553bc2c688ba6a18054b653',
    tarotRouterAddress: '0x3b47f0e57a6c537ecc198833728ccd5d02e29771'
  },
  '0xeaaf4f3aaf697becd881a070e4fa25dd396b7e81': {
    chainId: 1,
    lendingPoolAddress: '0xeaaf4f3aaf697becd881a070e4fa25dd396b7e81',
    isTarotVault: true,
    dex: DEX.SOLIDLY_V2,
    vaultType: VaultType.MONOLITH,
    pid: undefined,
    gaugeAddress: '0x6b022be53606a3dd3c5947ca436cfa6169e9ecee',
    symbol0: 'moSOLID',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '20000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x848578e351d25b6ec0d486e42677891521c3d743',
    tokenAddress1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    uniswapV2PairAddress: '0xf5e4b5f58703582ad142b15e6c303dbb3cf7de8a',
    collateralAddress: '0x3e9471c2a4174c695ce7c0238621d8200f17a3eb',
    borrowableAddress0: '0x1fa6b1f047c0d964c986a272318b9367ff0e4d36',
    borrowableAddress1: '0x08e48a587595122f4f4589ea31e5fe88c2910c50',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x1cafcb9f3b5a152b1553bc2c688ba6a18054b653',
    tarotRouterAddress: '0x3b47f0e57a6c537ecc198833728ccd5d02e29771'
  },

  // Kava pools
  '0x738114fc34d7b0d33f13d2b5c3d44484ec85c7f1': {
    chainId: 2222,
    lendingPoolAddress: '0x738114fc34d7b0d33f13d2b5c3d44484ec85c7f1',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0xbf257c745a8ada0bd8f682812f5fd95fa42bdf66',
    symbol0: 'TAROT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x165dbb08de0476271714952c3c1f068693bd60d7',
    tokenAddress1: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    uniswapV2PairAddress: '0x4b22d524f5301fb7c042a81c87687fd420b980e8',
    collateralAddress: '0xe93b3532252a4e954eb71148867017ab8ebacb17',
    borrowableAddress0: '0x915352a98f8730e72cd923d24d4aa895c271c7f9',
    borrowableAddress1: '0xc97b023a935200ab7fd0f7fd07e2550e622d6445',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0x2b973265fbd59ef0e14203ec229169d4a6ec31e3': {
    chainId: 2222,
    lendingPoolAddress: '0x2b973265fbd59ef0e14203ec229169d4a6ec31e3',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0xa250a3b6a5e5e8b398092537951f8bd80639ed5c',
    symbol0: 'VARA',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xe1da44c0da55b075ae8e2e4b6986adc76ac77d73',
    tokenAddress1: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    uniswapV2PairAddress: '0x9bf1e3ee61cbe5c61e520c8beff45ed4d8212a9a',
    collateralAddress: '0x752466d58858ecd367edbdc917ca2d304f201e82',
    borrowableAddress0: '0xdfcaa2dd2722c91ff6c0a051f47697e92911c077',
    borrowableAddress1: '0x7b1a32bd4390b9948b8b0d4159254951fd3d52e1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0x0de0fd789ec103bc076528e026f0e96bee3c69dc': {
    chainId: 2222,
    lendingPoolAddress: '0x0de0fd789ec103bc076528e026f0e96bee3c69dc',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0xca0d15b4bb6ad730fe40592f9e25a2e052842c92',
    symbol0: 'WKAVA',
    symbol1: 'VARA',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b',
    tokenAddress1: '0xe1da44c0da55b075ae8e2e4b6986adc76ac77d73',
    uniswapV2PairAddress: '0x7d8100072ba0e4da8dc6bd258859a5dc1a452e05',
    collateralAddress: '0x23ba7f8c3264ecb1e970e940e2aa6d2724fb54c8',
    borrowableAddress0: '0xa01b9cc4860eea6e34c67a6198e6aed175cd8b56',
    borrowableAddress1: '0x5815117c38e4d9f3e9bd562695d0a50aa7974ab7',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0x109f3289665a8f034e2cacdbcfb678cabe09f1d5': {
    chainId: 2222,
    lendingPoolAddress: '0x109f3289665a8f034e2cacdbcfb678cabe09f1d5',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0x50cd5b513999b81dc3f4b70595629f7cb92fcd32',
    symbol0: 'WKAVA',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b',
    tokenAddress1: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    uniswapV2PairAddress: '0x5c27a0d0e6d045b5113d728081268642060f7499',
    collateralAddress: '0xd48c5a6bfb810dc6b08e33a76d055ea09fc623cd',
    borrowableAddress0: '0x35948018c9b7d615ef09c29672dee536fdead959',
    borrowableAddress1: '0x014bef3be6dfd20e9539fadb48bcec48bbfda865',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0xbc50b9f7f8a4ac5cfbb02d214239033dd5a35527': {
    chainId: 2222,
    lendingPoolAddress: '0xbc50b9f7f8a4ac5cfbb02d214239033dd5a35527',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0x46b9fcac1b698ae54b9c1d52a734338964e28aee',
    symbol0: 'MARE',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xd86c8d4279ccafbec840c782bcc50d201f277419',
    tokenAddress1: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    uniswapV2PairAddress: '0x0e1bc1939d977c676cd38cff4b7e411c32b6d3ce',
    collateralAddress: '0x3d07ee6f3f47aac46de17b158997aea72fa6030c',
    borrowableAddress0: '0xc887f8c6f944ea23c352db7872619ab268de489a',
    borrowableAddress1: '0x1f0441823595c214dcd3b6b6c7d3570ac8c89da3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0x49381f3442631e81f3f4d8228d7309778ab02e42': {
    chainId: 2222,
    lendingPoolAddress: '0x49381f3442631e81f3f4d8228d7309778ab02e42',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0xadd74b7f414123e6b56d154c2027fbd8afdc4634',
    symbol0: 'LQDR',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x6c2c113c8ca73db67224ef4d8c8dfcec61e52a9c',
    tokenAddress1: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    uniswapV2PairAddress: '0x766ca762fcc03f5070860857ff4054d258c74517',
    collateralAddress: '0xee2fa63dca85ae87280d52ee4a7019095a32b399',
    borrowableAddress0: '0xa1a0b9c82d0332480575460377f70d19aab77712',
    borrowableAddress1: '0xaa9272ce189d2ba62f820ebb3973c65da875e16b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0xd0605d69c75dc0fbe229fef91b3d2fdc565b91c4': {
    chainId: 2222,
    lendingPoolAddress: '0xd0605d69c75dc0fbe229fef91b3d2fdc565b91c4',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0x371d33963fb89ec9542a11ccf955b3a90391f99f',
    symbol0: 'WKAVA',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b',
    tokenAddress1: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
    uniswapV2PairAddress: '0xb593e0a2e93864ff5f75689dade29f5f6dec64ef',
    collateralAddress: '0x4b8f1b2a2d9a3e782c7c854280f8b23572ecb59f',
    borrowableAddress0: '0xcfb6e8a8c2999b04f4edca2cd5cc7a6b589d2a12',
    borrowableAddress1: '0xad8fb70711eb4c4b03723222cd36a19fc4907e02',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0x169e28cd2b5ca52cb9e0719ab577b6245da8eef2': {
    chainId: 2222,
    lendingPoolAddress: '0x169e28cd2b5ca52cb9e0719ab577b6245da8eef2',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0x3f8185cce4cb25f17f6be2b4ff653c45f0dd4fed',
    symbol0: 'WBTC',
    symbol1: 'ETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
    tokenAddress1: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
    uniswapV2PairAddress: '0xd9eef9881068141000b4257cceb72faaba7186f6',
    collateralAddress: '0xa391ae61456223393094807bc1c0fdb75d79f79d',
    borrowableAddress0: '0xef0e17f47bcf68b9d80ba665b7d0f56ccf096e65',
    borrowableAddress1: '0x3bfc10c7c85f0dc427bc3d6262429466d1b31bb6',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0x3f4410faa08c5dadbbb0984a3ecc12a7b6a45b1b': {
    chainId: 2222,
    lendingPoolAddress: '0x3f4410faa08c5dadbbb0984a3ecc12a7b6a45b1b',
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0xa27a1f03479cfe4b0b97fd8c772a84ad815c1946',
    symbol0: 'WBTC',
    symbol1: 'WKAVA',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
    tokenAddress1: '0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b',
    uniswapV2PairAddress: '0x304b111e0c9ccc8137d98807d15d56aad84bce77',
    collateralAddress: '0x19f14cb2b3849a67c0e2cb6fbe4ea53ccdff68b1',
    borrowableAddress0: '0x6ed892d2fcd41a2b92cebf94109d5695a7ea3565',
    borrowableAddress1: '0xebc30f3479bd53099b6495764e2a7bf51233bf1b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0x66034687678d6dd74d9502f878ff7ea86a7dbb9a': {
    chainId: 2222,
    lendingPoolAddress: '0x66034687678d6dd74d9502f878ff7ea86a7dbb9a',
    stable: true,
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0x89ede1b513502aa876f9eb660ede9802f4462cfa',
    symbol0: 'MAI',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xb84df10966a5d7e1ab46d9276f55d57bd336afc7',
    tokenAddress1: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    uniswapV2PairAddress: '0xb65d7381686a7829dc238abf785bb2dee313fb20',
    collateralAddress: '0x9762876e290c828f0cc38dc6076ab51b512ec5af',
    borrowableAddress0: '0xdca6962fe17ddc590916dbb25155b88a8e6b2021',
    borrowableAddress1: '0x7258df1778ec211194807dfe47eb87391acbcaa1',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x54950cae3d8513ea041066f31697903de5909f57',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },
  '0xefa8952a4ab8b210a5f1dd2a378ed3d1200cf64b': {
    chainId: 2222,
    lendingPoolAddress: '0xefa8952a4ab8b210a5f1dd2a378ed3d1200cf64b',
    stable: true,
    isTarotVault: true,
    dex: DEX.EQUILIBRE,
    vaultType: VaultType.EQUILIBRE,
    pid: undefined,
    gaugeAddress: '0xe45086f996e4473e0973392c1fe97b52575466cf',
    symbol0: 'DAI',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x765277eebeca2e31912c9946eae1021199b39c61',
    tokenAddress1: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    uniswapV2PairAddress: '0x9acad463537d5b4fb1d7637bb558e79d0441b96f',
    collateralAddress: '0x9efc79e0af447bd4c21e7c66ec6df58674a255e0',
    borrowableAddress0: '0xa760f51f0de50007c7ec4408c16e648343839573',
    borrowableAddress1: '0x5401bb45b5f8d509aca1005923b05164e6e0d276',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x54950cae3d8513ea041066f31697903de5909f57',
    tarotRouterAddress: '0x283e62cfe14b352db8e30a9575481dcbf589ad98'
  },

  // Canto pools
  '0xf0653f0b377a8859cc9e6c95180687c78d1ad9fc': {
    chainId: 7700,
    lendingPoolAddress: '0xf0653f0b377a8859cc9e6c95180687c78d1ad9fc',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x08df266da776538a8fab368116320f3cf7edc6a7',
    symbol0: 'TAROT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x2bf9b864cdc97b08b6d79ad4663e71b8ab65c45c',
    tokenAddress1: '0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd',
    uniswapV2PairAddress: '0x744c6f8abe326417d2b0e70d283889833eeaf029',
    collateralAddress: '0x6cfa3cf12f72079c729041127aa0bb385006d569',
    borrowableAddress0: '0x6b67ec11d61bb89a8102a24b03300d39454dadb4',
    borrowableAddress1: '0xb8c79eb2e2affbc407fbc0a01e014edca6d11d65',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x1ee159dfb82adee45b57ab2225beac6820e05331': {
    chainId: 7700,
    lendingPoolAddress: '0x1ee159dfb82adee45b57ab2225beac6820e05331',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x09b303fe939bec703a7c81087de0eca0042b0339',
    symbol0: 'WCANTO',
    symbol1: 'FLOW',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x826551890dc65655a0aceca109ab11abdbd7a07b',
    tokenAddress1: '0xb5b060055f0d1ef5174329913ef861bc3addf029',
    uniswapV2PairAddress: '0x2cc24302fa019c5a8f252afc9a69fcfbb8dd8d2f',
    collateralAddress: '0xffc15b373a975ff49708d9be84331fb28c6b91ef',
    borrowableAddress0: '0xa6ea88c4528f2d29bbd7fe803cb3d96946fa7447',
    borrowableAddress1: '0x2bfae01944a8f74bdc47f69453a5e230d7e0be61',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x94378b67f1d1a5befe9d81c332c977edfefe1f9b': {
    chainId: 7700,
    lendingPoolAddress: '0x94378b67f1d1a5befe9d81c332c977edfefe1f9b',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0xc2fccee6d3b736258a39ea8d08d5203a57fa6aa4',
    symbol0: 'NOTE',
    symbol1: 'WCANTO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4e71a2e537b7f9d9413d3991d37958c0b5e1e503',
    tokenAddress1: '0x826551890dc65655a0aceca109ab11abdbd7a07b',
    uniswapV2PairAddress: '0xb06ed5fe7c94467450a163d63f352f4a8f7eaa71',
    collateralAddress: '0x3eed4c3baac948991dc967a715b789bb1ac26b83',
    borrowableAddress0: '0x2ab189a821f423868ba2ad7476d2ca84cc70705d',
    borrowableAddress1: '0x0ea0c959ab53d5896daa77170a55031203e5a0df',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x256b3baa2737e1f8971adf9abd832a9d6e3a5bed': {
    chainId: 7700,
    lendingPoolAddress: '0x256b3baa2737e1f8971adf9abd832a9d6e3a5bed',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x4badbfa80c704264566ac254c270e750be1bd1e3',
    symbol0: 'NOTE',
    symbol1: 'FLOW',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4e71a2e537b7f9d9413d3991d37958c0b5e1e503',
    tokenAddress1: '0xb5b060055f0d1ef5174329913ef861bc3addf029',
    uniswapV2PairAddress: '0x7e79e7b91526414f49ea4d3654110250b7d9444f',
    collateralAddress: '0x28dfbd1dd6ab9c2c0f77ca7d6e9459cf4fa4b2e9',
    borrowableAddress0: '0xcc3a2a3c3c4e03fd197bb505794841d842a25bb0',
    borrowableAddress1: '0x56a5be222bcd81baa6db605b5e6bc9dfce38a843',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x7b4b64ae3f121053941ecb5a69d871ed93c05aac': {
    chainId: 7700,
    lendingPoolAddress: '0x7b4b64ae3f121053941ecb5a69d871ed93c05aac',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x610a58cd9ffb9bfedbdb39506d5a19d57ffa57c4',
    symbol0: 'WCANTO',
    symbol1: 'ATOM',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x826551890dc65655a0aceca109ab11abdbd7a07b',
    tokenAddress1: '0xeceeefcee421d8062ef8d6b4d814efe4dc898265',
    uniswapV2PairAddress: '0x04a68fd82344377d7c2dc3cdfa8235c907805577',
    collateralAddress: '0x0203b6698589a63307d8b6bfc385d6fa8ed04055',
    borrowableAddress0: '0x41275c2b376a8f304833b428bd51d4b891dc7228',
    borrowableAddress1: '0xc0cac734aac8034c78374b80e2977f5a7d8c0a4b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x9cefdc5c7d0b2926e272226e457fe49770e24cd7': {
    chainId: 7700,
    lendingPoolAddress: '0x9cefdc5c7d0b2926e272226e457fe49770e24cd7',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x771665ec0b9c4f0f4660c5d222800853cd3dd856',
    symbol0: 'NOTE',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4e71a2e537b7f9d9413d3991d37958c0b5e1e503',
    tokenAddress1: '0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd',
    uniswapV2PairAddress: '0x0c3b568b8d76273e4b8c7ae43ea2ced197b53bc8',
    collateralAddress: '0x69f465b3bccec0d64967347165df45088fcfbcae',
    borrowableAddress0: '0x51f8b0df4290de06d94b015e2d4c219e9e9b72e6',
    borrowableAddress1: '0x0d0470dd37c1330b4f85a89bf2cebe602d9e34dc',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x15f947251cb74edb587b5cf94c393bbeceff4604': {
    chainId: 7700,
    lendingPoolAddress: '0x15f947251cb74edb587b5cf94c393bbeceff4604',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x462742397f17274f8735ea60855ff0a8d713ae16',
    symbol0: 'ETH',
    symbol1: 'WCANTO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5fd55a1b9fc24967c4db09c513c3ba0dfa7ff687',
    tokenAddress1: '0x826551890dc65655a0aceca109ab11abdbd7a07b',
    uniswapV2PairAddress: '0x96d976892c6f01ab5c13e843b38bee90c2238f03',
    collateralAddress: '0x737f280f5cecea6af8594b2d4242b3f9e5021eda',
    borrowableAddress0: '0x3305eccc719384278582b40cc4ef95dedecc46c6',
    borrowableAddress1: '0x116e3178a857bc86cacdddce854a4662aae1b133',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0xc30b21cda66f58df863879f750d37774d0a4332e': {
    chainId: 7700,
    lendingPoolAddress: '0xc30b21cda66f58df863879f750d37774d0a4332e',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0xae1e35547ca778127f7c596df5ca50363706737e',
    symbol0: 'ETH',
    symbol1: 'FLOW',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5fd55a1b9fc24967c4db09c513c3ba0dfa7ff687',
    tokenAddress1: '0xb5b060055f0d1ef5174329913ef861bc3addf029',
    uniswapV2PairAddress: '0x57e8efa2639a4ca7069cd90f7e27092758271e6b',
    collateralAddress: '0xd4e174b7022f75341ef2633c2749004193360631',
    borrowableAddress0: '0xcc370f7f420d3e3297411db251f074f6271a3861',
    borrowableAddress1: '0xadf5652f39bb17e14dd04f626d9fc185e2d4c42b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x096df807a8a48139a7eff581c3bf1f5f45df5ede': {
    chainId: 7700,
    lendingPoolAddress: '0x096df807a8a48139a7eff581c3bf1f5f45df5ede',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x31096b98216cf1c730b41112ef2d9f9e19e38adf',
    symbol0: 'USDC',
    symbol1: 'WCANTO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd',
    tokenAddress1: '0x826551890dc65655a0aceca109ab11abdbd7a07b',
    uniswapV2PairAddress: '0x9d047638826096ec3abca3cda9973db19c89a786',
    collateralAddress: '0x8d2ce978b46a67bfd427e25936a3bf03901907c8',
    borrowableAddress0: '0x31121981a0e8618d114f6564f8e608289ffe7459',
    borrowableAddress1: '0xe1cc87271c24fed7e2f6f91d929f6969bfa84a16',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x0fa78f708de4a434a7c5a2cf3938ead7fda8bd5f': {
    chainId: 7700,
    lendingPoolAddress: '0x0fa78f708de4a434a7c5a2cf3938ead7fda8bd5f',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x0e20e21f8993d2b930c6881758526de080a0d234',
    symbol0: 'FLOW',
    symbol1: 'USDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xb5b060055f0d1ef5174329913ef861bc3addf029',
    tokenAddress1: '0xd567b3d7b8fe3c79a1ad8da978812cfc4fa05e75',
    uniswapV2PairAddress: '0x6f00840f81c41dc2f7c6f81eb2e3eaea973dbf5f',
    collateralAddress: '0xac7a6d4dd8527c1963116a289e88322dbd9285d3',
    borrowableAddress0: '0x058aedf4710f5e446074d0e3b2a15ef242dd2011',
    borrowableAddress1: '0xf98c0d3ca33bae442c000854485a3c0cbf8ff19d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0xfcb2c2d63478d6170e790d5c6867e90127c6a495': {
    chainId: 7700,
    lendingPoolAddress: '0xfcb2c2d63478d6170e790d5c6867e90127c6a495',
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x9bfd7c6de91d71a38904ee5c55b0510e77884e64',
    symbol0: 'USDC',
    symbol1: 'FLOW',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd',
    tokenAddress1: '0xb5b060055f0d1ef5174329913ef861bc3addf029',
    uniswapV2PairAddress: '0x2267daa9b7458f5cfe03d3485cc871800977c2c6',
    collateralAddress: '0x83f4fdf1070c974081769b23b37c1bbc107a2e7c',
    borrowableAddress0: '0x71a7e7c7fb4dcbe4b357f4af40c4ff4f0018d98d',
    borrowableAddress1: '0x7ccc00ba80080c0f6a6e85956191d14a49457460',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0xd432fe1860de3d14d342752ab07867447cda2085': {
    chainId: 7700,
    lendingPoolAddress: '0xd432fe1860de3d14d342752ab07867447cda2085',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0xfcb044da3705746f20cb6d93dc47c38acd8b08cb',
    symbol0: 'NOTE',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4e71a2e537b7f9d9413d3991d37958c0b5e1e503',
    tokenAddress1: '0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd',
    uniswapV2PairAddress: '0x5cbb5ed3c767d7bcc7f8796acf37a9d181233a2f',
    collateralAddress: '0xd8c6c0b6753a1b8695fd048c8ecd702410641991',
    borrowableAddress0: '0x4beaa037e354b93d27d9c84ccd02d13f9950a464',
    borrowableAddress1: '0x041c526f4c4454fcd01c92e810a094f823e1ddc3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },
  '0x8b41cc6aeec489e804a669f5c14b876e834a218d': {
    chainId: 7700,
    lendingPoolAddress: '0x8b41cc6aeec489e804a669f5c14b876e834a218d',
    stable: true,
    isTarotVault: true,
    dex: DEX.VELOCIMETER,
    vaultType: VaultType.VELOCIMETER,
    pid: undefined,
    gaugeAddress: '0x191c0150417b897a0c570c68aa7185c203cd73ea',
    symbol0: 'USDC',
    symbol1: 'USDT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '2893518000000',
    adjustSpeed1: '2893518000000',
    kinkUtilizationRate0: '0',
    kinkUtilizationRate1: '0',
    safetyMarginSqrt: '0',
    kinkUtilizationRateLower0: '800000000000000000',
    kinkUtilizationRateUpper0: '900000000000000000',
    kinkUtilizationRateLower1: '800000000000000000',
    kinkUtilizationRateUpper1: '900000000000000000',
    liquidationIncentive: '1010000000000000000',
    liquidationFee: '5000000000000000',
    stableSafetyMargin: '1080000000000000000',
    decimals0: 6,
    decimals1: 6,
    tokenAddress0: '0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd',
    tokenAddress1: '0xd567b3d7b8fe3c79a1ad8da978812cfc4fa05e75',
    uniswapV2PairAddress: '0x1086b26cf340801b26ca2df58d20ce688fd3e779',
    collateralAddress: '0xae035016b6d0a94c5cb7846931b2e2030deeaf22',
    borrowableAddress0: '0x620695c234a240e5bc9ac6606e3a08e469be196a',
    borrowableAddress1: '0x8bd10d3e0e3278f83a85da1d5ab80875df061269',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x82b3413d575aa93806308a04b53c78ae2037da11',
    tarotRouterAddress: '0xc20099a3f0728634c1136489074508be7b406d3a'
  },

  // Avax Pools
  '0x5feeb4cbc5e844530d67d07a71aea60395463fbe': {
    chainId: 43114,
    lendingPoolAddress: '0x5feeb4cbc5e844530d67d07a71aea60395463fbe',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0x8535928caa01e59acba0494a99372540a8b584ce',
    symbol0: 'WAVAX',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    tokenAddress1: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    uniswapV2PairAddress: '0xdf214f497beca7f550a74ceb89240fb185ce19b4',
    collateralAddress: '0x5bbf8f2a8562d6720ecd8d6e7070c2b21498739a',
    borrowableAddress0: '0x331c5b28f1ff8a5dc6744ba020b66897ff79e1dd',
    borrowableAddress1: '0x300750dc7f51eb604091bb2baaf3249ccc1f82d8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0x130c1d405eab0e98d62ee29f2c8d3c8c2af613bb': {
    chainId: 43114,
    lendingPoolAddress: '0x130c1d405eab0e98d62ee29f2c8d3c8c2af613bb',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0x6588fb65a8afe494b5854e88fcfd2f3fb9534e91',
    symbol0: 'TAROT',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x7f8d31a49212c62a11b6718aa0b612e129256553',
    tokenAddress1: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    uniswapV2PairAddress: '0x469bbe944aa42ac4b64dbda787bbe6946937b484',
    collateralAddress: '0xb7fadb5ef2ea673273df639fec5550932ab38594',
    borrowableAddress0: '0x9426e97d3baf0cdd9f6466c021077d52797196bb',
    borrowableAddress1: '0xa3c7a7c02bb827cc5f0d51b362860156104a7ca3',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0xe663ac3245e39f6961a6fbe68fe3489b7dc9dc9d': {
    chainId: 43114,
    lendingPoolAddress: '0xe663ac3245e39f6961a6fbe68fe3489b7dc9dc9d',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0x1768040d19c29303d467a40e624f171f6e069633',
    symbol0: 'WAVAX',
    symbol1: 'SNEK',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    tokenAddress1: '0xeeee99b35eb6af5e7d76dd846dbe4bcc0c60ca1d',
    uniswapV2PairAddress: '0x5b9a790e602adfa8c127c480a511ab9f90807595',
    collateralAddress: '0x6f829d4cfab8e4896aeac69c1f5685421b263cd4',
    borrowableAddress0: '0x888b2a6842a1b4204d22b46f160b726c9ea6bcc0',
    borrowableAddress1: '0x9addf6d680893f4a874fc03efe22acae139659ab',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0xb90a7ed6a7d681a0033753432be532db8b8c8e43': {
    chainId: 43114,
    lendingPoolAddress: '0xb90a7ed6a7d681a0033753432be532db8b8c8e43',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0x8322487ef8e2159d21aaf3bbb51826031821e1a9',
    symbol0: 'USDC',
    symbol1: 'SNEK',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    tokenAddress1: '0xeeee99b35eb6af5e7d76dd846dbe4bcc0c60ca1d',
    uniswapV2PairAddress: '0x8d979af11481e0bddfbb0ca7d6d8e20ec6855f00',
    collateralAddress: '0x700e44b5c53dc1558e4d9d7a842850abc413480a',
    borrowableAddress0: '0x37b5673eff669b7b4ace48836c828b03e8be861f',
    borrowableAddress1: '0x73aacac350875b240c10733d5eafa8365d109090',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834',
    poolDeactivated: true
  },
  '0x6c566df20b2b20b99fe1cb644c6b5dd047819407': {
    chainId: 43114,
    lendingPoolAddress: '0x6c566df20b2b20b99fe1cb644c6b5dd047819407',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0x0ce8a6af9a8cad60b0cb5e580ba7bb708b601e98',
    symbol0: 'BTC.b',
    symbol1: 'WAVAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x152b9d0fdc40c096757f570a51e494bd4b943e50',
    tokenAddress1: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    uniswapV2PairAddress: '0x3b7fe0276915bbe081c178d68be472286f501d59',
    collateralAddress: '0x0074fc3916ec2b7fe08b3a6eec21366c7ff60c90',
    borrowableAddress0: '0x112b6de4a37264c07265cf41d30949db849d85b3',
    borrowableAddress1: '0x40258b380cf5c3de3bdc98fc0afb3dc91875460f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0xcebf25777b7454bac7e8d18dcf22c63a30516726': {
    chainId: 43114,
    lendingPoolAddress: '0xcebf25777b7454bac7e8d18dcf22c63a30516726',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0xb5a69207c5c9308d4fe5db939f593833ee4c643f',
    symbol0: 'WETH.e',
    symbol1: 'WAVAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    tokenAddress1: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    uniswapV2PairAddress: '0x8d2d4464cb5c65cbda6188d6c7653e0a3ad28828',
    collateralAddress: '0x661fbb91e551f3dc0fa0214a6a9a11d4c4631355',
    borrowableAddress0: '0xa7fa3d87d06c2c4ef88ce8f75e3114f7fd46b04c',
    borrowableAddress1: '0x73e1cdb584e5a318ee678684890119f31f3f20d0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0x2899d668e87d7c02ad6c6232d0c5c1868e16ae5c': {
    chainId: 43114,
    lendingPoolAddress: '0x2899d668e87d7c02ad6c6232d0c5c1868e16ae5c',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0x32c80ecae6faac781d2f5bb6367b9de01734f931',
    symbol0: 'WAVAX',
    symbol1: 'DEUS',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    tokenAddress1: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    uniswapV2PairAddress: '0x81d230d1a8a9db0ca319a346c1412383f8af4764',
    collateralAddress: '0x4dacdeb34e63a537f6c4a7a2bc3c1158137e4095',
    borrowableAddress0: '0xed4a67340ea4ea4e07d59edddf7f26d61632620b',
    borrowableAddress1: '0x0f2de63a5a9f1ddab523eea80a2d2627438cac41',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0x3eb84a6d719a48bd9aa91340776f9c53aab38244': {
    chainId: 43114,
    lendingPoolAddress: '0x3eb84a6d719a48bd9aa91340776f9c53aab38244',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0x4032176d2364b730a5bd461b9f194738a2179d9a',
    symbol0: 'OATH',
    symbol1: 'WAVAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x2c69095d81305f1e3c6ed372336d407231624cea',
    tokenAddress1: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    uniswapV2PairAddress: '0x4ea0708264b5419f16511ed2a1c46b0c55f38d1a',
    collateralAddress: '0x836bffd633a65e699d53953d10985419d4010387',
    borrowableAddress0: '0x75e4d431fde360a9c2eb76d4ff43b091f288dc32',
    borrowableAddress1: '0x5380c302c27c12c445fb4e2bec1e48ff9350c9d8',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0x92eed4fd4008c660b5adac9b788f5f861da93e86': {
    chainId: 43114,
    lendingPoolAddress: '0x92eed4fd4008c660b5adac9b788f5f861da93e86',
    isTarotVault: true,
    dex: DEX.SOLISNEK,
    vaultType: VaultType.SOLISNEK,
    pid: undefined,
    gaugeAddress: '0x2463cf8990e968a8210a31f5e66771dbf13fe5f8',
    symbol0: 'GRAIN',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x9df4ac62f9e435dbcd85e06c990a7f0ea32739a9',
    tokenAddress1: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    uniswapV2PairAddress: '0x656f946f413c08634fa489b4b40e09350daaa930',
    collateralAddress: '0x9d3baa86837b346bf258893b869d5721b94b14aa',
    borrowableAddress0: '0xf9a485cf533f37e966c27714e22c9868c9bab49c',
    borrowableAddress1: '0x3f5eae0ac1300b89d23647fe9fd64b4f2bc3280e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0xf9ce3a100c9e97fb206d194be946f970b620efa1': {
    chainId: 43114,
    lendingPoolAddress: '0xf9ce3a100c9e97fb206d194be946f970b620efa1',
    isTarotVault: true,
    dex: DEX.GLACIER,
    vaultType: VaultType.GLACIER,
    pid: undefined,
    gaugeAddress: '0xd9c41a9ec8c9136ff7748059dab1537d3d1e2fc1',
    symbol0: 'WAVAX',
    symbol1: 'USDT.e',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    tokenAddress1: '0xc7198437980c041c805a1edcba50c1ce5db95118',
    uniswapV2PairAddress: '0x5a5df1a7d9a35188243115dd9b6ce3b59b7f3a46',
    collateralAddress: '0xf7db6a98dff1cb5974e87fb77a9f2e95b4b0ab13',
    borrowableAddress0: '0x3fc4f0ea72a72cf1452f5b360807406013d00532',
    borrowableAddress1: '0xabdd9dd4b505d5f6da69e9e30e44f663def5ddea',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0x60a6a2b68cfdc3861d99c6913558b54bf8f1eaae': {
    chainId: 43114,
    lendingPoolAddress: '0x60a6a2b68cfdc3861d99c6913558b54bf8f1eaae',
    isTarotVault: true,
    dex: DEX.GLACIER,
    vaultType: VaultType.GLACIER,
    pid: undefined,
    gaugeAddress: '0x2e0d32c5d98e0007c95eed54d450d2e8155edf48',
    symbol0: 'WETH.e',
    symbol1: 'USDT.e',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    tokenAddress1: '0xc7198437980c041c805a1edcba50c1ce5db95118',
    uniswapV2PairAddress: '0x0717c121d012e46a429a488eba1db22ad1051fdd',
    collateralAddress: '0x6756137cacbc0285c44295a6496eef42d7de326f',
    borrowableAddress0: '0x19fe6fd51e2cdb6db9bc3f245263d7c240ebcfac',
    borrowableAddress1: '0xc41a1d2f9ef39cf432c41c0c54d8008c821425ba',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0x434043d76a31e7093be5f602e862128e3a88e3da': {
    chainId: 43114,
    lendingPoolAddress: '0x434043d76a31e7093be5f602e862128e3a88e3da',
    isTarotVault: true,
    dex: DEX.GLACIER,
    vaultType: VaultType.GLACIER,
    pid: undefined,
    gaugeAddress: '0xc0d0b21bff732ece3ad7f8502b58741edb48d4d4',
    symbol0: 'GMX',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x62edc0692bd897d2295872a9ffcac5425011c661',
    tokenAddress1: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    uniswapV2PairAddress: '0x32e3f290cd0dbe930fa286f0c2a9a958b3a03e30',
    collateralAddress: '0x5defe6b00044a74bdc0a23d5652b903288800144',
    borrowableAddress0: '0x97fbe741b456798f3828d93e2c1a9960af8322d1',
    borrowableAddress1: '0x3d31bf855159ed9b150f228e754f4d964c011b0f',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0x25d7f505a58d65c07d23cef2b85683dc96fa7443': {
    chainId: 43114,
    lendingPoolAddress: '0x25d7f505a58d65c07d23cef2b85683dc96fa7443',
    isTarotVault: true,
    dex: DEX.GLACIER,
    vaultType: VaultType.GLACIER,
    pid: undefined,
    gaugeAddress: '0xa4c42a2c93abdbe9cea8b40008967d66a7e23eb0',
    symbol0: 'BTC.b',
    symbol1: 'WAVAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 8,
    decimals1: 18,
    tokenAddress0: '0x152b9d0fdc40c096757f570a51e494bd4b943e50',
    tokenAddress1: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    uniswapV2PairAddress: '0x18947bf710e9dddea8f7c33e2c9b93a6482be6a2',
    collateralAddress: '0x142e1f14f7c013e284c180ec98f6ef1441427103',
    borrowableAddress0: '0x393363064e5d39709f1e31f5a93f0a1f0ebb1660',
    borrowableAddress1: '0xfbdc8366f0df057d761ec5daa23cc5e78aad6cff',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0xb26f30394493dd4242b3b25d4cac1b357958cdbb': {
    chainId: 43114,
    lendingPoolAddress: '0xb26f30394493dd4242b3b25d4cac1b357958cdbb',
    isTarotVault: true,
    dex: DEX.GLACIER,
    vaultType: VaultType.GLACIER,
    pid: undefined,
    gaugeAddress: '0xeb51de0a028f01e39a4718efcbafb6a694209a57',
    symbol0: 'OATH',
    symbol1: 'WETH.e',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x2c69095d81305f1e3c6ed372336d407231624cea',
    tokenAddress1: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    uniswapV2PairAddress: '0xe2e6b65119576fdd56ed12caec22acdf89c20581',
    collateralAddress: '0xcd3c2e5cce0e4d6fa24058fb9a090a436c205141',
    borrowableAddress0: '0x099fb64a7950338e2887cace49e402c7923a1769',
    borrowableAddress1: '0x502e1cb55d01276c003e783b532a1551a841ce06',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },
  '0x777712c225f33f32747d2e5555802c438d496fdb': {
    chainId: 43114,
    lendingPoolAddress: '0x777712c225f33f32747d2e5555802c438d496fdb',
    isTarotVault: true,
    dex: DEX.GLACIER,
    vaultType: VaultType.GLACIER,
    pid: undefined,
    gaugeAddress: '0xb8ef1caf4a1a0c3d69724657b179c8836da1737d',
    symbol0: 'GLCR',
    symbol1: 'WAVAX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x3712871408a829c5cd4e86da1f4ce727efcd28f6',
    tokenAddress1: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    uniswapV2PairAddress: '0x2071a39da7450d68e4f4902774203df208860da2',
    collateralAddress: '0x9b1ca5943c08dcafab38720d58a47f7fa8306ba4',
    borrowableAddress0: '0xa366e8ce36b06f73a179049bf9e8bd4e2bae7e92',
    borrowableAddress1: '0xfc0ac3c749f14500b1b3b5183ddbfa4a51784217',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834'
  },

  // Polygon Pools
  /*
  '0xf06de0368e21ef8c3e3d854791bae71d322fe507': {
    chainId: 137,
    lendingPoolAddress: '0xf06de0368e21ef8c3e3d854791bae71d322fe507',
    isTarotVault: true,
    dex: DEX.SATIN,
    vaultType: VaultType.SATIN,
    pid: undefined,
    gaugeAddress: '0xd54611ee103af71579815784e026a7be0c7c5dd2',
    symbol0: 'CASH',
    symbol1: 'SATIN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x80487b4f8f70e793a81a42367c225ee0b94315df',
    tokenAddress1: '0x9fc3104f6fc188fee65c85bbc4b94a48282ae76d',
    uniswapV2PairAddress: '0xdc342a4c1108a2a1f34a3036a40e04627b923067',
    collateralAddress: '0x57aae707ad5a8b67bad3b0ada72b084472d6903e',
    borrowableAddress0: '0x82fb3a63295c69f279ac565d0c370e88e321c7ae',
    borrowableAddress1: '0x79628b82160bc85c6b9bf54228ebf74e899355de',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834',
    poolDeactivated: true
  },
  */
  '0x52a9e79a70b90cb51a704c575d19114ebf7f3a6f': {
    chainId: 137,
    lendingPoolAddress: '0x52a9e79a70b90cb51a704c575d19114ebf7f3a6f',
    isTarotVault: true,
    dex: DEX.SATIN,
    vaultType: VaultType.SATIN,
    pid: undefined,
    gaugeAddress: '0x2bca533cfa803eca9e18b2b49c1be4e386f1d47f',
    symbol0: 'CASH',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x80487b4f8f70e793a81a42367c225ee0b94315df',
    tokenAddress1: '0xd23ed8ca350ce2631f7ecdc5e6bf80d0a1debb7b',
    uniswapV2PairAddress: '0x3ccc95b04fcd8ee99f7989ef4e5aaee4e3bc9c30',
    collateralAddress: '0x1045de3079784fbf96c83cd81f2694dfe0c11f99',
    borrowableAddress0: '0x9977056d87f0470fd74185797dafd4337273bca0',
    borrowableAddress1: '0x0f718022f8681e75e034bf3a3d02f713b53f9a3d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834',
    poolDeactivated: true
  },
  /*
  '0xe11ff3b05a7d18f2a1dc17849860f74e55683703': {
    chainId: 137,
    lendingPoolAddress: '0xe11ff3b05a7d18f2a1dc17849860f74e55683703',
    isTarotVault: true,
    dex: DEX.SATIN,
    vaultType: VaultType.SATIN,
    pid: undefined,
    gaugeAddress: '0x1805409957dba622a450d6302885afbf894bd7b9',
    symbol0: 'WMATIC',
    symbol1: 'CASH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    tokenAddress1: '0x80487b4f8f70e793a81a42367c225ee0b94315df',
    uniswapV2PairAddress: '0xef75a4eca52bee51fbf5596e65a681523ae0e69c',
    collateralAddress: '0x0b1d3d9664d78e72d7e829f281e9bea753b21a3a',
    borrowableAddress0: '0xc33c4090f7bc94de8af310314bc3ba63353ba4ce',
    borrowableAddress1: '0x2c8fd81c19c8201c4f1b86b2321c284e4000159b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834',
    poolDeactivated: true
  },
  '0xa55249f83c0ff4abd380b990e69f748b3ec23b9e': {
    chainId: 137,
    lendingPoolAddress: '0xa55249f83c0ff4abd380b990e69f748b3ec23b9e',
    isTarotVault: true,
    dex: DEX.SATIN,
    vaultType: VaultType.SATIN,
    pid: undefined,
    gaugeAddress: '0x5bcda73d8f928da2ec79cebdab3bd11664b23fc8',
    symbol0: 'WETH',
    symbol1: 'CASH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    tokenAddress1: '0x80487b4f8f70e793a81a42367c225ee0b94315df',
    uniswapV2PairAddress: '0x9d12cd4b821f0d29924baacb79dea6f0bab1446a',
    collateralAddress: '0x35236563160b3d4e7568571670ecb7f2e26ab6cb',
    borrowableAddress0: '0xef5b25a7e8412ebf0a005b790cd57cf4c2cdeaaa',
    borrowableAddress1: '0x80a4332957fea0d10b6d55e38163e5a23824a9d5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834',
    poolDeactivated: true
  },
  */
  '0xbce9b15019368ea45adbb1c58c8670be8cb630b5': {
    chainId: 137,
    lendingPoolAddress: '0xbce9b15019368ea45adbb1c58c8670be8cb630b5',
    isTarotVault: true,
    dex: DEX.SATIN,
    vaultType: VaultType.SATIN,
    pid: undefined,
    gaugeAddress: '0xbc16e9eb2796c906eb9e7183a68eb6433b65755c',
    symbol0: 'WMATIC',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    tokenAddress1: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    uniswapV2PairAddress: '0x4c6e0ebca60a15612f3f420c4d7ef95ef47c93e3',
    collateralAddress: '0x6186149e4318eb74ae28c396b1d325bef9407576',
    borrowableAddress0: '0x87033cac883367faa058e84664d3b341339ee1f7',
    borrowableAddress1: '0x07ba0e2f6b6716074f0265b3779660d408cfb697',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834',
    poolDeactivated: true
  },
  '0xe5a3e811ecaea9e70566fd461cfa48d32a52052d': {
    chainId: 137,
    lendingPoolAddress: '0xe5a3e811ecaea9e70566fd461cfa48d32a52052d',
    isTarotVault: true,
    dex: DEX.SATIN,
    vaultType: VaultType.SATIN,
    pid: undefined,
    gaugeAddress: '0x2631126b7cf92572041a6ccdebf1cfe0e8678386',
    symbol0: 'LQDR',
    symbol1: 'CASH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x434e7bbbc9ae9f4ffade0b3175fef6e8a4a1c505',
    tokenAddress1: '0x80487b4f8f70e793a81a42367c225ee0b94315df',
    uniswapV2PairAddress: '0xf0293deacf36e4bcdbf88b288a95e8579fd4d1f8',
    collateralAddress: '0x4e672abb03dc24f21ad1d239a29271364be3bcea',
    borrowableAddress0: '0x8af231c0bc17922bb60c8ef031c3f607eb445c2d',
    borrowableAddress1: '0x830a3e27ed23cd8469fe33f3bd5f09150f241acc',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x36df0a76a124d8b2205fa11766ec2eff8ce38a35',
    tarotRouterAddress: '0x6108feaa628155b073150f408d0b390ec3121834',
    poolDeactivated: true
  },

  // zkSync Era Pools
  '0x403e0a0f29ef66c97e8a0ff93e1410b1ba57544e': {
    chainId: 324,
    lendingPoolAddress: '0x403e0a0f29ef66c97e8a0ff93e1410b1ba57544e',
    isTarotVault: true,
    dex: DEX.VELOCORE,
    vaultType: VaultType.VELOCORE,
    pid: undefined,
    gaugeAddress: '0xb3120ad6c3285fc4e422581d9ad003277802cc47',
    symbol0: 'USDC',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4',
    tokenAddress1: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
    uniswapV2PairAddress: '0xcd52cbc975fbb802f82a1f92112b1250b5a997df',
    collateralAddress: '0xd051ff73390618863968153830458d905768b23a',
    borrowableAddress0: '0x161bd1794431b539c9c751a8b8fba6bace7859d9',
    borrowableAddress1: '0x563cb1233cad5f996e281338d54bfc282efbc8db',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xf450b51fb2e1e4f05daf9cf7d9bb97714540b4f4',
    tarotRouterAddress: '0x61bb845a4797a6ddd2bae9f0926a2019d7556aad'
  },
  '0x25739e9fd1a0575aff9b589da053919a9036890f': {
    chainId: 324,
    lendingPoolAddress: '0x25739e9fd1a0575aff9b589da053919a9036890f',
    isTarotVault: true,
    dex: DEX.VELOCORE,
    vaultType: VaultType.VELOCORE,
    pid: undefined,
    gaugeAddress: '0xcb6ad4a0c25bddad2e99ee51fe468b6cdb4b2576',
    symbol0: 'WETH',
    symbol1: 'VC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
    tokenAddress1: '0x85d84c774cf8e9ff85342684b0e795df72a24908',
    uniswapV2PairAddress: '0x80ab452b8ba46722029a69308ac52c0897d3a855',
    collateralAddress: '0xfcc96b5143cc98311bc1538f1629bb502d6393e9',
    borrowableAddress0: '0xd0fa9597960fc71ecba08b28123b230342d16710',
    borrowableAddress1: '0x181a9d8f7d38259a065effb1a12a0f3bc0993cd4',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xf450b51fb2e1e4f05daf9cf7d9bb97714540b4f4',
    tarotRouterAddress: '0x61bb845a4797a6ddd2bae9f0926a2019d7556aad'
  },

  // Base Pools
  '0x042c37762d1d126bc61eac2f5ceb7a96318f5db9': {
    chainId: 8453,
    lendingPoolAddress: '0x042c37762d1d126bc61eac2f5ceb7a96318f5db9',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0x4f09bab2f0e15e2a078a227fe1537665f55b8360',
    symbol0: 'USDC',
    symbol1: 'AERO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
    tokenAddress1: '0x940181a94a35a4569e4529a3cdfb74e38fd98631',
    uniswapV2PairAddress: '0x6cdcb1c4a4d1c3c6d054b27ac5b77e89eafb971d',
    collateralAddress: '0x801ee9d74d9d3caf5a7b023d1e73c71a291f7392',
    borrowableAddress0: '0xb72b27daf51d83b238c43f7d7ce6b461a774249b',
    borrowableAddress1: '0x51f51ccf2f17afec75f79d2a4fb0ac289dcc385b',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0x1f45b3b3fdd4ef399d153a5927300b455f4283d5': {
    chainId: 8453,
    lendingPoolAddress: '0x1f45b3b3fdd4ef399d153a5927300b455f4283d5',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0x9a202c932453fb3d04003979b121e80e5a14ee7b',
    symbol0: 'AERO',
    symbol1: 'USDbC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x940181a94a35a4569e4529a3cdfb74e38fd98631',
    tokenAddress1: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
    uniswapV2PairAddress: '0x2223f9fe624f69da4d8256a7bcc9104fba7f8f75',
    collateralAddress: '0xe99df122743fb5d0fd80913af9a49dfce64132c5',
    borrowableAddress0: '0x08383d1f85e1c5f6f1b08f005fb48c93a41ca2c7',
    borrowableAddress1: '0xbe1f1948723c93457361893a7da50125e2b0aba0',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0x6c0ee0072ef29d32e9c38c3f8d7c8b7e888230b5': {
    chainId: 8453,
    lendingPoolAddress: '0x6c0ee0072ef29d32e9c38c3f8d7c8b7e888230b5',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0x96a24ab830d4ec8b1f6f04ceac104f1a3b211a01',
    symbol0: 'WETH',
    symbol1: 'AERO',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x940181a94a35a4569e4529a3cdfb74e38fd98631',
    uniswapV2PairAddress: '0x7f670f78b17dec44d5ef68a48740b6f8849cc2e6',
    collateralAddress: '0x73c6cce04390534298cc9df192faf362018a6b73',
    borrowableAddress0: '0xb4ba739ae200d2cbc65930f4589383e9513497c0',
    borrowableAddress1: '0x4d12d8a31cabb91d5354c657531ab967b5bea835',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0xb556ee2761f5d2887b8f35a7dda367abd20503bf': {
    chainId: 8453,
    lendingPoolAddress: '0xb556ee2761f5d2887b8f35a7dda367abd20503bf',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0xa81dac2e9caa218fcd039d7cedeb7847cf362213',
    symbol0: 'WETH',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xf544251d25f3d243a36b07e7e7962a678f952691',
    uniswapV2PairAddress: '0x2d25e0514f23c6367687de89bd5167dc754d4934',
    collateralAddress: '0xbdd0ad2dc994d362fdea3ed54044caa1817f7f46',
    borrowableAddress0: '0x271dbacca7b447db75d4751ecb7fc3dab4910916',
    borrowableAddress1: '0xc7703c21f25251588bb7a6f5d8890b09c0bc469a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0x776236aead8a58ac9ec3cf214cda3c6335f46b2d': {
    chainId: 8453,
    lendingPoolAddress: '0x776236aead8a58ac9ec3cf214cda3c6335f46b2d',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0x65b4a4b9813e37da640bbef8abdd8e47100be5f8',
    symbol0: 'AERO',
    symbol1: 'TAROT',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x940181a94a35a4569e4529a3cdfb74e38fd98631',
    tokenAddress1: '0xf544251d25f3d243a36b07e7e7962a678f952691',
    uniswapV2PairAddress: '0x7810f8f3253ced11e8c56c6c8497f3134055d44c',
    collateralAddress: '0x7b6fedabf1f3fc0d030f86af8229f78a304e1b3a',
    borrowableAddress0: '0x1d63c27367dd5a8c86b59934645df46d5486240f',
    borrowableAddress1: '0xe46b5c98a03b31faaa16562f0a5c3191610e2e6d',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0x8880b9933c349ac7167a1a4111a9d687b796b172': {
    chainId: 8453,
    lendingPoolAddress: '0x8880b9933c349ac7167a1a4111a9d687b796b172',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0xeca7ff920e7162334634c721133f3183b83b0323',
    symbol0: 'WETH',
    symbol1: 'USDbC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
    uniswapV2PairAddress: '0xb4885bc63399bf5518b994c1d0c153334ee579d0',
    collateralAddress: '0xd47803f553a3b316f45ada7cf68b4060b933148f',
    borrowableAddress0: '0x90c957dea917fedaf5a4f317fe61072785c40653',
    borrowableAddress1: '0x94db4c0bc37e90aedbf6605a3ac8c95a159a7691',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0x55f64792ce23bc757a6b91de84ec067024872e0a': {
    chainId: 8453,
    lendingPoolAddress: '0x55f64792ce23bc757a6b91de84ec067024872e0a',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0xdf9d427711cce46b52feb6b2a20e4aeaea12b2b7',
    symbol0: 'cbETH',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22',
    tokenAddress1: '0x4200000000000000000000000000000000000006',
    uniswapV2PairAddress: '0x44ecc644449fc3a9858d2007caa8cfaa4c561f91',
    collateralAddress: '0x4df219c73ac5aa4175aa838b0b5645e2e86e6e68',
    borrowableAddress0: '0x484b6662742e037c6cfbbe9839bde0968f006aa1',
    borrowableAddress1: '0x3bea353be6a850fc1c02455be2e29fa805d11ce7',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0x1890c783ebbe71201914af1e92d3c114f2813693': {
    chainId: 8453,
    lendingPoolAddress: '0x1890c783ebbe71201914af1e92d3c114f2813693',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0x83fc503345dcde6197b2bd8eaa82ccb4b737be40',
    symbol0: 'GB',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x2af864fb54b55900cd58d19c7102d9e4fa8d84a3',
    tokenAddress1: '0x4200000000000000000000000000000000000006',
    uniswapV2PairAddress: '0x284ddada0b71f2d0d4e395b69b1013dbf6f3e6c1',
    collateralAddress: '0x9779832b5f8e9ab597ea5257fec50fca956b5473',
    borrowableAddress0: '0xcbb57bedb492eb17d85fceafdfc28780f768e54c',
    borrowableAddress1: '0x93e3af1734fb1bfecfcb07ea574438f84c8f0f5e',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    poolDeactivated: true,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0x750765780c049c0ade75832cf008916c0ffcab48': {
    chainId: 8453,
    lendingPoolAddress: '0x750765780c049c0ade75832cf008916c0ffcab48',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0x519bbd1dd8c6a94c46080e24f316c14ee758c025',
    symbol0: 'WETH',
    symbol1: 'USDC',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1020000000000000000',
    liquidationFee: '10000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 6,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
    uniswapV2PairAddress: '0xcdac0d6c6c59727a65f871236188350531885c43',
    collateralAddress: '0x89e3dfc385f74fe941fd4ef7ac994f1bdc9e6c37',
    borrowableAddress0: '0x686f4f27163750b07085e2e6a87e294e9e6b7218',
    borrowableAddress1: '0x8fb8e02fce8eb1ade5213564140090df7b5ab1b5',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0xfb5d7540faf21a3a0db0da41fd2fe923f93c74ea': {
    chainId: 8453,
    lendingPoolAddress: '0xfb5d7540faf21a3a0db0da41fd2fe923f93c74ea',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0xe157d5a0c9a533ebbdeadb43633ce76d61816778',
    symbol0: 'WETH',
    symbol1: 'TKN',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0x78b3c724a2f663d11373c4a1978689271895256f',
    uniswapV2PairAddress: '0x1a9f461a371559f82976fa18c46a6a0d29f131d0',
    collateralAddress: '0xd2f5cccec564f56bfcc8a61faee9c80afae281db',
    borrowableAddress0: '0x6b3e5a7e2774c5158d619aa28845d64d90a3926c',
    borrowableAddress1: '0x5118d6677618973a6f2744db243fd1c7184d1868',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },
  '0xc79ccab2f9693725e69f67bf357219e8a794f955': {
    chainId: 8453,
    lendingPoolAddress: '0xc79ccab2f9693725e69f67bf357219e8a794f955',
    isTarotVault: true,
    dex: DEX.AERODROME,
    vaultType: VaultType.AERODROME,
    pid: undefined,
    gaugeAddress: '0xd4ecf20068e5fa849abe62f9523a90f8c073f203',
    symbol0: 'WETH',
    symbol1: 'OX',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x4200000000000000000000000000000000000006',
    tokenAddress1: '0xba0dda8762c24da9487f5fa026a9b64b695a07ea',
    uniswapV2PairAddress: '0xe140c5930889c4064a3d11b17bb04fe7224a5cdc',
    collateralAddress: '0x6735b34963c9a27c764e423f5e101f6725d93bec',
    borrowableAddress0: '0x74705c3c2e01891044f8654445dccf6e28b51758',
    borrowableAddress1: '0x8e4d1af9b964cbcf455b38f8dbbf6afd5e47f9ca',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xeb5809eb0f79aab6e53e6374258b29a244dfc12d',
    tarotRouterAddress: '0xd7cabef2c1fd77a31c5ba97c724b82d3e25fc83c'
  },

  // Linea Pools
  '0x0ce87b9968a595233ed31f9e1eb31c349ec0afc5': {
    chainId: 59144,
    lendingPoolAddress: '0x0ce87b9968a595233ed31f9e1eb31c349ec0afc5',
    isTarotVault: true,
    dex: DEX.NILE,
    vaultType: VaultType.NILE,
    pid: undefined,
    gaugeAddress: '0xe480d379228f40dea32835f2c9b2c7a0cbd94495',
    symbol0: 'NILE',
    symbol1: 'WETH',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0xaaaac83751090c6ea42379626435f805ddf54dc8',
    tokenAddress1: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f',
    uniswapV2PairAddress: '0xfc6a4cd4007c3d24d37114d81a801a56f9536625',
    collateralAddress: '0xaad869f0e32c5a5008b9744dca4263ff074b6f82',
    borrowableAddress0: '0x374ea0e5096777bdef59fff451cdda3792870071',
    borrowableAddress1: '0x47910803bca6ca057a3f097f5d1e6a0cbf248248',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },
  '0x4a818a36168528c94d5953ad3e616daa260e2086': {
    chainId: 59144,
    lendingPoolAddress: '0x4a818a36168528c94d5953ad3e616daa260e2086',
    isTarotVault: true,
    dex: DEX.NILE,
    vaultType: VaultType.NILE,
    pid: undefined,
    gaugeAddress: '0x0476408b95675597941ff701066e7601f1638889',
    symbol0: 'USDC',
    symbol1: 'NILE',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 6,
    decimals1: 18,
    tokenAddress0: '0x176211869ca2b568f2a7d4ee941e073a821ee1ff',
    tokenAddress1: '0xaaaac83751090c6ea42379626435f805ddf54dc8',
    uniswapV2PairAddress: '0x9971dba6e18536b0415a6fbbf49d81ab12068ab7',
    collateralAddress: '0xb449562ecfcbe4b64e22a98f4323e1928e1fa22d',
    borrowableAddress0: '0x1d1cd87c689cd8a3c33608bfa45f078e5a29302f',
    borrowableAddress1: '0x9a925de39d7e8a838f78a3bf654ff46c8d6ddf0a',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0xb6193df61351736e5190bf1deb2e4f0769bd1bf2',
    tarotRouterAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55'
  },

  // Scroll Pools
  '0x6d6db1aa0f739880921ef5998f700e96cb48cb80': {
    chainId: 534352,
    lendingPoolAddress: '0x6d6db1aa0f739880921ef5998f700e96cb48cb80',
    isTarotVault: true,
    dex: DEX.NURI,
    vaultType: VaultType.NURI,
    pid: undefined,
    gaugeAddress: '0x52edc4da8423266d81f5cda947f2d1b438d479a1',
    symbol0: 'WETH',
    symbol1: 'NURI',
    shares0: '0',
    shares1: '0',
    reserveFactor0: '100000000000000000',
    reserveFactor1: '100000000000000000',
    adjustSpeed0: '50000000000000',
    adjustSpeed1: '50000000000000',
    kinkUtilizationRate0: '750000000000000000',
    kinkUtilizationRate1: '750000000000000000',
    liquidationIncentive: '1040000000000000000',
    liquidationFee: '40000000000000000',
    safetyMarginSqrt: '1414213560000000000',
    decimals0: 18,
    decimals1: 18,
    tokenAddress0: '0x5300000000000000000000000000000000000004',
    tokenAddress1: '0xaaae8378809bb8815c08d3c59eb0c7d1529ad769',
    uniswapV2PairAddress: '0x8ae1c699c9c62fac468a1e253e15b571b69a7ffe',
    collateralAddress: '0xd1471b1af68c48481ecc1c31e06994a8cc21ea3e',
    borrowableAddress0: '0x79e7674413855e01690cc7e078d64a71c1cf44c6',
    borrowableAddress1: '0x048740dd85d7b7b0dd0b6568093c0cc0fb9e6524',
    farmingPoolAddress0: '0x0000000000000000000000000000000000000000',
    farmingPoolAddress1: '0x0000000000000000000000000000000000000000',
    poolDisabled: false,
    tarotFactoryAddress: '0x2217aec3440e8fd6d49a118b1502e539f88dba55',
    tarotRouterAddress: '0x46fcde1b89d61f5cbfaab05c2914c367f8301f30'
  }
};

const iddpFromStorage = getLocalStorageItem('iddp');
const LENDING_POOL_DETAILS_MAP: {
  [pool: string]: LendingPoolDetails;
} = (iddpFromStorage && iddpFromStorage === 'include') ?
  LENDING_POOL_DETAILS_MAP_ROOT :
  Object.fromEntries(Object.entries(LENDING_POOL_DETAILS_MAP_ROOT).filter(([, poolDetail]) => !poolDetail.poolDeactivated && !poolDetail.poolDisabled));

const LENDING_POOL_IDS = Object.keys(LENDING_POOL_DETAILS_MAP);

const LENDING_POOLS_LIST = Object.values(LENDING_POOL_DETAILS_MAP);

const TAROT_LENDING_POOLS = [
  '0x7e635a33b48e3b61bc99fa33466568bc16eebfbf',
  '0x3cd9f7912b6b04b702232fbb3f12f94145b8a0e4',
  '0x3b749be6ca33f27e2837138ede69f8c6c53f9207',
  '0x80942a0066f72efff5900cf80c235dd32549b75d',
  // '0xafb56de46fba7bdcbc569eec0e6bb62b6e41f5f7',
  // '0xdffae68e2b7fb1f5438df5c771fd8ae73a9b4067',
  // '0xe46bf36bdcad0dce55bac4fa24aac9e8428a4f25',
  '0xf0653f0b377a8859cc9e6c95180687c78d1ad9fc',
  '0xf961f6e783566dae9f7a23f891b520e7832d7f4e',
  // '0x232cc9c8c27492775fb7d4532eedb774d0070764',
  // '0x1d1b8da1826cc7624b91c4c616ecd3fd1ca04b71',
  // '0xc7186b46c0a6e9930950fd113336fb45972dcd91',
  // '0xd06214f62d4624c0c531c8c27bf3263e72d868d4'
  // '0xec51a9f0dc97563147fb89176047283b9ae4cca9',
  // '0xc73adf1da8847f0d8a4d1c17d2d2b3861ea577ae',
  '0xd9c3e515e95c26cbaa3eeb5403699614728171b6',
  '0x092f6830eef16685ffb26efb783832d1cc4075d3',
  '0xaba34ce7221995a5dcc38f29068737aea7dfc9eb',
  // '0x63aa8566dc62ae89ce7efd8940636fc347cff61c',
  '0xa7d8b30859408400c849abe1d5ccc7bff5c6c3c0',
  '0x8d793311101468e3550b6a014bfbeeb7a8a8a3f1',
  // '0x1c59e2aed38d1a81e46625b721c33755b73ee679',
  '0x52a9e79a70b90cb51a704c575d19114ebf7f3a6f',
  '0x130c1d405eab0e98d62ee29f2c8d3c8c2af613bb',
  '0xb26c6b2b2c739d040513507df2bd5887786d1044',
  '0xb556ee2761f5d2887b8f35a7dda367abd20503bf',
  '0x776236aead8a58ac9ec3cf214cda3c6335f46b2d'
];

const DEPOSIT_FEE_BPS_MAP: {
  [pool: string]: BigNumberish;
} = {
  '0x4601ad6ffd55f15dadc639b8704b19dc4b7dfc91': '20', // BASED-MAI TOMBSWAP LP
  '0x6d368f3f94601cce3f9806381a6132feb0dd6272': '20' // TOMB-BASED TOMBSWAP LP
};

export const EMPTY_LENDING_POOL_DETAILS = {
  lendingPoolAddress: ZERO_ADDRESS,
  isTarotVault: false,
  dex: DEX.UNKNOWN,
  symbol0: '',
  symbol1: '',
  shares0: '',
  shares1: '',
  reserveFactor0: '',
  reserveFactor1: '',
  adjustSpeed0: '',
  adjustSpeed1: '',
  kinkUtilizationRate0: '',
  kinkUtilizationRate1: '',
  liquidationIncentive: '',
  safetyMarginSqrt: '',
  decimals0: 0,
  decimals1: 0,
  tokenAddress0: ZERO_ADDRESS,
  tokenAddress1: ZERO_ADDRESS,
  uniswapV2PairAddress: ZERO_ADDRESS,
  collateralAddress: ZERO_ADDRESS,
  borrowableAddress0: ZERO_ADDRESS,
  borrowableAddress1: ZERO_ADDRESS,
  farmingPoolAddress0: ZERO_ADDRESS,
  farmingPoolAddress1: ZERO_ADDRESS,
  poolDisabled: false,
  tarotFactoryAddress: ZERO_ADDRESS,
  tarotRouterAddress: ZERO_ADDRESS
};

export interface LendingPoolDetails {
  lendingPoolAddress: string;
  isTarotVault: boolean;
  dex: DEX;
  vaultType?: VaultType;
  pid?: number;
  gaugeAddress?: string;
  oxUserProxyAddress?: string;
  oxStakingAddress?: string;
  symbol0: string;
  symbol1: string;
  shares0: string;
  shares1: string;
  reserveFactor0: string;
  reserveFactor1: string;
  adjustSpeed0: string;
  adjustSpeed1: string;
  kinkUtilizationRate0: string;
  kinkUtilizationRate1: string;
  kinkUtilizationRateLower0?: string;
  kinkUtilizationRateLower1?: string;
  kinkUtilizationRateUpper0?: string;
  kinkUtilizationRateUpper1?: string;
  liquidationIncentive: string;
  liquidationFee?: string;
  safetyMarginSqrt: string;
  decimals0: number;
  decimals1: number;
  tokenAddress0: string;
  tokenAddress1: string;
  uniswapV2PairAddress: string;
  collateralAddress: string;
  borrowableAddress0: string;
  borrowableAddress1: string;
  farmingPoolAddress0: string;
  farmingPoolAddress1: string;
  poolDisabled?: boolean;
  poolDeactivated?: boolean;
  tarotFactoryAddress: string;
  tarotRouterAddress: string;
  chainId?: number;
  stable?: boolean;
  stableSafetyMargin?: string;
  isArbidexV1?: boolean;
}

export {
  LENDING_POOL_DETAILS_MAP,
  LENDING_POOL_IDS,
  LENDING_POOLS_LIST,
  TAROT_LENDING_POOLS,
  DEPOSIT_FEE_BPS_MAP
};
