/* eslint-disable @typescript-eslint/no-unused-vars */

import { Web3Provider } from '@ethersproject/providers';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWeb3React } from '@web3-react/core';
import clsx from 'clsx';
import InteractionButton, { ButtonState } from 'components/InteractionButton';
import TowerStakeInteractionModal from 'components/InteractionModal/TowerStakeInteractionModal';
import TowerUnstakeInteractionModal from 'components/InteractionModal/TowerUnstakeInteractionModal';
import QuestionHelper from 'components/QuestionHelper';
import TarotImage from 'components/UI/TarotImage';
import { SUPPLY_VAULTS } from 'config/web3/contracts/supply-vault';
import { DEX } from 'config/web3/dexs';
import { BigNumber } from 'ethers';
import { formatUnits, getAddress } from 'ethers/lib/utils';
import useClaimTowerReward from 'hooks/useClaimTowerReward';
import { useFullSupplyVaultsData, useTokenPriceMap } from 'hooks/useData';
import { useEffect, useState } from 'react';
import { Address, TEN_18, ZERO } from 'types/interfaces';
import { parse18 } from 'utils/big-amount';
import { formatAmount, formatPercentage, formatPercentageShort, formatUSD, formatUSDShort } from 'utils/format';

interface Props {
  towerPoolInfo: any;
  isDashboard: boolean;
  searchTerm?: string;
  hideDust?: boolean;
  account?: Address;
  hideInactive?: boolean;
}

interface PairCellCustomProps {
  tokenIconA: string;
  tokenIconB: string;
  symbolA?: string;
  symbolB?: string;
}

const TokenPairLabel = ({
  tokenIconA,
  tokenIconB,
  symbolA,
  symbolB,
  className
}: PairCellCustomProps & React.ComponentPropsWithRef<'div'>): JSX.Element => (
  <div
    className={clsx(
      'flex',
      'flex-shrink-0',
      'items-center',
      className
    )}>
    <TarotImage
      width={32}
      height={32}
      // TODO: could componentize
      className={clsx(
        'inline-block'
      )}
      src={tokenIconA}
      placeholder='/assets/images/default.png'
      error='/assets/images/default.png'
      alt='Token A' />
    <TarotImage
      width={32}
      height={32}
      className={clsx(
        'inline-block',
        '-ml-1.5'
      )}
      src={tokenIconB}
      placeholder='/assets/images/default.png'
      error='/assets/images/default.png'
      alt='Token B' />
    <span
      className={clsx(
        'font-medium',
        'text-textSecondary'
      )}>
      {symbolA}{symbolA && symbolB ? '/' : null}{symbolB}
    </span>
  </div>
);

const TimeLeft = ({
  periodFinish
}: { periodFinish: number; }): JSX.Element => {
  const calculateTimeLeft = () => {
    return Math.max(0, periodFinish - Math.floor(new Date().getTime() / 1000));
  };

  const padDHMS = (t: number) => {
    return `${t < 10 ? '0' : ''}${t}`;
  };

  const formatSecondsAsDHMS = (t: number) => {
    let n = t;
    const days = Math.floor(n / (24 * 3600));
    n %= (24 * 3600);
    const hours = Math.floor(n / 3600);
    n %= 3600;
    const minutes = Math.floor(n / 60);
    n %= 60;
    // const seconds = n;
    return (
      <>
        <span className='text-sm'>({padDHMS(days)}</span>
        <span className='text-xs'>D{`\u00A0`}</span>
        <span className='text-sm'>{padDHMS(hours)}</span>
        <span className='text-xs'>H{`\u00A0`}</span>
        <span className='text-sm'>{padDHMS(minutes)}</span>
        <span className='text-xs'>M</span>
        <span className='text-sm'>)</span>
        {/*
        <span className='text-base'>{padDHMS(seconds)}</span>
        <span className='text-sm'>S</span>
        */}
      </>);
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return formatSecondsAsDHMS(timeLeft);
};

const TowerPoolCard = ({
  towerPoolInfo,
  isDashboard
}: Props): JSX.Element => {
  const { chainId: web3ChainId } = useWeb3React<Web3Provider>();
  // const defaultChainId = useDefaultChainId();
  // const chainId = web3ChainId || defaultChainId;
  const { isSupplyVault, supplyVaultInfo, earnedList, accountBalance, tokenForTowerPool, underlyingForTokenForTowerPool, borrowableIndex, lendingPool, rewardInfoList } = towerPoolInfo;
  const fullSupplyVaultsData = useFullSupplyVaultsData() || {};
  const [showStakeModal, toggleStakeModal] = useState(false);
  const [showUnstakeModal, toggleUnstakeModal] = useState(false);
  const [claimRewardState, onClaimReward] = useClaimTowerReward(towerPoolInfo.towerPoolFactoryAddress, [towerPoolInfo.towerPool], [rewardInfoList.map((rewardInfo: { rewardTokenAddress: any; }) => rewardInfo.rewardTokenAddress)]);
  const tokenPriceMap = useTokenPriceMap();
  if (!tokenPriceMap) {
    return <></>;
  }
  const supplyVault = isSupplyVault && fullSupplyVaultsData[tokenForTowerPool.toLowerCase()];
  if (isSupplyVault && !supplyVault) {
    return <></>;
  }
  const vaultDetails = lendingPool && lendingPool.vaultDetails;
  const dex = lendingPool && lendingPool.lendingPool.dex;
  const stakeTokenPrice = tokenPriceMap[underlyingForTokenForTowerPool].priceUSD.value.mul(isSupplyVault ? supplyVault.shareValuedAsUnderlying.amount : lendingPool[`borrowableExchangeRate${borrowableIndex}`]).div(isSupplyVault ? BigNumber.from(10).pow(supplyVaultInfo.underlyingDecimals) : TEN_18);
  const totalStakedUSD = parseFloat(formatUnits(towerPoolInfo.totalSupply.mul(stakeTokenPrice).div(TEN_18), isSupplyVault ? supplyVaultInfo.decimals : lendingPool.lendingPool[`decimals${borrowableIndex}`]));
  const accountBalanceUSD = parseFloat(formatUnits(accountBalance.mul(stakeTokenPrice).div(TEN_18), isSupplyVault ? supplyVaultInfo.decimals : lendingPool.lendingPool[`decimals${borrowableIndex}`]));
  const rewardPrices = rewardInfoList.map((rewardInfo: { rewardTokenAddress: string; }) => tokenPriceMap[rewardInfo.rewardTokenAddress.toLowerCase()].priceUSD.value);
  const rewardAPRs: any[] = [];
  const pendingRewardsForAccountUSD: any[] = [];
  let totalPendingRewardsForAccountUSD = 0;
  rewardInfoList.forEach((rewardInfo: { rewardTokenSymbol: any; rewardRate: any; periodFinish: any; }, i: string | number) => {
    const pendingRewardsUSD = parseFloat(formatUnits(earnedList[i].mul(rewardPrices[i]).div(TEN_18), rewardInfoList[i].rewardTokenDecimals));
    pendingRewardsForAccountUSD.push(pendingRewardsUSD);
    totalPendingRewardsForAccountUSD += pendingRewardsUSD;
    if (towerPoolInfo.totalSupply.gt(0) && BigNumber.from(Math.floor(Date.now() / 1000)).lt(rewardInfo.periodFinish)) {
      rewardAPRs.push(TEN_18.mul(rewardInfo.rewardRate).mul(365).mul(24 * 60 * 60).mul(rewardPrices[i]).div(towerPoolInfo.totalSupply.mul(stakeTokenPrice).div(BigNumber.from(10).pow(isSupplyVault ? supplyVaultInfo.decimals : lendingPool.lendingPool[`decimals${borrowableIndex}`]))).div(BigNumber.from(10).pow(rewardInfoList[i].rewardTokenDecimals)));
    }
  });
  const totalAPR = (isSupplyVault ? parseFloat(formatUnits(supplyVault.supplyRate.amount.mul(365 * 24 * 60 * 60))) : lendingPool.supplyAPR[borrowableIndex]) + parseFloat(formatUnits(rewardAPRs.reduce((prev, curr) => prev.add(curr), BigNumber.from(0)), 18));
  const isDexTypeSolidly = isSupplyVault ? false : [DEX.SOLIDLY, DEX.SPIRIT_V2, DEX.VELODROME, DEX.VELODROME_V2, DEX.AERODROME, DEX.GLACIER, DEX.SOLISNEK, DEX.SATIN, DEX.EQUALIZERV2, DEX.EQUILIBRE, DEX.VELOCIMETER, DEX.VELOCORE, DEX.THENA, DEX.THENAV2, DEX.CHRONOS, DEX.XCAL, DEX.SOLIDLIZARD, DEX.RAMSES, DEX.NILE, DEX.NURI, DEX.STERLING, DEX.SOLIDLY_V2].includes(lendingPool.dex.id);

  return (
    <>
      <div>
        <div className='!mb-4 flex flex-col space-y-2 p-4'>
          <div className='flex flex-col space-y-2 justify-center items-center'>
            <TarotImage
              width={isSupplyVault ? 36 : 24}
              height={isSupplyVault ? 36 : 24}
              // TODO: could componentize
              className={clsx(
                'inline-block'
              )}
              src={isSupplyVault ? 'assets/images/token-icons/0x1F514A61bcde34F94Bc39731235690ab9da737F7.png' : vaultDetails ? vaultDetails.iconPath : dex.iconPath}
              placeholder='/assets/images/default.png'
              error='/assets/images/default.png'
              alt={isSupplyVault ? 'Optimism' : vaultDetails ? 'Vault' : 'Exchange'} />
            <span
              className={clsx(
                'text-textPrimary',
                'font-medium',
                isSupplyVault ? 'text-xl' : undefined
              )}>
              {isSupplyVault ? 'Optimism Rewards' : vaultDetails ? vaultDetails.vaultName : dex.dexName}
            </span>
            {lendingPool ?
              <div className='!mt-4'>
                <TokenPairLabel
                  tokenIconA={lendingPool.tokenIcon[0]}
                  tokenIconB={lendingPool.tokenIcon[1]}
                  className='justify-self-center' />
              </div> :
              <div className='!mb-6 !mt-0'>
                <div
                  className={clsx(
                    'inline-flex',
                    'items-center',
                    'px-1',
                    'pt-1',
                    'text-xs',
                    'xl:text-sm',
                    'font-medium',
                    'filter',
                    'text-textSecondary'
                  )}>
                  <a
                    target='_blank'
                    href='https://tarotfinance.medium.com/tarot-optimism-incentives-program-d79cef9f1914'
                    rel='noopener noreferrer'>
                Learn More <FontAwesomeIcon
                      icon={faExternalLinkAlt} />
                  </a>
                </div>
              </div>
            }
            {lendingPool &&
            <span
              className={clsx(
                'text-textPrimary',
                'font-medium'
              )}>
              {lendingPool.stable ? 'sAMM-' : isDexTypeSolidly ? 'vAMM-' : ''}{lendingPool.symbol[0]}/{lendingPool.symbol[1]}
            </span>
            }
            {lendingPool &&
            <span
              className={clsx(
                'text-textSecondary',
                'font-medium',
                'text-sm',
                'text-center'
              )}>
              {lendingPool.symbol[borrowableIndex]} Supply Rewards
            </span>
            }
          </div>
        </div>
        <div className={`flex flex-col items-center justify-start mb-4 space-y-2 ${isDashboard ? 'mt-6' : 'mt-2'} border rounded-lg border-tarotLilac-700 bg-tarotLilac-850 p-4`}>
          <div className='combined z-10 mb-2'>
            <div className='w-12 h-12 justify-center items-center flex transform-gpu'>
              <TarotImage
                width={48}
                height={48}
                className={clsx(
                  'inline-block',
                  'rounded-full'
                )}
                src={`/assets/images/token-icons/${getAddress(isSupplyVault ? tokenForTowerPool : underlyingForTokenForTowerPool)}.png`}
                placeholder='/assets/images/default.png'
                error='/assets/images/default.png'
                alt={isSupplyVault ? supplyVaultInfo.symbol : `${lendingPool.symbol[borrowableIndex]} bTAROT`} />
            </div>
          </div>
          <div className='text-center text-lg text-textPrimary'>{isSupplyVault ? `Stake ${supplyVaultInfo.symbol}, Earn OP` : 'Stake bTAROT, Earn Rewards'}
            {rewardInfoList.filter((r: any) => r.periodFinish.gt(BigNumber.from(Math.floor(Date.now() / 1000)))).length > 0 &&
            <QuestionHelper
              text={
                <div className='flex flex-col text-sm space-y-1'>
                  <div className='text-textSecondary'>Current Rewards:</div>
                  {rewardInfoList.filter((r: any) => r.periodFinish.gt(BigNumber.from(Math.floor(Date.now() / 1000)))).map((rewardInfo: any, i: number) =>
                    (
                      <div
                        key={`r-${i}`}
                        className='flex justify-between'>
                        <div className='text-textSecondary text-sm'>{rewardInfo.rewardTokenSymbol}{`\u00A0`}<TimeLeft periodFinish={rewardInfo.periodFinish} /></div>
                      </div>
                    )
                  )}

                </div>
              } />}
          </div>
        </div>
        {!isDashboard &&
        <>
          <div className='mt-2 flex flex-col items-center justify-end mb-4'>
            <div className='text-base sm:text-lg'>Staked TVL</div>
            <div className='text-lg sm:text-xl text-textPrimary flex items-start'><div className='text-lg -mt-0.5'>$</div>{formatAmount(totalStakedUSD)}</div>
          </div>

          <div className='flex flex-col items-center justify-end mb-6'>
            <div className='text-base sm:text-lg'>APR Estimate
              <QuestionHelper
                text={
                  <div className='flex flex-col text-sm space-y-1'>
                    {isSupplyVault ?
                      <div className='flex justify-between'>
                        <div className='text-textSecondary'>{supplyVaultInfo.symbol}{`\u00A0`}Base{`\u00A0`}APR:</div>
                        <div>{formatPercentage(parseFloat(formatUnits(supplyVault.supplyRate.amount.mul(365 * 24 * 60 * 60))))}</div>
                      </div> :
                      <div className='flex justify-between'>
                        <div className='text-textSecondary'>bTAROT{`\u00A0`}Base{`\u00A0`}APR:</div>
                        <div>{formatPercentage(lendingPool.supplyAPR[borrowableIndex])}</div>
                      </div>
                    }
                    {rewardInfoList.filter((r: any) => r.periodFinish.gt(BigNumber.from(Math.floor(Date.now() / 1000)))).map((rewardInfo: any, i: number) =>
                      (
                        <div
                          key={`r-${i}`}
                          className='flex justify-between'>
                          <div className='text-textSecondary text-sm'>{rewardInfo.rewardTokenSymbol}{`\u00A0`}Rewards:</div>
                          <div>{towerPoolInfo.totalSupply.gt(0) ? formatPercentage(parseFloat(formatUnits(rewardAPRs[i], 18))) : '-'}</div>
                        </div>
                      )
                    )}

                  </div>
                } />
            </div>
            <div className='text-xl sm:text-2xl flex items-start text-green-600 font-bold'>{formatPercentageShort(totalAPR, '')}<div className='text-lg -mt-0.5'>%</div></div>
          </div>

        </>
        }
        {(isDashboard || web3ChainId) &&
      <>
        <div className={`!mb-0 flex flex-col items-stretch justify-around space-y-4 w-full ${isDashboard ? 'mt-2' : 'mt-4 p-6 py-6 border border-tarotBlackHaze-600 rounded-lg bg-tarotBlackHaze-800'}`}>
          <div className='flex flex-col items-center'>
            <div className='text-base sm:text-lg text-center'>{isSupplyVault ? `Staked ${supplyVaultInfo.symbol}` : `Staked bTAROT (${lendingPool.symbol[borrowableIndex]})`}</div>
            <div
              className='text-base sm:text-lg text-textPrimary items-center flex flex-grow text-center'
              title={formatUnits(accountBalance, isSupplyVault ? supplyVaultInfo.decimals : 18)}>{isSupplyVault ? formatAmount(parseFloat(formatUnits(accountBalance, supplyVaultInfo.decimals))) : formatAmount(parse18(accountBalance.mul(lendingPool[`borrowableExchangeRate${borrowableIndex}`]).div(BigNumber.from(10).pow(lendingPool.lendingPool[`decimals${borrowableIndex}`]))))}{`\u00A0`}
              <TarotImage
                width={16}
                height={16}
                className={clsx(
                  'inline-block',
                  'rounded-full'
                )}
                src={`/assets/images/token-icons/${getAddress(isSupplyVault ? tokenForTowerPool : underlyingForTokenForTowerPool)}.png`}
                placeholder='/assets/images/default.png'
                error='/assets/images/default.png'
                alt={isSupplyVault ? supplyVaultInfo.symbol : `${lendingPool.symbol[borrowableIndex]} bTAROT`} />
            </div>
            {!isDashboard &&
          <div
            className='text-base sm:text-lg text-textSecondary items-center flex flex-grow text-center'
            title={`$${accountBalanceUSD}`}>(<div className='text-base -mt-0.5'>$</div>{formatAmount(accountBalanceUSD)})
          </div>
            }
          </div>
          <div className='flex flex-col items-center'>
            <div className='text-base sm:text-lg text-center'>Claimable Rewards</div>
            <div className='flex items-center justify-center flex-col'>
              {earnedList.map((earned: any, i: number) =>
                <div key={`e-${i}`}>
                  <div
                    key={`e-${i}`}
                    className='text-base sm:text-lg text-textPrimary items-center flex flex-grow text-center'
                    title={formatUnits(earned, rewardInfoList[i].rewardTokenDecimals)}>
                    {formatAmount(parseFloat(formatUnits(earned, rewardInfoList[i].rewardTokenDecimals)))}
                    {`\u00A0`}
                    <TarotImage
                      width={16}
                      height={16}
                      className={clsx(
                        'inline-block',
                        'rounded-full'
                      )}
                      src={`/assets/images/token-icons/${getAddress(rewardInfoList[i].rewardTokenAddress)}.png`}
                      placeholder='/assets/images/default.png'
                      error='/assets/images/default.png'
                      alt={rewardInfoList[i].rewardTokenSymbol} />
                  </div>
                </div>
              )}
            </div>
            {!isDashboard &&
          <div
            className='text-base sm:text-lg text-textSecondary items-center flex flex-grow text-center'
            title={`$${totalPendingRewardsForAccountUSD}`}>(<div className='text-base -mt-0.5'>$</div>{formatAmount(totalPendingRewardsForAccountUSD)})
          </div>
            }
          </div>
          {!isDashboard && web3ChainId &&
        <>
          <div className='mt-4 space-x-4 md:space-x-6 flex flex-row justify-around'>
            <div className='flex-grow'></div>
            <InteractionButton
              className='text-sm xs:text-base bg-tarotViolet-500'
              name='Claim Rewards'
              onCall={onClaimReward}
              state={claimRewardState} />
            <div className='flex-grow'></div>
          </div>
          <div className='mt-4 space-x-4 md:space-x-6 flex flex-row justify-around'>
            <div className='flex-grow'></div>
            <InteractionButton
              className='text-sm xs:text-base bg-tarotViolet-500'
              name='Stake'
              onCall={() => toggleStakeModal(true)}
              state={ButtonState.Ready} />
            <InteractionButton
              className='text-sm xs:text-base bg-tarotViolet-500'
              name='Unstake'
              onCall={() => toggleUnstakeModal(true)}
              state={ButtonState.Ready} />
            <div className='flex-grow'></div>
          </div>
        </>
          }
          {isDashboard &&
        <>
          <div
            className={clsx(
              'mt-3',
              'col-span-4 flex',
              'flex-col',
              'justify-between',
              '-mx-2',
              'p-2',
              'py-3',
              'border',
              'border-tarotBlackHaze-200',
              'rounded-lg',
              'bg-tarotBlackHaze-600',
              'space-y-3'
            )}>
            <div className='text-lg self-center justify-self-start'>
              Total Staked Value
            </div>
            <div
              className={clsx(
                'self-center',
                'justify-self-end',
                'text-2xl',
                'font-semibold',
                'text-textPrimary',
                'items-center',
                'flex',
                'flex-col',
                'mt-2',
                'space-y-2'
              )}>
              <div
                title={formatUSD(accountBalanceUSD)}
                className='self-center'>
                {formatUSDShort(accountBalanceUSD)}
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center justify-end'>
            <div className='text-base sm:text-lg'>
              APR Estimate
              <QuestionHelper
                text={
                  <div className='flex flex-col text-sm space-y-1'>
                    {isSupplyVault ?
                      <div>Vault APR</div> :
                      <div className='flex justify-between'>
                        <div className='text-textSecondary'>Supply APR:</div>
                        <div>{formatPercentage(lendingPool.supplyAPR[borrowableIndex])}</div>
                      </div>
                    }
                    {rewardInfoList.map((rewardInfo: any, i: number) =>
                      (
                        <div
                          key={`r-${i}`}
                          className='flex justify-between'>
                          <div className='text-textSecondary'>{rewardInfo.rewardTokenSymbol} Reward APR:</div>
                          <div>{towerPoolInfo.totalSupply.gt(0) ? formatPercentage(parseFloat(formatUnits(rewardAPRs[i], 18))) : '-'}</div>
                        </div>
                      )
                    )}

                  </div>
                } />
            </div>
            <div className='text-xl sm:text-2xl flex items-start text-green-600 font-bold'>{formatPercentageShort(totalAPR, '')}<div className='text-lg -mt-0.5'>%</div></div>
          </div>
        </>
          }
        </div>
      </>
        }
      </div>
      {!isDashboard && web3ChainId &&
      <>
        <TowerStakeInteractionModal
          show={showStakeModal}
          stakeSymbol={isSupplyVault ? supplyVaultInfo.symbol : 'bTAROT'}
          towerPoolAddress={towerPoolInfo.towerPool}
          stakeAddress={towerPoolInfo.tokenForTowerPool}
          toggleShow={toggleStakeModal} />
        <TowerUnstakeInteractionModal
          show={showUnstakeModal}
          towerPoolAddress={towerPoolInfo.towerPool}
          stakedSymbol={isSupplyVault ? supplyVaultInfo.symbol : 'bTAROT'}
          stakedBalance={accountBalance}
          stakedDecimals={isSupplyVault ? supplyVaultInfo.decimals : BigNumber.from(18)}
          toggleShow={toggleUnstakeModal} />
      </>
      }
    </>
  );
};

export default TowerPoolCard;
