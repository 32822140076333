
import { CHAIN_IDS } from 'config/web3/chains';

const TOKEN_INFO: {
  [chainId: number]: {
    [tokenToBridge: string]: {symbol: string}
  }
} = {
  [CHAIN_IDS.FANTOM]: {
    '0xc5e2b037d30a390e62180970b3aa4e91868764cd': {
      symbol: 'TAROTv1'
    },
    '0xfb98b335551a418cd0737375a2ea0ded62ea213b': {
      // miMATIC -> MAI
      symbol: 'MAI'
    },
    '0xc165d941481e68696f43ee6e99bfb2b23e0e3114': {
      // OXD -> OXDv1
      symbol: 'OXDv1'
    }
  },
  [CHAIN_IDS.OPTIMISM]: {
    '0x375488f097176507e39b9653b88fdc52cde736bf': {
      symbol: 'TAROTv1'
    },
    '0x4200000000000000000000000000000000000006': {
      // WETH -> ETH
      symbol: 'ETH'
    },
    '0x68f180fcce6836688e9084f035309e29bf0a2095': {
      // WBTC -> BTC
      symbol: 'BTC'
    },
    '0x7f5c764cbc14f9669b88837ca1490cca17c31607': {
      // USDC -> USDC.e
      symbol: 'USDC.e'
    }
  },
  [CHAIN_IDS.ARBITRUM]: {
    '0x6688b00f0c23a4a546beaae51a7c90c439895d48': {
      symbol: 'TAROTv1'
    },
    '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': {
      // WETH -> ETH
      symbol: 'ETH'
    },
    '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': {
      // WBTC -> BTC
      symbol: 'BTC'
    },
    '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': {
      // USDC -> USDC.e
      symbol: 'USDC.e'
    }
  },
  [CHAIN_IDS.BSC]: {
    '0xa8cd6e4bf45724d3ac27f9e31e47ba4e399a7b52': {
      symbol: 'TAROTv1'
    }
  },
  [CHAIN_IDS.ETHEREUM_MAIN_NET]: {
    '0x837d904a3799c0769079be9ecbaddf1abd4ccd6e': {
      symbol: 'TAROTv1'
    }
  },
  [CHAIN_IDS.KAVA]: {
    '0x165dbb08de0476271714952c3c1f068693bd60d7': {
      symbol: 'TAROTv1'
    }
  },
  [CHAIN_IDS.CANTO]: {
    '0x2bf9b864cdc97b08b6d79ad4663e71b8ab65c45c': {
      symbol: 'TAROTv1'
    }
  },
  [CHAIN_IDS.AVAX]: {
    '0x7f8d31a49212c62a11b6718aa0b612e129256553': {
      symbol: 'TAROTv1'
    }
  },
  [CHAIN_IDS.POLYGON]: {
    '0xd23ed8ca350ce2631f7ecdc5e6bf80d0a1debb7b': {
      symbol: 'TAROTv1'
    }
  },
  [CHAIN_IDS.ZKSYNC]: {
    '0x4c3861906b24a72adc944798c22cc450443a40ac': {
      symbol: 'TAROTv1'
    }
  },
  [CHAIN_IDS.BASE]: {
  },
  [CHAIN_IDS.LINEA]: {
  },
  [CHAIN_IDS.SCROLL]: {
  }
};

export {
  TOKEN_INFO
};
