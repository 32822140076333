
import { CHAIN_IDS } from 'config/web3/chains';

const WETH_ADDRESSES: {
  [chainId: number]: string;
} = {
  [CHAIN_IDS.FANTOM]: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  [CHAIN_IDS.OPTIMISM]: '0x4200000000000000000000000000000000000006',
  [CHAIN_IDS.ARBITRUM]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  [CHAIN_IDS.BSC]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  [CHAIN_IDS.KAVA]: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
  [CHAIN_IDS.CANTO]: '0x826551890Dc65655a0Aceca109aB11AbDbD7a07B',
  [CHAIN_IDS.AVAX]: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  [CHAIN_IDS.POLYGON]: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  [CHAIN_IDS.ZKSYNC]: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
  [CHAIN_IDS.BASE]: '0x4200000000000000000000000000000000000006',
  [CHAIN_IDS.LINEA]: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
  [CHAIN_IDS.SCROLL]: '0x5300000000000000000000000000000000000004'
};

export {
  WETH_ADDRESSES
};
